import { UserService, AdminService } from '../services'
import { useSelector } from 'react-redux'
import reduxStore from '../store'
import { useSnackbar } from '../helpers/snackbar-helper'
import { getObjectKey } from './model-helper'
import { trackPromise } from 'react-promise-tracker'
import { dynamicImageSrcRoot, isNil, updateFontFamily } from './helpers'
import { TYPE_PAYMENT_CONFIG_MODE } from '../const/const'

const DEFAULT_FONT_FAMILY_ID =
    'http://www.inmindcloud.com/application/schema.owl#PortalFontFamilyOption1'

const TYPE_PORTAL_FONT_FAMILY =
    'http://www.inmindcloud.com/application/schema.owl#PortalFontFamily'

const SETTING_COMMERCE_INQUIRY_ENABLED = 'SettingCommerceInquiryEnabled'

const getErrorMessageToDisplay = (axioErrorResponse) => {
    let out = ''

    if (axioErrorResponse) {
        let detail = getObjectKey(axioErrorResponse, 'response/data/detail')
        let message = getObjectKey(axioErrorResponse, 'message')
        out = detail || message || ''
    }

    return out
}

const initialAdminData = async () => {
    let promise = new Promise((resolve, reject) => {
        let promises = []
        promises.push(
            UserService.getMyUserDetail(),
            AdminService.getInitialSettingData(),
            AdminService.getAllLanguages(),
            AdminService.getMasterData({
                typeid: TYPE_PORTAL_FONT_FAMILY,
            }),
            AdminService.getMasterData({
                typeid: TYPE_PAYMENT_CONFIG_MODE,
            })
        )

        Promise.all(promises)
            .then(
                ([
                    userData,
                    portalSettingData,
                    languageMasterData,
                    portalFontFamilyMasterData,
                    portalPaymentConfigData,
                ]) => {
                    const updatedFontFamilyData = updateFontFamily(portalFontFamilyMasterData);
                    resolve({
                        userData,
                        portalSettingData,
                        languageMasterData,
                        portalFontFamilyMasterData: updatedFontFamilyData,
                        portalPaymentConfigData,
                    })
                }
            )
            .catch((error) => {
                reject()
            })
    })

    return promise
}

const createBRATemplate = (o) => {
    let merge = {}
    let template = {
        attribute: {
            fragment: '',
        },
        sequence: 0,
        label: '',

        required: false,
        oldValue: [],
        value: [],
        initialValue: [],
    }

    merge = Object.assign(template, o)

    return merge
}

const createBDATemplate = (o) => {
    let merge = {}
    let template = {
        attributeFragment: '',
        attributeLabel: '',
        sequence: 0,
        label: '',
        valuetype: '',
        required: false,
        oldValue: [],
        value: [],
        initialValue: [],
    }

    merge = Object.assign(template, o)
    merge = populateEmptyValue(merge)
    merge = populateInitialAndOldValue(merge)
    merge = assignLabel(merge)
    return merge
}

const assignLabel = (o) => {
    function getCustomLabel(key) {
        let label = ''

        switch (key) {
            case 'portalAddress1':
                label = 'Address 1'
                break
            case 'portalAddress2':
                label = 'Address 2'
                break
            case 'portalAddress3':
                label = 'Address 3'
                break
            case 'portalAddress4':
                label = 'Address 4'
                break
            case 'portalPhoneNumber':
                label = 'Phone Number'
                break

            case 'portalFaxNumber':
                label = 'Fax Number'
                break
            case 'portalEmailAddress':
                label = 'Email'
                break
        }

        return label
    }

    o.label = getCustomLabel(o.attributeFragment)

    return o
}

const populateEmptyValue = (BDATemplate) => {
    let valuetype = BDATemplate.valuetype
    let emptyValue
    if (Array.isArray(BDATemplate.value) && !BDATemplate.value.length) {
        switch (valuetype) {
            case 'string':
                emptyValue = ''
                break
            case 'numeric':
                emptyValue = null
                break
            case 'boolean':
                emptyValue = null
        }

        BDATemplate.value = emptyValue
    }

    return BDATemplate
}

const populateInitialAndOldValue = (BDATemplate) => {
    BDATemplate.oldValue = BDATemplate.value
    BDATemplate.initialValue = BDATemplate.value

    return BDATemplate
}

const isValueDifferentFromOldValue = (BDATemplate) => {
    let bDifferent = false

    if (BDATemplate.value !== BDATemplate.oldValue) {
        bDifferent = true
    }

    return bDifferent
}

const isValueDifferentFromInitialValue = (BDATemplate) => {
    let bDifferent = false

    if (BDATemplate.value !== BDATemplate.initialValue) {
        bDifferent = true
    }

    return bDifferent
}

const isMultipleIDValueDifferentFromOldValue = (BRATemplate) => {
    let bDifferent = false

    if (BRATemplate.value.length !== BRATemplate.oldValue.length)
        bDifferent = true

    BRATemplate.value.forEach((singleValue = '', index) => {
        if (BRATemplate.oldValue.indexOf(singleValue) !== index) {
            bDifferent = true
        }
    })

    return bDifferent
}

const useNotification = (text, param) => {
    const { enqueueSnackbar } = useSnackbar()

    let incomingSuccess = (text, param) => {
        enqueueSnackbar(text, { variant: 'success' })
    }

    let incomingError = (text, param) => {
        enqueueSnackbar(text, { variant: 'error' })
    }

    return {
        success: incomingSuccess,
        error: incomingError,
    }
}

//conver the dto into array with locale as key/id
const parseBannerTableMetaInfoTemplate = (textMetaInfo, buttonTextInfo) => {
    let result = []

    result = textMetaInfo.map((o) => {
        //add buttonText

        for (let i = 0; i < buttonTextInfo.length; i++) {
            let buttonTextInfoObject = buttonTextInfo[i]
            if (o.id === buttonTextInfoObject.id) {
                o.buttonLabelValue = buttonTextInfoObject.labelValue
                o.buttonLabelOldValue = buttonTextInfoObject.labelOldValue
                o.buttonLabelInitialValue =
                    buttonTextInfoObject.labelInitialValue
                break
            }
        }
        return o
    })

    return result
}

//always send all values
const getBannerTableMetaInfoDto = (metaInfoTemplate) => {
    let labelDtoObject = {}
    let commentDtoObject = {}
    let buttonLabelObject = {}

    metaInfoTemplate.forEach((o) => {
        labelDtoObject[o.id] = o.labelValue

        commentDtoObject[o.id] = o.commentValue
        buttonLabelObject[o.id] = o.buttonLabelValue
    })

    let out = {
        label: labelDtoObject,
        comment: commentDtoObject,
        buttonLabel: buttonLabelObject,
    }

    return out
}

const defaultSrc = '/favicon.png'

const useSetAdminFavicon = () => {
    const portalSettingData = useSelector((state) => {
        return state.adminCustomisationReducers.portalSettingData
    })

    let allMediaFile = []
    let faviconDefaultSrc = defaultSrc

    if (
        !isNil(portalSettingData) &&
        !isNil(portalSettingData.includesPortalMediaFile)
    ) {
        allMediaFile = portalSettingData.includesPortalMediaFile.value
    }

    const [faviconData] = allMediaFile.filter((o) => {
        if (
            o.uri ===
            'http://www.inmindcloud.com/application/schema.owl#PortalFavouriteLogo'
        ) {
            return o
        }
    })

    let finalSrcPath = process.env.PUBLIC_URL + faviconDefaultSrc

    if (faviconData && faviconData.mediaFilePath) {
        finalSrcPath = dynamicImageSrcRoot(faviconData.mediaFilePath.value)
    }

    let faviconDOM = document.getElementById('favicon')
    if (faviconDOM) {
        faviconDOM.href = finalSrcPath
    }
}

const useSetAdminDocumentTitle = ({ append = '' }) => {
    const portalSettingData = useSelector((state) => {
        return state.adminCustomisationReducers.portalSettingData
    })

    const documentTitleBrand =
        portalSettingData.portalCompanyName &&
            portalSettingData.portalCompanyName.value
            ? portalSettingData.portalCompanyName.value
            : ''

    let finalDisplayBrand = '...'
    if (documentTitleBrand) {
        finalDisplayBrand = documentTitleBrand
    }

    if (append) {
        document.title = finalDisplayBrand + ' - ' + append
    } else {
        document.title = finalDisplayBrand
    }
}

const useGetAdminLogoSrc = () => {
    var displayLogoSrc = {}

    const portalSettingData = useSelector((state) => {
        return state.adminCustomisationReducers.portalSettingData
    })

    if (!isNil(portalSettingData.includesPortalMediaFile)) {
        portalSettingData.includesPortalMediaFile.value.forEach((o) => {
            if (
                o.uri ===
                'http://www.inmindcloud.com/application/schema.owl#PortalMediaFileCorporateLogo'
            ) {
                if (o && o.mediaFilePath)
                    displayLogoSrc = dynamicImageSrcRoot(o.mediaFilePath.value)
                else displayLogoSrc = process.env.PUBLIC_URL + '/logo.png'
            }
        })
    } else {
        displayLogoSrc = process.env.PUBLIC_URL + '/logo.png'
    }

    return displayLogoSrc
}

const useGetAdminBrandName = () => {
    var brandName = ''
    const portalSettingData = useSelector((state) => {
        return state.adminCustomisationReducers.portalSettingData
    })

    brandName = !isNil(portalSettingData.portalCompanyName)
        ? portalSettingData.portalCompanyName.value
        : ''

    return brandName
}

//conver the dto into array with locale as key/id
const parseMetaInfoTemplate = (metaInfoDto) => {
    let result = []

    const state = reduxStore.getState()
    const brandLanguages = state.adminCustomisationReducers.brandLanguages

    //extract l10nkey from store definition for now
    brandLanguages.map((language) => {
        let templateShape = {
            entityId: metaInfoDto.id,
            entityType: metaInfoDto.type,
            entityUri: metaInfoDto.uri,
            localeKey: language.id,
            id: language.id,
            text: language.label,
            labelValue: '',
            labelOldValue: '',
            labelInitialValue: '',
            commentValue: '',
            commentOldValue: '',
            commentInitialValue: '',
        }
        result.push(templateShape)
    })

    if (metaInfoDto.label && Object.keys(metaInfoDto.label).length) {
        for (let key in metaInfoDto.label) {
            let value = metaInfoDto.label[key] || ''

            for (let i = 0; i < result.length; i++) {
                if (result[i].localeKey === key) {
                    result[i].labelValue = value
                    result[i].labelOldValue = value
                    result[i].labelInitialValue = value
                    break
                }
            }
        }
    }

    if (metaInfoDto.comment && Object.keys(metaInfoDto.comment).length) {
        for (let key in metaInfoDto.comment) {
            let value = metaInfoDto.comment[key] || ''

            for (let i = 0; i < result.length; i++) {
                if (result[i].localeKey === key) {
                    result[i].commentValue = value
                    result[i].commentOldValue = value
                    result[i].commentInitialValue = value
                    break
                }
            }
        }
    }

    return result
}

//always send all values
const getMetaInfoDto = (sourceDto, metaInfoTemplate) => {
    let labelDtoObject = {}
    let commentDtoObject = {}

    metaInfoTemplate.forEach((o) => {
        labelDtoObject[o.id] = o.labelValue

        commentDtoObject[o.id] = o.commentValue
    })

    sourceDto.label = labelDtoObject
    sourceDto.comment = commentDtoObject

    return sourceDto
}

const customizableIconTypes = ['addToCart', 'search', 'visualProductFinder', 'copy', 'addToCartAndConfigure', 'configureFromCheckoutScreeen', 'orderHistory',
    'favorites', 'bomArrow', 'configureVariant', 'sparePartFinder'];

const customizableDefaultImagesTypes = ['category', 'product', 'equipment'];

export {
    initialAdminData,
    createBDATemplate,
    createBRATemplate,
    useNotification,
    isValueDifferentFromOldValue,
    isMultipleIDValueDifferentFromOldValue,
    isValueDifferentFromInitialValue,
    parseMetaInfoTemplate,
    parseBannerTableMetaInfoTemplate,
    getMetaInfoDto,
    getBannerTableMetaInfoDto,
    DEFAULT_FONT_FAMILY_ID,
    getErrorMessageToDisplay,
    useSetAdminFavicon,
    useSetAdminDocumentTitle,
    useGetAdminLogoSrc,
    useGetAdminBrandName,
    customizableIconTypes,
    customizableDefaultImagesTypes
}
