import React, { useState, useEffect } from 'react'
import {
    Card,
    CardContent,
    Grid,
    Box,
    Typography,
    Fade,
    CircularProgress,
    Hidden,
    CssBaseline,
    IconButton,
} from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import {
    useGetDefaultLogoSrc,
    useGetDefaultLoginViewBannerSrc,
    useGetDefaultFaviconSrc,
    dynamicImageSrcRoot,
    useGetDefaultBrandName,
    getCssBackgroundImageValue,
} from '../../helpers/helpers'
import { useSelector, useDispatch } from 'react-redux'
import {
    makeStyles,
    createMuiTheme,
    ThemeProvider,
} from '@material-ui/core/styles'
import './login.scss'
import LoginForm from '../../components/login-form'
import {
    logOutUserSession,
    logInUserSession,
} from '../../store/user/user-action-types'
import customisingThemeTemplate from '../../theme/customising/theme'
import {
    updateLogoSrc,
    updateLoginViewBannerSrc,
    updateFavIconSrc,
} from '../../store/customisation/customisation-actions'

import theme from '../../theme/salesPortal/theme'
import { AuthenticationService, CustomisationService } from '../../services'
import { SnackbarProvider } from 'notistack'
import CloseIcon from '@material-ui/icons/CloseSharp'

export const useStyles = makeStyles((systemDefaultTheme) => ({
    root: {
        flexGrow: 1,
    },
    gridContainer: {
        height: '100%',
    },
    contentContainer: {
        marginTop: systemDefaultTheme.spacing(3),
        marginBottom: systemDefaultTheme.spacing(3),
    },
    formBox: {
        borderRadius: '.5rem',
        padding: systemDefaultTheme.spacing(2),
    },
    formBackgroud: {
        backgroundColor: theme.palette.background.default
    },
    snackbarSuccess: { backgroundColor: customisingThemeTemplate.palette.success.main },
    snackbarError: { backgroundColor: customisingThemeTemplate.palette.error.main },
    snackbarWarning: { backgroundColor: customisingThemeTemplate.palette.warning.main },
    snackbarInfo: { backgroundColor: customisingThemeTemplate.palette.info.main },
}))

export const LoginViewBanner = (props) => {
    const customisingloginBannerSrc = props.customisingloginBannerSrc
    const [imageLoaded, setImageLoaded] = useState(false)
    const [backgroundBannerStyle, setBackgroundBannerStyle] = useState({})
    const [progressStyle, setProgressStyle] = useState({
        position: 'absolute',
        zIndex: 1,
    })

    const defaultBannerSrc = useGetDefaultLoginViewBannerSrc()
    const [finalSrc, setFinalSrc] = useState('')

    useEffect(() => {
        if (customisingloginBannerSrc === null) return

        if (customisingloginBannerSrc)
            setFinalSrc(dynamicImageSrcRoot(customisingloginBannerSrc))
        else {
            setFinalSrc(process.env.PUBLIC_URL + defaultBannerSrc)
        }
    }, [customisingloginBannerSrc])

    const handleImageLoad = (event) => {
        setImageLoaded(true)
        setBackgroundBannerStyle({
            backgroundImage: getCssBackgroundImageValue(finalSrc),
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right',
        })
    }

    const exitTimeout = 2000 //ms

    let ignore = false
    useEffect(() => {
        if (!ignore) {
            if (imageLoaded)
                setTimeout(() => {
                    if (!ignore) {
                        setProgressStyle({ ...progressStyle, display: 'none' })
                    }
                }, exitTimeout)
        }
        return () => {
            ignore = true
        }
    }, [imageLoaded])

    return (
        <>
            <Fade in={!imageLoaded} timeout={{ exit: exitTimeout }}>
                <CircularProgress style={progressStyle} />
            </Fade>
            <Fade in={imageLoaded} timeout={{ enter: 600, appear: 500 }}>
                <Box height="100%" width="100%" style={backgroundBannerStyle}>
                    <img
                        src={finalSrc}
                        style={{ display: 'none' }}
                        onLoad={handleImageLoad}
                    />
                </Box>
            </Fade>
        </>
    )
}

export const LogoBox = (props) => {
    let customisingCompanyLogoSrc = props.customisingCompanyLogoSrc

    const defaultLogoSrc = useGetDefaultLogoSrc()
    const [finalSrc, setFinalSrc] = useState('')

    useEffect(() => {
        if (customisingCompanyLogoSrc === null) return
        if (customisingCompanyLogoSrc)
            setFinalSrc(dynamicImageSrcRoot(customisingCompanyLogoSrc))
        else {
            setFinalSrc(defaultLogoSrc)
        }
    }, [customisingCompanyLogoSrc])

    return (
        <Box style={{ marginBottom: theme.spacing(4) }}>
            <img src={finalSrc} style={{ width: '100%' }} />
        </Box>
    )
}

function LoginMain(props) {
    const { customTheme, customisingloginBannerSrc, backgroundColor, customisingCompanyLogoSrc, redirectURL } = props

    const classes = useStyles()
    const dispatch = useDispatch()

    const authenticateSuccess = () => {
        let oAuthTokenValue = AuthenticationService.getLocalStorageOAuthTokenValue()
        let userName = AuthenticationService.getLoggedInUserName()
        dispatch(
            logInUserSession({
                oAuthToken: oAuthTokenValue,
                userName: userName,
            })
        )

        if (redirectURL) props.history.push(redirectURL);
        else props.history.push('/');
    }

    // add action to all snackbars
    const notistackRef = React.createRef()
    const onClickDismiss = (key) => () => {
        notistackRef.current.closeSnackbar(key)
    }

    return (
        <ThemeProvider theme={customTheme}>
            <SnackbarProvider
                ref={notistackRef}
                maxSnack={1}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                autoHideDuration={5000}
                classes={{
                    variantSuccess: classes.snackbarSuccess,
                    variantError: classes.snackbarError,
                    variantWarning: classes.snackbarWarning,
                    variantInfo: classes.snackbarInfo,
                }}
                action={(key) => (
                    <IconButton onClick={onClickDismiss(key)}>
                        <CloseIcon style={{ color: '#ffffff' }} />
                    </IconButton>
                )}
            >
                <CssBaseline />
                <Box height="100%" className={classes.root}>
                    <Grid
                        container
                        item
                        xs={12}
                        className={classes.gridContainer}
                    >
                        <Hidden smDown>
                            <Grid
                                container
                                item
                                xs={false}
                                sm={false}
                                md={7}
                                alignItems="center"
                                justify="center"
                            >
                                <LoginViewBanner
                                    customisingloginBannerSrc={
                                        customisingloginBannerSrc
                                    }
                                />
                            </Grid>
                        </Hidden>
                        <Grid
                            container
                            item
                            xs={12}
                            md={5}
                            alignItems="center"
                            justify="center"
                            style={{ backgroundColor: backgroundColor }}
                        >
                            <Grid item xs={1} sm={2} lg={3}></Grid>
                            <Grid
                                item
                                xs={10}
                                sm={8}
                                lg={6}
                                className={classes.contentContainer}
                            >
                                <Grid container>
                                    <Grid item xs={5}>
                                        <LogoBox
                                            customisingCompanyLogoSrc={
                                                customisingCompanyLogoSrc
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Fade in>
                                    <Card
                                        elevation={2}
                                        className={classes.formBox}
                                        style={{
                                            width: '400px',
                                            minHeight: '350px',
                                        }}
                                    >
                                        <CardContent
                                            style={{
                                                padding: theme.spacing(0),
                                            }}
                                        >
                                            <LoginForm
                                                showForgotPassword={true}
                                                showRememberMe={true}
                                                authenticateSuccess={
                                                    authenticateSuccess
                                                }
                                            ></LoginForm>
                                        </CardContent>
                                    </Card>
                                </Fade>
                            </Grid>
                            <Grid item xs={1} sm={2} lg={3}></Grid>
                        </Grid>
                    </Grid>
                </Box>
            </SnackbarProvider>
        </ThemeProvider>
    )
}

export default LoginMain
