import React from 'react';
import {useHistory} from 'react-router-dom';
import {Card, CardContent, Typography, useTheme, makeStyles, Box, Button} from '@material-ui/core';
import PlayListAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';

import {useStyles as useLoginStyles} from '../login/login-main';
import {TranslationService} from '../../services';

const useStyles = makeStyles(theme => ({
    card: {
        minWidth: '400px',
        minHeight: '290px' 
    },
    outerContainer: {
        margin: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    checkedIconContainer: {
        border: '4px solid',
        borderColor: theme.palette.primary.main,
        padding: theme.spacing(1),
        paddingBottom: '2px',
        borderRadius: '50%'
    },
    successTextBox: {
        '& > p': {
            textAlign: 'center',
            marginTop: theme.spacing(2),
            '&:first-child': {
                fontWeight: 'bold'
            }
        }
    },
    signInBtn: {
        marginTop: theme.spacing(2)
    }
}));

const SignupSuccess = () => {
    const theme = useTheme();
    const loginClasses = useLoginStyles();
    const classes = useStyles();
    const {t} = TranslationService.useTranslation();
    const history = useHistory();
    
    const checkedIcon = <Box className = {classes.checkedIconContainer}>
        <PlayListAddCheckIcon color = 'primary' fontSize = 'large'/>
    </Box>
    const successText = <Box className = {classes.successTextBox}>
        <Typography color = 'textPrimary'>{t('registration.general.almostDone')}</Typography>
        <Typography color = 'textPrimary'>{t('registration.general.emailSend')}</Typography>
    </Box>;
    const signInButton = <Button className = {classes.signInBtn} disableElevation size = 'small' fullWidth color = 'primary' variant = 'contained'
        onClick = {() => history.push('/login')}>
        {t('registration.general.backToSignIn')}
    </Button>
    return <Card className = {[loginClasses.formBox, classes.card]}>
            <CardContent style = {{padding: theme.spacing(0)}}>
                <Box className = {classes.outerContainer}>
                    {checkedIcon}
                    {successText}
                    {signInButton}
                </Box>
            </CardContent>
    </Card>;
};

export default SignupSuccess;