import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { UserService, TranslationService } from '../../../../services'
import {
    Grid,
    IconButton,
    Typography,
    TextField,
    CircularProgress,
    Button,
    Box,
} from '@material-ui/core'

import { ChevronLeft } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { useSnackbar } from '../../../../helpers'

const useStyles = makeStyles((theme) => ({
    input: {
        marginBottom: theme.spacing(2),
        fontSize: '1.125rem',
    },

    forgotPasswordMessage: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        maxHeight: '6rem',
        overflowY: 'auto',
    },
    forgotPasswordButton: {
        fontSize: '1.125rem',
        textTransform: 'none',
    },
    forgotPasswordSubmitEmailHelperText: {
        marginTop: theme.spacing(2),
    },
}))

const ForgotPasswordForm = (props) => {
    const { t } = TranslationService.useTranslation()
    const classes = useStyles()

    const validateAttribute = props.validateAttribute

    const [requestPasswordData, setRequestPasswordData] = useState({
        text: t('general.send'),
        loading: false,
        disabled: false,
    })
    const [requestPasswordFormData, setRequestPasswordFormData] = useState({
        userName: { value: '', required: true, error: false, helperText: '' },
    })

    const { enqueueSnackbar } = useSnackbar()

    const handleForgotPasswordUsernameInputChange = (event) => {
        let value = event.target.value
        let copyRequestPasswordFormData

        copyRequestPasswordFormData = {
            ...requestPasswordFormData,
            userName: { ...requestPasswordFormData.userName, value: value },
        }

        let data = validateAttribute('userName', copyRequestPasswordFormData)
            .data

        setRequestPasswordFormData(data)
    }

    //focus on the email input when props.show
    const forgotPasswordEmailInputRef = useRef()
    useEffect(() => {
        if (props.show) {
            setTimeout(() => {
                forgotPasswordEmailInputRef.current.focus()
            }, 200)
        }
    }, [props.show])

    const handleBackButtonClick = () => {
        props.onBackButtonClick()
    }

    const handleForgotPasswordRequestClick = (event) => {
        event.preventDefault()
        let helperSetRequestPasswordData = (
            loading = false,
            disabled = false,
            helperText = ''
        ) => {
            setRequestPasswordData({
                ...requestPasswordData,
                loading: loading,
                disabled: disabled,
                helperText: helperText,
            })
        }
        let response = validateAttribute(null, requestPasswordFormData)
        let errorArray = response.error

        setRequestPasswordFormData(response.data)

        if (errorArray.length) {
            //show error
            helperSetRequestPasswordData(false, false)
        } else {
            helperSetRequestPasswordData(true, true)

            UserService.forgotPassword({
                userName: requestPasswordFormData.userName.value,
            })
                .then((response) => {
                    console.log('RESPO');
                    let messageType = 'warning'
                    let message = ''
                    let success = false
                    if (response.data.Result === 'Wrong credentials') {
                        messageType = 'error'
                        message = t(
                            'general.forgotYourPasswordRetrieve.invalidUserName'
                        )
                    } else if (
                        response.data.Result === 'User Email not maintained'
                    ) {
                        messageType = 'error'
                        message = t(
                            'general.forgotYourPasswordRetrieve.userEmailNotMaintained'
                        )
                    } else if (response.data.Result.indexOf('sent an email') !== -1) {
                        success = true
                        messageType = 'success'
                        message = t(
                            'general.forgotYourPasswordRetrieve.emailSuccessSent'
                        )
                    }

                    enqueueSnackbar(message, {
                        variant: messageType,
                    })

                    helperSetRequestPasswordData(false, false, message)
                    if (success) handleBackButtonClick()
                })
                .catch(() => {
                    helperSetRequestPasswordData(
                        false,
                        false,
                        t('general.forgotYourPasswordRetrieve.errorMessage')
                    )
                })
        }
    }

    const ForgotPasswordSubmitEmailButton = () => {
        const ButtonContent = () => {
            if (requestPasswordData.loading) {
                return <CircularProgress color="secondary" size={20} />
            } else {
                return (
                    <Typography variant="body1">
                        {requestPasswordData.text}
                    </Typography>
                )
            }
        }
        return (
            <>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disableElevation
                    fullWidth
                    onClick={handleForgotPasswordRequestClick}
                    disabled={requestPasswordData.disabled}
                    className={classes.forgotPasswordButton}
                >
                    <ButtonContent />
                </Button>
            </>
        )
    }

    const ForgotPasswordSubmitEmailHelperText = () => {
        return (
            <Typography
                align="right"
                variant="body2"
                noWrap={true}
                color="error"
                className={classes.forgotPasswordSubmitEmailHelperText}
                title={requestPasswordData.helperText}
            >
                {requestPasswordData.helperText}
            </Typography>
        )
    }

    return (
        <Box style={{ position: 'relative' }}>
            <Grid container alignItems="center" justify="flex-start">
                <Grid container item xs={12} alignItems="center">
                    <IconButton onClick={handleBackButtonClick}>
                        <ChevronLeft />
                    </IconButton>
                    <Typography
                        variant="h2"
                        noWrap={true}
                        style={{ maxWidth: '84%' }}
                        title={t('general.forgotYourPasswordRetrieve.title')}
                    >
                        {t('general.forgotYourPasswordRetrieve.title')}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Typography
                        variant="body1"
                        className={classes.forgotPasswordMessage}
                        title={t('general.forgotYourPasswordRetrieve.message')}
                    >
                        {t('general.forgotYourPasswordRetrieve.message')}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <form noValidate autoComplete="off">
                        <TextField
                            id="forgotPassword.email"
                            autoFocus
                            inputRef={forgotPasswordEmailInputRef}
                            placeholder={t('general.username')}
                            label=""
                            variant="outlined"
                            size="small"
                            fullWidth
                            className={classes.input}
                            value={requestPasswordFormData.userName.value}
                            onChange={handleForgotPasswordUsernameInputChange}
                            error={requestPasswordFormData.userName.error}
                            helperText={
                                requestPasswordFormData.userName.helperText
                            }
                        />

                        <ForgotPasswordSubmitEmailButton></ForgotPasswordSubmitEmailButton>
                        <ForgotPasswordSubmitEmailHelperText />
                    </form>
                </Grid>
            </Grid>
        </Box>
    )
}

ForgotPasswordForm.propTypes = {
    onBackButtonClick: PropTypes.func,
    validateAttribute: PropTypes.func,
}

export default ForgotPasswordForm
