import { customisationReducers } from '../customisation/customisation-reducers'
import { userReducers } from '../user/user-reducers'
import signUpReducer from '../sign-up/signupReducer';
import { CartReducer } from '../cart/cart-reducers'
import { masterdataReducers } from '../masterdata/masterdata-reducers'
import { orderHistoryReducers } from '../order-history/order-history-reducers'
import { productReducers } from '../product/product-reducers'
import { searchReducers } from '../search/search-reducers'
import { adminCustomisationReducers } from '../admin-customisation/admin-customisation-reducers'
import {adminSalesinfoReducer} from '../admin-sales-info/admin-sales-info-reducer';
import { inquiryReducers } from '../inquiry/inquiry-reducers'
import variantReducer from '../variant-comparison/variant-reducer';
import sparePartFinderReducer from '../spare-part-finder/spare-part-finder-reducer';

import { combineReducers } from 'redux'

export default combineReducers({
    customisationReducers,
    userReducers,
    signUpReducer,
    orderHistoryReducers,
    productReducers,
    searchReducers,
    adminCustomisationReducers,
    masterdataReducers,
    inquiryReducers,
    adminSalesinfoReducer,
    variantReducer,
    sparePartFinderReducer
})
