export const UPDATE_LOGO_SRC = 'UPDATE_LOGO_SRC'
export const UPDATE_LOGIN_VIEW_BANNER_SRC = 'UPDATE_LOGIN_VIEW_BANNER_SRC'
export const UPDATE_NAVIGATION_ITEM = 'UPDATE_NAVIGATION_ITEM'
export const UPDATE_BRAND_NAME_VALUE = 'UPDATE_BRAND_NAME_VALUE'

export const UPDATE_COMPANY_SETTING = 'UPDATE_COMPANY_SETTING'
export const UPDATE_ADDRESS_INFORMATION = 'UPDATE_ADDRESS_INFORMATION'
export const UPDATE_CONTACT_INFORMATION = 'UPDATE_CONTACT_INFORMATION'
export const SET_INITIAL_PORTAL_SETTING_BDA = 'SET_INITIAL_PORTAL_SETTING_BDA'
export const UPDATE_BDA = 'UPDATE_BDA'
export const REMOVE_BRA = 'REMOVE_BRA'
export const ADD_BRA = 'ADD_BRA'
export const SET_BRA_VALUE = 'SET_BRA_VALUE'
export const UPDATE_BRA_VALUE = 'UPDATE_BRA_VALUE'
export const SET_BRAND_LANGUAGE = 'SET_BRAND_LANGUAGE'
export const SET_PORTAL_FONT_FAMILY = 'SET_PORTAL_FONT_FAMILY'
export const SET_PAYMENT_CONFIG_MODE = 'SET_PAYMENT_CONFIG_MODE'
export const SET_HAS_PAYMENT_CONFIG_MODE = 'SET_HAS_PAYMENT_CONFIG_MODE'
export const SET_PAYMENT_GATEWAY_DESTINATION = 'SET_PAYMENT_GATEWAY_DESTINATION'
export const SET_TOP_LEVEL_PROPERTY = 'SET_TOP_LEVEL_PROPERTY'

export const REMOVE_NESTED_BRA = 'REMOVE_NESTED_BRA'
export const ADD_NESTED_BRA = 'ADD_NESTED_BRA'
export const SET_NESTED_BRA_VALUE = 'SET_NESTED_BRA_VALUE'
export const SET_SCREEN_CONFIG_SECTION_FIELDS =
    'SET_SCREEN_CONFIG_SECTION_FIELDS'
export const SET_SCREEN_CONFIG_SECTION_SUB_SECTIONS =
    'SET_SCREEN_CONFIG_SECTION_SUB_SECTIONS'
export const SET_SCREEN_CONFIG_SECTION = 'SET_SCREEN_CONFIG_SECTION'
