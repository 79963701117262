import React from 'react';
import {TextField as MTextField, makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    disabledTextField: {
        backgroundColor: theme.palette.grey[400],
        color: '#000000'
    },
    helperText: {
        marginLeft: 0,
        marginRight: 0
    }
}));

const TextField = (props) => {
    const classes = useStyles();
    
    return <MTextField InputProps = {{
        classes: {disabled: classes.disabledTextField}
    }} className = {classes.signupTextField} FormHelperTextProps = {{className: classes.helperText}} color = 'primary' variant = 'outlined' size = 'small' fullWidth
     {...props}/>;
};

export default TextField;
