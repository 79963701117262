import React from 'react';
import PropTypes from 'prop-types';
import {Fade, Card, CardContent, makeStyles} from '@material-ui/core';

import SignupForm from './signup-form/SignupForm';
import {useStyles as useLoginStyles} from '../login/login-main';

const useStyles = makeStyles({
    signupFormCard: {
        minWidth: '400px',
        minHeight: '290px'
    }
});

const VerifyEmail = (props) => {
    const {theme} = props;
    const classes = useStyles();
    const loginClasses = useLoginStyles();

    return <Fade in>
            <Card elevation={2} className={`${loginClasses.formBox} ${classes.signupFormCard}`}>
                <CardContent style={{padding: theme.spacing(0)}}>
                    <SignupForm />
                </CardContent>
            </Card>
          </Fade>;
};

export default VerifyEmail;

VerifyEmail.propTypes = {
    theme: PropTypes.object
};
