import {
    REMOVE_ORDER_HISTORY,
    SET_ORDER_HISTORY,
    ADD_ORDER_HISTORY,
} from './order-history-action-types'

const initialState = {
    orderHistoryObject: [],
}

export const orderHistoryReducers = (state = initialState, action) => {
    switch (action.type) {
        case SET_ORDER_HISTORY:
            return Object.assign({}, state, {
                orderHistoryObject: action.payload,
            })
        case ADD_ORDER_HISTORY:
            return Object.assign({}, state, {
                orderHistoryObject: action.payload,
            })
        case REMOVE_ORDER_HISTORY:
            return Object.assign({}, state, {
                orderHistoryObject: action.payload,
            })
        default:
            return state
    }
}
