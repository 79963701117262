import store from 'store'
import { getImageURL } from './product-helper'
import {
    getObjectKey,
    productCategoryImageSrc,
    productImageSrc,
    getStringByKey,
    getSortedArray,
    isNilOrEmpty,
} from './helpers'
import { api } from '../services/ajax'
import { FavouriteService, OrderService } from '../services'
import reduxStore from '../store'
import { SUB_ENTITY_TYPE_PRODUCT_CATEGORY, SUB_ENTITY_TYPE_PRODUCT_MATERIAL_GROUP } from '../views/administration/util'

const AUTOSUGGEST_TYPE = {
    PRODUCT: 'PRODUCT',
    RECENT_SEARCH: 'RECENT_SEARCH',
    RECENT_SEARCH_AND_PRODUCT: 'RECENT_SEARCH_AND_PRODUCT',
    NONE: 'NONE',
}

const BOOLEAN_OPERATORS_TO_QUERY = {
    OR: ' or ',
    AND: ' and ',
}

const SEARCH_SETTING_GROUP_TYPE_FILTER = 'SEARCH_SETTING_GROUP_TYPE_FILTER'
const SEARCH_SETTING_GROUP_TYPE_SORTER = 'SEARCH_SETTING_GROUP_TYPE_SORTER'
const SEARCH_TYPE_OPTION = {
    EQUIPMENT: 'EQUIPMENT',
    GENERIC: 'GENERIC',
    CONSUMABLES: 'CONSUMABLES',
    SPARE_PARTS: 'SPARE_PARTS',
    PRODUCTS: 'PRODUCTS',
    ALL: 'ALL',
}

const SORT_ORDER = {
    ASC: 'ASC',
    DESC: 'DESC',
}
const SEARCH_TYPE_OPTION_TO_ID = {
    CONSUMABLES: 'http://www.inmindcloud.com/application/schema.owl#CONSUMABLE',
    SPARE_PARTS: 'http://www.inmindcloud.com/application/schema.owl#SPARE_PART',
    PRODUCTS: 'http://www.inmindcloud.com/application/schema.owl#PRODUCT',
    ALL:
        'http://www.inmindcloud.com/application/schema.owl#CONSUMABLE AND hasProductClassification eq http://www.inmindcloud.com/application/schema.owl#SPARE_PART AND hasProductClassification eq http://www.inmindcloud.com/application/schema.owl#PRODUCT',
}

const FILTER_TYPE = {
    FAVOURITE: 'FAVOURITE',
    PREVIOUSLY_ORDERED: 'PREVIOUSLY_ORDERED',
    PRODUCT_TYPE: 'PRODUCT_TYPE',
    EQUIPMENT_TYPE: 'EQUIPMENT_TYPE',
    PRODUCT_CATEGORY: 'PRODUCT_CATEGORY',
    PRODUCT_MATERIAL_GROUP: 'PRODUCT_MATERIAL_GROUP',
    CONSUMABLES: 'CONSUMABLES',
    SPAREPARTS: 'SPAREPARTS',
    PRODUCTS: 'PRODUCTS',
    PRODUCT_CLASSIFICATION: 'PRODUCT_CLASSIFICATION',
    SELECT_BY_CLASSIFICATION_TYPE: 'SELECT_BY_CLASSIFICATION_TYPE',
}

const SEARCH_TYPE_OPTION_TO_DEFAULT_RESTRICTION_FILTER = {
    LINKS_PRODUCT_CATEGORY_IS_NOT_NULL: 'linksProductCategory IS_NOT_NULL',
    HAS_PRODUCT_MATERIAL_GROUP_IS_NOT_NULL:
        'hasProductMaterialGroup IS_NOT_NULL',
    GENERIC:
        'hasProductClassification eq http://www.inmindcloud.com/application/schema.owl#SPARE_PART OR hasProductClassification eq http://www.inmindcloud.com/application/schema.owl#CONSUMABLE OR hasProductClassification eq http://www.inmindcloud.com/application/schema.owl#PRODUCT AND linksProductCategory IS_NOT_NULL',
    GENERIC_CONFIGURATOR_DISABLED:
        'hasProductClassification eq http://www.inmindcloud.com/application/schema.owl#SPARE_PART OR hasProductClassification eq http://www.inmindcloud.com/application/schema.owl#CONSUMABLE AND linksProductCategory IS_NOT_NULL',
    CONSUMABLES:
        'hasProductClassification eq http://www.inmindcloud.com/application/schema.owl#CONSUMABLE AND linksProductCategory IS_NOT_NULL',
    SPARE_PARTS: `hasProductClassification eq http://www.inmindcloud.com/application/schema.owl#SPARE_PART AND linksProductCategory IS_NOT_NULL`,
    PRODUCTS: `hasProductClassification eq http://www.inmindcloud.com/application/schema.owl#PRODUCT AND linksProductCategory IS_NOT_NULL`,
    ALL: `hasProductClassification eq http://www.inmindcloud.com/application/schema.owl#SPARE_PART OR hasProductClassification eq http://www.inmindcloud.com/application/schema.owl#CONSUMABLE OR hasProductClassification eq http://www.inmindcloud.com/application/schema.owl#PRODUCT AND linksProductCategory IS_NOT_NULL`,
    EQUIPMENTS_CONSUMABLES:
        'hasProductClassification eq ' +
        SEARCH_TYPE_OPTION_TO_ID.CONSUMABLES,
    EQUIPMENTS_SPARE_PARTS:
        'hasProductClassification eq ' +
        SEARCH_TYPE_OPTION_TO_ID.SPARE_PARTS,
    EQUIPMENTS_PRODUCTS:
        'hasProductClassification eq ' +
        SEARCH_TYPE_OPTION_TO_ID.PRODUCTS,
    EQUIPMENTS_GENERIC: `hasProductClassification eq ${SEARCH_TYPE_OPTION_TO_ID.SPARE_PARTS} or hasProductClassification eq ${SEARCH_TYPE_OPTION_TO_ID.CONSUMABLES}`,
    EQUIPMENTS_ALL: `hasProductClassification eq ${SEARCH_TYPE_OPTION_TO_ID.SPARE_PARTS} or hasProductClassification eq ${SEARCH_TYPE_OPTION_TO_ID.CONSUMABLES} or hasProductClassification eq ${SEARCH_TYPE_OPTION_TO_ID.PRODUCTS}`,

    PREVIOUSLY_ORDERED_CONSUMABLES:
        'hasProductClassification eq ' +
        SEARCH_TYPE_OPTION_TO_ID.CONSUMABLES + ' AND linksProductCategory IS_NOT_NULL AND containsKnowledgeBase IS_NULL',
    PREVIOUSLY_ORDERED_SPARE_PARTS:
        'hasProductClassification eq ' +
        SEARCH_TYPE_OPTION_TO_ID.SPARE_PARTS + ' AND linksProductCategory IS_NOT_NULL AND containsKnowledgeBase IS_NULL',
    PREVIOUSLY_ORDERED_PRODUCTS:
        'hasProductClassification eq ' +
        SEARCH_TYPE_OPTION_TO_ID.PRODUCTS + ' AND linksProductCategory IS_NOT_NULL AND containsKnowledgeBase IS_NULL',
    PREVIOUSLY_ORDERED_GENERIC: `hasProductClassification eq ${SEARCH_TYPE_OPTION_TO_ID.SPARE_PARTS} or hasProductClassification eq ${SEARCH_TYPE_OPTION_TO_ID.CONSUMABLES} or hasProductClassification eq ${SEARCH_TYPE_OPTION_TO_ID.PRODUCTS} AND linksProductCategory IS_NOT_NULL AND containsKnowledgeBase IS_NULL`,
    PREVIOUSLY_ORDERED_ALL: `hasProductClassification eq ${SEARCH_TYPE_OPTION_TO_ID.SPARE_PARTS} or hasProductClassification eq ${SEARCH_TYPE_OPTION_TO_ID.CONSUMABLES} or hasProductClassification eq ${SEARCH_TYPE_OPTION_TO_ID.PRODUCTS} AND linksProductCategory IS_NOT_NULL AND containsKnowledgeBase IS_NULL`,
}

const createSearchHistoryModelTemplate = (o) => {
    let template = {
        query: '',
        queryUnix: Date.now(),
        id: '',
    }

    let merge = Object.assign({}, template, o)

    merge.id = merge.query.replace(/ /g, '') + '_' + merge.queryUnix.toString()
    return merge
}

const createFilterObjectTemplate = (o) => {
    let template = {
        id: o.id || '',
        name: o.name || '',
        type: o.type || SEARCH_SETTING_GROUP_TYPE_FILTER,
        displayTextLocaleKey: o.displayTextLocaleKey || '',
        label: o.label || '',
        option: o.option || [],
        operator: o.operator || 'eq',
        queryKey: o.queryKey || '',
        hidden: o.hidden || false,
        applyEffectWhileHidden: o.applyEffectWhileHidden || false,
        uris: o.uris || undefined
    }

    return template
}

const createFilterOptionTemplate = (o) => {
    let template = {
        id: o.id,
        name: o.name,
        displayText: o.displayText,

        count: o.count,
        value: o.value,
    }

    return template
}

//TODO
const createSorterObjectTemplate = (o) => {
    let template = {
        id: o.id || '',
        name: o.name || '',
        displayTextLocaleKey: o.displayTextLocaleKey || '',
        type: o.type || SEARCH_SETTING_GROUP_TYPE_SORTER,
        label: o.label || '',
        option: o.option || [],
        order: o.order || SORT_ORDER.ASC,
        hidden: o.hidden || false,
        applyEffectWhileHidden: o.applyEffectWhileHidden || false,
        value: o.value || '',
    }

    return template
}

const createSorterOptionTemplate = (o) => {
    let template = {
        id: o.id || '',
        name: o.name || '',
        displayText: o.displayText || '',
        order: o.order || SORT_ORDER.ASC || '',
        count: o.count || '',

        queryKey: o.queryKey || '',
    }

    return template
}

const formatRawQueryforLocalhost = (rawQuery = '') => {
    let out = rawQuery

    if (!out) return out

    out = out.trim()

    let hasWhitespace = out.indexOf(' ') > -1
    if (hasWhitespace) out = `${`'` + out + `'`}`

    return out
}

const formatRawQuery = (value) => {
    const keyWord = value.trim()
    const formattedQuery = () => {
        const spaceReplaced = keyWord.split(' ').join('*')
        const asteriskReplaced = spaceReplaced.split('*').join('*$')
        return asteriskReplaced;
    }

    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href)
    const hostname = publicUrl.hostname

    if (hostname !== 'localhost') {
        return formattedQuery();
    } else {
        const proxyURL = new URL(process.env.REACT_APP_IMAGE_ROOT_URL, window.location.href)
        const proxyHostname = proxyURL.hostname
        if (proxyHostname !== 'localhost') {
            return formattedQuery();
        } else {
            return formatRawQueryforLocalhost(keyWord)
        }
    }
}

const getRawQuery = (formattedQuery) => {
    const asteriskReplaced = formattedQuery.split('*$').join('*')
    const spaceReplaced = asteriskReplaced.split('*').join(' ')
    return spaceReplaced;
}

const createAutoSuggestModelTemplate = (o) => {
    let template = {
        name: '',
        imageSrc: '',
        queryUnix: Date.now(),
    }

    let merge = Object.assign({}, template, o)

    if (merge.productImage) merge.imageSrc = getImageURL(merge.productImage)
    delete merge.productImage

    return merge
}

//pass by reference
const getAllLocalSearchQuery = () => {
    let data = []
    let queryObject = store.get('query')

    if (queryObject && queryObject.data) data = queryObject.data

    return data
}

//pass by reference
const getLocalSearchQueryById = ({ queryId = '' }) => {
    let queryObject = store.get('query')
    let targetQuery = undefined

    if (queryObject.data) {
        for (let i = 0; i < queryObject.data.length; i++) {
            if (queryObject.data[i].id === queryId) {
                targetQuery = queryObject.data[i]
                break
            }
        }
    }

    return targetQuery
}

const addLocalSearchQuery = ({ rawQuery = '' }) => {
    let queryObject = createSearchHistoryModelTemplate({ query: rawQuery })

    let existingData = getAllLocalSearchQuery()
    const uniqueHistory = []

    for (var i = 0; i < existingData.length; i++) {
        if (existingData[i].query !== queryObject.query) {
            uniqueHistory.push(existingData[i])
        }
    }

    store.set('query', {
        data: [queryObject, ...uniqueHistory],
    })

    return queryObject
}

const removeLocalSearchQueryById = ({ queryId = '' }) => {
    let existingData = getAllLocalSearchQuery()

    let targetQuery = getLocalSearchQueryById({ queryId: queryId })

    if (targetQuery) {
        let targetQueryId = targetQuery.id
        let filteredData = existingData.filter((o) => {
            if (o.id !== targetQueryId) return o
        })

        store.set('query', {
            data: filteredData,
        })
    }
}

const removeAllLocalSearchQuery = () => {
    store.set('query', {
        data: [],
    })
}

const filterFavouriteType = async (userBO) => {
    return new Promise((resolve, reject) => {

        FavouriteService.getFavouriteProducts(userBO).then((response) => {
            let productIndividualURIQuery = []
            if (response.data.entry.length) {
                productIndividualURIQuery = response.data.entry.map((o) => {
                    return `uri eq ${o.uri}`
                })
            }

            let filterQuery = ''
            if (productIndividualURIQuery.length) {
                filterQuery = productIndividualURIQuery.join(' or ')
            }

            resolve(filterQuery)
        })
    })
}

const filterPreviouslyOrderedType = async () => {
    return new Promise((resolve, reject) => {
        OrderService.getPastOrdersAndProducts()
            .then((response) => {
                let includes = getObjectKey(response.data, 'include') || []
                let productIndividualURIQuery = []
                let products = []

                if (includes.length) {
                    products = includes.filter((o) => {
                        if (o.type === 'Product') {
                            return o
                        }
                    })

                    productIndividualURIQuery = products.map((o) => {
                        return `uri eq ${o.uri}`
                    })
                }

                let filterQuery = ''
                if (productIndividualURIQuery.length) {
                    filterQuery = productIndividualURIQuery.join(' or ')
                }

                resolve(filterQuery)
            })
            .catch((error) => { })
    })
}

const filterFavouriteTypeURIs = async (userBO) => {
    return new Promise((resolve, reject) => {

        FavouriteService.getFavouriteProducts(userBO).then((response) => {
            let productIndividualURIQuery = []
            if (response.data.entry.length) {
                productIndividualURIQuery = response.data.entry.map((o) => {
                    return o.uri
                })
            }

            resolve(productIndividualURIQuery)
        })
    })
}

const filterPreviouslyOrderedTypeURIs = async () => {
    return new Promise((resolve, reject) => {
        OrderService.getPastOrdersAndProducts()
            .then((response) => {
                let includes = getObjectKey(response.data, 'include') || []
                let productIndividualURIQuery = []
                let products = []

                if (includes.length) {
                    products = includes.filter(
                        (o) => getStringByKey(o, 'type') === 'Product'
                    )
                    productIndividualURIQuery = products.map((o) => {
                        return o.uri
                    })
                }

                resolve(productIndividualURIQuery)
            })
            .catch((error) => { })
    })
}

const createGuidedGroupTemplate = (o) => {
    let merge = {
        id: '',
        uri: '',
        label: '',
        imageSrc: '',
        categoryObjectName: '',
        categoryLabel: '',
        categoryId: '',
        categoryURI: '',
        imgSrc: '',
        subEntityType: '',
    }

    let out = Object.assign(merge, o)
    if (Array.isArray(out.imgSrc)) {
        out.imgSrc = ''
    } else {
        if (out.imgSrc) {
            out.imageSrc = productImageSrc(
                out.imgSrc,
                out.subEntityType,
                out.categoryURI
            )
        }
    }

    return out
}

const getGuidedGroupModelData = (portalNavigationBRATemplate) => {
    let guidedGroupData = []
    const state = reduxStore.getState()
    const allProductCategory = state.customisationReducers.productCategory.items
    const allProductMaterialGroup = state.customisationReducers.productMaterialGroup.items

    const subEntityType = !isNilOrEmpty(portalNavigationBRATemplate.subEntityType) ? portalNavigationBRATemplate.subEntityType : SUB_ENTITY_TYPE_PRODUCT_CATEGORY

    let masterData = []

    if (subEntityType === SUB_ENTITY_TYPE_PRODUCT_CATEGORY) {
        masterData = allProductCategory
    } else if (subEntityType === SUB_ENTITY_TYPE_PRODUCT_MATERIAL_GROUP) {
        masterData = allProductMaterialGroup
    }

    if (!masterData.length) return guidedGroupData

    let value =
        getObjectKey(
            portalNavigationBRATemplate,
            'includesPortalNavigationSubEntity'
        ) || []
    if (!value.length) {
        return guidedGroupData
    }

    guidedGroupData = value.map((o) => {
        let subEntityURI = ''

        if (subEntityType === SUB_ENTITY_TYPE_PRODUCT_CATEGORY) {
            subEntityURI = getObjectKey(o, 'usesCategory/0')
        } else if (subEntityType === SUB_ENTITY_TYPE_PRODUCT_MATERIAL_GROUP) {
            subEntityURI = getObjectKey(o, 'usesMaterialGroup/0')
        }

        let [found] = masterData.filter((o) => {
            if (o.uri === subEntityURI) {
                return JSON.parse(JSON.stringify(o))
            }
        })

        if (!found) {
            return null
        }

        let imgSrc = ''

        if (subEntityType === SUB_ENTITY_TYPE_PRODUCT_CATEGORY) {
            imgSrc = getObjectKey(found, 'productCategoryPicture')
        } else if (subEntityType === SUB_ENTITY_TYPE_PRODUCT_MATERIAL_GROUP) {
            imgSrc = getObjectKey(found, 'productMaterialGroupPicture')
        }

        let template = createGuidedGroupTemplate({
            ...o,
            categoryLabel: found.label,
            categoryURI: found.uri,
            categoryId: found.id,
            categoryObjectName: found.objectName,
            imgSrc: imgSrc,
            subEntityType: found.type,
        })
        return template
    })

    let filteredGuidedCategoryData = guidedGroupData.filter((o) => {
        if (o !== null) {
            return o
        }
    })

    return getSortedArray(filteredGuidedCategoryData, 'sequenceNumber')
}

export {
    BOOLEAN_OPERATORS_TO_QUERY,
    SEARCH_SETTING_GROUP_TYPE_FILTER,
    SEARCH_SETTING_GROUP_TYPE_SORTER,
    SEARCH_TYPE_OPTION,
    SEARCH_TYPE_OPTION_TO_ID,
    SEARCH_TYPE_OPTION_TO_DEFAULT_RESTRICTION_FILTER,
    createAutoSuggestModelTemplate,
    createSearchHistoryModelTemplate,
    getAllLocalSearchQuery,
    getLocalSearchQueryById,
    addLocalSearchQuery,
    removeLocalSearchQueryById,
    removeAllLocalSearchQuery,
    formatRawQuery,
    getRawQuery,
    createFilterObjectTemplate,
    createFilterOptionTemplate,
    createSorterObjectTemplate,
    createSorterOptionTemplate,
    SORT_ORDER,
    FILTER_TYPE,
    filterFavouriteType,
    filterPreviouslyOrderedType,
    getGuidedGroupModelData,
    filterFavouriteTypeURIs,
    filterPreviouslyOrderedTypeURIs,
    AUTOSUGGEST_TYPE,
}
