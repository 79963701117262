import { isNilOrEmpty, isNil, getObjectValue } from '.'
import { AdminService } from '../services'
import {
    getObjectBoolean,
    sortArrayByObjectKey,
    setObjectKey,
    uriFragment,
} from './helpers'
import reduxStore from '../store'

const APPSCHEMA = 'http://www.inmindcloud.com/application/schema.owl#'

const getAttributeFragment = (attributeId = '') => {
    //get the last array item
    if (typeof attributeId === 'string') {
        let split = attributeId.split('#')
        if (split.length) {
            return split[split.length - 1]
        }
    }

    return attributeId
}

const parseDto = (dto) => {
    if (!isNilOrEmpty(dto)) {
        const parseSingleDto = (inDto) => {
            let outDto = {
                parentInformation: {
                    id: '',
                    type: '',
                    l10nKey: '',
                },
            }

            const attributes = getObjectKey(inDto, 'attributes')

            outDto.parentInformation.id = getObjectKey(inDto, 'instance/id')
            outDto.parentInformation.type = getObjectKey(inDto, 'type/id')
            outDto.parentInformation.l10nKey = getObjectKey(inDto, 'l10nKey')

            for (let i = 0; i < attributes.length; i++) {
                let attribute = JSON.parse(JSON.stringify(attributes[i]))
                const attributeId = getObjectKey(attribute, 'attribute/id')

                if (!isNilOrEmpty(attributeId)) {
                    const attributeName = getAttributeFragment(attributeId)
                    let multiValued = getObjectKey(attribute, 'multiValued')
                    const attributeType = getObjectKey(attribute, 'type')

                    if (isNil(multiValued)) multiValued = false

                    if (!multiValued)
                        attribute['value'] = getObjectKey(attribute, 'value/0')

                    if (attributeType === 'relation') {
                        const inValueDto = getObjectKey(attribute, 'valueDto')

                        if (!isNilOrEmpty(inValueDto)) {
                            const outValueDto = parseDto(inValueDto)
                            attribute['valueDto'] = outValueDto
                        }
                    }

                    if (!isNilOrEmpty(attributeName))
                        outDto[attributeName] = attribute
                }
            }

            return outDto
        }

        if (Array.isArray(dto)) {
            let outArray = []

            for (let i = 0; i < dto.length; i++) {
                const data = parseSingleDto(dto[i])

                if (!isNil(data)) outArray.push(data)
            }

            return outArray
        } else {
            return parseSingleDto(dto)
        }
    } else return null
}

const parseDtoArray = (options) => {
    var sDefaultSortKey = 'parentInformation/l10nKey',
        sDefaultSortOrder = 'ascend'
    var dtoArray = options.array || []
    var sSortKey = options.sortKey || sDefaultSortKey
    var sSecondarySortKey = options.secondarySortKey || ''
    var bSkipSorting = options.skipSort || false // true|false
    var sSortOrder = options.sortOrder || sDefaultSortOrder // ascend|descend
    var sGetMeta = options.getMeta || false

    // coerce to correct value if wrong.
    if (sSortOrder !== 'ascend' && sSortOrder !== 'descend')
        sSortOrder = sDefaultSortOrder

    if (typeof bSkipSorting !== 'boolean') bSkipSorting = false

    if (typeof sSortKey !== 'string') sSortKey = sDefaultSortKey

    if (!isNilOrEmpty(dtoArray)) {
        for (let i = 0; i < dtoArray.length; i++) {
            const dto = dtoArray[i]
            if (sGetMeta) dtoArray[i] = parseDtoUsingMeta(dto)
            else dtoArray[i] = parseDto(dto)
        }

        if (!bSkipSorting) {
            dtoArray = sortArrayByObjectKey({
                objectArray: dtoArray,
                sortKey: sSortKey,
                secondarySortKey: sSecondarySortKey,
                sortOrder: sSortOrder,
            })
        }
    } else dtoArray = []

    return dtoArray
}

const parseDtoUsingMeta = (oObject) => {
    let oIncludeMap = {}
    let oDefaultStructure = {}

    const parseDtoStructure = (oDtoMetaStructure, oInEntry) => {
        if (isNil(oDtoMetaStructure)) {
            // deep cloning the object
            oDtoMetaStructure = JSON.parse(JSON.stringify(oDtoMetaStructure))

            let sLabel = getObjectKey(oInEntry, 'label')
            let entryId = getObjectKey(oInEntry, 'id')
            let entryType = getObjectKey(oInEntry, 'type')

            if (isNilOrEmpty(entryId)) entryId = ''

            setObjectKey(oDtoMetaStructure, 'instance/id', atob(entryId))

            if (isNilOrEmpty(entryType)) entryType = ''

            setObjectKey(oDtoMetaStructure, 'type/id', APPSCHEMA + entryType)

            const attributes = getObjectKey(oDtoMetaStructure, 'attributes')

            for (let i = 0; i < attributes.length; i++) {
                const attribute = attributes[i]
                const attributeId = getObjectKey(attribute, 'attribute/id')
                const attributeKey = uriFragment(attributeId)

                if (!isNilOrEmpty(attributeKey)) {
                    const aEntryValues = oInEntry[attributeKey]

                    if (aEntryValues.length === 0)
                        setObjectKey(attribute, 'value', null)
                    else {
                    }
                }
            }

            for (const sDtoKey in oInEntry) {
                let oValue = oInEntry[sDtoKey]

                if (isNilOrEmpty(oValue)) oValue = ''

                switch (sDtoKey) {
                    case 'id':
                        setObjectKey(
                            oDtoMetaStructure,
                            'parentInformation/id',
                            oValue
                        )
                        break
                    case 'uri':
                        setObjectKey(
                            oDtoMetaStructure,
                            'parentInformation/uri',
                            oValue
                        )
                        break
                    case 'type':
                        setObjectKey(
                            oDtoMetaStructure,
                            'parentInformation/type'
                            // imcUtil.globals.APPSCHEMA + oValue
                        )
                        break
                    default:
                        if (!isNil(oValue)) {
                            if (!isNilOrEmpty(getObjectKey(oValue, '0/id'))) {
                                let aValues = []

                                for (let i = 0; i < oValue.length; i++) {
                                    aValues.push(
                                        atob(
                                            getObjectKey(
                                                oValue[i],
                                                'parentInformation/id'
                                            )
                                        )
                                    )
                                }

                                setObjectKey(
                                    oDtoMetaStructure,
                                    sDtoKey + '/value',
                                    aValues
                                )
                                setObjectKey(
                                    oDtoMetaStructure,
                                    sDtoKey + '/valueDto',
                                    oValue
                                )
                            } else {
                                const sValueType = getObjectKey(
                                    oDtoMetaStructure,
                                    sDtoKey + '/valuetype'
                                )

                                if (sValueType === 'date') {
                                    if (!isNilOrEmpty(oValue))
                                        setObjectKey(
                                            oDtoMetaStructure,
                                            sDtoKey + '/date',
                                            new Date(oValue)
                                        )
                                    else
                                        setObjectKey(
                                            oDtoMetaStructure,
                                            sDtoKey + '/date',
                                            ''
                                        )
                                }

                                setObjectKey(
                                    oDtoMetaStructure,
                                    sDtoKey + '/value',
                                    oValue
                                )
                            }
                        }

                        break
                }
            }

            oDtoMetaStructure = parseModelData({
                data: oDtoMetaStructure,
            })

            // oDtoMetaStructure = imcUtil().modelUtil(
            //     'parseDto',
            //     oDtoMetaStructure
            // )

            var sObjectName = getObjectValue(oDtoMetaStructure, 'objectName')

            if (isNilOrEmpty(sLabel)) sLabel = sObjectName || ''

            setObjectKey(oDtoMetaStructure, 'parentInformation/l10nKey', sLabel)

            oDefaultStructure = oDtoMetaStructure
        }
    }

    const handleParsing = (oInEntry) => {
        var sType = getObjectKey(oInEntry, 'type')

        if (!isNilOrEmpty(sType)) {
            reduxStore.getState()
            // var oModel = sap.ui.getCore().getModel('schemaData')
            // var oSchemaData = oModel.getData()
            // var oDto = oModel.getProperty('/' + sType)
            // if (isNil(oDto)) {
            //     $.rest('genericAjaxGet', {
            //         // uri: serverLocation + 'webapp/metadatas/' + sType,
            //         async: false,
            //     }).done(function (oTypeSchema) {
            //         parseDtoStructure(oSchemaData[sType], oInEntry)
            //     })
            // } else parseDtoStructure(oDto, oInEntry)
        }

        return oDefaultStructure
    }

    /**
     * oObject is a resource
     */
    if (
        hasOwnProperty(oObject, 'entry') &&
        hasOwnProperty(oObject, 'include')
    ) {
        const aInclude = getObjectKey(oObject, 'include')

        if (!isNilOrEmpty(aInclude)) {
            for (let i = 0; i < aInclude.length; i++) {
                const oInclude = aInclude[i]
                const sUri = getObjectKey(oInclude, 'uri')

                oIncludeMap[sUri] = oInclude
            }
        }

        let aEntries = getObjectKey(oObject, 'entry')

        aEntries = JSON.stringify(aEntries)

        if (!isNilOrEmpty(aEntries)) {
            for (const sKey in oIncludeMap) {
                const oValue = oIncludeMap[sKey]
                const re = new RegExp('"' + sKey + '"', 'g')

                aEntries = aEntries.replace(re, JSON.stringify(oValue))
            }
        }

        aEntries = JSON.parse(aEntries)

        if (!isNilOrEmpty(aEntries)) {
            for (let i = 0; i < aEntries.length; i++) {
                const oEntry = aEntries[i]

                aEntries[i] = handleParsing(oEntry)
            }
        }

        oObject = aEntries
    } else {
        /**
         * oObject is a bundle
         */
        if (Array.isArray(oObject)) {
            for (var objIdx = 0; objIdx < oObject.length; objIdx++) {
                oObject[objIdx] = handleParsing(oObject[objIdx])
            }
        } else oObject = handleParsing(oObject)
    }

    return oObject
}

const parseModelData = (options) => {
    let o = {
        data: null,
        deep: true,
        overrideDeep: true,
        // introduce overrideDeep because in
        // most cases we only process 2 level
        // deep
        full: true,
        min: false,
        dto: true,
        includesMeta: true,
        fromNew: true,
    }
    o = Object.assign(o, options)

    if (!isNil(o.data)) {
        var parseData = function (oData) {
            if (!isNil(oData)) {
                let oTempValueDto = {
                    attributes: [],
                    instance: {
                        id: '',
                    },
                    type: {
                        id: '',
                    },
                    position: 0,
                }

                if (!o.min) {
                    for (const sKey in oData) {
                        const oObj = oData[sKey]

                        if (!isNil(oObj)) {
                            if (sKey === 'parentInformation') {
                                if (!!isNil(oObj.id))
                                    delete oTempValueDto.instance
                                else
                                    setObjectKey(
                                        oTempValueDto,
                                        'instance/id',
                                        oObj.id
                                    )

                                if (!!isNil(oObj.type))
                                    delete oTempValueDto.type
                                else
                                    setObjectKey(
                                        oTempValueDto,
                                        'type/id',
                                        oObj.type
                                    )

                                if (!!isNil(oObj.position))
                                    delete oTempValueDto.position
                                else
                                    setObjectKey(
                                        oTempValueDto,
                                        'position',
                                        oObj.position
                                    )

                                setObjectKey(
                                    oTempValueDto,
                                    'type/id',
                                    oObj.type
                                )

                                if (o.fromNew)
                                    oTempValueDto['l10nKey'] = getObjectKey(
                                        oObj,
                                        'l10nKey'
                                    )
                            } else if (!isNil(oObj.type)) {
                                if (
                                    oObj.type === 'data' ||
                                    oObj.type === 'relation'
                                ) {
                                    // var oAttribute = imcUtil().modelUtil(
                                    //     'parseObjectV2',
                                    //     {
                                    //         data: oObj,
                                    //         deep: o.deep,
                                    //         skipdiff: o.full,
                                    //         overrideDeep: o.overrideDeep,
                                    //         includesMeta: true,
                                    //         fromNew: o.fromNew,
                                    //     }
                                    // )
                                    // if (!isNil(oAttribute))
                                    //     oTempValueDto.attributes.push(
                                    //         oAttribute
                                    //     )
                                }
                            }
                        }
                    }

                    if (oTempValueDto.attributes.length > 0)
                        return oTempValueDto
                    else return null
                } else {
                    if (!isNil(oData) && !isNil(oData.parentInformation)) {
                        setObjectKey(
                            oTempValueDto,
                            'instance/id',
                            !!isNilOrEmpty(
                                getObjectKey(oData, 'parentInformation/id')
                            )
                                ? getObjectKey(oData, 'parentInformation/id')
                                : ''
                        )

                        setObjectKey(
                            oTempValueDto,
                            'type/id',
                            !!isNilOrEmpty(
                                getObjectKey(oData, 'parentInformation/type ')
                            )
                                ? getObjectKey(oData, 'parentInformation/type ')
                                : ''
                        )

                        if (
                            !!isNilOrEmpty(
                                getObjectKey(
                                    oData,
                                    'parentInformation/position'
                                )
                            )
                        )
                            oTempValueDto.position = getObjectKey(
                                oData,
                                'parentInformation/position'
                            )
                        else delete oTempValueDto['position']
                    }

                    return oTempValueDto
                }
            } else {
                let oTempValueDto = {}

                for (const sKey in oData) {
                    const oObj = oData[sKey]

                    // let oValue = imcUtil().modelUtil('parseValueV2', {
                    //     data: oObj,
                    //     deep: o.deep,
                    //     skipdiff: o.skipdiff,
                    //     dto: o.dto,
                    // })

                    // if (!isNil(oValue)) oTempValueDto[sKey] = oValue
                }

                if (!isNilOrEmpty(oTempValueDto)) return oTempValueDto
                else return null
            }
        }

        // if ($.isArray(o.data)) {
        //     var aUIModel = []

        //     for (var iDataIndex = 0; iDataIndex < o.data.length; iDataIndex++) {
        //         var oData = parseData(o.data[iDataIndex])

        //         if (!isNil(oData)) aUIModel.push(oData)
        //     }

        //     if (aUIModel.length > 0) return aUIModel
        //     else return null
        // } else return parseData(o.data)
    } else return null
}
/**
 * return undefined if key not found
 */
const getObjectKey = (sourceObject = {}, path = '') => {
    let pathSplit = path.split('/')
    let currentObject
    let targetObject

    if (typeof path !== 'string') return undefined

    for (let i = 0; i < pathSplit.length; i++) {
        let pathSegment = pathSplit[i]
        let targetObjectKey

        if (!currentObject) targetObject = sourceObject
        else targetObject = currentObject

        if (typeof parseInt(pathSegment) === 'number' && !isNaN(pathSegment)) {
            targetObjectKey = targetObject[parseInt(pathSegment)]
        } else {
            targetObjectKey = targetObject[pathSegment]
        }

        currentObject = targetObjectKey
    }

    return currentObject
}

const getAttributeById = (attributes = [], attributeId = '') => {
    let attribute = {}

    let found = attributes.filter((att) => {
        const id = getObjectKey(att, 'attribute/id')
        if (id.includes('#' + attributeId)) {
            return att
        }
    })
    if (!isNilOrEmpty(found)) attribute = found[0]
    return attribute
}

const getAllBDA = (meta) => {
    let out = []
    let allAttributes = meta.attributes

    out = allAttributes.filter((att) => {
        const type = getObjectKey(att, 'type')
        if (type === 'data') {
            return att
        }
    })

    return out
}

const getAllBRA = (meta) => {
    let out = []
    let allAttributes = meta.attributes

    out = allAttributes.filter((att) => {
        const type = getObjectKey(att, 'type')
        if (type === 'relation') {
            return att
        }
    })

    return out
}

const getSingleValuedBDA = (meta) => {
    let out = []
    let allAttributes = meta.attributes

    out = allAttributes.filter((att) => {
        const type = getObjectKey(att, 'type')
        const mutivalued = getObjectBoolean(att, 'multiValued')
        if (type === 'data' && !mutivalued) {
            return att
        }
    })

    return out
}

const getSingleValuedBRA = (meta) => {
    let out = []
    let allAttributes = meta.attributes

    out = allAttributes.filter((att) => {
        const type = getObjectKey(att, 'type')
        const mutivalued = getObjectBoolean(att, 'multiValued')
        if (type === 'relation' && !mutivalued) {
            return att
        }
    })

    return out
}

const getValueType = (metaData, attributeId) => {
    let valueType = 'string'

    if (!isNilOrEmpty(metaData)) {
        let out = []
        let allAttributes = metaData.attributes
        out = allAttributes.filter((att) => {
            const id = getObjectKey(att, 'attribute/id')
            if (id.endsWith('#' + attributeId)) {
                return att
            }
        })

        if (out.length > 0) {
            const attr = out[0]
            valueType = getObjectKey(attr, 'valuetype')
        }
    }
    return valueType
}

const attributeArrayToObject = (attributeDtos = []) => {
    let attributeObject = {}

    if (!Array.isArray(attributeDtos)) {
        return attributeObject
    }

    attributeDtos.map((attributeDto) => {
        let attributeFragment = getAttributeFragment(
            getObjectKey(attributeDto, 'attribute/id')
        )

        if (attributeFragment) {
            attributeObject[attributeFragment] = getAttributeTemplate(
                attributeDto
            )
        }
    })

    return attributeObject
}

const getAttributeTemplate = (incoming = {}) => {
    let template = {
        attribute: {
            id: '',
        },
        l10nKey: '',
        position: 0,
        type: 'data',
        valuetype: 'string',
        value: [''],
    }

    let merge = Object.assign(template, incoming)
    setValueKey(merge)

    return merge

    function setValueKey(o) {
        let hasValidValue = false

        if (
            o.value !== null &&
            o.value !== undefined &&
            Array.isArray(o.value) &&
            o.value.length
        )
            hasValidValue = true

        if (o.valuetype === 'string') {
            let valueToUse = ''
            if (hasValidValue) valueToUse = o.value[0]
            o.value = valueToUse
            o.oldValue = valueToUse
        }
    }
}

export {
    getAttributeFragment,
    getAttributeTemplate,
    attributeArrayToObject,
    getObjectKey,
    parseDto,
    parseDtoArray,
    parseModelData,
    getAttributeById,
    getAllBDA,
    getAllBRA,
    getValueType,
}
