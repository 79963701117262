import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    outerContainer: {
        marginBottom: theme.spacing(1),
    },
    labelBox: {
        marginBottom: '4px',
        minHeight: '1.3em',
    },
    requiredBox: {
        minHeight: '1.3em',
        borderLeft: '3px solid',
        borderColor: theme.palette.error.main,
        padding: '0 4px',
    },
}))

const FormField = (props) => {
    const { label, required, children } = props
    const classes = useStyles()

    let requiredBoxClass = `${classes.labelBox}`
    if (required)
        requiredBoxClass = `${requiredBoxClass} ${classes.requiredBox}`
    return (
        <Box className={classes.outerContainer}>
            <Box className={requiredBoxClass}>
                <Typography>{label}</Typography>
            </Box>
            {children}
        </Box>
    )
}

export default FormField

FormField.propTypes = {
    label: PropTypes.string,
    required: PropTypes.bool,
    children: PropTypes.any,
}
