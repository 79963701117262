import {api} from './ajax';
import {isNilOrEmpty} from '../helpers';
import {getDefaultBdaBraForProductCard, getSectionsForScreens, getAllIncludeParamForSections, getAllIncludeElementsForSections, mergeObject} from '../helpers/screen-configuration-helper';
import {AuthenticationService} from './authentication-service';

export const VariantService = {
    getProductVariants: (uri, forScreens, filters = []) => {
        let filter = `includesKMAT eq ${uri}`;
        for (const f of filters) filter += ` AND ${f}`;
        const defaultBdaBra = getDefaultBdaBraForProductCard();
        let uniqueIncludeParams = defaultBdaBra.include;
        let elements = {};
        let includeElements = {};
        if (forScreens) {
            const sections = getSectionsForScreens(forScreens);
            let dynamicIncludeParams = getAllIncludeParamForSections(sections);
            let dynamicIncludeElements = getAllIncludeElementsForSections(sections);
            elements = dynamicIncludeElements.elements;
            includeElements = dynamicIncludeElements.includeElements;
            dynamicIncludeParams = dynamicIncludeParams.concat(defaultBdaBra.include);
            uniqueIncludeParams = [...new Set(dynamicIncludeParams)];
        }
        let data = {type: 'search', filter, include: uniqueIncludeParams};
        let finalElementsParam = defaultBdaBra.elements;
        if (!isNilOrEmpty(elements)) {
            finalElementsParam = mergeObject(elements, defaultBdaBra.elements);
            if (finalElementsParam.bra) finalElementsParam.bra.push('complementary');
            else finalElementsParam.bra = ['complementary'];
            data = {...data, elements: finalElementsParam};
        }
        let finalIncludElementsParam = defaultBdaBra.includeElements;
        if (!isNilOrEmpty(includeElements)) {
            finalIncludElementsParam = mergeObject(includeElements, defaultBdaBra.includeElements);
            data = {...data, includeElements: finalIncludElementsParam};
        }
        return api.post('/products/search', {data}, {headers: {
            Authorization: AuthenticationService.getLocalStorageOAuthTokenValue('oauthToken')
        }, trackThisPromise: true});
    },
    getKMATVariant: uri => {
        return api.post('/products/search', {
            data: {type: 'search', filter: `includesKMAT eq ${uri}`, elements: {bda: ['objectName'], bra: ['']}}
        });
    },
    getVariantDetails: id => api.get(`/products/${id}/classifiedDetails`)
};