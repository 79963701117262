import store from 'store'

const REMEMBER_ME_KEY = 'rememberme'

export const RememberMe = {
    schema(o) {
        let template = {
            unix: o.unix || null,
            userName: o.userName || '',
            password: o.password || '',
        }
        return template
    },
    setLocalStorageValue(o) {
        let storeUnix = Date.now()

        store.set(
            REMEMBER_ME_KEY,
            RememberMe.schema({
                unix: storeUnix,
                userName: o.userName,
                password: o.password,
            })
        )
    },
    getRememberMeLocalStorageValue() {
        let o = store.get(REMEMBER_ME_KEY)
        if (!o || !o.hasOwnProperty('userName')) return undefined

        return RememberMe.schema({
            unix: o.unix,
            userName: o.userName,
            password: o.password,
        })
    },
    clearLocalStorageValue() {
        store.remove(REMEMBER_ME_KEY)
    },
}
