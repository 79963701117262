import React from 'react'
import { useHistory } from 'react-router-dom'
import '../../theme/salesPortal/theme.scss'
import Loader from '../../components/administration/loader'
import ReportIcon from '@material-ui/icons/Report';
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
    Typography,
    Box,
    Button,
} from '@material-ui/core'
import '../../theme/salesPortal/theme.scss'
import { TranslationService } from '../../services'
import {
    isNilOrEmpty,
    getObjectKey
} from '../../helpers'

const useStyles = makeStyles((theme) => ({
    icon: {
        fontSize: '10rem',
        border: '10px solid',
        borderRadius: '7rem',
        padding: '1rem',
    },
}))

const Error = () => {
    const classes = useStyles()
    const { t } = TranslationService.useTranslation()
    const history = useHistory()
    const state = getObjectKey(history, 'location/state')

    return (
        <Box className="app-content-wrapper">
            <Box className="app-content-wrapper">
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    p={4}
                >
                    <Box marginBottom="44px">
                        <Typography variant="h2">
                            {isNilOrEmpty(state) ? t('general.afterSalesErrorMessage.title') : state.msg}
                        </Typography>
                    </Box>
                    <Box marginBottom="11px">
                        <ReportIcon
                            color="primary"
                            className={classes.icon}
                        />
                    </Box>
                    {/* <Box marginBottom="44px">
                        <Typography variant="h1" color="primary">
                            {t('general.afterSalesErrorMessage.errorCode')}
                        </Typography>
                    </Box> */}
                    <Box marginBottom="22px">
                        <Typography variant="subtitle1">
                            {t('general.afterSalesErrorMessage.errorMessage')}
                        </Typography>
                    </Box>
                    <Box marginBottom="22px">
                        <Typography style={{ fontSize: '14px' }}>
                            {t('general.afterSalesErrorMessage.solutionMessage')}
                        </Typography>
                    </Box>
                    <Box>
                        <Button
                            variant="contained"
                            color="primary"
                            component={RouterLink}
                            to="/"
                            style={{ width: 247, height: 44 }}
                        >
                            {t('general.afterSalesErrorMessage.buttonText')}
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Loader useFullScreen={false}></Loader>
        </Box>
    )
}

export default Error
