const customisingThemeTemplate = {
    overrides: {
        // MuiPaper: {
        //     root: { color: '#464646' },
        // },
        MuiLink: {
            underlineHover: {
                cursor: 'pointer',
            },
        },
        MuiTableCell: {
            head: {
                'white-space': 'nowrap',
            },
        },
        MuiTab: {
            root: {
                textTransform: 'none',
            },
        },
        MuiButton: {
            root: {
                textTransform: 'none',
                minHeight: '44px'
            },
        },
        MuiBreadcrumbs: {
            li: {
                fontSize: '.77777777777rem',
            },
        },
        MuiDropzoneArea: {
            root: {
                backgroundColor: 'transparent',
                maxHeight: 125,
                minHeight: 125,
            },
            text: { display: 'none' },
            icon: {
                display: 'none',
            },
        },
        MuiInputBase: {
            root: {
                minHeight: '44px'
            }
        }
    },
    typography: {
        fontFamily: [
            'Roboto',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        h1: {
            fontSize: '2.1111111111rem',
            fontWeight: 'bold',
        },
        h2: {
            fontSize: '1.5555555555555556rem',
            fontWeight: 'bold',
        },
        h3: {
            fontSize: '1.3333333333333333rem',
            fontWeight: 'bold',
        },
        subtitle1: {
            fontSize: '0.8888888888888888rem',
            fontWeight: 'bold',
        },
        body1: {
            fontSize: '0.7777777777777778rem',
        },
        caption: {
            fontSize: '0.6666666666666666rem',
        },

        button: {
            fontSize: '1rem',
            fontWeight: '300',
        },
    },
    props: {
        MuiButtonBase: {
            // The default props to change
            disableRipple: true, // Disable ripple effect
        },
        MuiButton: {
            variant: 'outlined',
            size: 'medium',
        },
    },
    palette: {
        background: {
            default: '#fffdfa', //https://material-ui.com/components/css-baseline/
            footer: '#17384a', //custom prop
            footerText: '#f7f1e8',
            disableBackground: '#fafafa', //custom prop does it work?
        },
        text: {
            primary: '#17384a',
            secondary: '#17384a',
            disabled: '#ccc',
        },
        primary: {
            // light: '#fff879',
            main: '#E4A116',
            // dark: '#bb950d',
            contrastText: '#fff',
        },
        secondary: {
            //light: '#fff879',
            main: '#464646',
            //dark: '#bb950d',
            contrastText: '#fff',
        },
        success: {
            main: '#469880',
            contrastText: '#fff',
        },
        info: {
            main: '#104ba0',
            contrastText: '#fff',
        },
        warning: {
            main: '#EB9C23',
            contrastText: '#fff',
        },
        error: {
            main: '#f1675c',
            contrastText: '#fff',
        },
        grey: {
            '400': '#eaeaea',
            '500': '#a6a49d'
        },
        landingNavigationBackGround: {
            main: 'transparent',
            contrastText: '#FFFFFF',
            primaryText: '#484C4F'
        },
        navigationBackGround: {
            main: '#f7f1e8',
            primary: '#17384a',
        },
        triangle: {
            main: '#CACACA',
        },
        actionBox: {
            main: '#f7f1e8',
        },
        panelHolder: {
            main: '#EFEFEF',
        },
        placeHolderTextIcon: {
            main: '#CCCCCC',
        },
        filterSection: {
            main: '#f7f1e8',
        },
        landingPage: {
            main: '#eaeaea',
        },
        portalSelectBy: {
            main: '#f7f1e8',
        },
        portalFieldBackground: {
            main: '#fcf7f2',
        },
        checkboxDefault: {
            main: '#c1c0c0'
        }
    },
    customThemeLoaded: false,
}

customisingThemeTemplate.overrides = {...customisingThemeTemplate.overrides, ...{
    MuiCheckbox: {
        root: {
            color: customisingThemeTemplate.palette.checkboxDefault.main,
            '&:hover': {
                color: customisingThemeTemplate.palette.text.primary
            }
        }
    },
    MuiRadio: {
        root: {
            color: customisingThemeTemplate.palette.checkboxDefault.main,
            '&:hover': {
                color: customisingThemeTemplate.palette.text.primary
            }
        }
    }
}};

export default customisingThemeTemplate
