import { api, base } from './ajax'
import { AuthenticationService } from './authentication-service'
import { AdminService } from './admin-service'
import {
    findAndMergePricingData,
    getImageURL,
    isNilOrEmpty,
    getMainProductImage,
    isConfigurableProduct,
    isNil,
} from '../helpers'
import { getObjectKey } from '../helpers/model-helper'
import { getObjectBoolean } from '../helpers'
import { displayERPId, getProductModelTemplate } from '../helpers/product-helper'
import reduxStore from '../store'
import {
    SEARCH_TYPE_OPTION_TO_DEFAULT_RESTRICTION_FILTER,
    SEARCH_TYPE_OPTION_TO_ID,
} from '../helpers/search-helper'
import { useSelector } from 'react-redux'
import { CustomisationService } from './customisation-service'
import {
    getAllIncludeElementsForSections,
    getAllIncludeParamForSections,
    getDefaultBdaBraForProductCard,
    getSectionsForScreens,
    mergeObject,
    PRODUCT_CARD_LIST_SCREEN_CONFIG,
    PRODUCT_CARD_TILE_SCREEN_CONFIG,
} from '../helpers/screen-configuration-helper'
import { configure } from '@testing-library/react'

export const ProductService = {
    setFavourite({ productID = '', favouriteValue = false }) {
        return api.post('/favourite/mock', {
            params: {
                productID: productID,
                favourite: favouriteValue,
            },
        })
    },
    Attachment_METADATA: {},
    async getMetadata(type) {
        return api.get('/metadatas/' + type)
    },
    createProductModelTemplate(o) {
        let template = {
            name: '',
            label: '',
            id: '',
            uri: '',
            price1: '',
            price2: '',
            currency: '',
            uom: '',
            imageSrc: '',
            type: '',
            productId: '',
            productUri: '',
            productImage: '',
            productClassificationUri: '',
            productConfigurable: false,
            complementary: [],
            hasUnitofMeasure: {
                label: '',
                objectName: '',
                uri: '',
                objectExternalId: '',
                objectERPId: '',
            },
            linksProductCategory: {
                label: '',
                objectName: '',
                uri: '',
                objectExternalId: '',
                objectERPId: '',
            },
            displayERPId: '',
            priceUOM: ''
        }

        let merge = Object.assign({}, template, o)

        if (merge.objectName) {
            merge.name = merge.objectName
        }

        if (typeof merge.name !== 'string') merge.name = ''

        let sourceProductImage = merge.productImage
        if (
            (sourceProductImage && !Array.isArray(sourceProductImage)) ||
            (Array.isArray(sourceProductImage) && sourceProductImage.length)
        )
            merge.imageSrc = getImageURL(sourceProductImage)

        if (!merge.uom) {
            if (o.hasUnitofMeasure) {
                const objectERPId = isNilOrEmpty(
                    getObjectKey(o.hasUnitofMeasure, 'objectERPId')
                )
                    ? ''
                    : getObjectKey(o.hasUnitofMeasure, 'objectERPId')
                const label = getObjectKey(o.hasUnitofMeasure, 'label')
                const objectName = getObjectKey(
                    o.hasUnitofMeasure,
                    'objectName'
                )
                merge.uom = objectERPId || label || objectName || ''
            }
        }

        if (!merge.productClassificationUri) {
            if (getObjectKey(o, 'hasProductClassification/0')) {
                merge.productClassificationUri = getObjectKey(
                    o,
                    'hasProductClassification/0'
                )
            }
        }

        if (merge.erpId) {
            merge.displayERPId = displayERPId(merge.erpId)
        } else {
            merge.displayERPId = displayERPId(
                getObjectKey(merge, 'objectERPId')
            )
        }

        return merge
    },

    search(
        {
            type = 'Search',
            filter = '',
            search = '',
            limit = 20,
            top = 0,
            include = [],
            useDefaultProductRestriction = true,
            includeMeta = [],
            asyncExecution = true
        },
        appOption
    ) {
        let trackThisPromise = true
        if (appOption && appOption.hasOwnProperty('trackThisPromise')) {
            trackThisPromise = appOption.trackThisPromise
        }

        let finalFilterValue = filter
        if (useDefaultProductRestriction) {
            if (filter)
                finalFilterValue = `${filter} AND ${this.getProductRestriction()}`
            else finalFilterValue = this.getProductRestriction()
        }

        return api.post(
            '/products/search',
            {
                data: {
                    type: type,
                    search: 
                    {
                        "key": search,
                        "fields": [
                            "objectName",
                            "objectERPId",
                            "label"
                        ]
                    },
                    filter: finalFilterValue,
                    includeMeta: includeMeta,
                    limit: limit,
                    include: include,
                    asyncExecution: asyncExecution
                },
            },
            { trackThisPromise: trackThisPromise }
        )
    },

    async getProductAttachments({ productUri }) {
        return new Promise((resolve, reject) => {
            api.get('/attachment/getAttachmentList', {
                params: {
                    primary: productUri,
                    instanceid: productUri,
                },
            })
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    async getProductCategory({
        search = '',
        filter = '',
        useDefaultProductRestriction = true,
    }) {
        return new Promise((resolve, reject) => {
            let finalFilter = filter
            if (useDefaultProductRestriction) {
                if (isNilOrEmpty(filter))
                    finalFilter = this.getProductRestriction()
                else {
                    finalFilter =
                        filter + ' AND ' + this.getProductRestriction()
                }
            }

            api.post(
                '/products/groupBy',
                {
                    data: {
                        type: 'GroupBy',
                        target: 'ProductCategory',
                        relation: 'Product.linksProductCategory',
                        search: search,
                        filter: finalFilter,
                        elements: {
                            bda: ['objectName', 'objectERPId'],
                            bra: [''],
                        },
                        includeCount: true,
                    },
                },
                { trackThisPromise: false }
            )
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    async getProductCategoryFilter({
        search = '',
        filter = '',
        useDefaultProductRestriction = true,
    }) {
        return new Promise((resolve, reject) => {
            let finalFilter = filter
            if (useDefaultProductRestriction) {
                if (isNilOrEmpty(filter))
                    finalFilter = this.getProductRestriction()
                else {
                    finalFilter =
                        filter + ' AND ' + this.getProductRestriction()
                }
            }

            api.post(
                '/products/search',
                {
                    data: {
                        search: {
                            "key": search,
                            "fields": [
                                "objectName",
                                "objectERPId",
                                "label"
                            ]
                        },
                        filter: finalFilter,
                        elements: {
                            bda: ['', ''],
                            bra: ['linksProductCategory'],
                        },
                        include: ['linksProductCategory'],
                    },
                },
                { trackThisPromise: false }
            )
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    async getProductClassifications({
        search = '',
        filter = '',
        useDefaultProductRestriction = true,
    }) {
        return new Promise((resolve, reject) => {
            let finalFilter = filter
            if (useDefaultProductRestriction) {
                if (isNilOrEmpty(filter))
                    finalFilter = this.getProductRestriction()
                else {
                    finalFilter =
                        filter + ' AND ' + this.getProductRestriction()
                }
            }

            api.post(
                '/products/search',
                {
                    data: {
                        type: 'search',
                        search: {
                            "key": search,
                            "fields": [
                                "objectName",
                                "objectERPId",
                                "label"
                            ]
                        },
                        filter: finalFilter,
                        elements: {
                            bda: ['', ''],
                            bra: ['hasProductClassification'],
                        },
                        include: ['hasProductClassification'],
                    },
                },
                { trackThisPromise: false }
            )
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    async getProductMaterialGroups({
        search = '',
        filter = '',
        useDefaultProductRestriction = true,
    }) {
        return new Promise((resolve, reject) => {
            let finalFilter = filter
            if (useDefaultProductRestriction) {
                if (isNilOrEmpty(filter))
                    finalFilter = this.getProductRestriction()
                else {
                    finalFilter =
                        filter + ' AND ' + this.getProductRestriction()
                }
            }

            api.post(
                '/commerce/filter/productMaterialGroup',
                {
                    data: {
                        type: 'search',
                        search: search,
                        filter: finalFilter,
                        elements: {
                            bda: ['objectName', 'objectERPId', 'label'],
                            bra: [''],
                        },
                        include: ['hasProductMaterialGroup'],
                    },
                },
                { trackThisPromise: false }
            )
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    async getProductsByFilter({
        search = '',
        filter = '',
        useDefaultProductRestriction = true,
    }) {
        return new Promise((resolve, reject) => {
            let finalFilter = filter
            if (useDefaultProductRestriction) {
                if (isNilOrEmpty(filter))
                    finalFilter = this.getProductRestriction()
                else {
                    finalFilter =
                        filter + ' AND ' + this.getProductRestriction()
                }
            }

            api.post(
                '/products/search',
                {
                    data: {
                        type: 'search',
                        search: {
                            "key": search,
                            "fields": [
                                "objectName",
                                "objectERPId",
                                "label"
                            ]
                        },
                        filter: finalFilter,
                        elements: {
                            bda: ['', ''],
                            bra: [''],
                        },
                    },
                },
                { trackThisPromise: false }
            )
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    async getProductType({
        search = '',
        filter = '',
        useDefaultProductRestriction = true,
    }) {
        return new Promise((resolve, reject) => {
            let finalFilter = filter
            if (useDefaultProductRestriction) {
                if (isNilOrEmpty(filter))
                    finalFilter = this.getProductRestriction()
                else {
                    finalFilter =
                        filter + ' AND ' + this.getProductRestriction()
                }
            }

            api.post(
                '/commerce/filter/productType',
                {
                    data: {
                        type: 'GroupBy',
                        target: 'ProductType',
                        relation: 'Product.hasProductType',
                        search: search,
                        filter: finalFilter,
                        elements: {
                            bda: ['objectName', 'objectERPId'],
                            bra: [''],
                        },
                        includeCount: true,
                    },
                },
                { trackThisPromise: false }
            )
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    async searchPaginate(
        {
            type = 'Search',
            search = '',
            filter = '',
            sort = ['label ASC'],
            offset = 0,
            limit = 0,
            useDefaultProductRestriction = true,
            forScreens = [
                PRODUCT_CARD_TILE_SCREEN_CONFIG,
                PRODUCT_CARD_LIST_SCREEN_CONFIG,
            ],
        },
        appOption, runInBackground = false
    ) {
        let finalFilterValue = filter
        if (!sort.length) sort = ['label ASC']
        if (useDefaultProductRestriction) {
            if (!isNilOrEmpty(filter))
                finalFilterValue = `${filter} AND ${this.getProductRestriction()}`
            else finalFilterValue = this.getProductRestriction()
        }

        let getPricing = true
        if (appOption) {
            if (
                appOption.hasOwnProperty('getPricing') &&
                typeof appOption.getPricing === 'boolean'
            ) {
                getPricing = appOption.getPricing
            }
        }

        let defaultBdaBra = getDefaultBdaBraForProductCard()
        let defaultElements = defaultBdaBra.elements
        defaultElements.bra.push('linksProductCategory')
        let defaultIncludes = defaultBdaBra.include
        let defaultIncludeElements = defaultBdaBra.includeElements

        const sections = getSectionsForScreens(forScreens)
        let dynamicIncludeParams = getAllIncludeParamForSections(sections)
        dynamicIncludeParams = dynamicIncludeParams.concat(defaultIncludes)
        dynamicIncludeParams.push('hasUnitofMeasure:UnitofMeasurement')
        dynamicIncludeParams.push('linksProductCategory:ProductCategory')
        dynamicIncludeParams.push('definesAnnotation')

        let uniqueIncludeParams = [...new Set(dynamicIncludeParams)]

        let dynamicIncludeElements = getAllIncludeElementsForSections(sections)
        let elements = dynamicIncludeElements.elements
        let includeElements = dynamicIncludeElements.includeElements

        let data = {
            type: type,
            search: {
                "key": search,
                "fields": [
                    "objectName",
                    "objectERPId",
                    "label"
                ]
            },
            filter: finalFilterValue,
            limit: limit,
            offset: offset,
            include: uniqueIncludeParams,
            sort: sort,
        }

        let finalElementsParam = defaultElements
        if (!isNilOrEmpty(elements)) {
            finalElementsParam = mergeObject(elements, defaultElements)

            data = {
                ...data,
                elements: finalElementsParam
            }
        }

        let finalIncludElementsParam = defaultIncludeElements
        if (!isNilOrEmpty(includeElements)) {
            finalIncludElementsParam = mergeObject(includeElements, defaultIncludeElements)

            data = {
                ...data,
                includeElements: includeElements
            }
        }

        return new Promise((resolve, reject) => {
            api.post(
                '/products/search',
                {
                    data: data,
                },
                { trackThisPromise: !runInBackground }
            )
                .then((response) => {
                    let dtoEntries = response.data.entry || []
                    let total = response.data.total || 0
                    let include = response.data.include || []

                    let entries = dtoEntries.map((dtoEntry) => {
                        let props = getProductModelTemplate(dtoEntry, include)

                        let [hasUnitofMeasure] = include.filter((o) => {
                            if (
                                o.type === 'UnitofMeasurement' &&
                                o.uri ===
                                getObjectKey(dtoEntry, 'hasUnitofMeasure/0')
                            ) {
                                return o
                            }
                        })

                        let definesAnnotation = dtoEntry.definesAnnotation.map((o) => {
                            return o
                        })

                        if (hasUnitofMeasure) {
                            props.hasUnitofMeasure = hasUnitofMeasure
                        }

                        if (definesAnnotation) {

                            definesAnnotation.sort((current, next) => {
                                if (current.productImagePosition > next.productImagePosition)
                                    return 1
                                else if (current.productImagePosition < next.productImagePosition)
                                    return -1

                                return 0
                            })

                            props.definesAnnotation = definesAnnotation
                        }

                        let [linksCategoryInclude] = include.filter((o) => {
                            if (
                                o.type === 'ProductCategory' &&
                                o.uri ===
                                getObjectKey(
                                    dtoEntry,
                                    'linksProductCategory/0/uri'
                                )
                            ) {
                                return o
                            }
                        })

                        if (linksCategoryInclude) {
                            props.linksProductCategory = linksCategoryInclude
                        }

                        let productData = ProductService.createProductModelTemplate(
                            props
                        )

                        return productData
                    })

                    let resolveResponse = {
                        meta: {
                            total: total,
                            offset: offset,
                            limit: limit,
                            filteredQuery: search,
                            sort: sort,
                        },
                        result: entries,
                    }
                    if (appOption && appOption.productsFetched) appOption.productsFetched(resolveResponse);

                    if (getPricing && entries.length) {
                        const nonConfigurableProducts = entries.filter((o) => !o.productConfigurable)

                        let productURIs = nonConfigurableProducts.map((o) => {
                            return o.uri
                        })

                        ProductService.getPricing(productURIs, undefined, true).then(
                            (pricingResponse) => {
                                if (pricingResponse !== null)
                                    findAndMergePricingData(
                                        entries,
                                        pricingResponse.data
                                    )

                                resolve(resolveResponse)
                            }
                        ).catch((err) => {
                            resolve(resolveResponse)
                        })
                    } else {
                        resolve(resolveResponse)
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getAllTopLevelCategories(runInBackground = false) {
        let path = '/productCategories/search'

        return new Promise((resolve, reject) => {
            api.post(path, {
                data: {
                    type: 'Search',
                    filter: 'hasParentProductCategory IS_NULL',
                }
            }, {trackThisPromise: !runInBackground})
                .then((response) => {
                    let dtoEntries = response.data.entry || []
                    let total = response.data.total || 0

                    let entries = dtoEntries.map((dtoEntry) => {
                        return dtoEntry
                    })

                    resolve({
                        meta: {
                            total: total,
                        },
                        result: entries,
                    })
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getAllChildCategories({ categoryId = '' }) {
        let path = '/productCategories/' + categoryId + '/getAllChildHierarchy'

        return new Promise((resolve, reject) => {
            api.get(path)
                .then((response) => {
                    let dtoEntries = response.data.entry || []
                    let total = response.data.total || 0

                    let entries = dtoEntries.map((dtoEntry) => {
                        return dtoEntry
                    })

                    resolve({
                        meta: {
                            total: total,
                        },
                        result: entries,
                    })
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    //only get direct child categories
    getAllDirectChildCategories({ categoryId = '' }) {
        return new Promise((resolve, reject) => {
            ProductService.getAllChildCategories({ categoryId: categoryId })
                .then((response) => {
                    let responseCopy = JSON.parse(JSON.stringify(response))
                    let filteredResult = responseCopy.result.filter((o) => {
                        if (
                            btoa(
                                getObjectKey(o, 'hasParentProductCategory/0')
                            ) === categoryId
                        ) {
                            return o
                        }
                    })
                    responseCopy.result = filteredResult
                    responseCopy.meta.total = filteredResult.length

                    resolve(responseCopy)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getComplementaryProducts(
        {
            type = 'Search',
            filter = '',
            search = '',
            limit = 9999,
            top = 0,
            useDefaultProductRestriction = true,
            include = [],
        },
        appOption
    ) {
        let finalFilterValue = filter
        if (useDefaultProductRestriction) {
            if (!isNilOrEmpty(filter))
                finalFilterValue = `${filter} AND ${this.getProductRestriction()}`
            else finalFilterValue = this.getProductRestriction()
        }

        let getPricing = true
        if (appOption) {
            if (
                appOption.hasOwnProperty('getPricing') &&
                typeof appOption.getPricing === 'boolean'
            ) {
                getPricing = appOption.getPricing
            }
        }

        const forScreens = [PRODUCT_CARD_TILE_SCREEN_CONFIG]
        const sections = getSectionsForScreens(forScreens)
        let dynamicIncludeParams = getAllIncludeParamForSections(sections)

        let aDefaultIncludes = [
            'hasUnitofMeasure:UnitofMeasurement',
            'definesAnnotation',
        ]

        dynamicIncludeParams = dynamicIncludeParams.concat(aDefaultIncludes)
        let uniqueIncludeParams = [...new Set(dynamicIncludeParams)]

        return new Promise((resolve, reject) => {
            api.post(
                '/products/search',
                {
                    data: {
                        type: type,
                        filter: finalFilterValue,
                        include: uniqueIncludeParams,
                    },
                },
                { trackThisPromise: false }
            )
                .then((response) => {
                    let complementaryDtoEntries = response.data.entry || []
                    let total = complementaryDtoEntries.length
                    const includes = getObjectKey(response, 'data/include')
                    const includeMap = new Map()

                    if (!isNilOrEmpty(includes)) {
                        for (let i = 0; i < includes.length; i++) {
                            const include = includes[i]
                            const includeBO = getObjectKey(include, 'uri')

                            if (!isNilOrEmpty(includeBO))
                                includeMap.set(includeBO, include)
                        }
                    }

                    let entries = complementaryDtoEntries.map((dtoEntry) => {
                        const uomBO = getObjectKey(
                            dtoEntry,
                            'hasUnitofMeasure/0'
                        )
                        const uom = includeMap.get(uomBO)

                        let productData = getProductModelTemplate(dtoEntry, includes)

                        // let productData = ProductService.createProductModelTemplate(
                        //     {
                        //         name: dtoEntry.objectName,
                        //         label: dtoEntry.label,
                        //         erpId: dtoEntry.objectERPId,
                        //         id: dtoEntry.id,
                        //         uri: dtoEntry.uri,
                        //         productImage: getMainProductImage(dtoEntry),
                        //         productUri: dtoEntry.uri,
                        //         productId: dtoEntry.id,
                        //         productClassificationUri:
                        //             dtoEntry.hasProductClassification[0],
                        //         hasUnitofMeasure: uom,
                        //         sourceObject: {
                        //             resource: dtoEntry,
                        //             include: includes,
                        //         },
                        //     }
                        // )

                        return productData
                    })

                    let filteredEntries = entries.filter((o) => {
                        if (o.productClassificationUri) {
                            if (
                                o.productClassificationUri ===
                                SEARCH_TYPE_OPTION_TO_ID.CONSUMABLES ||
                                o.productClassificationUri ===
                                SEARCH_TYPE_OPTION_TO_ID.SPARE_PARTS
                            ) {
                                return o
                            }
                        }
                    })

                    total = filteredEntries.length

                    let resolveResponse = {
                        meta: {
                            total: total,
                            filteredQuery: search,
                        },
                        result: filteredEntries,
                    }
                    if (getPricing && filteredEntries.length) {
                        if (appOption?.cb) appOption.cb(resolveResponse);
                        const nonConfigurableProducts = filteredEntries.filter((o) => !o.productConfigurable)
                        let productURIs = nonConfigurableProducts.map((o) => {
                            return o.uri
                        })

                        ProductService.getPricing(productURIs, 1, true).then(
                            (pricingResponse) => {
                                if (pricingResponse !== null)
                                    findAndMergePricingData(
                                        entries,
                                        pricingResponse.data
                                    )

                                resolve(resolveResponse)
                            }
                        )
                    } else {
                        if (appOption?.cb) appOption.cb(resolveResponse);
                        else resolve(resolveResponse)
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    extractRequiredData(inData, metadata) {
        //we extract what we need.

        return AdminService.extractRequiredData(inData, metadata)
    },

    getAllProductCategoriesTree(runInBackground = false) {
        return api.post('/productCategories/search', {
            data: {
                type: 'Search',
                limit: 99999,
                offset: 0,
            }
        },
            { trackThisPromise: !runInBackground })
    },

    async getPricing(productURI = [], quantity = 1, inBackground = false) {
        let data = reduxStore.getState()
        let salesInfo = data.userReducers.user.salesInfo.priceRequestParam

        let allHasValidValue = true
        for (let key in salesInfo) {
            if (!salesInfo[key]) allHasValidValue = false
        }

        if (allHasValidValue && !isNilOrEmpty(productURI)) {
            let linksItem = []

            if (!isNilOrEmpty(productURI)) {
                for (let i = 0; i < productURI.length; i++) {
                    linksItem.push({
                        linksProduct: productURI[i],
                        itemQuantity: quantity,
                    })
                }
            }

            let requestPayload = {
                ...JSON.parse(JSON.stringify(salesInfo)),
                linksItem: linksItem,
            }

            return api.post(
                '/pricing/product',
                {
                    data: requestPayload,
                },
                { trackThisPromise: !inBackground }
            );
        } else {
            return new Promise((resolve, reject) => {
                resolve(null)
                //return
                //TODO TEST
                //resolve( {
                //    data : [
                //        {
                //            "netPrice": 123,
                //            "listPrice": 163.000000,
                //            "discount": 20.000000,
                //            "linksProduct": "http://localhost:8080/iss/Product#847afecf41e34531ac8699bd7e2c2566",
                //            "includesPriceItemDetail": []
                //        },
                //        {
                //            "netPrice": 0.1200,
                //            "listPrice": 0.120000,
                //            "discount": 0.000000,
                //            "linksProduct": "http://40.114.200.164/800/Product#BAR",
                //            "includesPriceItemDetail": [
                //                {
                //                    "baseAmountValue": "150.0000",
                //                    "targetAmountValue": "0.1200",
                //                    "hasPriceItemType": "PR00",
                //                    "hasBaseCurrency": "http://www.inmindcloud.dev.com/application/application-implementation.owl#USD",
                //                    "hasTargetCurrency": "http://www.inmindcloud.dev.com/application/application-implementation.owl#EUR"
                //                },
                //                {
                //                    "baseAmountValue": "-3.0000",
                //                    "targetAmountValue": "0.0000",
                //                    "hasPriceItemType": "K007",
                //                    "hasBaseCurrency": "",
                //                    "hasTargetCurrency": "http://www.inmindcloud.dev.com/application/application-implementation.owl#EUR"
                //                },
                //                {
                //                    "baseAmountValue": "0.0000",
                //                    "targetAmountValue": "0.0000",
                //                    "hasPriceItemType": "RC00",
                //                    "hasBaseCurrency": "http://www.inmindcloud.dev.com/application/application-implementation.owl#EUR",
                //                    "hasTargetCurrency": "http://www.inmindcloud.dev.com/application/application-implementation.owl#EUR"
                //                },
                //                {
                //                    "baseAmountValue": "0.0000",
                //                    "targetAmountValue": "0.0000",
                //                    "hasPriceItemType": "ZWST",
                //                    "hasBaseCurrency": "",
                //                    "hasTargetCurrency": "http://www.inmindcloud.dev.com/application/application-implementation.owl#EUR"
                //                }
                //            ]
                //        },
                //        {
                //            "netPrice": 666.4300,
                //            "listPrice": 811.230000,
                //            "discount": -144.800000,
                //            "linksProduct": "http://40.114.200.164/800/Product#HR_COIL",
                //            "includesPriceItemDetail": [
                //                {
                //                    "baseAmountValue": "1000.0000",
                //                    "targetAmountValue": "811.2300",
                //                    "hasPriceItemType": "PR00",
                //                    "hasBaseCurrency": "http://www.inmindcloud.dev.com/application/application-implementation.owl#USD",
                //                    "hasTargetCurrency": "http://www.inmindcloud.dev.com/application/application-implementation.owl#EUR"
                //                },
                //                {
                //                    "baseAmountValue": "-5.0000",
                //                    "targetAmountValue": "-40.5600",
                //                    "hasPriceItemType": "KA00",
                //                    "hasBaseCurrency": "",
                //                    "hasTargetCurrency": "http://www.inmindcloud.dev.com/application/application-implementation.owl#EUR"
                //                },
                //                {
                //                    "baseAmountValue": "-3.0000",
                //                    "targetAmountValue": "-23.1200",
                //                    "hasPriceItemType": "K007",
                //                    "hasBaseCurrency": "",
                //                    "hasTargetCurrency": "http://www.inmindcloud.dev.com/application/application-implementation.owl#EUR"
                //                },
                //                {
                //                    "baseAmountValue": "-100.0000",
                //                    "targetAmountValue": "-81.1200",
                //                    "hasPriceItemType": "K004",
                //                    "hasBaseCurrency": "http://www.inmindcloud.dev.com/application/application-implementation.owl#USD",
                //                    "hasTargetCurrency": "http://www.inmindcloud.dev.com/application/application-implementation.owl#EUR"
                //                },
                //                {
                //                    "baseAmountValue": "0.0000",
                //                    "targetAmountValue": "0.0000",
                //                    "hasPriceItemType": "RC00",
                //                    "hasBaseCurrency": "http://www.inmindcloud.dev.com/application/application-implementation.owl#EUR",
                //                    "hasTargetCurrency": "http://www.inmindcloud.dev.com/application/application-implementation.owl#EUR"
                //                }
                //            ]
                //        },
                //        {
                //            "netPrice": 333.2100,
                //            "listPrice": 405.610000,
                //            "discount": -72.400000,
                //            "linksProduct": "http://40.114.200.164/800/Product#HR_COIL_HOLDER",
                //            "includesPriceItemDetail": [
                //                {
                //                    "baseAmountValue": "500.0000",
                //                    "targetAmountValue": "405.6100",
                //                    "hasPriceItemType": "PR00",
                //                    "hasBaseCurrency": "http://www.inmindcloud.dev.com/application/application-implementation.owl#USD",
                //                    "hasTargetCurrency": "http://www.inmindcloud.dev.com/application/application-implementation.owl#EUR"
                //                },
                //                {
                //                    "baseAmountValue": "-5.0000",
                //                    "targetAmountValue": "-20.2800",
                //                    "hasPriceItemType": "KA00",
                //                    "hasBaseCurrency": "",
                //                    "hasTargetCurrency": "http://www.inmindcloud.dev.com/application/application-implementation.owl#EUR"
                //                },
                //                {
                //                    "baseAmountValue": "-3.0000",
                //                    "targetAmountValue": "-11.5600",
                //                    "hasPriceItemType": "K007",
                //                    "hasBaseCurrency": "",
                //                    "hasTargetCurrency": "http://www.inmindcloud.dev.com/application/application-implementation.owl#EUR"
                //                },
                //                {
                //                    "baseAmountValue": "-50.0000",
                //                    "targetAmountValue": "-40.5600",
                //                    "hasPriceItemType": "K004",
                //                    "hasBaseCurrency": "http://www.inmindcloud.dev.com/application/application-implementation.owl#USD",
                //                    "hasTargetCurrency": "http://www.inmindcloud.dev.com/application/application-implementation.owl#EUR"
                //                },
                //                {
                //                    "baseAmountValue": "0.0000",
                //                    "targetAmountValue": "0.0000",
                //                    "hasPriceItemType": "RC00",
                //                    "hasBaseCurrency": "http://www.inmindcloud.dev.com/application/application-implementation.owl#EUR",
                //                    "hasTargetCurrency": "http://www.inmindcloud.dev.com/application/application-implementation.owl#EUR"
                //                }
                //            ]
                //        }
                //    ]
                //})
                //reject( { message : 'Incomplete parameters'})
            })
        }

        //let requestPayload = {
        //    hasAccount : '',
        //    hasSalesOrg : '',
        //    hasDistributionChannel : '',
        //    hasDivision : '',
        //    hasCurrency : '',
        //    linksProduct : productURI
        //    //"hasAccount" : "http://40.114.200.164/800/Account#0000001000",
        //    //"hasSalesOrg": "http://localhost:8080/iss/OrgUnit#22f2635696b44bdb903b26ad51f63cd1",
        //    //"hasDistributionChannel": "http://www.inmindcloud.dev.com/application/application-implementation.owl#DC-10",
        //    //"hasDivision": "http://www.inmindcloud.dev.com/application/application-implementation.owl#Div-00",
        //    //"hasCurrency": "http://www.inmindcloud.dev.com/application/application-implementation.owl#EUR",
        //}
        //
        //return api.get( '/pricing/product', {
        //    data : requestPayload
        //})
    },

    async getPricingByFilter(searchCriteria) {
        let data = reduxStore.getState()
        let salesInfo = data.userReducers.user.salesInfo.priceRequestParam;
        if (!searchCriteria?.sort?.length) searchCriteria.sort = ['label ASC'];
        if (!isNil(searchCriteria.search) && searchCriteria.search !== undefined) searchCriteria.search = {key: searchCriteria.search, "fields": [
            "objectName",
            "objectERPId",
            "label"
        ]};

        let allHasValidValue = true
        for (let key in salesInfo) {
            if (!salesInfo[key]) allHasValidValue = false
        }

        if (allHasValidValue) {
            let requestPayload = {
                ...searchCriteria,
                ...JSON.parse(JSON.stringify(salesInfo)),
                elements: {
                    bda: [''],
                    bra: ['']
                }
            }

            // const kbFilter = "containsKnowledgeBase IS_NULL"

            // requestPayload.filter = requestPayload.filter ? requestPayload.filter + ' AND ' + kbFilter : kbFilter

            return api.post(
                '/products/pricing',
                {
                    data: requestPayload,
                },
                { trackThisPromise: false }
            )
        } else {
            return new Promise((resolve, reject) => {
                resolve(null)
            })
        }
    },

    getProductRestriction() {
        return SEARCH_TYPE_OPTION_TO_DEFAULT_RESTRICTION_FILTER.GENERIC
        // const configuratorEnabled = CustomisationService.getCommerceInquirySetting()
        // if (configuratorEnabled) {
        //     return SEARCH_TYPE_OPTION_TO_DEFAULT_RESTRICTION_FILTER.GENERIC
        // } else {
        //     return SEARCH_TYPE_OPTION_TO_DEFAULT_RESTRICTION_FILTER.GENERIC_CONFIGURATOR_DISABLED
        // }
    },

    getProductClassifiedDetails(productId) {
        return api.get(`/products/${productId}/classifiedDetails`)
    },

    getAccountRecommendedProducts(accountBO) {
        return api.get('/products/accountRecommendations', {
                params: {
                    accountBO: accountBO
                },
            })
    },
 
    async updatePGARecords(accountBO, productERPs, status) {
        return api.post('/products/updatePGARecords', {
            params: {
                accountBO: accountBO,
                status: status
            },
            data: productERPs
        })
    },
    
    isProductEligibleForSparePrtFinder: (productId, runInBackground = false) => {
        const payload = {productId};
        const result = api.post('/products/isEligibleForSparePartsFinder', {
            data: payload
        }, {
            trackThisPromise: !runInBackground
        });
        return result;
    }
}
