export const MIN_CONTENT_WIDTH = 1024
export const MAX_CONTENT_WIDTH = 2560
export const FOOTER_PADDING_TOP = 70
export const QUCIK_ACTION_HEIGHT = 250
export const SEARCH_INPUT_HEIGHT = 50
export const RIBBON_HEIGHT = 490

//Permissions
export const SEND_ORDER_FOR_REVIEW_PERMISSION =
    'http://www.inmindcloud.com/application/schema.owl#SEND_ORDER_FOR_REVIEW_PERMISSION'
export const PLACE_ORDER_PERMISSION =
    'http://www.inmindcloud.com/application/schema.owl#PLACE_ORDER_PERMISSION'
export const REVIEW_ORDER_PERMISSION =
    'http://www.inmindcloud.com/application/schema.owl#REVIEW_ORDER_PERMISSION'
export const SUBMIT_INQUIRY_PERMISSION =
    'http://www.inmindcloud.com/application/schema.owl#SUBMIT_INQUIRY_PERMISSION'
export const REVIEW_INQUIRY_PERMISSION =
    'http://www.inmindcloud.com/application/schema.owl#REVIEW_INQUIRY_PERMISSION'
export const MANAGE_USER_PERMISSION =
    'http://www.inmindcloud.com/application/schema.owl#MANAGEUSERPERMISSION'

//Roles
export const ROLE_PORTAL_END_USER =
    'http://www.inmindcloud.com/application/schema.owl#ROLE_PORTAL_END_USER'
export const ROLE_PORTAL_BIZ_ADMIN =
    'http://www.inmindcloud.com/application/schema.owl#ROLE_PORTAL_BIZ_ADMIN'
export const ROLE_PORTAL_SYS_ADMIN =
    'http://www.inmindcloud.com/application/schema.owl#ROLE_PORTAL_SYS_ADMIN'
export const ROLE_PORTAL_SALES_ADMIN =
    'http://www.inmindcloud.com/application/schema.owl#ROLE_PORTAL_SALES_ADMIN'

//Order Status
export const STATUS_ORDER_COMPLETED =
    'http://www.inmindcloud.com/application/schema.owl#ORDER_COMPLETED'
export const STATUS_ORDER_SENT_TO_ERP =
    'http://www.inmindcloud.com/application/schema.owl#ORDER_SENT_TO_ERP'
export const STATUS_ORDER_PENDING_REVIEW = 'http://www.inmindcloud.com/application/schema.owl#ORDER_PENDING_REVIEW'
export const STATUS_ORDER_PENDING_PAYMENT = 'http://www.inmindcloud.com/application/schema.owl#ORDER_PENDING_PAYMENT'
export const STATUS_ORDER_WITHDRAWN = 'http://www.inmindcloud.com/application/schema.owl#ORDER_WITHDRAWN'
export const STATUS_ORDER_REJECTED = 'http://www.inmindcloud.com/application/schema.owl#ORDER_REJECTED'
export const STATUS_ORDER_CANCELLED = 'http://www.inmindcloud.com/application/schema.owl#ORDER_CANCELLED'

//Order Payment Mode
export const ORDER_PAYMENT_MODE_PO =
    'http://www.inmindcloud.com/application/schema.owl#OrderPaymentModePO'
export const ORDER_PAYMENT_MODE_DIRECT =
    'http://www.inmindcloud.com/application/schema.owl#OrderPaymentModeDirect'

//Commerce Payment Mode
export const COMMERCE_PAYMENT_MODE_BOTH =
    'http://www.inmindcloud.com/application/schema.owl#CommercePaymentModeBoth'
export const COMMERCE_PAYMENT_MODE_DIRECT =
    'http://www.inmindcloud.com/application/schema.owl#CommercePaymentModeDirect'
export const DEFAULT_PAYMENT_CONFIG_MODE =
    'http://www.inmindcloud.com/application/schema.owl#CommercePaymentModePO'
export const TYPE_PAYMENT_CONFIG_MODE =
    'http://www.inmindcloud.com/application/schema.owl#CommercePaymentMode'

//Decimal precision
export const DECIMAL_PRECISION = 2

//Pagination count
export const PAGINATION_ITEMS_PER_PAGE = 20

//sales-doc screen ID
export const SCREEN_ID_MY_ORDERS = 'MyOrders'
export const SCREEN_ID_ORDER_PLACED_BY_OTHERS = 'PlacedByOthers'
export const SCREEN_ID_MY_INQUIRIES = 'MyInquiries'
export const SCREEN_ID_INQUIRIED_BY_OTHERS = 'InquiriedByOthers'

//Date Filter Types
export const DURATION_FILTER_THIS_MONTH = 'DURATION_FILTER_THIS_MONTH'
export const DURATION_FILTER_LAST_MONTH = 'DURATION_FILTER_LAST_MONTH'
export const DURATION_FILTER_LAST_THREE_MONTH = 'DURATION_FILTER_LAST_THREE_MONTH'
export const DURATION_FILTER_LAST_SIX_MONTH = 'DURATION_FILTER_LAST_SIX_MONTH'
export const DURATION_FILTER_CUSTOM_RANGE = 'DURATION_FILTER_CUSTOM_RANGE'
