import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { UserService, CustomisationService, AdminService, MasterDataService, AuthenticationService } from '../services'
import { getAttributeFragment } from './index'

import iconExcel from '../assets/attachment-icons/Attachment-Excel.png'
import iconGif from '../assets/attachment-icons/Attachment-GIF.png'
import iconJpg from '../assets/attachment-icons/Attachment-JPG.png'
import iconPng from '../assets/attachment-icons/Attachment-PNG.png'
import iconTxt from '../assets/attachment-icons/Attachment-TXT.png'
import iconMediaEncoder from '../assets/attachment-icons/Attachment-Media-Enconder.png'
import iconOneNote from '../assets/attachment-icons/Attachment-OneNote.png'
import iconOtherFiles from '../assets/attachment-icons/Attachment-Other-files.png'
import iconPdf from '../assets/attachment-icons/Attachment-PDF.png'
import iconPowerpoint from '../assets/attachment-icons/Attachment-Powerpoint.png'
import iconSvg from '../assets/attachment-icons/Attachment-SVG.png'
import iconWord from '../assets/attachment-icons/Attachment-Microsoft-Word.png'
import iconURL from '../assets/attachment-icons/Attachment-URL.gif'
import iconZip from '../assets/attachment-icons/Attachment-Zip-File.png'
import { ROLE_PORTAL_SALES_ADMIN, ROLE_PORTAL_SYS_ADMIN, SEND_ORDER_FOR_REVIEW_PERMISSION, SUBMIT_INQUIRY_PERMISSION } from '../const/const'

const SETTING_COMMERCE_INQUIRY_ENABLED = 'SettingCommerceInquiryEnabled'

const getObjectKey = (object, path) => {
    let oValue = null

    if (isNil(object)) return oValue

    if (isNilOrEmpty(path)) return object
    else {
        const aPath = path.split('/')
        let oCurrentObject = null

        for (var iPathIndex = 0; iPathIndex < aPath.length; iPathIndex++) {
            var oPath = aPath[iPathIndex]

            if (!isNilOrEmpty(oPath)) {
                if (isNil(oCurrentObject)) oCurrentObject = object[oPath]
                else oCurrentObject = oCurrentObject[oPath]

                if (isNil(oCurrentObject)) {
                    break
                } else if (iPathIndex === aPath.length - 1) {
                    oValue = oCurrentObject
                }
            }
        }
    }

    return oValue
}

const getObjectValue = (oObject, sPath) => {
    var oValue = null

    if (isNil(oObject)) return oValue

    if (isNilOrEmpty(sPath)) oValue = getObjectKey(oObject, 'value')
    else {
        /**
         * check if sPath endsWith /value if not we append for the
         * developer
         */
        if (!sPath.endsWith('value')) {
            if (!sPath.endsWith('/')) sPath += '/'

            sPath += 'value'
        }

        oValue = getObjectKey(oObject, sPath)
    }

    return oValue
}

const setObjectKey = (obj, path, value) => {
    if (isNil(obj)) return false

    if (isNilOrEmpty(path)) return false
    else {
        let aPath = []
        let oCurrentObject = null

        aPath = path.split('/')

        if (path.startsWith('/')) aPath = aPath.splice(1)

        for (var iPathIndex = 0; iPathIndex < aPath.length; iPathIndex++) {
            const oPath = aPath[iPathIndex]

            if (!isNilOrEmpty(oPath)) {
                if (
                    (!isNil(oCurrentObject) &&
                        oCurrentObject.hasOwnProperty(oPath)) ||
                    obj.hasOwnProperty(oPath)
                ) {
                    if (iPathIndex < aPath.length - 1) {
                        if (iPathIndex === 0) oCurrentObject = obj[oPath]
                        else oCurrentObject = oCurrentObject[oPath]
                    } else if (iPathIndex === aPath.length - 1) {
                        if (!isNil(oCurrentObject))
                            oCurrentObject[oPath] = value
                        else if (!isNil(obj)) obj[oPath] = value
                    }
                } else {
                    if (iPathIndex === aPath.length - 1) {
                        if (!isNil(oCurrentObject))
                            oCurrentObject[oPath] = value
                        else if (!isNil(obj)) obj[oPath] = value
                    }
                    break
                }
            }
        }
    }
}

const getObjectBoolean = (oObject, sPath) => {
    var oValue = false

    if (isNil(oObject)) return oValue

    if (isNilOrEmpty(sPath)) return oValue

    if (isNilOrEmpty(getObjectKey(oObject, sPath))) return oValue
    else {
        return getObjectKey(oObject, sPath)
    }
}

const isDifferent = (val1, val2) => {
    let out = true

    if(isNilOrEmpty(val1) && isNilOrEmpty(val2))
        out = false
    
    if(val1 === val2)
        out = false

    return out
}

const getStringByKey = (object, path) => {
    var value = getObjectKey(object, path)
    if (!Array.isArray(value)) return value
    if (Array.isArray(value) && value.length > 0) return value[0]
}

const getLabelOrObjectName = (o) => {
    const lebel = getStringByKey(o, 'label')
    if (!isNilOrEmpty(lebel)) return lebel

    return getStringByKey(o, 'objectName')
}

const useSetDocumentTitle = ({ append = '' }) => {
    const documentTitleBrand = useSelector((state) => {
        return state.customisationReducers.commerceCompanyName
    })

    let finalDisplayBrand = '...'
    if (documentTitleBrand) {
        finalDisplayBrand = documentTitleBrand
    }

    if (append) {
        document.title = finalDisplayBrand + ' - ' + append
    } else {
        document.title = finalDisplayBrand
    }
}

const useGetDefaultBrandName = () => {
    const defaultPortalCompanyName = useSelector((state) => {
        return state.customisationReducers.commerceCompanyName
    })

    return defaultPortalCompanyName
}

const isNil = (value) => {
    return value == null
}

const isNilOrEmpty = (value) => {
    if (isNil(value)) return true
    else {
        if (Array.isArray(value)) return value.length === 0
        else if (typeof value === 'string') return value.trim().length === 0
        else if (typeof value === 'number') return isNil(value)
        else if (typeof value === 'boolean') return isNil(value)
        else return Object.keys(value).length === 0
    }
}

const isInvalidEmail = value => {
    if (!value) return true;
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !re.test(value);
}

const isInvalidNumberField = value => {
    const re = /^\d+$/;
    return !re.test(value);
};

const uriFragment = (value) => {
    try {
        const url = new URL(value)
        const hash = url.hash

        return !isNilOrEmpty(hash) ? hash.substr(1) : ''
    } catch (e) {
        return ''
    }
}

const hasOwnProperty = (oObject, sPath) => {
    var bValue = false

    if (isNil(oObject)) {
        bValue = false
        return bValue
    }

    if (isNilOrEmpty(sPath)) {
        bValue = false
        return false
    }

    var aPath = sPath.split('/'),
        oCurrentObject
    for (var iPathIndex = 0; iPathIndex < aPath.length; iPathIndex++) {
        var oPath = aPath[iPathIndex]

        if (!isNilOrEmpty(oPath)) {
            if (isNilOrEmpty(oCurrentObject)) oCurrentObject = oObject[oPath]
            else oCurrentObject = oCurrentObject[oPath]

            if (isNil(oCurrentObject)) {
                bValue = false
                break
            } else if (iPathIndex === aPath.length - 1) bValue = true
        }
    }

    return bValue
}

const useGetUserLanguage = () => {
    const localeKey = useSelector((state) => {
        return state.userReducers.user.userLanguage
    })

    return localeKey || ''
}

const useGetUserDisplayName = () => {
    const userFirstLastName = useSelector((state) => {
        return {
            personFirstNameValue: getObjectValue(
                state.userReducers,
                'user/personFirstName'
            ),
            personLastNameValue: getObjectValue(
                state.userReducers,
                'user/personLastName'
            ),
        }
    })

    return (
        userFirstLastName.personFirstNameValue +
        ' ' +
        userFirstLastName.personLastNameValue
    )
}

const useGetUsername = () => {
    const userName = useSelector((state) => {
        return state.userReducers.session.userName
    })

    return userName
}

const useGetUserMobileNumber = () => {
    const mobile = useSelector((state) => {
        return getObjectValue(state.userReducers, 'user/personMobile')
    })

    return mobile
}

const imageSrc = (img) => {
    if (isNilOrEmpty(img)) return ''
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href)
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return process.env.REACT_APP_IMAGE_URL + img
    } else {
        let path = publicUrl.pathname
        path = path.replace('portal', 'api')
        return `${publicUrl.origin + path + 'images/Product/' + img}`
    }
}

const dynamicImageSrcRoot = (img) => {
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href)

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return process.env.REACT_APP_IMAGE_ROOT_URL + img
    } else {
        let path = publicUrl.pathname
        path = path.replace('portal', 'api')
        return `${publicUrl.origin + path + 'images/' + img}`
    }
}

const productCategoryImageSrc = (img, productCategoryUri) => {
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href)

    if (typeof img !== 'string') return ''

    let imageFileName = img.split('|')[0]
    let categoryAttributeFragment = getAttributeFragment(productCategoryUri)

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        let final =
            process.env.REACT_APP_IMAGE_ROOT_URL +
            'ProductCategory/' +
            categoryAttributeFragment +
            '/' +
            imageFileName
        return final
    } else {
        let path = publicUrl.pathname
        path = path.replace('portal', 'api')
        let final = `${publicUrl.origin +
            path +
            'images/ProductCategory/' +
            categoryAttributeFragment +
            '/' +
            imageFileName
            }`

        return final
    }
}

const productImageSrc = (img, type, productGroupUri) => {
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href)

    if (typeof img !== 'string') return ''

    let imageFileName = img.split('|')[0]
    let groupAttributeFragment = getAttributeFragment(productGroupUri)

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        let final =
            process.env.REACT_APP_IMAGE_ROOT_URL +
            type +
            '/' +
            groupAttributeFragment +
            '/' +
            imageFileName
        return final
    } else {
        let path = publicUrl.pathname
        path = path.replace('portal', 'api')
        let final = `${publicUrl.origin +
            path +
            'images/' +
            type +
            '/' +
            groupAttributeFragment +
            '/' +
            imageFileName
            }`

        return final
    }
}

/**
 * whenever logo tree has updated props, check for latest src value.
 *
 * if !src value, return defaultSrc value. else return src value.
 */

const staticImageSrc = (img) => {
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href)
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(publicUrl.href + img)
        return publicUrl.href + img
    } else {
        let path = publicUrl.pathname
        path = path.replace('portal', 'api')
        return `${publicUrl.origin + path + img}`
    }

    console.log(process.env.PUBLIC_URL + img)
    return `${process.env.PUBLIC_URL + img}`
}

const useSetFavicon = () => {
    const { allMediaFile, faviconDefaultSrc } = useSelector((state) => {
        return JSON.parse(
            JSON.stringify({
                allMediaFile:
                    state.customisationReducers.commerceMediaFile.items,
                faviconDefaultSrc:
                    state.customisationReducers.favicon.defaultSrc,
            })
        )
    })

    const [faviconData] = allMediaFile.filter((o) => {
        if (
            o.uri ===
            'http://www.inmindcloud.com/application/schema.owl#PortalFavouriteLogo'
        ) {
            return o
        }
    })

    let finalSrcPath = process.env.PUBLIC_URL + faviconDefaultSrc

    if (faviconData && faviconData.mediaFilePath) {
        finalSrcPath = dynamicImageSrcRoot(faviconData.mediaFilePath)
    }

    let faviconDOM = document.getElementById('favicon')
    if (faviconDOM) {
        faviconDOM.href = finalSrcPath
    }
}

const useGetLogoSrc = () => {
    const { allMediaFile, logoDefaultSrc } = useSelector((state) => {
        return JSON.parse(
            JSON.stringify({
                allMediaFile:
                    state.customisationReducers.commerceMediaFile.items,
                logoDefaultSrc: state.customisationReducers.logo.defaultSrc,
            })
        )
    })

    const [logoData] = allMediaFile.filter((o) => {
        if (
            o.uri ===
            'http://www.inmindcloud.com/application/schema.owl#PortalMediaFileCorporateLogo'
        ) {
            return o
        }
    })

    if (logoData && logoData.mediaFilePath)
        return dynamicImageSrcRoot(logoData.mediaFilePath)
    return process.env.PUBLIC_URL + logoDefaultSrc
}

const useGetWhiteLogoSrc = () => {
    const { allMediaFile, logoDefaultSrc } = useSelector((state) => {
        return JSON.parse(
            JSON.stringify({
                allMediaFile:
                    state.customisationReducers.commerceMediaFile.items,
                logoDefaultSrc: state.customisationReducers.whiteLogo.defaultSrc,
            })
        )
    })

    const [logoData] = allMediaFile.filter((o) => {
        if (
            o.uri ===
            'http://www.inmindcloud.com/application/schema.owl#PortalMediaFileWhiteLogo'
        ) {
            return o
        }
    })

    if (logoData && logoData.mediaFilePath)
        return dynamicImageSrcRoot(logoData.mediaFilePath)
    return process.env.PUBLIC_URL + logoDefaultSrc
}

const useGetDefaultLogoSrc = () => {
    const { logoDefaultSrc } = useSelector((state) => {
        return JSON.parse(
            JSON.stringify({
                logoDefaultSrc: state.customisationReducers.logo.defaultSrc,
            })
        )
    })

    return process.env.PUBLIC_URL + logoDefaultSrc
}
const useGetDefaultFaviconSrc = () => {
    const { faviconDefaultSrc } = useSelector((state) => {
        return JSON.parse(
            JSON.stringify({
                faviconDefaultSrc:
                    state.customisationReducers.favicon.defaultSrc,
            })
        )
    })
}

const useGetAvatarSrc = () => {
    const avatarData = useSelector((state) => {
        let containsPersonInstanceIdFragment = getAttributeFragment(
            state.userReducers.user.instanceId.value
        )

        let personPictureValue = ''
        let personPictureRawValue = getObjectValue(
            state.userReducers,
            'user/personPicture/'
        )
        let aSplitPersonPicture = !isNil(personPictureRawValue)
            ? personPictureRawValue.split('|')
            : []

        if (personPictureRawValue) {
            let imagePath =
                'Person/' +
                containsPersonInstanceIdFragment +
                '/' +
                aSplitPersonPicture[0]
            personPictureValue = dynamicImageSrcRoot(imagePath)
        }

        return {
            defaultSrc: state.customisationReducers.defaultAvatar.src,
            userSrc: personPictureValue,
        }
    })

    if (avatarData.userSrc) return avatarData.userSrc
    return avatarData.defaultSrc
}

const useGetDefaultLoginViewBannerSrc = () => {
    const { loginBannerDefaultSrc } = useSelector((state) => {
        return JSON.parse(
            JSON.stringify({
                loginBannerDefaultSrc:
                    state.customisationReducers.loginViewBanner.defaultSrc,
            })
        )
    })

    return process.env.PUBLIC_URL + loginBannerDefaultSrc
}

const useGetBrandName = () => {
    const portalCompanyName = useSelector((state) => {
        return state.customisationReducers.commerceCompanyName
    })
    const fallbackBrandNameData = useSelector((state) => {
        return state.customisationReducers.brandName
    })

    if (portalCompanyName) return portalCompanyName
    return fallbackBrandNameData.defaultValue
}

const useGetAllMyPermission = () => {
    const permissionValues = useSelector((state) => {
        let value = []
        let valueFromStore = state.userReducers.user.permissions.value
        if (Array.isArray(valueFromStore)) value = [].concat(valueFromStore)
        return value
    })

    return permissionValues
}

const useCanAccessAdminView = () => {
    let permissions = useGetAllMyPermission()
    let hasPortalAdminPermission = false
    hasPortalAdminPermission = permissions.find((permissionBO) => {
        if (permissionBO.indexOf('#PORTALADMINUIPERMISSION') > -1) return true
    })

    return hasPortalAdminPermission
}

const useScrollToTop = () => {
    const { pathname } = useLocation()

    useEffect(() => {
        let ignore = false
        if (!ignore) window.scrollTo(0, 0)

        return () => {
            ignore = true
        }
    }, [pathname])

    return null
}

//retuns true for Roles ROLE_PORTAL_SYS_ADMIN and ROLE_PORTAL_SALES_ADMIN
const checkEmployeeUser = (userData) => {
    const hasRole = userData?.hasRole?.value

    if (hasRole === ROLE_PORTAL_SYS_ADMIN || hasRole === ROLE_PORTAL_SALES_ADMIN) {
        CustomisationService.setEmployeeUser(true)
        return true
    }

    return false
}

const initialAppData = async (callBackCommerceOrderAndInquirySettings, callBackUserBO) => {
    let promise = new Promise((resolve, reject) => {
        let promises = []
        promises.push(UserService.getMyUserDetail(callBackUserBO))
        promises.push(CustomisationService.getInitialSettingData())
        promises.push(CustomisationService.getAllLanguages())
        //promises.push(CustomisationService.getMetaDataByType('Product'))

        Promise.all(promises)
            .then(
                ([
                    userData,
                    customisationData,
                    languageMasterData,
                ]) => {
                    let inquiryEnabled = false
                    let orderEnabled = false

                    const isEmployeeUser = checkEmployeeUser(userData)
                    if (userData?.username?.value) AuthenticationService.setLoggedInUserName(userData.username.value);

                    if (!isEmployeeUser) {
                        const bCommerceEnableCustomerAccountInquiry = !isNilOrEmpty(customisationData.commerceEnableCustomerAccountInquiry) ? customisationData.commerceEnableCustomerAccountInquiry : false
                        const bCommerceEnableProspectAccountInquiry = !isNilOrEmpty(customisationData.commerceEnableProspectAccountInquiry) ? customisationData.commerceEnableProspectAccountInquiry : false

                        const bCommerceEnableCustomerAccountOrder = !isNilOrEmpty(customisationData.commerceEnableCustomerAccountOrder) ? customisationData.commerceEnableCustomerAccountOrder : false
                        const bCommerceEnableProspectAccountOrder = !isNilOrEmpty(customisationData.commerceEnableProspectAccountOrder) ? customisationData.commerceEnableProspectAccountOrder : false

                        const bIsProspectAccount = !isNilOrEmpty(userData.isProspectAccount) ? userData.isProspectAccount : false

                        if (bIsProspectAccount) {
                            inquiryEnabled = bCommerceEnableProspectAccountInquiry
                            orderEnabled = bCommerceEnableProspectAccountOrder
                        } else {
                            inquiryEnabled = bCommerceEnableCustomerAccountInquiry
                            orderEnabled = bCommerceEnableCustomerAccountOrder
                        }

                        // let inquiryEnabled = bCommerceEnableCustomerAccountInquiry || bCommerceEnableProspectAccountInquiry
                        // let orderEnabled = bCommerceEnableCustomerAccountOrder || bCommerceEnableProspectAccountOrder

                        let userDeniedPermissions = userData.hasDeniedPermission ? userData.hasDeniedPermission.value : undefined

                        if (orderEnabled) {
                            let index = userDeniedPermissions.indexOf(SEND_ORDER_FOR_REVIEW_PERMISSION)
                            if (index > -1) {
                                orderEnabled = false
                            }
                        }

                        if (inquiryEnabled) {
                            let index = userDeniedPermissions.indexOf(SUBMIT_INQUIRY_PERMISSION)
                            if (index > -1) {
                                inquiryEnabled = false
                            }
                        }
                    }

                    const orderInquirySettings = {
                        settingCommerceInquiryEnabled: inquiryEnabled,
                        settingCommerceOrderEnabled: orderEnabled
                    }

                    if (callBackCommerceOrderAndInquirySettings)
                        callBackCommerceOrderAndInquirySettings(orderInquirySettings)

                    CustomisationService.setCommerceInquirySetting(
                        inquiryEnabled
                    )
                    CustomisationService.setCommerceOrderSetting(
                        orderEnabled
                    )

                    resolve([
                        userData,
                        customisationData,
                        languageMasterData,
                    ])
                }
            )
            .catch((errorResponse) => {
                reject(errorResponse)
            })
    })

    return promise
}

const getUnion = function (arr1, arr2) {
    var ret = []
    arr1.sort()
    arr2.sort()
    for (var i = 0; i < arr1.length; i += 1) {
        if (arr2.indexOf(arr1[i]) > -1) {
            ret.push(arr1[i])
        }
    }
    return ret
}

const formatDate = (value) => {
    var options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    }
    if (!isNilOrEmpty(value)) {
        return new Intl.DateTimeFormat('default', options).format(new Date(value).getTime())
    } else {
        return ''
    }
}

const formatDateYear = (value) => {
    var options = {
        year: 'numeric',
    }
    if (!isNilOrEmpty(value)) {
        return new Intl.DateTimeFormat('default', options).format(value)
    } else {
        return ''
    }
}

const getNavigationRoutePath = (objectId) => {
    if (
        isNilOrEmpty(
            CustomisationService.PortalNavigationPage_RouteMapping[objectId]
        )
    )
        return CustomisationService.getCustomRouteMapping(objectId)
    else return CustomisationService.PortalNavigationPage_RouteMapping[objectId]
}

const getHomeBannerAspectRatio = () => {
    return 1920 / 782
}
const getLoginBannerAspectRatio = () => {
    return 1920 / 782
}

const getAttachmentIcon = ({ attachmentType = '', objectName = '' }) => {
    //return other file type icon first. all icon dimension are inconsistent
    let image = iconOtherFiles

    switch (attachmentType) {
        case 'image/jpeg':
            image = iconJpg
            break
        case 'image/png':
            image = iconPng
            break
        case 'image/gif':
            image = iconGif
            break
        case 'application/zip':
            image = iconZip
            break
        case 'application/pdf':
            image = iconPdf
            break
        case 'uri':
            image = iconURL
            break
        case 'text/plain':
            image = iconTxt
            break
        case 'application/octet-stream':
            if (objectName.indexOf('.doc') > -1 || objectName.indexOf('.docx') > -1) {
                image = iconWord
            }
            if (objectName.indexOf('.xls') > -1 || objectName.indexOf('.xlsx') > -1) {
                image = iconExcel
            }
            if (objectName.indexOf('.ppt') > -1 || objectName.indexOf('.pptx') > -1) {
                image = iconPowerpoint
            }
            break
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            image = iconExcel
            break
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            image = iconPowerpoint
            break
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            image = iconWord
            break
        default:
            image = iconOtherFiles
    }

    return image
}

const useIsInitialAppDataLoaded = () => {
    const timeStampLoaded = useSelector((state) => {
        return state.userReducers.initialDataLoaded
    })
    return timeStampLoaded
}

const getCssBackgroundImageValue = (fullImageSrc = '') => {
    let out = ''
    if (fullImageSrc) {
        out = `url("${fullImageSrc}")`
    }

    return out
}

const getSortedArray = (array, sortFiled) => {
    let sortedArray = array
    if (!isNilOrEmpty(array)) {
        sortedArray = array.sort((a, b) => {
            const val1 = getStringByKey(a, sortFiled)
            const val2 = getStringByKey(b, sortFiled)
            if (val1 < val2) {
                return -1
            }
            if (val1 > val2) {
                return 1
            }
            return 0
        })
    }
    return sortedArray
}

const sortArrayByObjectKey = (options) => {
    var o = {
        objectArray: [],
        sortKey: '',
        secondarySortKey: '',
        sortOrder: 'ascend',
    }
    Object.assign(o, options)

    if (isNilOrEmpty(o.objectArray)) return false

    if (o.objectArray.length > 0) {
        const fnSecondaryCompare = (o1, o2) => {
            let sO1 = getObjectKey(o1, o.secondarySortKey)
            let sO2 = getObjectKey(o2, o.secondarySortKey)

            if (isNilOrEmpty(sO1) && !isNilOrEmpty(sO2)) return -1
            else if (!isNilOrEmpty(sO1) && isNilOrEmpty(sO2)) return 1
            else if (isNilOrEmpty(sO1) && isNilOrEmpty(sO2)) return 0
            else {
                sO1 = sO1.toString()
                sO2 = sO2.toString()

                let iPosition = sO1.localeCompare(sO2, 'en', {
                    sensitivity: 'base',
                    numeric: true,
                })

                if (o.sortOrder === 'ascend') return iPosition
                else return iPosition * -1
            }
        }

        o.objectArray.sort((o1, o2) => {
            let sO1 = getObjectKey(o1, o.sortKey)
            let sO2 = getObjectKey(o2, o.sortKey)

            if (isNilOrEmpty(sO1) && !isNilOrEmpty(sO2)) return -1
            else if (!isNilOrEmpty(sO1) && isNilOrEmpty(sO2)) return 1
            else if (isNilOrEmpty(sO1) && isNilOrEmpty(sO2)) return 0
            else {
                sO1 = sO1.toString()
                sO2 = sO2.toString()

                let iPosition = sO1.localeCompare(sO2, 'en', {
                    sensitivity: 'base',
                    numeric: true,
                })

                if (o.sortOrder === 'ascend') {
                    if (isNilOrEmpty(o.secondarySortKey)) return iPosition
                    else {
                        if (iPosition !== 0) return iPosition
                        else return fnSecondaryCompare(o1, o2)
                    }
                } else {
                    if (isNilOrEmpty(o.secondarySortKey)) return iPosition * -1
                    else return fnSecondaryCompare(o1, o2)
                }
            }
        })
    }

    return o.objectArray
}

const isFontGrotesque = (font) => font === 'Grotesque';

const updateFontFamily = (fontFamilies) => {
    return fontFamilies ? fontFamilies.filter(f => !isFontGrotesque(f?.objectName?.value)) : [];
};
const getQueryString = (qs, key) => {
    const urlParams = new URLSearchParams(qs);
    return urlParams.get(key);
};

const getDefaultStringValue = value => typeof value === 'string' ? value : '';

const createAddressField = (street, city, countryName, state, zip) => {
    const fields = [street, city, state, countryName, zip];
    let finalAddressField = '';
    for (const [index, item] of fields.entries()) {
        if (getDefaultStringValue(item)) {
            finalAddressField += index === 0 ? item : `, ${item}`;
        }
    }
    return finalAddressField;
}

const filterSalesInfoData = (list, searchTerm, fieldsToFilter, performSearch) => {
    if (!list || !fieldsToFilter || !searchTerm || !performSearch) return list;
    const filteredData = list.filter(item => {
        let containsText = false;
        for (const f of fieldsToFilter) {
            const fieldValue = item[f.key];
            if (fieldValue) {
                containsText = fieldValue.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
                if (containsText) break;
            }
        }
        return containsText;
    });
    return filteredData;
};

const validateFileExtension = (fileName, extension) => {
    if (fileName && extension) {
        const validExtensionRegularExpression = new RegExp('^.{1,}' + '(.' + extension + ')', 'i');
        return validExtensionRegularExpression.test(fileName);
    }
    return false;
};

const handleAPIError = exception => {
    let error = 'Unexpected Error';
    const {response, message, request, data} = exception;
    if (response?.data) error = response.data.detail ? response.data.detail : (response.data.message ? response.data.message : response.statusText);
    else if (request?.responseText) error = request.responseText;
    else if (data) error = error.message;
    else if (message) error = message;
    return error;
};

export {
    getAttachmentIcon,
    useSetDocumentTitle,
    useSetFavicon,
    useGetLogoSrc,
    useGetWhiteLogoSrc,
    useGetDefaultLogoSrc,
    useGetDefaultFaviconSrc,
    useGetDefaultLoginViewBannerSrc,
    useGetDefaultBrandName,
    useGetBrandName,
    useScrollToTop,
    useGetAvatarSrc,
    useGetUserDisplayName,
    useGetUsername,
    useGetUserMobileNumber,
    useGetUserLanguage,
    getObjectKey,
    getObjectValue,
    getObjectBoolean,
    isDifferent,
    getStringByKey,
    getLabelOrObjectName,
    hasOwnProperty,
    isNil,
    isNilOrEmpty,
    isInvalidEmail,
    isInvalidNumberField,
    initialAppData,
    uriFragment,
    imageSrc,
    staticImageSrc,
    dynamicImageSrcRoot,
    productCategoryImageSrc,
    productImageSrc,
    formatDate,
    formatDateYear,
    useGetAllMyPermission,
    useCanAccessAdminView,
    getNavigationRoutePath,
    getHomeBannerAspectRatio,
    getLoginBannerAspectRatio,
    getUnion,
    useIsInitialAppDataLoaded,
    getCssBackgroundImageValue,
    getSortedArray,
    setObjectKey,
    sortArrayByObjectKey,
    updateFontFamily,
    getQueryString,
    getDefaultStringValue,
    createAddressField,
    filterSalesInfoData,
    validateFileExtension,
    handleAPIError
}
