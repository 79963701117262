export const INITIAL_SEARCH_HISTORY = 'INITIAL_SEARCH_HISTORY'
export const ADD_SEARCH_HISTORY = 'ADD_SEARCH_HISTORY'
export const REMOVE_SEARCH_HISTORY = 'REMOVE_SEARCH_HISTORY'
export const REMOVE_ALL_SEARCH_HISTORY = 'REMOVE_ALL_SEARCH_HISTORY'

export const UPDATE_PREVIOUS_RAW_QUERY_VALUE = 'UPDATE_PREVIOUS_RAW_QUERY_VALUE'
export const ADD_SEARCH_SETTING_FILTER = 'ADD_SEARCH_SETTING_FILTER'
export const UPDATE_SEARCH_SETTING_FILTER_OPTION =
    'UPDATE_SEARCH_SETTING_FILTER_OPTION'
export const REMOVE_SEARCH_SETTING_FILTER = 'REMOVE_SEARCH_SETTING_FILTER'
export const REMOVE_ALL_SEARCH_SETTING_FILTER =
    'REMOVE_ALL_SEARCH_SETTING_FILTER'

export const ADD_SEARCH_SETTING_SORTER = 'ADD_SEARCH_SETTING_SORTER'
export const UPDATE_SEARCH_SETTING_SORTER_OPTION =
    'UPDATE_SEARCH_SETTING_SORTER_OPTION'
export const REMOVE_SEARCH_SETTING_SORTER = 'REMOVE_SEARCH_SETTING_SORTER'
export const REMOVE_ALL_SEARCH_SETTING_SORTER =
    'REMOVE_ALL_SEARCH_SETTING_SORTER'

export const UPDATE_SEARCH_SETTING_MODE = 'UPDATE_SEARCH_SETTING_MODE'
