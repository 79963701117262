import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {Box, Typography, TextField, makeStyles, Slide, Button, Link, CircularProgress} from '@material-ui/core';
import {useSnackbar} from 'notistack';

import {TranslationService, SignupService} from '../../../services';
import {isNilOrEmpty, isInvalidEmail} from '../../../helpers/helpers';

import Recaptcha from 'react-recaptcha';

const useStyles = makeStyles(theme => ({
    signupOuterContainer: {
         margin:20
    },
    signupTitle: {
        marginBottom: theme.spacing(2)
    },
    input: {
        marginBottom: theme.spacing(2),
        fontSize: '0.7777777777777778rem'
    },
    verifyButton: {
        marginTop: theme.spacing(2)
    },
    existingUser: {
        marginTop: theme.spacing(),
        marginBottom: theme.spacing(1),
        textAlign: 'center'
    },
    circularProgress: {
        color: '#ffffff'
    },
    grecaptcha: {
        position: 'relative',
        left: '13px'
    }
}));

const SignupForm = () => {
    const [formData, setFormData] = useState({
        userEmail: {value: '', error: '', validators: [{fn: isNilOrEmpty, invalidMessage: 'registration.error.invalidEmail'}, {fn: isInvalidEmail, invalidMessage: 'registration.error.invalidEmail'}]}
    });
    const [submitted, setSubmitted] = useState(false);
    const [apiCallData, setAPICallData] = useState({
        progress: false,
        error: null
    });
    const {t} = TranslationService.useTranslation();
    const {enqueueSnackbar} = useSnackbar();
    const history = useHistory();
    const classes = useStyles();

    const [captchaVisible, setCaptchaVisible] = useState(false)
    const [captchaProvider, setCaptchaProvider] = useState("")
    const [captchaSiteKey, setCaptchaSiteKey] = useState("")
    const [verifyButtonDisabled, setVerifyButtonDisabled] = useState(true)
    const [initialLoad, setInitialLoad] = useState(true);
    const [emailEmpty, setEmailEmpty] = useState(true)

    const validateFormDataField = (frmData, fieldName) => {
        const formDataField = frmData[fieldName];
        if (formDataField.validators) {
            for (const val of formDataField.validators) {
                const valid = !val.fn(formDataField.value);
                if (!valid) {
                    formDataField.error = t(val.invalidMessage);
                    break;
                }
                else formDataField.error = '';
            }
        }
    };

    const validateArguments = (fieldName, validateAll = false) => {
        if (fieldName && !submitted) return true;
        const tempFormData = {...formData};
        for (const key of Object.keys(tempFormData)) {
            if (!validateAll) {
                if (key === fieldName) {
                    validateFormDataField(tempFormData, key);
                }
            } else validateFormDataField(tempFormData, key);
        }
        setFormData(tempFormData);
        if (validateAll) {
            let allValid = true;
            for(const key of Object.keys(tempFormData)) {
                const field = tempFormData[key];
                if (!isNilOrEmpty(field.error)) {
                    allValid = false;
                    break;
                }
            }
            return allValid;
        }
    };

    useEffect(() => {
        if (apiCallData.error) setAPICallData({...apiCallData, error: null});
        validateArguments('userEmail');
    }, [formData.userEmail.value]);

    useEffect (()=> {
        if (initialLoad == true)
        {
            //api call
            SignupService.getCaptchaDetail().then(response => {
                //set value/state (have/no captcha)

                // response.data = {Name: "CAPTCHA_AUTHENTICATION",
                //     URL: "https://www.google.com/recaptcha",
                //     active: "true",
                //     isCaptchaAuthentication: "true",
                //     provider: "recaptcha_v2",
                //     sitekey: "6LefoN4bAAAAAPNvWUFOA8R1vsz4VKFLAZVrtu42"
                // }
                if (!isNilOrEmpty(response.data.provider) && !isNilOrEmpty(response.data.sitekey))
                {
                    setCaptchaVisible(true)
                    setVerifyButtonDisabled(true)
                    setCaptchaProvider(response.data.provider)
                    setCaptchaSiteKey(response.data.sitekey)                    
                }
                else {
                    setVerifyButtonDisabled(false)
                }
            }).catch(error => {});
        }
        setInitialLoad(false)
    })

    const handleFieldChange = e => {
        const fieldName = e.target.id;
        const fieldValue = e.target.value;
        setFormData({...formData, [fieldName]: {...formData[fieldName], value: fieldValue}})

        if (!isNilOrEmpty(e.target.value)) setEmailEmpty(false)
        else setEmailEmpty(true)
    };

    const handleResponse = response => {
        const {UserExists, Status} = response;
        if (UserExists) {
            setAPICallData({...apiCallData, error: t('registration.error.userExist')});
        } else if (Status.toLowerCase() === 'verification_email_sent') {
            history.push('/selfRegistration/success');
        }
    };

    const handleError = error => {
        const responseError = error?.response?.data;
        if (responseError && responseError.hasError) {
            if (responseError.errorMessageLabel === 'NON_BUSINESS_DOMAIN') {
                setAPICallData({...apiCallData, error: t('registration.error.nonBusinessDomain')});
            } else {
                setAPICallData({...apiCallData, error: t('registration.error.invalidEmail')});
            }
        } else {
            enqueueSnackbar(t('general.generalErrorMsg'), {
                variant: 'error'
            });
        }
    };

    const recaptchaCallback = (e) => {
        if (e)
            setVerifyButtonDisabled(false)            
    };

    const recaptchaExpiredCallback = () => {
        setVerifyButtonDisabled(true)
    }
    
    const handleVerifyClick = (e) => {
        setSubmitted(true);
        if (!apiCallData.progress) {
            const {userEmail} = formData;
            const allFieldsValid = validateArguments(null, true);
            if (allFieldsValid) {
                setAPICallData({...apiCallData, progress: true, error: null});
                SignupService.verfiyEmail({userEmail: userEmail.value}).then(response => {
                    setAPICallData({...apiCallData, progress: false, error: null});
                    handleResponse(response.data);
                }).catch(error => {
                    setAPICallData({...apiCallData, progress: false});
                    handleError(error);
                });
            }
        }
        e.preventDefault();
    };

    const errorExist = !isNilOrEmpty(formData.userEmail.error) || !isNilOrEmpty(apiCallData.error);
    const errorMessage = formData.userEmail.error || apiCallData.error;
    return <Slide direction = 'right' in>
            <Box className = {classes.signupOuterContainer}> 
                <Typography variant = 'h3' color = 'textPrimary' className = {classes.signupTitle}>{t('general.signup')}</Typography>
                <form noValidate onSubmit = {handleVerifyClick}>
                    <TextField id = 'userEmail' variant = 'outlined' fullWidth label = '' placeholder = {t('general.businessEmail')}
                    value = {formData.userEmail.value} error = {errorExist} size = 'small' className = {classes.input}
                    helperText = {errorMessage} onChange = {handleFieldChange}/>
                    
                    {(captchaVisible == true && captchaProvider=="recaptcha_v2" && !isNilOrEmpty(captchaSiteKey)) ? <div ><Recaptcha 
                        sitekey={captchaSiteKey}
                        render="explicit"
                        //onloadCallback={recaptchaCallback}
                        verifyCallback = {recaptchaCallback}
                        expiredCallback = {recaptchaExpiredCallback}
                        className = {classes.grecaptcha} /></div> : null}

                    <Button type = 'submit' variant = 'contained' size = 'small' color = 'primary' fullWidth disableElevation 
                    className = {classes.verifyButton} disabled={verifyButtonDisabled || emailEmpty} >
                         {apiCallData.progress ? <CircularProgress size = {20} className = {classes.circularProgress}/> :
                         <Typography variant = 'body1'>{t('general.verify')}</Typography>}
                    </Button>
                    <Typography color = 'textPrimary' className = {classes.existingUser}>
                        {t('general.existingUser')} <Link color = 'primary' href = '#/login'>{t('general.login')}</Link>
                    </Typography>
                </form>
            </Box>
    </Slide>;
};

export default SignupForm;
