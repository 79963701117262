import React, {useState, useEffect} from 'react';
import {Switch, Route, useLocation, useRouteMatch} from 'react-router-dom';
import {Box, Grid, ThemeProvider, IconButton, CssBaseline, makeStyles, Hidden} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseSharp';
import {SnackbarProvider} from 'notistack';

import {useStyles as useLoginStyles, LoginViewBanner, LogoBox} from '../login/login-main';
import VerifyEmail from './VerifyEmail';
import Registration from './Registration';
import SignupSuccess from './SignupSuccess';
import Loader from '../../components/administration/loader';
import usePortalInitialData from '../../components/portal-initial-data/usePortalInitialData';
import {getQueryString, isNilOrEmpty} from '../../helpers/helpers';

const useSignUpStyles = makeStyles({
    leftContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
});

const Signup = () => {
    const {customTheme, banner, companyLogo, dataLoaded, backgroundColor} = usePortalInitialData();
    const [isRegistrationPage, setIsRegistrationPage] = useState(false);
    const notiStackRef = React.createRef();
    const classes = useLoginStyles();
    const signupClasses = useSignUpStyles();
    const {search} = useLocation();
    const {path} = useRouteMatch();
    
    useEffect(() => {
        if (search) {
            setIsRegistrationPage(!isNilOrEmpty(getQueryString(search, 't')));
        } else {
            setIsRegistrationPage(false);
        }
    }, [search]);

    const LeftBanner = <Hidden smDown>
                        <Grid item md = {7} className = {signupClasses.leftContainer}>
                            <LoginViewBanner customisingloginBannerSrc = {banner}/>
                        </Grid>
    </Hidden>;

    const RightContainer = <Grid item md = {5} className = {signupClasses.leftContainer} style = {{backgroundColor: backgroundColor}}>
                                <Grid item xs={1} sm={2} lg={3}></Grid>
                                <Grid item xs={10} sm={8} lg={6} className = {classes.contentContainer}>
                                <Grid container>
                                    <Grid item xs={5}>
                                        <LogoBox customisingCompanyLogoSrc={companyLogo}/>
                                    </Grid>
                                    <Switch>
                                        <Route path = {`${path}/success`}>
                                            <SignupSuccess />
                                        </Route>
                                        <Route path = '/'>
                                            {!isRegistrationPage ? <VerifyEmail theme = {customTheme}/> : <Registration />}
                                        </Route>
                                    </Switch>
                                </Grid>
                                </Grid>
                                <Grid item xs={1} sm={2} lg={3}></Grid>
    </Grid>;

    if (!dataLoaded) return null;
    return <ThemeProvider theme = {customTheme}>
                <SnackbarProvider ref = {notiStackRef} maxSnack = {1} anchorOrigin = {{vertical: 'top', horizontal: 'right'}} autoHideDuration = {5000}
                classes = {{variantSuccess: classes.snackbarSuccess, variantWarning: classes.snackbarWarning, variantError: classes.snackbarError, variantInfo: classes.snackbarInfo}}
                action = {(key) => (
                    <IconButton onClick={() => notiStackRef.current.closeSnackbar(key)}>
                        <CloseIcon style={{ color: '#ffffff' }} />
                    </IconButton>
                )}>
                    <CssBaseline />
                    <Box height = '100%' className = {classes.root}>
                        <Grid container className = {classes.gridContainer}>
                            {LeftBanner}
                            {RightContainer}
                        </Grid>
                    </Box>
                    <Loader useFullScreen={false}></Loader>
                </SnackbarProvider>
    </ThemeProvider>;
};

export default Signup;