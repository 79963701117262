import * as TYPES from './signupActions';

const initialState = {
    registeredUser: null,
    registrationAccount: null,
    accountAddresssCountries: null,
    accountAddressStates: null,
    isSelfRegistrationEnabled: null
};

export default (state = initialState, action) => {
    switch(action.type) {
        case TYPES.SET_REGISTRATION_USER:
            return {...state, registeredUser: action.user};
        case TYPES.SET_REGISTRATION_ACCOUNT:
            return {...state, registrationAccount: action.account};
        case TYPES.SET_ACCOUNT_ADDRESS_COUNTRIES:
            return {...state, accountAddresssCountries: action.list};
        case TYPES.SET_ACCOUNT_ADDRESS_STATES:
            return {...state, accountAddressStates: action.list};
        case TYPES.SET_IS_SELF_REGISTRATION_ENABLED:
            return {...state, isSelfRegistrationEnabled: action.status};
        default:
            return state;
    }
};