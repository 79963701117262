export const UPDATE_LOGO_SRC = 'UPDATE_LOGO_SRC'
export const UPDATE_FAVICON_SRC = 'UPDATE_FAVICON_SRC'
export const UPDATE_LOGIN_VIEW_BANNER_SRC = 'UPDATE_LOGIN_VIEW_BANNER_SRC'
export const UPDATE_NAVIGATION_ITEM = 'UPDATE_NAVIGATION_ITEM'
export const UPDATE_BRAND_NAME_VALUE = 'UPDATE_BRAND_NAME_VALUE'
export const SET_INITIAL_DATA = 'SET_INITIAL_DATA'
export const UPDATE_BDA = 'UPDATE_BDA'
export const SET_BRA_VALUE = 'SET_BRA_VALUE'
export const SET_BRAND_LANGUAGE = 'SET_BRAND_LANGUAGE'
export const SET_TOP_LEVEL_PROPERTY = 'SET_TOP_LEVEL_PROPERTY'
export const SET_COMMERCE_SETTINGS = 'SET_COMMERCE_SETTINGS'
export const SET_COMMERCE_ORDER_AND_INQUIRY_SETTINGS = 'SET_COMMERCE_ORDER_AND_INQUIRY_SETTINGS'