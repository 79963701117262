export const ADD_ITEM_TO_SHOPPING_CART = 'ADD_ITEM_TO_SHOPPING_CART'
export const DELETE_ITEM_FROM_SHOPPING_CART = 'DELETE_ITEM_FROM_SHOPPING_CART'
export const UPDATE_ITEM_TO_SHOPPING_CART = 'UPDATE_ITEM_TO_SHOPPING_CART'
export const SET_SHOPPING_CART = 'SET_SHOPPING_CART'
export const UPDATE_SHOPPING_CART = 'UPDATE_SHOPPING_CART'
export const CHECK_PRODUCT_IN_CART = 'CHECK_PRODUCT_IN_CART'
export const CART_FULL_REFRESH = 'CART_FULL_REFRESH'
export const CART_DELTA_REFRESH = 'CART_DELTA_REFRESH'
export const CART_DELTA_REFRESH_NO_PRICNG = 'CART_DELTA_REFRESH_NO_PRICNG'
export const CART_PRICE_REFRESH = 'CART_PRICE_REFRESH'
export const CART_CLEAR = 'CART_CLEAR'