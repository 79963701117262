import { GET_INQUIRY, SET_INQUIRY } from './inquiry-actions'
import { getObjectKey, isNilOrEmpty } from '../../helpers'

const initialState = {
    //for session, current source of truth is data from localStorage
    quoteBO: null,
    salesItemId: null,
}

export const inquiryReducers = (state = initialState, action) => {
    const data = getObjectKey(action, 'payload')

    switch (action.type) {
        case GET_INQUIRY:
            return {
                ...state,
            }
        case SET_INQUIRY:
            return {
                ...state,
                ...data,
            }
        default:
            return state
    }
}
