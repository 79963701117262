import ajax, { api } from './ajax'
import {
    getAttributeFragment,
    getAttributeTemplate,
    getObjectKey,
    attributeArrayToObject,
    isNilOrEmpty,
    isNil,
} from '../helpers'
import { trackPromise } from 'react-promise-tracker'
import {
    createBDATemplate,
    createBRATemplate,
    updateBackdropLoading,
} from '../helpers/admin-helper'
import { MasterDataService } from './masterdata-service'
import { DestinationService } from './destination-service'

export const AdminService = {
    PORTAL_ENTITY_TYPE_TEXT:
        'http://www.inmindcloud.dev.com/application/schema.owl#EnumPortalEntityTypeText',
    PORTAL_ENTITY_TYPE_BUTTON:
        'http://www.inmindcloud.dev.com/application/schema.owl#EnumPortalEntityTypeButton',
    PortalSettings_METADATA: {},
    PortalSocialMediaLink_METADATA: {},
    PortalAdditionalLink_METADATA: {},
    PortalNavigationPage_METADATA: {},
    PortalLandingPageBanner_METADATA: {},
    PortalTheme_METADATA: {},
    PortalMediaFile_METADATA: {},
    PortalEntity_METADATA: {},
    PortalFontFamily_METADATA: {},
    PortalCustomIcon_METADATA: {},
    PortalCustomDefaultImage_METADATA: {},
    PaymentConfigMode_METADATA: {},
    PortalProductFilter_METADATA: {},
    PortalNavigationSubEntity_METADATA: {},
    PortalNavigationCustomSetting_METADATA: {},
    ProductCategory_METADATA: {},
    Language_METADATA: {},
    User_METADATA: {},
    Contact_METADATA: {},
    PortalScreenConfiguration_METADATA: {},
    PortalScreenConfigurationSection_METADATA: {},
    PortalScreenConfigurationField_METADATA: {},
    PortalScreenConfigurationSubSection_METADATA: {},
    // PortalProductFilters_METADATA: {},
    portalPaymentConfigMode_METADATA: {},
    METADATA: [],
    CommerceInquiryEnabled: null,
    DestinationConfig: null,

    async getMetadata(type) {
        return api.get('/metadatas/' + type)
    },

    async getInitialSettingData() {
        this.PortalSettings_METADATA = await AdminService.getMetadata(
            'PortalSettings'
        )
        this.PortalSocialMediaLink_METADATA = await AdminService.getMetadata(
            'PortalSocialMediaLink'
        )
        this.PortalAdditionalLink_METADATA = await AdminService.getMetadata(
            'PortalAdditionalLink'
        )
        this.PortalNavigationPage_METADATA = await AdminService.getMetadata(
            'PortalNavigationPage'
        )
        this.PortalLandingPageBanner_METADATA = await AdminService.getMetadata(
            'PortalLandingPageBanner'
        )
        this.PortalMediaFile_METADATA = await AdminService.getMetadata(
            'PortalMediaFile'
        )
        this.PortalTheme_METADATA = await AdminService.getMetadata(
            'PortalTheme'
        )

        this.PortalEntity_METADATA = await AdminService.getMetadata(
            'PortalEntity'
        )
        this.PortalFontFamily_METADATA = await AdminService.getMetadata(
            'PortalFontFamily'
        )
        this.PortalCustomIcon_METADATA = await AdminService.getMetadata(
            'PortalCustomIcon'
        )
        this.PortalCustomDefaultImage_METADATA = await AdminService.getMetadata(
            'PortalCustomDefaultImage'
        )
        this.PortalProductFilter_METADATA = await AdminService.getMetadata(
            'PortalProductFilter'
        )

        this.PortalNavigationSubEntity_METADATA = await AdminService.getMetadata(
            'PortalNavigationSubEntity'
        )

        this.PortalNavigationCustomSetting_METADATA = await AdminService.getMetadata(
            'PortalNavigationCustomSetting'
        )

        const productCategory = await AdminService.getMetadata(
            'ProductCategory'
        )
        this.ProductCategory_METADATA = productCategory
        this.METADATA.push({
            businessType: 'ProductCategory',
            metadata: productCategory.data,
        })

        this.PortalScreenConfiguration_METADATA = await AdminService.getMetadata(
            'PortalScreenConfiguration'
        )

        this.PortalScreenConfigurationSection_METADATA = await AdminService.getMetadata(
            'PortalScreenConfigurationSection'
        )

        this.PortalScreenConfigurationField_METADATA = await AdminService.getMetadata(
            'PortalScreenConfigurationField'
        )

        this.PortalScreenConfigurationSubSection_METADATA = await AdminService.getMetadata(
            'PortalScreenConfigurationSubSection'
        )

        this.Language_METADATA = await AdminService.getMetadata('Language')
        // this.PortalProductFilters_METADATA = await AdminService.getMetadata(
        //     'PortalProductFilters'
        // )

        return new Promise((resolve, reject) => {
            api.post('/settings/search', {
                data: {
                    type: 'Search',
                    include: [
                        'includesPortalSocialMediaLink',
                        'includesPortalNavigationPage',
                        'includesPortalNavigationPage.includesPortalNavigationSubEntity',
                        'includesPortalNavigationPage.includesPortalNavigationCustomSetting',
                        'includesPortalNavigationPage.includesPortalNavigationSubEntity.usesCategory',
                        'includesPortalMediaFile',
                        'includesPortalAdditionalLink',
                        'includesPortalTheme',
                        'includesSelfRegistrationGoldenSalesArea',
                        'includesPortalTheme.hasPortalFontFamily',
                        'includesPortalLandingPageBanner',
                        'includesPortalLandingPageBanner.includesPortalEntity',
                        'includesPortalProductFilter',
                        'includesPortalScreenConfiguration',
                        'includesPortalScreenConfiguration.includesPortalScreenConfigurationSection',
                        'includesPortalScreenConfiguration.includesPortalScreenConfigurationSection.includesPortalScreenConfigurationField',
                        'includesPortalScreenConfiguration.includesPortalScreenConfigurationSection.includesPortalScreenConfigurationField.includesConfigurationFieldAdditionalAttributes',
                        'includesPortalScreenConfiguration.includesPortalScreenConfigurationSection.includesPortalScreenConfigurationField.includesConfigurationFieldAdditionalAttributesDropDownList',
                        'includesPortalScreenConfiguration.includesPortalScreenConfigurationSection.includesPortalScreenConfigurationSubSection',
                        'includesPortalTheme.includesPortalCustomIcon',
                        'includesPortalTheme.includesPortalCustomDefaultImage'
                    ],
                },
            })
                .then((response) => {
                    let portalSettingMetadata =
                        AdminService.PortalSettings_METADATA

                    let settingData = AdminService.extractRequiredData(
                        response.data.entry[0],
                        portalSettingMetadata.data
                    )
                    const bCommerceEnableCustomerAccountInquiry = !isNilOrEmpty(settingData.commerceEnableCustomerAccountInquiry) ? settingData.commerceEnableCustomerAccountInquiry.value : false
                    const bCommerceEnableProspectAccountInquiry = !isNilOrEmpty(settingData.commerceEnableProspectAccountInquiry) ? settingData.commerceEnableProspectAccountInquiry.value : false

                    AdminService.setCommerceInquirySetting(bCommerceEnableCustomerAccountInquiry || bCommerceEnableProspectAccountInquiry)
                    resolve(settingData)
                })
                .catch((error, a) => {
                    if (error.response) {
                        switch (error.response.status) {
                            case 401:
                                let splitCurrentLocation = window.location.href.split(
                                    '#'
                                )
                                let baseUrl = splitCurrentLocation[0]
                                // if (baseUrl[baseUrl.length - 1] !== '/')
                                //     baseUrl += '/'
                                window.location.href = baseUrl + '#/login'
                                break
                        }
                    }

                    reject(error)
                })
        })
    },
    extractRequiredData(inData, metadata) {
        //we extract what we need for setting only.

        let out = {}

        let data = inData
        let include = []

        if (inData && inData.type === 'Bundle') {
            data = isNilOrEmpty(inData.entry) ? {} : inData.entry[0]
            include = inData.include
        }

        var metadataMap = {}
        let metadataAttribute = metadata.attributes.map((dto) => {
            let attributeId = dto.attribute.id
            let attributeFragment = getAttributeFragment(attributeId)
            metadataMap[attributeFragment] = dto
        })

        function getCustomLabel(key) {
            let label = ''

            switch (key) {
                case 'portalAddress1':
                    label = 'Address 1'
                    break
                case 'portalAddress2':
                    label = 'Address 2'
                    break
                case 'portalAddress3':
                    label = 'Address 3'
                    break
                case 'portalAddress4':
                    label = 'Address 4'
                    break
                case 'portalPhoneNumber':
                    label = 'Phone Number'
                    break

                case 'portalFaxNumber':
                    label = 'Fax Number'
                    break
                case 'portalEmailAddress':
                    label = 'Email'
                    break
            }

            return label
        }

        function findValueFromInclude(uri) {
            let [found] = include.filter((incl) => {
                if (incl.uri === uri) {
                    return incl
                }
            })

            if (found) {
                return found
            } else {
                return uri
            }
        }

        for (let key in data) {
            let value = data[key]

            if (metadataMap[key]) {
                if (metadataMap[key].type === 'data') {
                    out[key] = createBDATemplate({
                        attributeFragment: key,
                        attributeLabel: metadataMap[key].l10nKey || '',
                        valuetype: metadataMap[key].valuetype,
                        value: value,
                        type: 'data',
                    })
                } else if (metadataMap[key].type === 'relation') {
                    let braValues = value
                    if (!isNilOrEmpty(value)) {
                        braValues = []
                        value.forEach((val) => {
                            if (typeof val === 'string') {
                                braValues.push(findValueFromInclude(val))
                            } else {
                                braValues.push(val)
                            }
                        })
                    }

                    out[key] = createBRATemplate({
                        attributeFragment: key,
                        value: braValues,
                        oldValue: braValues,
                        initialValue: braValues,
                        type: 'relation',
                    })
                    let bdaValue = out[key].value.map((o) => {
                        if (
                            AdminService[o.type + '_METADATA'] &&
                            AdminService[o.type + '_METADATA'].data
                        ) {
                            let typeMeta =
                                AdminService[o.type + '_METADATA'].data
                            return AdminService.extractRequiredData(o, typeMeta)
                        } else {
                            console.warn(`metadata not defined for - ${o.type}`)
                            return o
                        }
                    })
                    out[key].value = bdaValue
                }
            }
        }

        out = {
            ...out,
            ...{
                id: data.id,
                uri: data.uri,
                type: data.type,
                label: data.label || '',
                comment: data.comment || '',
            },
        }

        return out
    },
    createPrimaryShapeFromMeta(inData, metadata) {
        let out = {}

        let data = inData

        var metadataMap = {}
        let metadataAttribute = metadata.attributes.map((dto) => {
            let attributeId = dto.attribute.id
            let attributeFragment = getAttributeFragment(attributeId)
            metadataMap[attributeFragment] = dto
        })

        for (let key in metadataMap) {
            if (metadataMap[key]) {
                let value = data[key] || []
                if (metadataMap[key].type === 'data') {
                    out[key] = createBDATemplate({
                        attributeFragment: key,
                        valuetype: metadataMap[key].valuetype,
                        value: value,
                        type: 'data',
                    })
                } else if (metadataMap[key].type === 'relation') {
                    out[key] = createBRATemplate({
                        attributeFragment: key,
                        value: value,
                        type: 'relation',
                    })
                    let bdaValue = out[key].value.map((o) => {
                        //if no type provided, just return the value intact
                        if (!o.type) {
                            return o
                        }

                        if (
                            AdminService[o.type + '_METADATA'] &&
                            AdminService[o.type + '_METADATA'].data
                        ) {
                            let typeMeta =
                                AdminService[o.type + '_METADATA'].data
                            return AdminService.createPrimaryShapeFromMeta(
                                o,
                                typeMeta
                            )
                        } else {
                            console.warn(`metadata not defined for - ${o.type}`)
                        }
                    })
                    out[key].value = bdaValue
                }
            }
        }

        out = {
            ...out,
            ...{
                id: data.id,
                uri: data.uri,
                type: data.type,
                label: data.label || '',
                comment: data.comment || '',
            },
        }

        return out
    },
    updatePortalSettingBDAWithValidation(payload) {
        return api.patch('/settings/portalBlacklistedEmailDomain', {
            data: payload,
        })
    },
    updatePortalSettingBDA(payload) {
        return api.patch('/settings', {
            data: payload,
        })
    },
    updateCommerceOrderInquirySettingBDA(payload) {
        return api.patch('/settings', {
            data: payload,
        })
    },
    createAdditionalLink(payload) {
        let portalSettingId = payload.id
        return api.post(
            '/settings/' + portalSettingId + '/PortalAdditionalLink',
            { data: payload }
        )
    },
    updateAdditionalLink(portalSettingId, payload) {
        let portalAdditionalLinkId = payload.id
        return AdminService.updateDependant(
            portalSettingId,
            'PortalAdditionalLink',
            portalAdditionalLinkId,
            payload
        )
    },
    updateAdditionalLinkBulk(portalSettingId, payload = []) {
        let path = '/settings/' + portalSettingId + '/PortalAdditionalLink'
        return api.patch(path, { data: payload })
    },
    deleteAdditionalLink(portalSettingId, portalAdditionalLinkId) {
        let path =
            '/settings/' +
            portalSettingId +
            '/PortalAdditionalLink/' +
            portalAdditionalLinkId
        return api.delete(path)
    },
    createSocialMediaLink(payload) {
        let portalSettingId = payload.id
        return api.post(
            '/settings/' + portalSettingId + '/PortalSocialMediaLink',
            { data: payload }
        )
    },
    updateSocialMediaLink(portalSettingId, payload) {
        let portalSocialMediaLinkId = payload.id
        return AdminService.updateDependant(
            portalSettingId,
            'PortalSocialMediaLink',
            portalSocialMediaLinkId,
            payload
        )
    },
    updateSocialMediaLinkBulk(portalSettingId, payload = []) {
        let path = '/settings/' + portalSettingId + '/PortalSocialMediaLink'
        return api.patch(path, { data: payload })
    },
    updateNavigationPageBulk(portalSettingId, payload = []) {
        let path = '/settings/' + portalSettingId + '/PortalNavigationPage'
        return api.patch(path, { data: payload })
    },
    updateNavigationPage(portalSettingId, payload) {
        let navigationPageId = payload.id
        return AdminService.updateDependant(
            portalSettingId,
            'PortalNavigationPage',
            navigationPageId,
            payload
        )
    },
    updateSubEntityBulk(portalSettingId, payload = []) {
        let path = '/settings/' + portalSettingId + '/PortalNavigationSubEntity'
        return api.patch(path, { data: payload })

        //return new Promise( ( resolve, reject) => {
        //    let promises = []
        //    for ( let i = 0; i < payload.length; i++ ) {
        //        let individualPayload = payload[i]
        //        let individualPayloadId = individualPayload.id
        //        promises.push( AdminService.updateDependant( portalSettingId, 'PortalNavigationSubEntity', individualPayloadId, individualPayload ) )
        //    }
        //    Promise.all( promises ).then( ()=> {
        //        resolve( ...arguments)
        //    }).catch( ( error ) => {
        //        reject( error )
        //    })
        //
        //})
    },

    deletePortalNavigationSubEntity(portalSettingId, dependantId) {
        let path =
            '/settings/' +
            portalSettingId +
            '/' +
            'PortalNavigationSubEntity' +
            '/' +
            dependantId
        return api.delete(path)
    },

    deletePortalNavigationSubEntitiesBulk(portalSettingId, payload = []) {
        let path =
            '/settings/' +
            portalSettingId +
            '/' +
            'PortalNavigationSubEntity/deleteBulk'
        return api.patch(path, { data: payload })
    },

    updatePortalThemeFontFamily(portalSettingId, payload) {
        let portalThemeFontFamilyId = payload.id
        return AdminService.updateDependant(
            portalSettingId,
            'PortalThemeFontFamily',
            portalThemeFontFamilyId,
            payload
        )
    },
    updateDependant(portalSettingId, dependantType, dependantId, payload) {
        let path =
            '/settings/' +
            portalSettingId +
            '/' +
            dependantType +
            '/' +
            dependantId
        return api.patch(path, { data: payload })
    },
    deleteSocialMediaLink(portalSettingId, portalSocialMediaLinkId) {
        let path =
            '/settings/' +
            portalSettingId +
            '/PortalSocialMediaLink/' +
            portalSocialMediaLinkId
        return api.delete(path)
    },
    createLandingPageBanner(portalSettingId, payload) {
        return api.post(
            '/settings/' + portalSettingId + '/PortalLandingPageBanner',
            { data: payload }
        )
    },
    updateLandingPageBannerBulk(portalSettingId, payload = []) {
        let path = '/settings/' + portalSettingId + '/PortalLandingPageBanner'
        return api.patch(path, { data: payload })
    },
    updatePortalPaymentConfigMode(portalSettingId, payload) {
        let path = '/settings/'
        return api.patch(path, { data: payload })
    },
    deleteLandingPageBanner(portalSettingId, portalLandingPageBannerId) {
        let path =
            '/settings/' +
            portalSettingId +
            '/PortalLandingPageBanner/' +
            portalLandingPageBannerId
        return api.delete(path)
    },
    createLandingPagePortalEntity(
        portalSettingId,
        portalLandingPageBannerId,
        payload
    ) {
        let path =
            '/settings/' +
            portalSettingId +
            '/PortalLandingPageBanner/' +
            portalLandingPageBannerId

        return api.post(path, { data: payload })
    },
    updateLandingPagePortalEntity(portalSettingId, portalEntityId, payload) {
        let path =
            '/settings/' + portalSettingId + '/PortalEntity/' + portalEntityId
        return api.patch(path, { data: payload })
    },
    createPortalTheme(portalSettingId = '', payload) {
        return api.post('/settings/' + portalSettingId + '/PortalTheme', {
            data: payload,
        })
    },
    updatePortalTheme(portalSettingId = '', payload) {
        let portalThemeId = payload.id
        return AdminService.updateDependant(
            portalSettingId,
            'PortalTheme',
            portalThemeId,
            payload
        )
    },
    updatePortalThemeBulk(portalSettingId, payload = []) {
        let path = '/settings/' + portalSettingId + '/PortalTheme'
        return api.patch(path, { data: payload })
    },
    deletePortalTheme(portalSettingId, portalThemeId) {
        let path =
            '/settings/' + portalSettingId + '/PortalTheme/' + portalThemeId
        return api.delete(path)
    },
    uploadBDAImage(portalSettingId, payload) {
        //TODO once confirm
        let file = payload.file
        let dependantType = payload.dependantType
        let dependantId = payload.dependantId

        let data = new FormData()
        data.append('attachment', file)

        let path =
            '/settings/' +
            portalSettingId +
            '/' +
            dependantType +
            '/' +
            dependantId +
            '/media'

        return api.post(path, {
            data: data,
            headers: { 'content-type': 'multipart/form-data' },
        })
    },
    removeBDAImage(portalSettingId, payload) {
        let dependantType = payload.dependantType
        let dependantId = payload.dependantId

        let path =
            '/settings/' +
            portalSettingId +
            '/' +
            dependantType +
            '/' +
            dependantId +
            '/media'

        return api.delete(path)
    },
    updatePortalSettings(payload) {
        return api.patch('/settings', {
            data: payload,
        })
    },

    getLabel(portalSettingId, portalEntityId) {
        let path =
            '/settings/' +
            portalSettingId +
            '/PortalEntity/' +
            portalEntityId +
            '/metaInfo'
        return api.get(path)
    },

    getPortalPaymentConfigMode() {
        return api.get('/settings')
    },

    getLabelForDependant(portalSettingId, dependantType, dependantId) {
        let path =
            '/settings/' +
            portalSettingId +
            '/' +
            dependantType +
            '/' +
            dependantId +
            '/metaInfo'
        return api.get(path)
    },
    updateLabelForDependant(
        portalSettingId,
        dependantType,
        dependantId,
        payload
    ) {
        let path =
            '/settings/' +
            portalSettingId +
            '/' +
            dependantType +
            '/' +
            dependantId +
            '/metaInfo'
        return api.patch(path, {
            data: payload,
        })
    },

    createPortalNavigationPage(portalSettingId, payload) {
        return api.post(
            '/settings/' + portalSettingId + '/PortalNavigationPage',
            { data: payload }
        )
    },

    createPortalNavigationPortalNavigationCustomSetting(
        portalSettingId,
        portalNavigationPageId,
        payload
    ) {
        let path =
            '/settings/' +
            portalSettingId +
            '/PortalNavigationPage/' +
            portalNavigationPageId

        return api.post(path, { data: payload })
    },

    deletePortalNavigationPage(portalSettingId, portalNavigationPageId) {
        let path =
            '/settings/' +
            portalSettingId +
            '/PortalNavigationPage/' +
            portalNavigationPageId
        return api.delete(path)
    },

    createPortalNavigationPageNavigationSubEntity(
        portalSettingId,
        portalNavigationPageId,
        payload
    ) {
        let path =
            '/settings/' +
            portalSettingId +
            '/PortalNavigationPage/' +
            portalNavigationPageId

        return api.post(path, { data: payload })
    },
    updatePortalNavigationPageNavigationSubEntity(
        portalSettingId,
        portalNavigationSubEntityId,
        payload
    ) {
        return AdminService.updateDependant(
            portalSettingId,
            'PortalNavigationSubEntity',
            portalNavigationSubEntityId,
            payload
        )
    },
    updatePortalNavigationPageCustomSetting(
        portalSettingId,
        portalNavigationCustomSettingId,
        payload
    ) {
        return AdminService.updateDependant(
            portalSettingId,
            'PortalNavigationCustomSetting',
            portalNavigationCustomSettingId,
            payload
        )
    },
    async getMasterData({ typeid = '', isFull = false }) {
        let path = '/masterdata/businessType/businessObject/all'

        return new Promise((resolve, reject) => {
            api.get(path, {
                params: {
                    typeid: typeid,
                    isFull: isFull,
                    pricingOnly: false,
                },
            })
                .then((response) => {
                    let dtoData = response.data
                    let masterDataData = dtoData.map((o) => {
                        let instance = {
                            type: o.type.id,
                            label: o.l10nKey,
                            id: o.instance.id,
                        }

                        instance = {
                            ...instance,
                            ...attributeArrayToObject(o.attributes),
                        }

                        return instance
                    })

                    resolve(masterDataData)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    },

    async getAllLanguages() {
        let path = '/masterdata/businessType/businessObject/all'

        return new Promise((resolve, reject) => {
            api.get(path, {
                params: {
                    typeid:
                        'http://www.inmindcloud.com/application/schema.owl#Language',
                    isFull: false,
                    pricingOnly: false,
                },
            })
                .then((response) => {
                    let dtoData = response.data
                    let masterDataData = dtoData.map((o) => {
                        let instance = {
                            type: o.type.id,
                            label: o.l10nKey,
                        }

                        instance = {
                            ...instance,
                            ...attributeArrayToObject(o.attributes),
                        }
                        instance.id = instance.objectExternalId.value
                        instance.objectId = {
                            value: o.instance.id,
                        }

                        return instance
                    })

                    resolve(masterDataData)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    },

    async setCommerceInquirySetting(value) {
        this.CommerceInquiryEnabled = value
    },

    getCommerceInquirySetting() {
        return this.CommerceInquiryEnabled
    },

    async setDestinationConfig() {
        return new Promise((resolve, reject) => {
            DestinationService.getAllDestinationConfig()
                .then((response) => {
                    const result = response.data
                    if (!isNilOrEmpty(result.entry)) {
                        const destinations = result.entry
                        const onlyActive = destinations.filter(
                            (config) => config.active === 'true'
                        )
                        this.DestinationConfig = onlyActive
                        resolve(onlyActive)
                    } else {
                        resolve([])
                    }
                })
                .catch((error) => {
                    console.log(error)
                    this.DestinationConfig = []
                    resolve([])
                })
        })
    },

    async getDestinationConfig() {
        if (isNil(this.DestinationConfig)) {
            await this.setDestinationConfig()
        }

        return this.DestinationConfig
    },

    reloadSettings() {
        return api.get('/masterdata/refreshCollectionCache')
    },

    updateScreenConfigurationConfigurationFieldAdditionalAttributesBulk(portalSettingId, payload = []) {
        let path =
            '/settings/' + portalSettingId + '/ConfigurationFieldAdditionalAttributes'
        return api.patch(path, { data: payload })
    },

    updateScreenConfigurationFieldsBulk(portalSettingId, payload = []) {
        let path =
            '/settings/' + portalSettingId + '/PortalScreenConfigurationField'
        return api.patch(path, { data: payload })
    },

    updateScreenConfigurationSubSectionsBulk(portalSettingId, payload = []) {
        let path =
            '/settings/' +
            portalSettingId +
            '/PortalScreenConfigurationSubSection'
        return api.patch(path, { data: payload })
    },

    updateScreenConfigurationSection(portalSettingId, payload = []) {
        let path =
            '/settings/' + portalSettingId + '/PortalScreenConfigurationSection'
        return api.patch(path, { data: payload })
    },

    createPortalScreenconfigurationField(
        portalSettingId,
        portalSectionId,
        payload
    ) {
        let path =
            '/settings/' +
            portalSettingId +
            '/PortalScreenConfigurationSection/' +
            portalSectionId

        return new Promise((resolve, reject) => {
            api.post(path, { data: payload })
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    createPortalScreenConfigurationNestedField(
        portalSettingId,
        portalFieldId,
        payload
    ) {
        let path =
            '/settings/' +
            portalSettingId +
            '/PortalScreenConfigurationField/' +
            portalFieldId

        return new Promise((resolve, reject) => {
            api.post(path, { data: payload })
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    deletePortalScreenConfigField(portalSettingId, dependantId) {
        let path =
            '/settings/' +
            portalSettingId +
            '/' +
            'PortalScreenConfigurationField' +
            '/' +
            dependantId
        return api.delete(path)
    },

    async getMetaDataByType(businessType) {
        let cached = this.METADATA.find((a) => a.businessType === businessType)

        if (!isNilOrEmpty(cached)) {
            return cached.metadata
        }

        let metadata = await AdminService.getMetadata(businessType)

        if (!isNilOrEmpty(metadata)) {
            let found = this.METADATA.find(
                (a) => a.businessType === businessType
            )

            if (isNilOrEmpty(found))
                this.METADATA.push({
                    businessType: businessType,
                    metadata: metadata.data,
                })

            return metadata.data
        }
    },
    createGoldenSalesArea(portalSettingId, payload) {
        return api.post(`/settings/${portalSettingId}/SalesInfo`, {
            data: { type: 'SalesInfo', ...payload }
        });
    },
    updateGoldenSalesArea(portalSettingId, globalSalesInfoId, payload) {
        return api.patch(`/settings/${portalSettingId}/SalesInfo/${globalSalesInfoId}`, { data: payload });
    },
    createPortalCustomIcon(portalSettingId, themeId, payload) {
        return api.post(`/settings/${portalSettingId}/PortalTheme/${themeId}`, { data: payload });
    },
    updatePortalCustomIcon(portalSettingId, portalCustomIconId, payload) {
        return api.patch(`/settings/${portalSettingId}/PortalCustomIcon/${portalCustomIconId}`, { data: payload });
    },
    updatePortalCustomDefaultImage(portalSettingId, portalCustomImageId, payload) {
        return api.patch(`/settings/${portalSettingId}/PortalCustomDefaultImage/${portalCustomImageId}`, { data: payload });
    },
    uploadCustomIconSVGToCMIS(portalThemeId, iconGroup, image) {
        const formData = new FormData();
        formData.append('image', image);
        return api.post(`/settings/${portalThemeId}/PortalCustomIcon/${iconGroup}/uploadCustomIcons`, { data: formData });
    },
    uploadCustomImagesToCMIS(portalThemeId, iconGroup, image) {
        const formData = new FormData();
        formData.append('image', image);
        return api.post(`/settings/${portalThemeId}/PortalCustomDefaultImage/${iconGroup}/uploadCustomIcons`, { data: formData });
    },
    deleteCustomIconSVGFromCMIS(portalThemeId, iconGroup) {
        return api.delete(`/settings/${portalThemeId}/PortalCustomIcon/${iconGroup}/deleteCustomIcons`);
    },
    deleteCustomImageFromCMIS(portalThemeId, iconGroup) {
        return api.delete(`/settings/${portalThemeId}/PortalCustomDefaultImage/${iconGroup}/deleteCustomIcons`);
    }
}
