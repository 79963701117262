import i18n from 'i18next'
import { initReactI18next, useTranslation } from 'react-i18next'

import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import i18next from 'i18next'
import { isNil, isNilOrEmpty } from '../../helpers'
import {UserService} from '../user-service';

const FALLBACK_LANGUAGE = 'en'
export const TranslationService = {
    init(cb) {
        let hash = window.location.hash
        let selectedLanguage = FALLBACK_LANGUAGE
        if (localStorage.getItem('language') && !hash.startsWith('#/administration'))
            selectedLanguage = localStorage.getItem('language')
        else localStorage.setItem('language', selectedLanguage);
        UserService.getTranslationJSON(selectedLanguage).then(res => {
            TranslationService.initializeTranslationInstance(!isNilOrEmpty(res.data) ? res.data : null, selectedLanguage, isNilOrEmpty(res.data), cb);
        }).catch(ex => {
            TranslationService.initializeTranslationInstance(null, selectedLanguage, true, cb);
        });
    },
    _instance: null,
    setInstance(instance) {
        this._instance = instance
    },
    getInstance() {
        return this._instance
    },
    initializeTranslationInstance: (data, selectedLanguage, useLocalTranslation, cb) => {
        let initOptions = {
            fallbackLng: FALLBACK_LANGUAGE,
            lng: selectedLanguage,
            debug: true,
            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default
            }
        };
        if (!useLocalTranslation) {
            initOptions.resources = {[selectedLanguage]: {translation: data}};
        } else {
            initOptions = {...initOptions, ...{defaultNS: 'translation',
                backend: {
                    crossDomain: true,
                    loadPath: process.env.REACT_APP_URL + '/locales/{{lng}}/{{ns}}.json',
            }}};
        }
        i18n
            // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
            // learn more: https://github.com/i18next/i18next-http-backend
            .use(Backend)
            // detect user language
            // learn more: https://github.com/i18next/i18next-browser-languageDetector
            .use(LanguageDetector)
            // pass the i18n instance to react-i18next.
            .use(initReactI18next)
            // init i18next
            // for all options read: https://www.i18next.com/overview/configuration-options
            .init(
                initOptions,
                (err, t) => {
                    if (err) {
                        TranslationService.initLocalFallbackLocale({
                            selectedLanguage: selectedLanguage, data, useLocalTranslation, cb
                        })
                    } else if (isNil(err)) {
                        TranslationService.initLocalFallbackLocale({
                            selectedLanguage: selectedLanguage, data, useLocalTranslation, cb
                        })
                    }
                }
            )

        TranslationService.setInstance(i18n)
    },
    initLocalFallbackLocale({ selectedLanguage = FALLBACK_LANGUAGE, data, useLocalTranslation, cb }) {
        let initOptions = {
            fallbackLng: FALLBACK_LANGUAGE,
            lng: selectedLanguage,
            debug: true,
            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default
            }
        };
        if (!useLocalTranslation) {
            initOptions.resources = {[selectedLanguage]: {translation: data}};
        } else {
            initOptions = {...initOptions, ...{defaultNS: 'translation',
                backend: {
                    crossDomain: true,
                    loadPath: process.env.PUBLIC_URL + '/locales/{{lng}}/{{ns}}.json',
            }}};
        }
        let local = i18next.createInstance()
        local
            .use(Backend)
            // detect user language
            // learn more: https://github.com/i18next/i18next-browser-languageDetector
            .use(LanguageDetector)
            // pass the i18n instance to react-i18next.
            .use(initReactI18next)
            .init(initOptions, () => {
                if (cb) cb();
            });

        TranslationService.setInstance(local)
    },
    useTranslation() {
        return useTranslation(...arguments)
    },
}