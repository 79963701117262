import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation, useHistory} from 'react-router-dom';
import Box from '@material-ui/core/Box';

import {logInUserSession} from '../../store/user/user-action-types';
import {AuthenticationService} from '../../services/authentication-service';
import {getQueryString} from '../../helpers/helpers';

const LoginSSO = () => {
    const {search} = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (search) {
            const oAuthToken = getQueryString(search, 't');
            let redirectURL =  getQueryString(search, 'redirectURL');
            if (redirectURL) redirectURL = decodeURIComponent(redirectURL);
            let userName = getQueryString(search, 'userName') || '';
            if (userName) userName = decodeURIComponent(userName);
            AuthenticationService.registerSuccessfulLogin(userName, {access_token: oAuthToken});
            dispatch(logInUserSession({oAuthToken,userName}));
            history.replace(redirectURL ? redirectURL : '/');
        }
    }, [search]);

    return <Box>

    </Box>;
};

export default LoginSSO; 