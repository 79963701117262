import { isNilOrEmpty } from '../../helpers'
import {
    INITIAL_USER_PROFILE,
    UPDATE_USER_PROFILE,
    UPDATE_USER_SESSION,
    LOGOUT_USER_SESSION,
    LOGIN_USER_SESSION,
    UPDATE_USER_ADDRESS,
    SET_APP_INIT_DATA_LOADED,
    SET_USER_BO,
    SET_USER_ACCOUNT_PAYMENT_MODE,
    SET_IS_SSO_ENABLED,
    SET_SSO_CONFIG
} from './user-actions'

const initialState = {
    //for session, current source of truth is data from localStorage
    session: {
        initialDispatchCalled: false,
        oAuthToken: '',
        userName: '',
        toLogOut: null,
    },
    initialDataLoaded: null,
    user: {
        contactContainedBy: {
            value: '',
        },
        instanceId: { value: '' },
        hasDeniedPermission: { value: [] },
        objectId: { value: '' },
        userBO: { value: '' },
        userId: { value: '' },
        username: { value: '' },
        personFirstName: { value: '' },
        personLastName: { value: '' },
        personEmail: { value: '' },
        personPicture: { value: '' },
        personMobile: { value: '' },
        permissions: {
            value: [],
        },
        userLanguage: {
            value: '',
        },
        userPasswordStatus: {
            value: '',
        },
        systemCurrency: {
            value: '',
        },
        salesInfo: {
            priceRequestParam: {
                hasAccount: '',
                hasSalesOrg: '',
                hasDistributionChannel: '',
                hasDivision: '',
                hasCurrency: '',
            },
            currencyData: {},
        },
        sscEnabled: {
            value: false,
        },
        accountPaymentMode: null
    },
    userBO: null,
    ssoEnabled: false,
    ssoConfig: null
}

export const userReducers = (state = initialState, action) => {
    switch (action.type) {
        case SET_APP_INIT_DATA_LOADED:
            return {
                ...state,
                initialDataLoaded: action.payload.value,
            }
        case UPDATE_USER_SESSION:
            return {
                ...state,
                session: {
                    ...state.session,
                    oAuthToken: action.payload.oAuthToken,
                    userName: action.payload.userId,
                },
            }
        case LOGOUT_USER_SESSION:
            return {
                ...state,
                session: {
                    ...state.session,
                    toLogOut: true,
                    oAuthToken: '',
                    userName: '',
                },
            }
        case LOGIN_USER_SESSION:
            return {
                ...state,
                session: {
                    ...state.session,
                    toLogOut: false,
                    oAuthToken: action.payload.oAuthToken,
                    userName: action.payload.userName,
                    initialDispatchCalled: true,
                    isLogoutClicked: action.payload?.isLogoutClicked ? action.payload.isLogoutClicked : false
                },
                userBO: isNilOrEmpty(action.payload.oAuthToken) ? null : state.userBO
            }
        case INITIAL_USER_PROFILE:
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload,
                },
            }
        case UPDATE_USER_PROFILE:
            return { ...state, user: { ...state.user, ...action.payload } }
        case UPDATE_USER_ADDRESS:
            return {
                ...state,
                user: {},
            }
        case SET_USER_BO:
            return {
                ...state,
                userBO: action.payload,
            }
        case SET_USER_ACCOUNT_PAYMENT_MODE:
            return {...state, user: {...state.user, accountPaymentMode: action.payload}};
        case SET_IS_SSO_ENABLED:
            return {...state, ssoEnabled: action.enabled};
        case SET_SSO_CONFIG:
            return {...state, ssoConfig: action.config};
        default:
            return state
    }
}
