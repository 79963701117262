
import { DECIMAL_PRECISION } from "../const/const";
import { UserService } from "../services";
import { isNilOrEmpty } from './helpers'

const formatNumber = (number) => {
    let userLanguage = UserService.getUserPreferredLanguage()

    userLanguage = userLanguage.value || 'en'

    const formatConfig = {
        minimumFractionDigits: DECIMAL_PRECISION,
        maximumFractionDigits: DECIMAL_PRECISION
      };

    let formattedNumber = Intl.NumberFormat(userLanguage, formatConfig).format(number)

    return formattedNumber
}

const convertWithPrecision = (number, precision) => {
    const toPrecision = isNilOrEmpty(precision) ? DECIMAL_PRECISION : precision
    
    return parseFloat(number).toFixed(toPrecision)
}

export {
    formatNumber,
    convertWithPrecision
}

