import {
    UPDATE_LOGO_SRC,
    UPDATE_LOGIN_VIEW_BANNER_SRC,
    UPDATE_NAVIGATION_ITEM,
    UPDATE_BRAND_NAME_VALUE,
    SET_BRAND_LANGUAGE,
    SET_PORTAL_FONT_FAMILY,
    SET_TOP_LEVEL_PROPERTY,
    SET_INITIAL_PORTAL_SETTING_BDA,
    UPDATE_BDA,
    REMOVE_BRA,
    ADD_BRA,
    SET_BRA_VALUE,
    UPDATE_BRA_VALUE,
    REMOVE_NESTED_BRA,
    ADD_NESTED_BRA,
    SET_NESTED_BRA_VALUE,
    SET_SCREEN_CONFIG_SECTION_FIELDS,
    SET_SCREEN_CONFIG_SECTION_SUB_SECTIONS,
    SET_SCREEN_CONFIG_SECTION,
    SET_PAYMENT_CONFIG_MODE,
    SET_HAS_PAYMENT_CONFIG_MODE,
    SET_PAYMENT_GATEWAY_DESTINATION,
} from './admin-customisation-action-types'

const initialState = {
    brandLanguages: [],
    documentTitleBrand: 'AfterSales Portal',
    brandName: {
        value: '',
        defaultValue: 'AfterSales Portal',
    },

    portalSettings: {
        id: '',
    },
    loginViewBanner: {
        //use
        src: '',
    },
    productCategory: [],
    productMaterialGroup: [],
    portalFontFamily: [],
    portalPaymentConfigMode: [],
    portalSettingData: {},
    objectName: {
        attribute: {
            id: '',
        },
        required: true,
        value: '',
        oldValue: '',
    },
    logoutURL: {
        attribute: {
            id: '',
        },
        value: '',
        oldValue: '',
    },
    corporateLogo: {
        attribute: {
            id: '',
        },
        required: true,
        value: '',
        oldValue: '',
        src: '',
    },
    whiteLogo: {
        attribute: {
            id: '',
        },
        required: true,
        value: '',
        oldValue: '',
        src: '',
    },
    address1: {
        attribute: {
            id: '',
        },
        value: '',
        oldValue: '',
    },
    address2: {
        attribute: {
            id: '',
        },
        value: '',
        oldValue: '',
    },
    address3: {
        attribute: {
            id: '',
        },
        value: '',
        oldValue: '',
    },
    address4: {
        attribute: {
            id: '',
        },
        value: '',
        oldValue: '',
    },

    portalPhoneNumber: {
        attribute: {
            id: '',
        },
        value: '',
        oldValue: '',
    },

    portalFaxNumber: {
        attribute: {
            id: '',
        },
        value: '',
        oldValue: '',
    },
    portalEmailAddress: {
        attribute: {
            id: '',
        },
        value: '',
        oldValue: '',
    },
    bannerDisplayDuration: {
        attribute: {
            id: '',
        },
        value: 100, //ms
        oldValue: 100,
    },
    portalNavigationPages: [],
    socialMediaLinks: {
        items: {
            fb: {
                id: 'fb',
                sequence: 0,
                hidden: false,
                to: 'http://example.com',
                icon: 'facebook',
            },
            ig: {
                id: 'ig',
                sequence: 0,
                hidden: false,
                to: 'http://example.com',
                icon: 'instagram',
            },
            linkedIn: {
                id: 'linkedIn',
                sequence: 0,
                hidden: false,
                to: 'http://example.com',
                icon: 'linkedin',
            },
            pinterest: {
                id: 'pinterest',
                sequence: 0,
                hidden: false,
                to: 'http://example.com',
                icon: 'pinterest',
            },
            twitter: {
                id: 'twitter',
                sequence: 0,
                hidden: false,
                to: 'http://example.com',
                icon: 'twitter',
            },
        },
        order: ['fb', 'ig', 'linkedIn', 'pinterest', 'twitter'],
    },
    customLinkItems: {
        items: {
            aboutUs: {
                id: 'aboutUs',
                i18nValue: 'menu.aboutUs',
                sequence: 0,
                hidden: false,
                to: 'http://example.com',
            },
            contactUs: {
                id: 'contactUs',
                i18nValue: 'menu.contactUs',
                sequence: 1,
                hidden: false,
                to: 'http://example.com',
            },
            faq: {
                id: 'faq',
                i18nValue: 'menu.faq',
                sequence: 2,
                hidden: false,
                to: 'http://example.com',
            },
        },
        order: ['aboutUs', 'contactUs', 'faq'],
    },

    address: {
        full: `MBS Building, 6001 Beach Rd, 
        #15-10, GOLDEN MILE TOWER
         Singapore 199589`,
    },
    contactEmail: {
        value: 'support@inmindcloud.com',
        displayValue: 'support@inmindcloud.com',
    },
    contactPhone: {
        value: '+6562276985',
        displayValue: '+65 6227 6985',
    },
    footerAdditionalLinks: {
        items: {
            aboutUs: {
                id: 'aboutUs',
                i18nValue: 'footer.additionalLinks.aboutUs',
                sequence: 0,
                hidden: false,
                to: '/aboutus',
            },
            contactUs: {
                id: 'contactUs',
                i18nValue: 'footer.additionalLinks.contactUs',
                sequence: 0,
                hidden: false,
                to: '/contactus',
            },
            faqs: {
                id: 'faqs',
                i18nValue: 'footer.additionalLinks.faqs',
                sequence: 0,
                hidden: false,
                to: '/faq',
            },
        },
        order: ['aboutUs', 'contactUs', 'faqs'],
    },
}

export const adminCustomisationReducers = (state = initialState, action) => {
    switch (action.type) {
        case SET_BRAND_LANGUAGE:
            return {
                ...state,
                brandLanguages: action.payload,
            }
        case SET_PORTAL_FONT_FAMILY:
            return {
                ...state,
                portalFontFamily: action.payload,
            }
        case SET_PAYMENT_CONFIG_MODE:
            return {
                ...state,
                portalPaymentConfigMode: action.payload,
            }
        case SET_HAS_PAYMENT_CONFIG_MODE:
            var hasCommercePaymentMode =
                state.portalSettingData.hasCommercePaymentMode
            hasCommercePaymentMode.oldValue = hasCommercePaymentMode.value
            hasCommercePaymentMode.initialValue = hasCommercePaymentMode.value
            hasCommercePaymentMode.value = [action.payload]
            //get value of hasCommercePaymentMode from state.portalSettingData

            var newPayload = {
                ...state,
                portalSettingData: {
                    ...state.portalSettingData,
                    hasCommercePaymentMode: hasCommercePaymentMode,
                },
            }
            return newPayload
        case SET_PAYMENT_GATEWAY_DESTINATION:
            var paymentGatewayDestinationName =
                state.portalSettingData.paymentGatewayDestinationName
            paymentGatewayDestinationName.oldValue =
                paymentGatewayDestinationName.value
            paymentGatewayDestinationName.initialValue =
                paymentGatewayDestinationName.value
            paymentGatewayDestinationName.value = [action.payload]

            var newPayload = {
                ...state,
                portalSettingData: {
                    ...state.portalSettingData,
                    paymentGatewayDestinationName: paymentGatewayDestinationName,
                },
            }
            return newPayload
        case SET_TOP_LEVEL_PROPERTY:
            return { ...state, [action.payload.key]: action.payload.data }
        case SET_INITIAL_PORTAL_SETTING_BDA:
            var newUpdatedPayload = {
                ...state,
                portalSettingData: { ...action.payload },
            }

            return newUpdatedPayload

        case UPDATE_BDA:
            var newUpdatedPayload = {
                ...state,
                portalSettingData: { ...state.portalSettingData },
            }

            for (let bdaKey in action.payload) {
                newUpdatedPayload.portalSettingData[bdaKey] = {
                    ...state.portalSettingData[bdaKey],
                    value: action.payload[bdaKey].value,
                    oldValue: action.payload[bdaKey].oldValue,
                    initialValue: action.payload[bdaKey].initialValue,
                }
            }

            return newUpdatedPayload
        case REMOVE_BRA:
            var parentType = action.payload.parentType
            var idToRemove = action.payload.id

            var newUpdatedPayload = {
                ...state,
                portalSettingData: {
                    ...state.portalSettingData,
                    [parentType]: {
                        ...state.portalSettingData[parentType],
                        value: state.portalSettingData[parentType].value.filter(
                            (o) => {
                                if (o.id !== idToRemove) return o
                            }
                        ),
                    },
                },
            }
            return newUpdatedPayload
        case ADD_BRA:
            var parentType = action.payload.parentType
            var braTemplate = action.payload.BRATemplate

            var newUpdatedPayload = {
                ...state,
                portalSettingData: {
                    ...state.portalSettingData,
                    [parentType]: {
                        ...state.portalSettingData[parentType],
                        value: [
                            ...state.portalSettingData[parentType].value,
                            braTemplate,
                        ],
                    },
                },
            }
            return newUpdatedPayload
        case SET_BRA_VALUE:
            var parentType = action.payload.parentType
            var value = action.payload.value

            var newUpdatedPayload = {
                ...state,
                portalSettingData: {
                    ...state.portalSettingData,
                    [parentType]: {
                        ...state.portalSettingData[parentType],
                        value: value,
                    },
                },
            }
            return newUpdatedPayload
        case UPDATE_BRA_VALUE:
            var parentType = action.payload.parentType
            var templateToUpdate = action.payload.value
            var id = templateToUpdate.id

            var newUpdatedPayload = {
                ...state,
                portalSettingData: {
                    ...state.portalSettingData,
                    [parentType]: {
                        ...state.portalSettingData[parentType],
                        value: state.portalSettingData[parentType].value.map(
                            (o) => {
                                if (o.id === id) {
                                    return templateToUpdate
                                } else return o
                            }
                        ),
                    },
                },
            }
            return newUpdatedPayload
        // case UPDATE_COMPANY_SETTING:
        //     return {
        //         ...state,
        //         ...action.payload,
        //     }
        // case UPDATE_ADDRESS_INFORMATION:
        //     return {
        //         ...state,
        //         ...action.payload,
        //     }
        // case UPDATE_CONTACT_INFORMATION:
        //     return {
        //         ...state,
        //         ...action.payload,
        //     }

        case UPDATE_BRAND_NAME_VALUE:
            return {
                ...state,
                brandName: {
                    ...state.brandName,
                    value: action.payload,
                },
            }

        case UPDATE_LOGIN_VIEW_BANNER_SRC:
            return {
                ...state,
                loginViewBanner: {
                    ...state.loginViewBanner,
                    src: action.payload,
                },
            }
        case UPDATE_NAVIGATION_ITEM:
            //TODO
            return {
                ...state,
                navigationItems: {
                    ...state.navigationItems,
                    items: {
                        ...state.navigationItems.items,
                        ['equipments']: {
                            ...state.navigationItems.items['equipments'],
                            hidden: action.payload,
                        },
                    },
                },
            }
        case REMOVE_NESTED_BRA:
            var topParentType = action.payload.topParentType
            var parentType = action.payload.parentType
            var parentId = action.payload.parentId
            var idToRemove = action.payload.id

            var topParentValue =
                state.portalSettingData[topParentType].value || []
            var updatedTopParentValue = topParentValue.map((o, i) => {
                if (o.id === parentId) {
                    o[parentType].value = o[parentType].value.filter((o) => {
                        if (o.id !== idToRemove) {
                            return o
                        }
                    })
                }
                return o
            })

            var newUpdatedPayload = {
                ...state,
                portalSettingData: {
                    ...state.portalSettingData,
                    [topParentType]: {
                        ...state.portalSettingData[topParentType],
                        value: updatedTopParentValue,
                    },
                },
            }
            return newUpdatedPayload
        case ADD_NESTED_BRA:
            var topParentType = action.payload.topParentType
            var parentType = action.payload.parentType
            var braTemplate = action.payload.BRATemplate

            var newUpdatedPayload = {
                ...state,
                portalSettingData: {
                    ...state.portalSettingData,
                    [topParentType]: {
                        ...state.portalSettingData[topParentType],
                        [parentType]: {
                            ...state.portalSettingData[topParentType][
                                parentType
                            ],
                            value: [
                                ...state.portalSettingData[parentType].value,
                                braTemplate,
                            ],
                        },
                    },
                },
            }
            return newUpdatedPayload
        case SET_NESTED_BRA_VALUE:
            var topParentType = action.payload.topParentType
            var parentType = action.payload.parentType
            var parentId = action.payload.parentId
            var value = action.payload.value

            var topParentValue =
                state.portalSettingData[topParentType].value || []
            var updatedTopParentValue = topParentValue.map((o, i) => {
                if (o.id === parentId) {
                    o[parentType].value = value
                }
                return o
            })

            var newUpdatedPayload = {
                ...state,
                portalSettingData: {
                    ...state.portalSettingData,
                    [topParentType]: {
                        ...state.portalSettingData[topParentType],
                        value: updatedTopParentValue,
                    },
                },
            }
            return newUpdatedPayload

        case SET_SCREEN_CONFIG_SECTION_FIELDS:
            var screenConfigBRA = 'includesPortalScreenConfiguration'
            var sectionBRA = 'includesPortalScreenConfigurationSection'
            var subSectionBRA = 'includesPortalScreenConfigurationField'
            var sectionId = action.payload.sectionId
            var subSections = action.payload.fields

            var screenConfigurations =
                state.portalSettingData[screenConfigBRA].value || []
            var found = false
            for (let index = 0; index < screenConfigurations.length; index++) {
                const screen = screenConfigurations[index]

                var sections = screen[sectionBRA].value || []
                var updatedSections = sections.map((o, i) => {
                    if (o.id === sectionId) {
                        found = true
                        o[subSectionBRA].value = subSections
                    }
                    return o
                })

                if (found) {
                    screen[sectionBRA].value = updatedSections
                    screenConfigurations[index] = screen
                    break
                }
            }

            var newUpdatedPayload = {
                ...state,
                portalSettingData: {
                    ...state.portalSettingData,
                    [screenConfigBRA]: {
                        ...state.portalSettingData[screenConfigBRA],
                        value: screenConfigurations,
                    },
                },
            }
            return newUpdatedPayload
        case SET_SCREEN_CONFIG_SECTION_SUB_SECTIONS:
            var screenConfigBRA = 'includesPortalScreenConfiguration'
            var sectionBRA = 'includesPortalScreenConfigurationSection'
            var subSectionBRA = 'includesPortalScreenConfigurationSubSection'
            var sectionId = action.payload.sectionId
            var subSections = action.payload.subSections

            var screenConfigurations =
                state.portalSettingData[screenConfigBRA].value || []
            var found = false
            for (let index = 0; index < screenConfigurations.length; index++) {
                const screen = screenConfigurations[index]

                var sections = screen[sectionBRA].value || []
                var updatedSections = sections.map((o, i) => {
                    if (o.id === sectionId) {
                        found = true
                        o[subSectionBRA].value = subSections
                    }
                    return o
                })

                if (found) {
                    screen[sectionBRA].value = updatedSections
                    screenConfigurations[index] = screen
                    break
                }
            }

            var newUpdatedPayload = {
                ...state,
                portalSettingData: {
                    ...state.portalSettingData,
                    [screenConfigBRA]: {
                        ...state.portalSettingData[screenConfigBRA],
                        value: screenConfigurations,
                    },
                },
            }
            return newUpdatedPayload
        case SET_SCREEN_CONFIG_SECTION:
            var screenConfigBRA = 'includesPortalScreenConfiguration'
            var sectionBRA = 'includesPortalScreenConfigurationSection'
            var sectionId = action.payload.sectionId
            var section = action.payload.section

            var screenConfigurations =
                state.portalSettingData[screenConfigBRA].value || []
            var found = false
            for (let index = 0; index < screenConfigurations.length; index++) {
                const screen = screenConfigurations[index]

                var sections = screen[sectionBRA].value || []
                var updatedSections = sections.map((o, i) => {
                    if (o.id === sectionId) {
                        found = true
                        o = section
                    }
                    return o
                })

                if (found) {
                    screen[sectionBRA].value = updatedSections
                    screenConfigurations[index] = screen
                    break
                }
            }

            var newUpdatedPayload = {
                ...state,
                portalSettingData: {
                    ...state.portalSettingData,
                    [screenConfigBRA]: {
                        ...state.portalSettingData[screenConfigBRA],
                        value: screenConfigurations,
                    },
                },
            }
            return newUpdatedPayload
        default:
            return state
    }
}
