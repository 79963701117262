import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './views/app'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import reduxStore from './store'



ReactDOM.render(
    <Suspense fallback={null}>
        <Provider store={reduxStore}>
            <App></App>
        </Provider>
    </Suspense>,

    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
