import * as TYPES from './variant-action-types';

const defaultState = {
    kmatProduct: null,
    variantProducts:null,
    selectedVariants: [],
    variantSearch: '',
    variantFilters: [],
    filterCount: {favorites: 0, prevOrdered: 0},
    previouslyOrderedURIs: [],
    allVariantAttributes: [],
    variantComparisonData: []
};

export default (state = defaultState, action) => {
    switch(action.type) {
        case TYPES.SET_KMAT_PRODUCT:
            return {...state, kmatProduct: action.product};
        case TYPES.SET_VARIANT_PRODUCTS:
            return {...state, variantProducts: action.data};
        case TYPES.SET_SELECTED_VARIANT_PRODUCTS:
            return {...state, selectedVariants: action.data};
        case TYPES.ADD_SELECTED_VARIANT_PRODUCT:
            if (state.selectedVariants.length < 5 && state.selectedVariants.indexOf(action.data.uri) === -1) 
                return {...state, selectedVariants: [...state.selectedVariants, action.data]};
            return state;
        case TYPES.REMOVE_SELECTED_VARIANT_PRODUCT:
             const updatedSelectedVariants = state.selectedVariants.filter(v => v.uri !== action.uri);
             return {...state, selectedVariants: updatedSelectedVariants};
        case TYPES.RESET_VARIANT_DATA:
            return {...state, variantProducts: null, selectedVariants: [], kmatProduct: null, allVariantAttributes: [], variantComparisonData: [],
            filterCount: {favorites: 0, prevOrdered: 0}};
        case TYPES.SET_VARIANT_SEARCH:
            return {...state, variantSearch: action.value};
        case TYPES.ADD_VARIANT_FILTER:
            if (state.variantFilters.find(f => f.id === action.filter.id) === undefined)
                return {...state, variantFilters: [...state.variantFilters, action.filter]};
            return state;
        case TYPES.SET_PREVIOUSLY_ORDERED_PRODUCTS_URIS:
            return {...state, previouslyOrderedURIs: action.uris};
        case TYPES.REMOVE_VARIANT_FILTER:
            const updatedFilters = state.variantFilters.filter(f => f.id !== action.id);
            return {...state, variantFilters: updatedFilters};
        case TYPES.SET_FILTER_COUNT:
            return {...state, filterCount: action.data};
        case TYPES.RESET_VARIANT_SEARCH_FILTERS:
            return {...state, variantSearch: '', variantFilters: []};
        case TYPES.SET_ALL_VARIANT_ATTRIBUTES:
            return {...state, allVariantAttributes: action.attributes};
        case TYPES.SET_VARIANT_COMPARISON_DATA:
            return {...state, variantComparisonData: action.data};
        default:
            return state;
    }
};