import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import Signup from './Signup';
import Error from '../error';
import {CustomisationService, AuthenticationService} from '../../services';
import {setIsSelfRegistrationEnabled} from '../../store/sign-up/signUpActionTypes';
import {isNil} from '../../helpers/helpers';

const SignupWrapper = () => {
    const isSelfRegistrationEnabled = useSelector(state => state.signUpReducer.isSelfRegistrationEnabled);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [selfRegistrationDataLoaded, setSelfRegistrationDataLoaded] = useState(!isNil(isSelfRegistrationEnabled));
    const dispatch = useDispatch();

    AuthenticationService.useAuthenticatorTrack(valid => setIsLoggedIn(valid));

    useEffect(() => {
        if (isNil(isSelfRegistrationEnabled)) {
            CustomisationService.getPublicCustomisationData().then(response => {
                const publicPortalSettingDto = response.data;
                dispatch(setIsSelfRegistrationEnabled(typeof publicPortalSettingDto.portalEnableSelfRegistration === 'boolean' && Boolean(publicPortalSettingDto.portalEnableSelfRegistration)));
                setSelfRegistrationDataLoaded(true);
            });
        }
    }, [isSelfRegistrationEnabled]);

    if (!selfRegistrationDataLoaded) return null;
    return (isSelfRegistrationEnabled && !isLoggedIn) ? <Signup /> : <Error />;
};

export default SignupWrapper;