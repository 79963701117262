export const SUB_ENTITY_TYPE_PRODUCT_CATEGORY = 'http://www.inmindcloud.com/application/schema.owl#subEntityTypeProductCategory'
export const SUB_ENTITY_TYPE_PRODUCT_MATERIAL_GROUP = 'http://www.inmindcloud.com/application/schema.owl#subEntityTypeProductMaterialGroup'


//classification
export const PRODUCT_CLASSIFICATION_CONSUMABLE = 'http://www.inmindcloud.com/application/schema.owl#CONSUMABLE'
export const PRODUCT_CLASSIFICATION_SPARE_PART = 'http://www.inmindcloud.com/application/schema.owl#SPARE_PART'
export const PRODUCT_CLASSIFICATION_PRODUCT = 'http://www.inmindcloud.com/application/schema.owl#PRODUCT'

export const SECTIONS_WITH_NO_DISPLAY_LABEL = ['PortalScreenConfigurationSparePartFinderHierarchyEquipment', 'PortalScreenConfigurationSparePartFinderHierarchyProduct',
'PortalScreenConfigurationSparePartFinderBOM', 'PortalScreenConfigurationSparePartFinderProductDetailPrimary', 'PortalScreenConfigurationSparePartFinderProductDetailSecondary',
'PortalScreenConfigurationShoppingCartInquiryOptional'].map(
    i => `http://www.inmindcloud.com/application/schema.owl#${i}`);
export const SECTIONS_WITH_HIDE_OPTION = ['PortalScreenConfigurationSparePartFinderProductDetailSecondary', 'PortalScreenConfigurationShoppingCartInquiryOptional'].map(
    i => `http://www.inmindcloud.com/application/schema.owl#${i}`);
export const SECTIONS_WITH_NO_TECHINCAL = ['PortalScreenConfigurationShoppingCartInquiryOptional'].map(i => `http://www.inmindcloud.com/application/schema.owl#${i}`);
export const SECTIONS_WITH_NO_LOCALIZATION = ['PortalScreenConfigurationShoppingCartInquiryOptional'].map(i => `http://www.inmindcloud.com/application/schema.owl#${i}`);