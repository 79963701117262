import React, {useState, useEffect} from 'react';
import {createMuiTheme} from '@material-ui/core/styles';

import {CustomisationService} from '../../services';
import systemDefaultTheme from '../../theme/salesPortal/theme';
import customisingThemeTemplate from '../../theme/customising/theme';
import {useGetDefaultBrandName, useGetDefaultFaviconSrc, isNil, isNilOrEmpty, dynamicImageSrcRoot} from '../../helpers/helpers';

const MEDIA_FILE_URI = {
    banner: 'http://www.inmindcloud.com/application/schema.owl#PortalMediaFileLoginBanner',
    companyLogo: 'http://www.inmindcloud.com/application/schema.owl#PortalMediaFileCorporateLogo',
    favIcon: 'http://www.inmindcloud.com/application/schema.owl#PortalFavouriteLogo'
};

const usePortalInitialData = () => {
    const [customTheme, setCustomTheme] = useState(systemDefaultTheme);
    const [banner, setBanner] = useState(null);
    const [companyLogo, setCompanyLogo] = useState(null);
    const [companyName, setCompanyName] = useState('');
    const [favIcon, setFavIcon] = useState(null);
    const [backgroundColor, setBackgroundColor] = useState();
    const [dataLoaded, setDataLoaded] = useState(false);
    const defaultBrandName = useGetDefaultBrandName();
    const defaultFavIcon = useGetDefaultFaviconSrc();

    const getActiveTheme = (data) => {
        const themeData = data.includesPortalTheme || [];
        const [activeTheme] = themeData.filter(t => t.isDefaultPortalTheme);
        return activeTheme; 
    };

    const getMediaFilePath = (mediaFile) => {
        if (!isNil(mediaFile)) {
            const mediaPath = mediaFile.mediaFilePath;
            if (mediaPath && typeof mediaPath === 'string')
                return mediaPath;
        }
        return '';
    };

    const processThemeTemplate = activePortalTheme => {
        if (!isNilOrEmpty(activePortalTheme)) {
            customisingThemeTemplate.palette.primary.main =
            activePortalTheme.portalPrimaryMainColor

            customisingThemeTemplate.palette.background.default =
                activePortalTheme.portalBackgroundDefaultColor

            customisingThemeTemplate.palette.info.main =
                activePortalTheme.portalInfoMainColor
            customisingThemeTemplate.palette.success.main =
                activePortalTheme.portalSuccessMainColor
            customisingThemeTemplate.palette.error.main =
                activePortalTheme.portalErrorMainColor

            setBackgroundColor(activePortalTheme.portalBackgroundDefaultColor)
            setCustomTheme(createMuiTheme(customisingThemeTemplate));
        }
        setDataLoaded(true);
    };

    //initializes the state of the portal data component
    const filterInitialPortalData = (data) => {
        if (data) {
            setCompanyName(data.portalCompanyName || '');
            const activeTheme = getActiveTheme(data);
            processThemeTemplate(activeTheme);
            const {includesPortalMediaFile} = data;
            if (!isNil(includesPortalMediaFile)) {
                for (const file of includesPortalMediaFile) {
                    const mediaFilePath = getMediaFilePath(file);
                    switch(file.uri) {
                        case MEDIA_FILE_URI.banner:
                            setBanner(mediaFilePath);
                            break;
                        case MEDIA_FILE_URI.companyLogo:
                            setCompanyLogo(mediaFilePath);
                            break;
                        case MEDIA_FILE_URI.favIcon:
                            setFavIcon(mediaFilePath);
                            break;
                        default:
                    }
                }
            }
        }
    };

    useEffect(() => {
        if (!isNil(favIcon)) {
            let finalFavIcon = favIcon ? dynamicImageSrcRoot(favIcon) : process.env.PUBLIC_URL + defaultFavIcon;
            const favIconDOM = document.getElementById('favicon');
            if (favIconDOM) favIconDOM.href = finalFavIcon;
        }
    }, [favIcon]);

    useEffect(() => {
        if (!isNil(companyName)) {
            const finalCompanyName = companyName || defaultBrandName;
            document.title = finalCompanyName;
        }
    }, [companyName]);


    useEffect(() => {
        CustomisationService.getPublicCustomisationData().then(response => filterInitialPortalData(response.data));
    }, []);

    return {customTheme, banner, companyLogo, companyName, dataLoaded, backgroundColor};
};

export default usePortalInitialData;