import { isNilOrEmpty } from '../../helpers'
import { UPDATE_SHOPPING_CART } from './cart-action-types'

const initialState = {
    cart: { items: [] },
    cartCount: 0
}

const CartReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_SHOPPING_CART:
            const updatedCart = action.payload
            let cartCount = 0

            if (!isNilOrEmpty(updatedCart.items)) {
                updatedCart.items.map((item) => {
                    if (
                        !isNilOrEmpty(item.quantity) &&
                        !isNaN(item.quantity)
                    )
                        cartCount += item.quantity
                })
            }

            return {
                ...state,
                cart: updatedCart,
                cartCount: cartCount,
            }
        default:
            return state
    }
}

export { CartReducer }
