import axios from 'axios'
import { trackPromise } from 'react-promise-tracker'
const USER_NAME_SESSION_ATTRIBUTE_NAME = 'authenticatedUser'
const TOKEN_SESSION_ATTRIBUTE_NAME = 'oauthToken'
const CancelToken = axios.CancelToken

const getHost = () => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return process.env.REACT_APP_URL_API
    } else {
        const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href)
        let path = publicUrl.pathname
        path = path.replace('portal', 'api')
        console.log('***************************** api url')
        console.log(publicUrl.origin + path)
        return publicUrl.origin + path
    }
}

const getBaseHost = () => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return process.env.REACT_APP_URL
    } else {
        const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href)
        let path = publicUrl.pathname
        path = path.replace('portal', '')
        console.log(publicUrl.origin + path)
        return publicUrl.origin + path
    }
}

const apiAxiosInstance = axios.create({
    baseURL: getHost(),
    timeout: 60000,
    //headers: { 'X-Custom-Header': 'foobar' },
})

const baseAxiosInstance = axios.create({
    baseURL: getBaseHost(),
    timeout: 60000, // 1 minute
    params: {}, // do not remove this, its added to add params later in the config
})

const apiAxiosInterceptor = apiAxiosInstance.interceptors.request.use(
    function (config) {
        // Do something before request is sent]

        if (!config.headers.authorization) {
            config.headers.authorization = localStorage.getItem(
                TOKEN_SESSION_ATTRIBUTE_NAME
            )
        }

        config.withCredentials = true

        return config
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error)
    }
)

const resourceAxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_URL_API + '/resource/',
    timeout: 60000,
    //headers: { 'X-Custom-Header': 'foobar' },
})

const ajax = {
    _getCorrectAxiosInstance(axiosInstance = '') {
        switch (axiosInstance) {
            case 'apiAxiosInstance':
                return apiAxiosInstance
            case 'baseAxiosInstance':
                return baseAxiosInstance
            case 'resourceAxiosInstance':
                return resourceAxiosInstance
            default:
                return null
        }
    },

    get(axiosInstance = '', url = '', axiosOption = {}, appOption = {}) {
        return this._getCorrectAxiosInstance(axiosInstance).request({
            ...axiosOption,
            url: url,
            method: 'get',
        })
    },
    post(axiosInstance = '', url = '', axiosOption = {}, appOption = {}) {
        const source = CancelToken.source()
        return this._getCorrectAxiosInstance(axiosInstance).request({
            ...axiosOption,
            cancelToken: source.token,
            url: url,
            method: 'post',
        })
    },
    delete(axiosInstance = '', url = '', axiosOption = {}, appOption = {}) {
        return this._getCorrectAxiosInstance(axiosInstance).request({
            ...axiosOption,
            url: url,
            method: 'delete',
        })
    },
    patch(axiosInstance = '', url = '', axiosOption = {}, appOption = {}) {
        return this._getCorrectAxiosInstance(axiosInstance).request({
            ...axiosOption,
            url: url,
            method: 'patch',
        })
    },
    put(axiosInstance = '', url = '', axiosOption = {}, appOption = {}) {
        return this._getCorrectAxiosInstance(axiosInstance).request({
            ...axiosOption,
            url: url,
            method: 'put',
        })
    },
}

const base = {
    get(url = '', axiosOption = {}, appOption = {}) {
        return new Promise((resolve, reject) => {
            ajax.get('baseAxiosInstance', url, axiosOption, appOption)
                .then((response) => {
                    resolve(response)
                })
                .catch((...error) => {
                    reject(...error)
                })
        })
    },
}

const api = {
    get(url = '', axiosOption = {}, appOption = {}) {
        return new Promise((resolve, reject) => {
            let action = ajax
                .get('apiAxiosInstance', url, axiosOption, appOption)
                .then((response) => {
                    resolve(response)
                })
                .catch((...error) => {
                    reject(...error)
                })

            if (appOption.hasOwnProperty('trackThisPromise')) {
                if (!appOption.trackThisPromise) {
                    return action
                }
            }

            return trackPromise(action)
        })
    },
    post(url = '', axiosOption = {}, appOption = {}) {
        return new Promise((resolve, reject) => {
            let action = ajax
                .post('apiAxiosInstance', url, axiosOption, appOption)
                .then((response) => {
                    resolve(response)
                })
                .catch((...error) => {
                    reject(...error)
                })

            if (appOption.hasOwnProperty('trackThisPromise')) {
                if (!appOption.trackThisPromise) {
                    return action
                }
            }
            return trackPromise(action)
        })
    },
    delete(url = '', axiosOption = {}, appOption = {}) {
        return new Promise((resolve, reject) => {
            trackPromise(
                ajax
                    .delete('apiAxiosInstance', url, axiosOption, appOption)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((...error) => {
                        reject(...error)
                    })
            )
        })
    },
    patch(url = '', axiosOption = {}, appOption = {}) {
        return new Promise((resolve, reject) => {
            let action = ajax
                .patch('apiAxiosInstance', url, axiosOption, appOption)
                .then((response) => {
                    resolve(response)
                })
                .catch((...error) => {
                    reject(...error)
                })

            if (appOption.hasOwnProperty('trackThisPromise')) {
                if (!appOption.trackThisPromise) {
                    return action
                }
            }
            trackPromise(action)
        })

        // return new Promise((resolve, reject) => {
        //     trackPromise(
        //         ajax
        //             .patch('apiAxiosInstance', url, axiosOption, appOption)
        //             .then((response) => {
        //                 resolve(response)
        //             })
        //             .catch((...error) => {
        //                 reject(...error)
        //             })
        //     )
        // })
    },
    put(url = '', axiosOption = {}, appOption = {}) {
        return new Promise((resolve, reject) => {
            trackPromise(
                ajax
                    .put('apiAxiosInstance', url, axiosOption, appOption)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((...error) => {
                        reject(...error)
                    })
            )
        })
    },
}

export { ajax as default, api, base }
