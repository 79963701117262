import { api } from './ajax'
import { isNil, isNilOrEmpty } from '../helpers'
const requestMaping = '/attachment/'

export const AttachmentService = {
    uploadAttachment(primaryId, instanceId, files) {
        let formData = new FormData()
        for (let i = 0; i < files.length; i++) {
            formData.append('attachment', files[i])
        }
        return api.post(requestMaping + 'store', {
            params: {
                primaryId: atob(primaryId),
                instanceId: atob(instanceId),
            },
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: formData,
        })
    },

    getAttachmentList(primaryId, instanceId) {
        return api.get(requestMaping + 'getAttachmentList', {
            params: {
                primary: atob(primaryId),
                instanceid: atob(instanceId),
            },
        })
    },

    deleteAttachment(primaryId, instanceId, attachmentURI) {
        return api.post(requestMaping + 'delete', {
            params: {
                primaryId: atob(primaryId),
                instanceId: atob(instanceId),
            },
            data: attachmentURI,
        })
    },

    getAttachmentsAndFolder(primaryId, instanceId) {
        return api.get(
            'attachments/getAttachmentsAndFolder',
            {
                params: {
                    primaryId: primaryId,
                    instanceId: instanceId,
                },
            },
            { trackThisPromise: false }
        )
    },
}
