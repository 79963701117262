import { api } from './ajax'

export const SignupService = {
    verfiyEmail: (payload) =>
        api.post(
            'selfRegistration/verify',
            { data: payload },
            { trackThisPromise: false }
        ),
    validate: (token) =>
        api.post(
            'selfRegistration/validate',
            { headers: { authorization: `Bearer ${token}` } },
            { trackThisPromise: false }
        ),
    register: (payload, token) =>
        api.post('selfRegistration/register', {
            data: payload,
            headers: { authorization: `Bearer ${token}` },
        }),
    getStates: (payload, token) =>
        api.post('selfRegistration/getCountryRegions', {
            data: payload,
            headers: { authorization: `Bearer ${token}` },
        }),
    getCaptchaDetail: () =>
        api.get(
            'selfRegistration/getCaptchaDetail',
            {},
            { trackThisPromise: false }
        ),
    getGoogleApiKey: (token) =>
        api.get(
            'selfRegistration/getGoogleApiKey',
            {headers: { authorization: `Bearer ${token}` },},
            { trackThisPromise: false }
        ),
    getPortalSettings: (token) =>
        api.get(
            'selfRegistration/settings',
            { headers: { authorization: `Bearer ${token}` } },
            { trackThisPromise: false }
        ),
}
