import React, { useState, useEffect, useRef } from 'react'
import { InputLabel, makeStyles } from '@material-ui/core'
import { SignupService } from '../../services'
import { isNilOrEmpty } from '../../helpers'
import { SET_SCREEN_CONFIG_SECTION_SUB_SECTIONS } from '../../store/admin-customisation/admin-customisation-action-types'

let autoComplete

const loadScript = (url, callback) => {
    let script = document.createElement('script')
    script.type = 'text/javascript'

    if (script.readyState) {
        script.onreadystatechange = function () {
            if (
                script.readyState === 'loaded' ||
                script.readyState === 'complete'
            ) {
                script.onreadystatechange = null
                callback()
            }
        }
    } else {
        script.onload = () => callback()
    }

    script.src = url
    document.getElementsByTagName('head')[0].appendChild(script)
}

function handleScriptLoad(
    updateQuery,
    autoCompleteRef,
    formDataNew,
    setFormDataNew,
    accountCountries,
    token
) {
    autoComplete = new window.google.maps.places.Autocomplete(
        autoCompleteRef.current,
        { types: ['address'], componentRestrictions: {} } // country: "us" } }
    )
    autoComplete.setFields(['address_components', 'formatted_address'])
    autoComplete.addListener('place_changed', () =>
        handlePlaceSelect(
            updateQuery,
            formDataNew,
            setFormDataNew,
            accountCountries,
            token
        )
    )
}

async function handlePlaceSelect(
    updateQuery,
    formDataNew,
    setFormDataNew,
    accountCountries,
    token
) {
    const addressObject = autoComplete.getPlace()
    //const query = addressObject.formatted_address;
    let wait = false

    let cityFieldExist = !isNilOrEmpty(formDataNew['Address-addressCity'])
        ? !isNilOrEmpty(formDataNew['Address-addressCity']) &&
          !formDataNew['Address-addressCity'].isHidden
        : false
    let streetFieldExist = !isNilOrEmpty(formDataNew['Address-addressStreet'])
        ? !isNilOrEmpty(formDataNew['Address-addressStreet']) &&
          !formDataNew['Address-addressStreet'].isHidden
        : false
    let zipFieldExist = !isNilOrEmpty(formDataNew['Address-addressZip'])
        ? !isNilOrEmpty(formDataNew['Address-addressZip']) &&
          !formDataNew['Address-addressZip'].isHidden
        : false
    let unitNoExist = !isNilOrEmpty(formDataNew['Address-addressUnitNo'])
        ? !isNilOrEmpty(formDataNew['Address-addressUnitNo']) &&
          !formDataNew['Address-addressUnitNo'].isHidden
        : false

    //updateQuery(query);
    updateQuery('')
    console.log(addressObject)

    if (addressObject.address_components) {
        let street, city, postal_code, state, unitNo, country, listOfStates
        let street0 = '',
            street1 = '',
            street2 = '',
            street3 = ''
        for (var i = 0; i < addressObject.address_components.length; i++) {
            if (addressObject.address_components[i].types.includes('route'))
                street0 = addressObject.address_components[i]
            else if (
                addressObject.address_components[i].types.includes(
                    'sublocality_level_3'
                )
            )
                street1 = addressObject.address_components[i]
            else if (
                addressObject.address_components[i].types.includes(
                    'sublocality_level_2'
                )
            )
                street2 = addressObject.address_components[i]
            else if (
                addressObject.address_components[i].types.includes(
                    'sublocality_level_1'
                )
            )
                street3 = addressObject.address_components[i]
            else if (
                addressObject.address_components[i].types.includes('locality')
            )
                city = addressObject.address_components[i]
            else if (
                addressObject.address_components[i].types.includes(
                    'postal_code'
                )
            )
                postal_code = addressObject.address_components[i]
            else if (
                addressObject.address_components[i].types.includes('country')
            )
                country = addressObject.address_components[i]
            else if (
                addressObject.address_components[i].types.includes(
                    'administrative_area_level_1'
                )
            )
                state = addressObject.address_components[i]
            else if (
                addressObject.address_components[i].types.includes(
                    'street_number'
                )
            )
                unitNo = addressObject.address_components[i]
        }

        // street = street0.long_name + ', '+ street1.long_name + ', '+ street2.long_name + ', '+ street3.long_name;

        if (street0 != '') street = street0.long_name
        if (street1 != '') street += ', ' + street1.long_name
        if (street2 != '') street += ', ' + street2.long_name
        if (street3 != '') street += ', ' + street3.long_name

        if (country) {
            var countryExist = false
            console.log(accountCountries)
            for (var i = 0; i < accountCountries.length; i++) {
                if (
                    country.long_name == accountCountries[i].label ||
                    country.long_name == accountCountries[i].objectName
                ) {
                    country = accountCountries[i]
                    countryExist = true
                    break
                } else if (
                    country.short_name ==
                        accountCountries[i].objectExternalId ||
                    country.short_name == accountCountries[i].objectId
                ) {
                    country = accountCountries[i]
                    countryExist = true
                    break
                }
            }

            if (!countryExist) {
                country = null
            } else {
                const payload = { Country: country.uri }

                wait = true
                SignupService.getStates(payload, token)
                    .then((response) => {
                        //wait for results before proceeding
                        const responseData = response.data
                        const entries = responseData.entry
                        if (entries) listOfStates = entries
                        else listOfStates = []

                        if (state && listOfStates) {
                            var stateExist = false
                            for (var i = 0; i < listOfStates.length; i++) {
                                if (
                                    state.long_name == listOfStates[i].label ||
                                    state.long_name ==
                                        listOfStates[i].objectName
                                ) {
                                    state = listOfStates[i]
                                    stateExist = true
                                    break
                                } else if (
                                    state.short_name ==
                                        listOfStates[i].objectExternalId ||
                                    state.short_name == listOfStates[i].objectId
                                ) {
                                    state = listOfStates[i]
                                    stateExist = true
                                    break
                                }
                            }

                            if (!stateExist) {
                                state = null
                            }
                        }

                        // const updatedFormData = {
                        //   ...formDataNew,
                        //   ['Address-addressCity']: {
                        //       ...formDataNew['Address-addressCity'],
                        //       value: city?city.long_name:'',
                        //   },
                        //   ['Address-addressStreet']: {
                        //     ...formDataNew['Address-addressStreet'],
                        //     value: street? street.long_name:'',
                        //   },
                        //   ['Address-addressZip']: {
                        //     ...formDataNew['Address-addressZip'],
                        //     value: postal_code? postal_code.long_name:'',
                        //   },
                        //   ['Address-hasCountry']: {
                        //     ...formDataNew['Address-hasCountry'],
                        //     value: country? country:'',
                        //   },
                        //   ['Address-hasCountryRegion']: {
                        //     ...formDataNew['Address-hasCountryRegion'],
                        //     value: state?state:'',
                        //   },
                        // }

                        var updatedFormData = formDataNew

                        if (cityFieldExist)
                            updatedFormData = {
                                ...updatedFormData,
                                ['Address-addressCity']: {
                                    ...formDataNew['Address-addressCity'],
                                    value: city ? city.long_name : '',
                                },
                            }

                        if (streetFieldExist)
                            updatedFormData = {
                                ...updatedFormData,
                                ['Address-addressStreet']: {
                                    ...formDataNew['Address-addressStreet'],
                                    value: street ? street : '',
                                },
                            }

                        if (zipFieldExist)
                            updatedFormData = {
                                ...updatedFormData,
                                ['Address-addressZip']: {
                                    ...formDataNew['Address-addressZip'],
                                    value: postal_code
                                        ? postal_code.long_name
                                        : '',
                                },
                            }

                        if (unitNoExist)
                            updatedFormData = {
                                ...updatedFormData,
                                ['Address-addressUnitNo']: {
                                    ...formDataNew['Address-addressUnitNo'],
                                    value: unitNo ? unitNo.long_name : '',
                                },
                            }

                        updatedFormData = {
                            ...updatedFormData,
                            ['Address-hasCountry']: {
                                ...formDataNew['Address-hasCountry'],
                                value: country ? country : '',
                            },
                        }

                        updatedFormData = {
                            ...updatedFormData,
                            ['Address-hasCountryRegion']: {
                                ...formDataNew['Address-hasCountryRegion'],
                                value: state ? state : '',
                            },
                        }

                        setFormDataNew(updatedFormData)
                    })
                    .catch(() => {
                        // enqueueSnackbar(t('general.generalErrorMsg'), {
                        //     variant: 'error',
                        // })
                    })
            }
        }

        if (!wait) {
            var updatedFormData = formDataNew

            if (cityFieldExist)
                updatedFormData = {
                    ...updatedFormData,
                    ['Address-addressCity']: {
                        ...formDataNew['Address-addressCity'],
                        value: city ? city.long_name : '',
                    },
                }

            if (streetFieldExist)
                updatedFormData = {
                    ...updatedFormData,
                    ['Address-addressStreet']: {
                        ...formDataNew['Address-addressStreet'],
                        value: street ? street : '',
                    },
                }

            if (zipFieldExist)
                updatedFormData = {
                    ...updatedFormData,
                    ['Address-addressZip']: {
                        ...formDataNew['Address-addressZip'],
                        value: postal_code ? postal_code.long_name : '',
                    },
                }

            if (unitNoExist)
                updatedFormData = {
                    ...updatedFormData,
                    ['Address-addressUnitNo']: {
                        ...formDataNew['Address-addressUnitNo'],
                        value: unitNo ? unitNo.long_name : '',
                    },
                }

            updatedFormData = {
                ...updatedFormData,
                ['Address-hasCountry']: {
                    ...formDataNew['Address-hasCountry'],
                    value: country ? country : '',
                },
            }

            // const updatedFormData = {
            //   ...formDataNew,
            //   ['Address-addressCity']: {
            //       ...formDataNew['Address-addressCity'],
            //       value: city?city.long_name:'',
            //   },
            //   ['Address-addressStreet']: {
            //     ...formDataNew['Address-addressStreet'],
            //     value: street? street.long_name:'',
            //   },
            //   ['Address-addressZip']: {
            //     ...formDataNew['Address-addressZip'],
            //     value: postal_code? postal_code.long_name:'',
            //   },
            //   ['Address-hasCountry']: {
            //     ...formDataNew['Address-hasCountry'],
            //     value: country? country:'',
            //   },
            // }
            setFormDataNew(updatedFormData)
        }
    }
}

const SearchLocationInput = ({
    formDataNew,
    setFormDataNew,
    accountCountries,
    token,
    label,
    showLabel,
}) => {
    const [query, setQuery] = useState('')
    const autoCompleteRef = useRef(null)

    const [apikey, setApikey] = useState('')

    const divStyle = {
        height: '2.4rem',
        width: '100%',
        borderRadius: '4px',
        border: '1px solid #c4bfbf',
    }

    const paddingBottom = {
        padding: '0 0 9px 0',
    }

    useEffect(() => {
        //replace with api call
        SignupService.getGoogleApiKey(token).then((response) => {
            setApikey(response.data)
        })
        // setApikey('AIzaSyAxVOGrZ_LNlukamnatNgKgeJhOb8mPFH8')
    }, [])

    useEffect(() => {
        if (!isNilOrEmpty(apikey))
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${apikey}&libraries=places`,
                () =>
                    handleScriptLoad(
                        setQuery,
                        autoCompleteRef,
                        formDataNew,
                        setFormDataNew,
                        accountCountries,
                        token
                    )
            )
    }, [apikey, formDataNew])

    if (accountCountries && apikey)
        return (
            <div className="search-location-input">
                <InputLabel style={paddingBottom}>
                    {showLabel ? label : ''}
                </InputLabel>
                <input
                    ref={autoCompleteRef}
                    onChange={(event) => {
                        setQuery(event.target.value)
                    }}
                    placeholder=""
                    value={query}
                    style={divStyle}
                    onKeyPress={(e) => {
                        e.key === 'Enter' && e.preventDefault()
                    }}
                />


                


            </div>
        )
    else return null
}

export default SearchLocationInput
