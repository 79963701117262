import { isNilOrEmpty, SEARCH_TYPE_OPTION_TO_DEFAULT_RESTRICTION_FILTER } from '../helpers'
import { getAllIncludeElementsForSections, getAllIncludeParamForSections, getDefaultBdaBraForProductCard, getSectionsForScreens, mergeObject } from '../helpers/screen-configuration-helper'
import { api } from './ajax'
import { AuthenticationService } from './authentication-service'

export const FavouriteService = {
    getFavouriteProducts(userBo, forScreens, runInBackground) {
        if(isNilOrEmpty(userBo)) {
            return {}
        }
        let trackThisPromise = isNilOrEmpty(runInBackground) ? true : !runInBackground
        const filter = `objectFollowedByUser eq ${userBo} AND ` + SEARCH_TYPE_OPTION_TO_DEFAULT_RESTRICTION_FILTER.GENERIC

        let defaultBdaBra = getDefaultBdaBraForProductCard()

        let uniqueIncludeParams = defaultBdaBra.include
        let elements = {}
        let includeElements = {}

        if (!isNilOrEmpty(forScreens)) {
            const sections = getSectionsForScreens(forScreens)
            let dynamicIncludeParams = getAllIncludeParamForSections(sections)
            let dynamicIncludeElements = getAllIncludeElementsForSections(sections)
            elements = dynamicIncludeElements.elements
            includeElements = dynamicIncludeElements.includeElements
    
            dynamicIncludeParams = dynamicIncludeParams.concat(defaultBdaBra.include)
            uniqueIncludeParams = [...new Set(dynamicIncludeParams)];
        }

        let data = {
            type: 'search',
            filter: filter,
            include: uniqueIncludeParams,
        }

        let finalElementsParam = defaultBdaBra.elements
        if(!isNilOrEmpty(elements)) {
            finalElementsParam = mergeObject(elements, defaultBdaBra.elements)

            data = {
                ...data,
                elements: finalElementsParam
            }
        }

        let finalIncludElementsParam = defaultBdaBra.includeElements
        if(!isNilOrEmpty(includeElements)) {
            finalIncludElementsParam = mergeObject(includeElements, defaultBdaBra.includeElements)

            data = {
                ...data,
                includeElements: includeElements
            }
        }

        return api.post(
            '/products/search',
            {
                data,
            },
            {
                headers: {
                    Authorization: AuthenticationService.getLocalStorageOAuthTokenValue(
                        'oauthToken'
                    ),
                },
                trackThisPromise: trackThisPromise,
            }
        ).then((response) => {
            return response
        })
    },

    getFavouriteProductsByURI(forScreens, URIs) {
        const productURIs = URIs
        const filter = productURIs.map((uri) => `uri eq ${uri}`).join(' AND ')

        const sections = getSectionsForScreens(forScreens)
        let dynamicIncludeParams = getAllIncludeParamForSections(sections)

        let aDefaultIncludes = [
            'hasUnitofMeasure:UnitofMeasurement',
            'definesAnnotation',
        ]

        dynamicIncludeParams = dynamicIncludeParams.concat(aDefaultIncludes)
        let uniqueIncludeParams = [...new Set(dynamicIncludeParams)];

        let data = {
            type: 'search',
            filter: filter,
            include: uniqueIncludeParams,
        }

        return api.post(
            '/products/search',
            {
                data,
            },
            {
                headers: {
                    Authorization: AuthenticationService.getLocalStorageOAuthTokenValue(
                        'oauthToken'
                    ),
                },
                trackThisPromise: true,
            }
        ).then((response) => {
            return response
        }).catch(() => {
            return []
        })
    },

    followProduct({ productUri = '', trackThisPromise = true }) {
        return api.get('/user/follow', {
            params: {
                primarybo: productUri,
            },
            headers: {
                Authorization: AuthenticationService.getLocalStorageOAuthTokenValue(
                    'oauthToken'
                ),
            },
        }, { trackThisPromise: trackThisPromise })
    },
    unfollowProduct({ productUri = '', trackThisPromise = true }) {
        return api.get('/user/unfollow', {
            params: {
                primarybo: productUri,
            },
            headers: {
                Authorization: AuthenticationService.getLocalStorageOAuthTokenValue(
                    'oauthToken'
                ),
            },
        }, { trackThisPromise: trackThisPromise })
    },
}
