import { useSnackbar as useNotistack } from 'notistack'

const useSnackbar = () => {
    return useNotistack()
}

const useNotification = (text, param) => {
    const { enqueueSnackbar } = useSnackbar()

    let incomingSuccess = (text, param) => {
        enqueueSnackbar(text, { variant: 'success' })
    }

    let incomingError = (text, param) => {
        enqueueSnackbar(text, { variant: 'error' })
    }

    return {
        success: incomingSuccess,
        error: incomingError,
    }
}

export { useSnackbar, useNotification }
