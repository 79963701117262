import { GET_DATA, SET_DATA } from './masterdata-actions'
import { getObjectKey, isNilOrEmpty } from '../../helpers'

const initialState = {
    //for session, current source of truth is data from localStorage
    masterdata: new Map(),
}

export const masterdataReducers = (state = initialState, action) => {
    switch (action.type) {
        case GET_DATA:
            return {
                ...state,
            }
        case SET_DATA:
            let inMasterData = state.masterdata
            const type = getObjectKey(action, 'payload/type')
            const data = getObjectKey(action, 'payload/data')

            if (!isNilOrEmpty(type)) inMasterData.set(type, data)

            return {
                ...state,
                masterdata: inMasterData,
            }
        default:
            return state
    }
}
