import {
    isNil,
    isNilOrEmpty,
    getObjectKey,
    setObjectKey,
    parseDtoArray,
} from '../helpers'
import { sortArrayByObjectKey } from './helpers'

function createRangeData(id, name, objectERPId) {
    return { id, name, objectERPId }
}

const parseConfigItem = (configItem) => {
    let mandatoryCount = 0
    let costingRelevantCount = 0
    let pricingRelevantCount = 0
    let quoteRelevantCount = 0
    let orderRelevantCount = 0
    let attributeCount = 0
    let userDefinedCount = 0
    let systemDefinedCount = 0
    let defaultValueCount = 0
    let emptyValueCount = 0
    let actualUsedAttibutes = []

    // if (!isNil(inObject)) {
    // for (let configItemId in inObject) {
    // const configItem = inObject[configItemId]
    const inGroups = getObjectKey(configItem, 'group')
    const groups = []
    const inSubGroups = getObjectKey(configItem, 'subGroup')
    const subgroupsMap = new Map()
    let inAttributes = getObjectKey(configItem, 'dynamicAttribute')
    const attrs = getObjectKey(configItem, 'attributes')
    let kbVersion = ''
    let configItemDAValues = []
    const configItemDAValuesMap = new Map()

    if (!isNilOrEmpty(attrs))
        for (let i = 0; i < attrs.length; i++) {
            const attr = attrs[i]

            for (let key in attr) {
                if (key === 'kbSnapshotVersion') kbVersion = attr[key]
                else if (key === 'configItemDAValues')
                    configItemDAValues = attr[key]
            }
        }

    if (!isNilOrEmpty(configItemDAValues)) {
        if (!Array.isArray(configItemDAValues))
            configItemDAValues = [configItemDAValues]

        for (let i = 0; i < configItemDAValues.length; i++) {
            const APPSCHEMA =
                'http://www.inmindcloud.com/application/schema.owl#'
            let configItemDAValue = configItemDAValues[i]
            let configItemDAValueSplit = configItemDAValue.split('|')
            // the first value is always the da URI
            const daBO = configItemDAValueSplit[0]
            let userJustified = false
            let systemJustified = false
            let defaultValue = false

            configItemDAValueSplit.splice(0, 1)
            configItemDAValue = configItemDAValueSplit.join('|')

            if (isNilOrEmpty(configItemDAValuesMap.get(daBO))) {
                if (
                    !isNilOrEmpty(
                        configItemDAValue.match(
                            new RegExp(`${APPSCHEMA}UserJustified`)
                        )
                    )
                ) {
                    userJustified = true
                    userDefinedCount++
                }

                if (
                    !isNilOrEmpty(
                        configItemDAValue.match(
                            new RegExp(`${APPSCHEMA}SystemJustified`)
                        )
                    )
                ) {
                    systemJustified = true
                    systemDefinedCount++
                }

                defaultValue = !userJustified && !systemJustified

                if (defaultValue) defaultValueCount++

                configItemDAValuesMap.set(daBO, {
                    userJustified,
                    systemJustified,
                    defaultValue,
                })
            }
        }
    }

    if (!isNilOrEmpty(inSubGroups)) {
        for (let inSubgroupBO in inSubGroups) {
            const subgroup = inSubGroups[inSubgroupBO]
            const subgroupBO = getObjectKey(subgroup, 'uri')

            subgroup['attributes'] = []

            subgroupsMap.set(subgroupBO, subgroup)
        }
    }

    inAttributes = inAttributes.sort((a, b) => {
        return getObjectKey(a, 'position') - getObjectKey(b, 'position')
    })

    const updateRelevant = (attribute) => {
        const cost = getObjectKey(attribute, 'dynamicAttributeCostingRelevant')
        const price = getObjectKey(attribute, 'dynamicAttributePricingRelevant')
        const quoteRelevant = getObjectKey(
            attribute,
            'dynamicAttributeQuoteRelevant'
        )
        const order = getObjectKey(attribute, 'dynamicAttributeOrderRelevant')
        const attributeValues = getObjectKey(attribute, 'value')

        if (cost) costingRelevantCount++

        if (price) pricingRelevantCount++

        if (quoteRelevant) quoteRelevantCount++

        if (order) orderRelevantCount++

        if (isNilOrEmpty(attributeValues)) emptyValueCount++

        actualUsedAttibutes.push(JSON.parse(JSON.stringify(attribute)))
    }

    if (!isNilOrEmpty(inAttributes)) {
        inAttributes = JSON.parse(JSON.stringify(inAttributes))
        attributeCount = inAttributes.length

        for (let i = 0; i < inAttributes.length; i++) {
            const attribute = inAttributes[i]
            const multivalue = getObjectKey(attribute, 'multiValued')
            const inSubgroups = getObjectKey(attribute, 'subGroup')
            const attributeBO = getObjectKey(attribute, 'uri')

            const attributeMandatory = getObjectKey(
                attribute,
                'dynamicAttributeMandatory'
            )

            const oDAValues = configItemDAValuesMap.get(attributeBO)

            setObjectKey(
                attribute,
                'userJustified',
                getObjectKey(oDAValues, 'userJustified')
            )
            setObjectKey(
                attribute,
                'systemJustified',
                getObjectKey(oDAValues, 'systemJustified')
            )
            setObjectKey(
                attribute,
                'defaultValue',
                getObjectKey(oDAValues, 'defaultValue')
            )

            if (attributeMandatory) mandatoryCount++

            if (!multivalue) {
                let value = getObjectKey(attribute, 'value')

                setObjectKey(
                    attribute,
                    'value',
                    isNilOrEmpty(value) ? null : value
                )
            } else setObjectKey(attribute, 'value', [])

            if (!isNilOrEmpty(inSubgroups)) {
                inSubgroups.forEach((inSubgroupBO) => {
                    const subgroup = subgroupsMap.get(inSubgroupBO)

                    if (!isNil(subgroup)) {
                        updateRelevant(attribute)

                        let attributes = getObjectKey(subgroup, 'attributes')
                        const inAttribute = JSON.parse(
                            JSON.stringify(attribute)
                        )
                        const type = getObjectKey(inAttribute, 'type')
                        let rangeDTO = getObjectKey(inAttribute, 'rangeDto')

                        const data = []

                        if (!isNilOrEmpty(rangeDTO) && type === 'relation') {
                            rangeDTO = parseDtoArray({
                                array: rangeDTO,
                            })

                            for (let i = 0; i < rangeDTO.length; i++) {
                                const range = rangeDTO[i]
                                const name = getObjectKey(
                                    range,
                                    'parentInformation/l10nKey'
                                )
                                const objectERPId = getObjectKey(
                                    range,
                                    'objectERPId/value'
                                )
                                const rangeBO = getObjectKey(
                                    range,
                                    'parentInformation/id'
                                )

                                data.push(
                                    createRangeData(rangeBO, name, objectERPId)
                                )
                            }
                        } else if (type === 'data') {
                            const ranges = getObjectKey(attribute, 'range')

                            if (!isNilOrEmpty(ranges))
                                for (let i = 0; i < ranges.length; i++) {
                                    data.push(
                                        createRangeData(ranges[i], ranges[i])
                                    )
                                }
                        }

                        if (data.length > 0) {
                            if (!attributeMandatory)
                                data.splice(0, 0, {
                                    id: '',
                                    name: '',
                                    objectERPId: '',
                                })

                            setObjectKey(inAttribute, 'valueDomain', data)
                        }

                        attributes.push(inAttribute)

                        subgroup['attributes'] = attributes

                        subgroupsMap.set(inSubgroupBO, subgroup)
                    }
                })
            } else {
                const inGroups = getObjectKey(attribute, 'group')

                if (!isNilOrEmpty(inGroups))
                    inGroups.forEach((inGroupBO) => {
                        const subgroup = subgroupsMap.get(inGroupBO)

                        if (!isNil(subgroup)) {
                            updateRelevant(attribute)

                            let attributes = getObjectKey(
                                subgroup,
                                'attributes'
                            )

                            attributes.push(
                                JSON.parse(JSON.stringify(attribute))
                            )

                            setObjectKey(subgroup, 'attributes', attributes)

                            subgroupsMap.set(inGroupBO, subgroup)
                        }
                    })
            }
        }
    }

    if (!isNilOrEmpty(inGroups)) {
        for (let groupBO in inGroups) {
            const group = inGroups[groupBO]
            const subgroups = getObjectKey(group, 'linksSubGroup')
            let hasAttributes = false

            if (!isNilOrEmpty(subgroups)) {
                for (let i = subgroups.length - 1; i >= 0; i--) {
                    const inSubgroupBO = subgroups[i]
                    const inSubgroup = subgroupsMap.get(inSubgroupBO)
                    if (!isNilOrEmpty(getObjectKey(inSubgroup, 'attributes'))) {
                        hasAttributes = true
                        subgroups[i] = inSubgroup
                    } else {
                        subgroups.splice(i, 1)
                    }
                }

                sortArrayByObjectKey({
                    objectArray: subgroups,
                    sortKey: 'position',
                })
            }

            if (hasAttributes) groups.push(group)
        }
    }

    sortArrayByObjectKey({
        objectArray: groups,
        sortKey: 'position',
    })

    setObjectKey(configItem, 'groups', groups)
    setObjectKey(configItem, 'kbVersion', kbVersion)

    // inObject = configItem
    // }
    // }

    return {
        data: configItem,
        mandatory: mandatoryCount,
        costingRelevant: costingRelevantCount,
        pricingRelevant: pricingRelevantCount,
        quoteRelevant: quoteRelevantCount,
        orderRelevant: orderRelevantCount,
        attributes: attributeCount,
        userDefined: userDefinedCount,
        systemDefined: systemDefinedCount,
        defaultValue: defaultValueCount,
        emptyValue: emptyValueCount,
        actualAttributes: actualUsedAttibutes,
    }
}

export { parseConfigItem }
