import * as TYPES from './admin-sales-info-actions';

const initialState = {
    salesOrg: null,
    division: null,
    distChannel: null,
    currency: null,
    emailBlackList: null,
    dataLoaded: false,
    selectedSalesInfoData: {
        hasSalesOrg: '',
        hasDivision: '',
        hasDistributionChannel: '',
        hasCurrency: ''
    }
};

export const adminSalesinfoReducer = (state = initialState, action) => {
    const {data} = action;
    switch(action.type) {
        case TYPES.SET_SALES_INFO_ORG:
            return {...state, salesOrg: data};
        case TYPES.SET_SALES_INFO_DIVISION:
            return {...state, division: data};
        case TYPES.SET_SALES_INFO_DIST_CHANNEL:
            return {...state, distChannel: data};
        case TYPES.SET_SALES_INFO_CURRENCY:
            return {...state, currency: data};
        case TYPES.SET_SALES_INFO_DATA_LOADED:
            return {...state, dataLoaded: true};
        case TYPES.SET_SALES_INFO_EMAIL_DOMAIN_BLACKLIST:
            return {...state, emailBlackList: data};
        case TYPES.SET_SELECTED_SALES_INFO_DATA: {
            return {...state, selectedSalesInfoData: {...state.selectedSalesInfoData, ...action.data}};
        }
        default:
            return state;
    }
};
