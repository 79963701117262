import React, { useState, useEffect } from 'react'
import { withRouter, useLocation } from 'react-router-dom'
import {
    useGetDefaultFaviconSrc,
    dynamicImageSrcRoot,
    useGetDefaultBrandName,
    isNilOrEmpty,
} from '../../helpers/helpers'
import { useDispatch } from 'react-redux'
import {
    createMuiTheme,
} from '@material-ui/core/styles'
import './login.scss'
import customisingThemeTemplate from '../../theme/customising/theme'
import {
    updateFavIconSrc,
} from '../../store/customisation/customisation-actions'
import {setIsSelfRegistrationEnabled} from '../../store/sign-up/signUpActionTypes';
import { CustomisationService } from '../../services'
import theme from '../../theme/salesPortal/theme'
import LoginMain from './login-main'

const systemDefaultTheme = theme

const Login = (props) => {
    const dispatch = useDispatch()

    const [
        customisingActivePortalTheme,
        setCustomisingActivePortalTheme,
    ] = useState(null)
    const [
        attemptToGetCustomPortalTheme,
        setAttemptToGetCustomPortalTheme,
    ] = useState(false)

    const [customTheme, setCustomTheme] = useState(systemDefaultTheme)

    const [customisingloginBannerSrc, setCustomisingLoginBannerSrc] = useState(
        null
    )
    const [customisingCompanyLogoSrc, setCustomisingCompanyLogoSrc] = useState(
        null
    )
    const [customisingFaviconSrc, setCustomisingFaviconSrc] = useState(null)

    const [customisingCompanyName, setCustomisingCompanyName] = useState(null)
    const defaultBrandName = useGetDefaultBrandName()
    const defaultFaviconSrc = useGetDefaultFaviconSrc()
    const {search: queryString} = useLocation();

    const [backgroundColor, setBackgroundColor] = useState(customTheme.palette.background.default || '#ffffff');

    useEffect(() => {
        if (customisingFaviconSrc === null) return

        let finalFaviconSrc = process.env.PUBLIC_URL + defaultFaviconSrc

        if (customisingFaviconSrc) {
            finalFaviconSrc = dynamicImageSrcRoot(customisingFaviconSrc)
            dispatch(
                updateFavIconSrc(customisingFaviconSrc)
            )
        }

        let faviconDOM = document.getElementById('favicon')
        if (faviconDOM) {
            faviconDOM.href = finalFaviconSrc
        }
    }, [customisingFaviconSrc])

    useEffect(() => {
        if (customisingCompanyName === null) return

        let finalCompanyName = defaultBrandName
        if (customisingCompanyName) {
            finalCompanyName = customisingCompanyName
        }
        document.title = finalCompanyName
    }, [customisingCompanyName])
    function getLoginBannerMediaPathValue(publicPortalSettingDto) {
        let [
            loginBannerMediaFile,
        ] = publicPortalSettingDto.includesPortalMediaFile.filter((o) => {
            if (
                o.uri ===
                'http://www.inmindcloud.com/application/schema.owl#PortalMediaFileLoginBanner'
            ) {
                return o
            }
        })

        let mediaFilePathValue = loginBannerMediaFile.mediaFilePath
        if (mediaFilePathValue && typeof mediaFilePathValue === 'string')
            return mediaFilePathValue

        return ''
    }

    function getFaviconMediaPathValue(publicPortalSettingDto) {
        let [
            companyFaviconMediaFile,
        ] = publicPortalSettingDto.includesPortalMediaFile.filter((o) => {
            if (
                o.uri ===
                'http://www.inmindcloud.com/application/schema.owl#PortalFavouriteLogo'
            ) {
                return o
            }
        })

        let mediaFilePathValue = companyFaviconMediaFile.mediaFilePath
        if (mediaFilePathValue && typeof mediaFilePathValue === 'string')
            return mediaFilePathValue

        return ''
    }

    function getCompanyLogoMediaPathValue(publicPortalSettingDto) {
        let [
            companyLogoMediaFile,
        ] = publicPortalSettingDto.includesPortalMediaFile.filter((o) => {
            if (
                o.uri ===
                'http://www.inmindcloud.com/application/schema.owl#PortalMediaFileCorporateLogo'
            ) {
                return o
            }
        })

        let mediaFilePathValue = companyLogoMediaFile.mediaFilePath
        if (mediaFilePathValue && typeof mediaFilePathValue === 'string')
            return mediaFilePathValue

        return ''
    }

    function getPortalCompanyName(publicPortalSettingDto) {
        let portalCompanyNameValue = publicPortalSettingDto.portalCompanyName

        return portalCompanyNameValue || ''
    }

    function getActivePortalTheme(publicPortalSettingDto) {
        let includesPortalThemeValue =
            publicPortalSettingDto.includesPortalTheme || []
        let [activePortalTheme] = includesPortalThemeValue.filter((o) => {
            if (o.isDefaultPortalTheme) {
                return o
            }
        })

        return activePortalTheme
    }

    function processThemeTemplate(activePortalTheme) {
        if (!isNilOrEmpty(activePortalTheme)) {
            customisingThemeTemplate.palette.primary.main =
            activePortalTheme.portalPrimaryMainColor

            customisingThemeTemplate.palette.background.default =
                activePortalTheme.portalBackgroundDefaultColor

            customisingThemeTemplate.palette.info.main =
                activePortalTheme.portalInfoMainColor
            customisingThemeTemplate.palette.success.main =
                activePortalTheme.portalSuccessMainColor
            customisingThemeTemplate.palette.error.main =
                activePortalTheme.portalErrorMainColor

            setBackgroundColor(activePortalTheme.portalBackgroundDefaultColor)
            setCustomTheme(createMuiTheme(customisingThemeTemplate))
        }

        setAttemptToGetCustomPortalTheme(true)
    }

    useEffect(() => {
        CustomisationService.getPublicCustomisationData()
            .then((response) => {
                let publicPortalSettingDto = response.data

                let loginBannerMediaPathValue = getLoginBannerMediaPathValue(
                    publicPortalSettingDto
                )
                let companyLogoMediaPathValue = getCompanyLogoMediaPathValue(
                    publicPortalSettingDto
                )

                let faviconMediaPathValue = getFaviconMediaPathValue(
                    publicPortalSettingDto
                )

                let activePortalTheme = getActivePortalTheme(
                    publicPortalSettingDto
                )

                let portalCompanyName = getPortalCompanyName(
                    publicPortalSettingDto
                )

                setCustomisingActivePortalTheme(activePortalTheme)
                setCustomisingLoginBannerSrc(loginBannerMediaPathValue)
                setCustomisingCompanyLogoSrc(companyLogoMediaPathValue)
                setCustomisingCompanyName(portalCompanyName)
                setCustomisingFaviconSrc(faviconMediaPathValue)
                dispatch(setIsSelfRegistrationEnabled(typeof publicPortalSettingDto.portalEnableSelfRegistration === 'boolean' && Boolean(publicPortalSettingDto.portalEnableSelfRegistration)));
            })
            .catch((error) => {
                setAttemptToGetCustomPortalTheme(true)
            })
    }, [])

    useEffect(() => {
        if (customisingActivePortalTheme === null) return

        processThemeTemplate(customisingActivePortalTheme)
    }, [customisingActivePortalTheme])

    function getComponent() {
        if (!attemptToGetCustomPortalTheme) return null

        let redirectURL = '';
        if (queryString) {
            const urlSearch = new URLSearchParams(decodeURIComponent(queryString));
            redirectURL = urlSearch.get('redirect');
        }
        return <LoginMain
            customTheme={customTheme}
            customisingloginBannerSrc={customisingloginBannerSrc}
            backgroundColor={backgroundColor}
            customisingCompanyLogoSrc={customisingCompanyLogoSrc}
            redirectURL={redirectURL}
            {...props} />
    }

    return getComponent()
}

export default withRouter(Login)
