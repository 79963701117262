import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import {
    Card,
    CardContent,
    makeStyles,
    useTheme,
    Box,
    Typography,
    Grid,
    Button,
    Slide,
    Link,
    Select,
    MenuItem,
    LinearProgress,
} from '@material-ui/core'
import AutoComplete from '@material-ui/lab/Autocomplete'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import { useSnackbar } from 'notistack'

import FormField from '../../components/form-field/FormField'
import SignupTextField from './text-field/TextField'
import {
    TranslationService,
    SignupService,
    AuthenticationService,
} from '../../services'
import {
    setRegistrationUser,
    setRegistrationAccount,
    setAccountAddressCountries,
    setAccountAddressStates,
} from '../../store/sign-up/signUpActionTypes'
import {
    isNilOrEmpty,
    isInvalidEmail,
    isInvalidNumberField,
    getQueryString,
    isNil,
    createAddressField,
    getDefaultStringValue,
    getObjectKey,
    getSortedArray,
    getObjectBoolean,
    setObjectKey,
} from '../../helpers/helpers'
import { createUserData } from '../../helpers/signup-helper'
import {
    getFieldKey,
    getFields,
    getParsedFieldWithOutValue,
    getSections,
} from '../../helpers/screen-configuration-helper'
import CustomField from './CustomField'
import SearchLocationInput from './SearchLocationInput'

const useStyles = makeStyles((theme) => ({
    registrationCard: {
        minWidth: '400px',
        minHeight: '100px',
        padding: theme.spacing(2),
    },
    gridContainer: {
        paddingBottom: 8,
    },
    outerContainer: {
        margin: 20,
    },
    header: {
        marginBottom: theme.spacing(2),
    },
    registerBtn: {
        marginTop: theme.spacing(2),
        '&:disabled': {
            color: '#ffffff',
        },
    },
    existingUser: {
        textAlign: 'center',
    },
    addressDDN: {
        width: '100%',
    },
    addressOutline: {
        padding: '11px 39px 10px 12px',
        '&:focus': {
            backgroundColor: 'transparent',
        },
    },
    linearProgressBox: {
        width: '100%',
    },
    autoCompletePopupIndicator: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
}))

const Registration = () => {
    const classes = useStyles()
    const theme = useTheme()
    // const [formSequence, setFormSequence] = useState([])
    const [formSections, setFormSections] = useState([])
    const [formDataNew, setFormDataNew] = useState({
        'Contact-personEmail': {
            value: '',
            error: '',
            validators: [],
        },
        'Contact-personFirstName': {
            value: '',
            error: '',
            validators: [],
        },
        'Contact-personLastName': {
            value: '',
            error: '',
            validators: [],
        },
        'Account-objectName': {
            value: '',
            error: '',
            validators: [],
        },
        'Address-hasCountry': {
            value: '',
            error: '',
            validators: [],
        },
        'Address-hasCountryRegion': {
            value: '',
            error: '',
            validators: [],
        },
    })
    const [submitted, setSubmitted] = useState(false)
    const [dataLoaded, setDataLoaded] = useState(false)
    const [isUserValidated, setIsUserValidated] = useState(false)
    const [listOfAddresses, setListOfAddresses] = useState(null)
    const [selectedAddress, setSelectedAddress] = useState('')
    const [listOfStates, setListOfStates] = useState([])
    const [userCreated, setUserCreated] = useState(null)
    const registeredUser = useSelector(
        (state) => state.signUpReducer.registeredUser
    )
    const registrationAccount = useSelector(
        (state) => state.signUpReducer.registrationAccount
    )
    const accountCountries = useSelector(
        (state) => state.signUpReducer.accountAddresssCountries
    )
    const accountStates = useSelector(
        (state) => state.signUpReducer.accountAddressStates
    )
    const { t } = TranslationService.useTranslation()
    const { search } = useLocation()
    const token = getQueryString(search, 't')

    const { enqueueSnackbar } = useSnackbar()
    const history = useHistory()
    const dispatch = useDispatch()

    const getCurrentCountry = (country) => {
        if (
            accountCountries &&
            country &&
            Array.isArray(country) &&
            country.length
        ) {
            const countryUri = country[0]
            const [cntry] = accountCountries.filter((c) => c.uri === countryUri)
            if (cntry) return cntry
        }
        return ''
    }

    const getCurrentState = (state) => {
        if (accountStates && state && Array.isArray(state) && state.length) {
            const stateURI = state[0]
            const [st] = accountStates.filter((s) => s.uri === stateURI)
            if (st) return st
        }
        return ''
    }

    const getValueFromEntry = (entries, type) => {
        if (entries && Array.isArray(entries)) {
            const [user] = entries.filter((e) => e.type === type)
            if (user) return user
        }
        return null
    }

    const handleError = (error) => {
        if (error.toLowerCase() === 'invalid_token') {
            enqueueSnackbar(t('registration.error.linkExpired'), {
                variant: 'error',
            })
            history.push('/selfRegistration')
        } else {
            enqueueSnackbar(t('general.generalErrorMsg'), {
                variant: 'error',
            })
        }
    }

    const redirectToLoginPage = (delay) => {
        setTimeout(() => {
            AuthenticationService.logoutSystemUser(token)
                .then(() => {
                    AuthenticationService.clearUserInfo()
                    history.push('/login')
                    window.location.reload()
                })
                .catch((error) => {
                    AuthenticationService.clearUserInfo()
                    history.push('/login')
                    window.location.reload()
                })
        }, delay)
    }

    const handleResponse = (response) => {
        const { hasError, errorMessageLabel } = getObjectKey(
            response,
            'entry/0'
        )
        if (hasError) {
            handleError(errorMessageLabel)
        } else {
            const entries = response?.entry
            const registeredUser = getValueFromEntry(entries, 'Status')
            const rAccount = getValueFromEntry(entries, 'Account')
            const includes = response?.include
            if (registeredUser?.statusCode === 'CONTACT_EXISTS_USER_CREATED') {
                setUserCreated(true)
            } else if (registeredUser?.statusCode === 'USER_EXISTS') {
                enqueueSnackbar(
                    t('registration.general.userAlreadyRegistered'),
                    {
                        variant: 'error',
                    }
                )
                redirectToLoginPage(3000)
            } else {
                if (registeredUser) {
                    dispatch(setRegistrationUser(registeredUser))
                    if (
                        registeredUser.statusCode ===
                        'ACCOUNT_EXISTS_NO_CONTACT'
                    ) {
                        if (rAccount) {
                            dispatch(setRegistrationAccount(rAccount))
                        }
                    }
                    if (includes && Array.isArray(includes)) {
                        const listOfCountries = includes
                            .filter((i) => i.type === 'Country')
                            .sort((a, b) => {
                                const firstCountryName = a?.objectName
                                    ? a.objectName.toLowerCase()
                                    : ''
                                const secondCountryName = b?.objectName
                                    ? b.objectName.toLowerCase()
                                    : ''
                                return firstCountryName === secondCountryName
                                    ? 0
                                    : firstCountryName > secondCountryName
                                    ? 1
                                    : -1
                            })
                        const listOfStates = includes.filter(
                            (i) => i.type === 'CountryRegion'
                        )
                        dispatch(setAccountAddressCountries(listOfCountries))
                        dispatch(setAccountAddressStates(listOfStates))
                    }
                }
            }
        }
    }

    const getListOfStates = (countryURI) => {
        if (!countryURI) return
        const payload = { Country: countryURI }
        SignupService.getStates(payload, token)
            .then((response) => {
                const responseData = response.data
                const entries = responseData.entry
                if (entries) setListOfStates(entries)
                else setListOfStates([])
            })
            .catch(() => {
                enqueueSnackbar(t('general.generalErrorMsg'), {
                    variant: 'error',
                })
            })
    }

    const parseFormData = async (sections) => {
        let formDataMap = {}
        if (!isNilOrEmpty(sections) && Array.isArray(sections)) {
            sections.sort((a, b) => {
                return (
                    getObjectKey(a, 'sequenceNumber') -
                    getObjectKey(b, 'sequenceNumber')
                )
            })

            for (const section of sections) {
                const sectionName = getObjectKey(section, 'objectName')
                const fields = getFields(section)
                const sortedFields = getSortedArray(fields, 'sequenceNumber')

                const getFieldMap = async () => {
                    for (let index = 0; index < sortedFields.length; index++) {
                        const field = sortedFields[index]
                        const parsedField = await getParsedFieldWithOutValue(
                            field,
                            'SelfRegistration'
                        )
                        const label =
                            getObjectKey(parsedField, 'label') ||
                            getObjectKey(parsedField, 'objectName')
                        const path = getObjectKey(parsedField, 'metadataPath')
                        const isMandatoryField = getObjectBoolean(
                            parsedField,
                            'isMandatoryField'
                        )
                        const isEmail =
                            getObjectKey(parsedField, 'metadataPath') ===
                            'personEmail'
                        const isNumeric =
                            path === 'personPhone' || path === 'personMobile'
                        // || path === 'addressZip'

                        const fullWidth =
                            isEmail ||
                            (sectionName === 'Account' &&
                                path === 'objectName') ||
                            (sectionName === 'Address' &&
                                path === 'GoogleAutocomplete')

                        const validators = []
                        if (isMandatoryField) {
                            validators.push({
                                fn: isNilOrEmpty,
                                errorMessage: t(
                                    'registration.errorTemplate.required',
                                    {
                                        label,
                                    }
                                ),
                            })
                        }
                        if (isEmail) {
                            validators.push({
                                fn: isInvalidEmail,
                                errorMessage: t(
                                    'registration.error.invalidEmail'
                                ),
                            })
                        }
                        if (isNumeric) {
                            validators.push({
                                fn: isInvalidNumberField,
                                valueRequired: true,
                                errorMessage: t(
                                    'registration.error.zipOnlyNumbersAllowed'
                                ),
                            })
                        }
                        const fieldData = {
                            value: '',
                            error: '',
                            validators: validators,
                            isHidden: getObjectKey(parsedField, 'hideField'),
                        }

                        formDataMap[
                            getFieldKey(sectionName, parsedField.metadataPath)
                        ] = fieldData

                        setObjectKey(parsedField, 'fullWidth', fullWidth)
                        setObjectKey(sortedFields, '/' + index, parsedField)
                    }
                }
                await getFieldMap()
            }
            setFormSections(sections)
            setFormDataNew(formDataMap)
        }
    }

    useEffect(() => {
        const init = async () => {
            await SignupService.getPortalSettings(token)
                .then(async (response) => {
                    const sections = getSections(getObjectKey(response, 'data'))
                    await parseFormData(sections)
                })
                .catch(() => {
                    enqueueSnackbar(t('general.generalErrorMsg'), {
                        variant: 'error',
                    })
                })

            SignupService.validate(token)
                .then((response) => {
                    setDataLoaded(true)
                    setIsUserValidated(true)
                    handleResponse(response.data)
                })
                .catch((error) => {
                    const responseError = error?.response?.data?.error
                    setDataLoaded(true)
                    setIsUserValidated(true)
                    handleError(responseError || '')
                })
        }

        init()

        return () => {
            dispatch(setRegistrationUser(null))
            dispatch(setRegistrationAccount(null))
            dispatch(setAccountAddressCountries(null))
            dispatch(setAccountAddressStates(null))
        }
    }, [])

    useEffect(() => {
        if (registeredUser) {
            const { userEmail } = registeredUser
            handleOnInputChange({
                target: { id: 'Contact-personEmail', value: userEmail },
            })
        }
    }, [registeredUser])

    useEffect(() => {
        if (registrationAccount) {
            const { objectName, includesAddress } = registrationAccount
            handleOnInputChange({
                target: { id: 'Account-objectName', value: objectName },
            })
            setListOfAddresses(includesAddress)
        }
    }, [registrationAccount])

    useEffect(() => {
        if (selectedAddress) {
            const [selAddress] = listOfAddresses.filter(
                (a) => a.id === selectedAddress
            )
            if (selAddress) {
                let inFormData = { ...formDataNew }
                formSections.map((section) => {
                    if (getObjectKey(section, 'objectName') === 'Address') {
                        const formFields = getFields(section)
                        formFields.map((field) => {
                            let addressValue = null
                            const fieldPath = getObjectKey(
                                field,
                                'metadataPath'
                            )
                            const value = selAddress[fieldPath]
                            switch (fieldPath) {
                                case 'hasCountry':
                                    addressValue = getCurrentCountry(value)
                                    break
                                case 'hasCountryRegion':
                                    addressValue = getCurrentState(value)
                                    break
                                default:
                                    addressValue = value
                            }
                            const addressKey = getFieldKey('Address', fieldPath)
                            inFormData[addressKey] = {
                                ...inFormData[addressKey],
                                value: addressValue,
                            }
                        })
                    }
                })

                setFormDataNew(inFormData)
            }
        }
    }, [selectedAddress, listOfAddresses])

    useEffect(() => {
        if (userCreated) {
            enqueueSnackbar(t('registration.general.userCreated'), {
                variant: 'success',
            })
            redirectToLoginPage(4000)
        }
    }, [userCreated])

    const validateFormDataField = (frmData, fieldName) => {
        const formDataField = frmData[fieldName]
        if (formDataField?.validators) {
            for (const val of formDataField.validators) {
                if (
                    !val.valueRequired ||
                    (val.valueRequired && !isNilOrEmpty(formDataField.value))
                ) {
                    const valid = !val.fn(formDataField.value)
                    if (!valid) {
                        if (
                            fieldName === 'Address-hasCountryRegion' &&
                            !listOfStates?.length > 0
                        ) {
                            formDataField.error = ''
                        } else {
                            formDataField.error = val.errorMessage
                        }
                        break
                    } else formDataField.error = ''
                } else {
                    formDataField.error = ''
                }
            }
        }
    }

    const validateFormField = (fieldName, validateAll = false) => {
        if (fieldName && !submitted) return true
        const tempFormData = { ...formDataNew }

        formSections.map((section) => {
            const formFields = getFields(section)
            const sectionName = getObjectKey(section, 'objectName')
            formFields.map((field) => {
                const fieldPath = getObjectKey(field, 'metadataPath')
                const fieldKey = getFieldKey(sectionName, fieldPath)
                if (!validateAll) {
                    if (fieldKey === fieldName)
                        validateFormDataField(tempFormData, fieldKey)
                } else {
                    validateFormDataField(tempFormData, fieldKey)
                }
            })
        })
        setFormDataNew(tempFormData)
        if (validateAll) {
            let allValid = true
            for (const key of Object.keys(tempFormData)) {
                const field = tempFormData[key]
                if (!isNilOrEmpty(field.error)) {
                    allValid = false
                    break
                }
            }
            return allValid
        }
    }

    const handleRegisterClick = (e) => {
        setSubmitted(true)
        const allFieldsValid = validateFormField(null, true)
        if (allFieldsValid) {
            const registerPayload = createUserData(
                formDataNew,
                registeredUser.statusCode !== 'NO_ACCOUNT'
            )
            SignupService.register(registerPayload, token)
                .then((response) => {
                    const responseData = response.data
                    if (responseData.statusCode === 'USER_CREATED') {
                        setUserCreated(true)
                    }
                })
                .catch(() => {
                    enqueueSnackbar(t('general.generalErrorMsg'), {
                        variant: 'error',
                    })
                })
        }
        e.preventDefault()
    }

    useEffect(() => {
        if (formDataNew['Address-hasCountry']?.value) {
            getListOfStates(formDataNew['Address-hasCountry'].value.uri)
        }
    }, [formDataNew['Address-hasCountry']])

    const handleOnInputChange = (e) => {
        const fieldName = e.target.id
        const fieldValue = e.target.value || ''
        if (typeof fieldValue === 'string') {
            const updatedFormData = {
                ...formDataNew,
                [fieldName]: { ...formDataNew[fieldName], value: fieldValue },
            }
            if (fieldName && submitted) {
                validateFormDataField(updatedFormData, fieldName)
            }
            setFormDataNew(updatedFormData)
        }
    }

    const handleAddressMenuItemClick = (id) => {
        setSelectedAddress(id)
    }

    const getAddresssDDNMenu = (address, i) => {
        if (!address) return ''
        const {
            id,
            addressStreet,
            addressCity,
            addressZip,
            hasCountry,
            hasCountryRegion,
        } = address
        const selectedCountry = getCurrentCountry(hasCountry)
        const selectedState = getCurrentState(hasCountryRegion)
        const finalAddress = createAddressField(
            addressStreet,
            addressCity,
            selectedCountry ? selectedCountry.objectName : '',
            selectedState ? selectedState.objectName : '',
            addressZip
        )
        return (
            <MenuItem
                key={i}
                value={id}
                onClick={() => handleAddressMenuItemClick(id)}
            >
                {finalAddress}
            </MenuItem>
        )
    }

    const header = (
        <Typography variant="h3" className={classes.header}>
            {t('general.registration')}
        </Typography>
    )
    const addressDDNMenuProps = {
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        getContentAnchorEl: null,
    }
    const addressDDN = !isNilOrEmpty(listOfAddresses) ? (
        <FormField label={t('attribute.companyAddress')}>
            <Select
                value={selectedAddress}
                classes={{ outlined: classes.addressOutline }}
                variant="outlined"
                MenuProps={addressDDNMenuProps}
                className={classes.addressDDN}
                IconComponent={KeyboardArrowDown}
            >
                {listOfAddresses.map((addr, index) =>
                    getAddresssDDNMenu(addr, index)
                )}
            </Select>
        </FormField>
    ) : null

    if (!(dataLoaded && registeredUser))
        return (
            <Box className={classes.linearProgressBox}>
                {!isUserValidated && (
                    <>
                        <Typography color="textPrimary">
                            {!userCreated
                                ? t('registration.general.validating')
                                : t('registration.general.redirecting')}
                        </Typography>
                        <LinearProgress color="primary" />
                    </>
                )}
            </Box>
        )
    return (
        <Card className={classes.registrationCard}>
            <CardContent style={{ padding: theme.spacing(0) }}>
                <Slide direction="right" in>
                    <Box display="flex" flexDirection="row">
                        <Box className={classes.outerContainer}>
                            {header}
                            <form
                                noValidate
                                onSubmit={handleRegisterClick}
                                autoComplete="off"
                            >
                                {formSections.map((section) => {
                                    const formFields = getFields(section)
                                    const sectionName = getObjectKey(
                                        section,
                                        'objectName'
                                    )
                                    return (
                                        <Grid
                                            container
                                            spacing={2}
                                            className={classes.gridContainer}
                                        >
                                            {sectionName === 'Address' &&
                                            registeredUser.statusCode !==
                                                'NO_ACCOUNT' &&
                                            !isNilOrEmpty(listOfAddresses) ? (
                                                <Grid item xs={12}>
                                                    {addressDDN}
                                                </Grid>
                                            ) : null}
                                            {formFields.map((field) => {
                                                const hidden = getObjectKey(
                                                    field,
                                                    'hideField'
                                                )
                                                const fieldPath = getObjectKey(
                                                    field,
                                                    'metadataPath'
                                                )
                                                const fieldWidth = getObjectBoolean(
                                                    field,
                                                    'fullWidth'
                                                )
                                                    ? 12
                                                    : 6
                                                return (
                                                    !hidden && (
                                                        <Grid
                                                            item
                                                            sm={fieldWidth}
                                                        >
                                                            <CustomField
                                                                registrationAccount={
                                                                    registrationAccount
                                                                }
                                                                formDataNew={
                                                                    formDataNew
                                                                }
                                                                parent={
                                                                    sectionName
                                                                }
                                                                field={field}
                                                                handleOnInputChange={
                                                                    handleOnInputChange
                                                                }
                                                                setFormDataNew={
                                                                    setFormDataNew
                                                                }
                                                                accountCountries={
                                                                    accountCountries
                                                                }
                                                                listOfStates={
                                                                    listOfStates
                                                                }
                                                                validateFormDataField={
                                                                    validateFormDataField
                                                                }
                                                                submitted={
                                                                    submitted
                                                                }
                                                                token={token}
                                                            />
                                                        </Grid>
                                                    )
                                                )
                                            })}
                                        </Grid>
                                    )
                                })}
                                <Grid
                                    container
                                    spacing={2}
                                    className={classes.gridContainer}
                                >
                                    <Grid item xs={12}>
                                        <Button
                                            disabled={Boolean(userCreated)}
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                            fullWidth
                                            size="small"
                                            className={classes.registerBtn}
                                            disableElevation
                                        >
                                            {t('general.register')}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            color="textPrimary"
                                            className={classes.existingUser}
                                        >
                                            {t('general.existingUser')}
                                            <Link
                                                color="primary"
                                                href="#/login"
                                            >
                                                {t('general.login')}
                                            </Link>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </form>
                        </Box>
                    </Box>
                </Slide>
            </CardContent>
        </Card>
    )
}

export default Registration
