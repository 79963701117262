export const SET_CURRENT_ROOT_ID = 'SET_CURRENT_ROOT_ID';
export const SET_ROOT_NODE = 'SET_ROOT_NODE';
export const ADD_NODE_CHILDREN = 'ADD_NODE_CHILDREN';
export const SET_CHILDREN_LOADING = 'SET_CHILDREN_LOADING';
export const SELECT_NODE = 'SELECTED_NODE';
export const TOGGLE_NODE = 'TOGGLE_NODE';
export const SET_NODE_IMAGES = 'SET_NODE_IMAGES';
export const SET_SELECTED_IMAGE = 'SET_SELECTED_IMAGE';
export const SET_NODE_BOM = 'SET_NODE_BOM';
export const SET_NODE_DOCUMENTS = 'SET_NODE_DOCUMENTS';
export const SET_NODE_SELECTED_BOM = 'SET_NODE_SELECTED_BOM';
export const SET_NODE_SELECTED_BOM_DETAIL = 'SET_NODE_SELECTED_BOM_DETAIL';
export const RESET_ALL_DATA = 'RESET_ALL_DATA';