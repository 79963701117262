import * as TYPES from './signupActions';

export const setRegistrationUser = user => ({type: TYPES.SET_REGISTRATION_USER, user});

export const setRegistrationAccount = account => ({type: TYPES.SET_REGISTRATION_ACCOUNT, account});

export const setAccountAddressCountries = list => ({type: TYPES.SET_ACCOUNT_ADDRESS_COUNTRIES, list});

export const setAccountAddressStates = list => ({type: TYPES.SET_ACCOUNT_ADDRESS_STATES, list});

export const setIsSelfRegistrationEnabled = status => ({type: TYPES.SET_IS_SELF_REGISTRATION_ENABLED, status});
