import React from 'react'

import FormField from '../../components/form-field/FormField'
import SignupTextField from './text-field/TextField'
import {
    isNilOrEmpty,
    getObjectKey,
    getObjectBoolean,
} from '../../helpers/helpers'
import { getFieldKey } from '../../helpers/screen-configuration-helper'
import AutoComplete from '@material-ui/lab/Autocomplete'
import { InputLabel, makeStyles } from '@material-ui/core'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import SearchLocationInput from './SearchLocationInput'

const useStyles = makeStyles((theme) => ({
    autoCompletePopupIndicator: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
}))

const CustomField = ({
    parent,
    field,
    formDataNew,
    registrationAccount,
    handleOnInputChange,
    setFormDataNew,
    accountCountries,
    listOfStates,
    validateFormDataField,
    submitted,
    token
}) => {
    const classes = useStyles()
    const metadataPath = getObjectKey(field, 'metadataPath')
    const label =
        getObjectKey(field, 'label') || getObjectKey(field, 'objectName')
    const showLabel = getObjectBoolean(field, 'showLabel')
    const isEditableField = getObjectBoolean(field, 'isEditableField')
    const isMandatoryField = getObjectBoolean(field, 'isMandatoryField')

    let disabled = !isEditableField

    if (parent === 'Account' && metadataPath === 'objectName') {
        disabled = Boolean(registrationAccount?.objectName)
    }

    const fieldKey = getFieldKey(parent, field.metadataPath)
    const fieldData = formDataNew[fieldKey]

    const getCountryTextField = (params) => (
        <SignupTextField
            id={fieldKey}
            error={!isNilOrEmpty(fieldData?.error)}
            helperText={fieldData?.error}
            {...params}
        />
    )
    const getStateTextField = (params) => (
        <SignupTextField
            id={fieldKey}
            error={!isNilOrEmpty(fieldData?.error)}
            helperText={fieldData?.error}
            {...params}
        />
    )



    if (parent === 'Address' && metadataPath === 'hasCountry') {
        return (
            <FormField
                label={showLabel ? label : ''}
                required={isMandatoryField}
            >
                {accountCountries && (
                    <AutoComplete
                        id={fieldKey}
                        options={accountCountries}
                        getOptionLabel={(option) => option.label || option.objectName || ''}
                        disableClearable
                        openOnFocus
                        size="small"
                        renderInput={(params) => getCountryTextField(params)}
                        onChange={(e, opt) => {
                            const updatedFormData = {
                                ...formDataNew,
                                [fieldKey]: {
                                    ...formDataNew[fieldKey],
                                    value: opt,
                                },
                                ['Address-hasCountryRegion']: {
                                    ...formDataNew['Address-hasCountryRegion'],
                                    value: null,
                                },
                            }
                            if (fieldKey && submitted) {
                                validateFormDataField(updatedFormData, fieldKey)
                            }
                            setFormDataNew(updatedFormData)
                        }}
                        value={fieldData?.value}
                        classes={{
                            popupIndicator: classes.autoCompletePopupIndicator,
                        }}
                        popupIcon={<KeyboardArrowDown />}
                    />
                )}
            </FormField>
        )
    }
    if (parent === 'Address' && metadataPath === 'hasCountryRegion') {
        return (
            <FormField
                label={showLabel ? label : ''}
                required={isMandatoryField && listOfStates?.length > 0}
            >
                <AutoComplete
                    id={fieldKey}
                    disabled={!listOfStates?.length}
                    options={listOfStates}
                    getOptionLabel={(opt) => opt.label || opt.objectName || ''}
                    disableClearable
                    openOnFocus
                    size="small"
                    renderInput={(params) => getStateTextField(params)}
                    onChange={(e, opt) => {
                        const updatedFormData = {
                            ...formDataNew,
                            [fieldKey]: {
                                ...formDataNew[fieldKey],
                                value: opt,
                            },
                        }
                        if (fieldKey && submitted) {
                            validateFormDataField(updatedFormData, fieldKey)
                        }
                        setFormDataNew(updatedFormData)
                    }}
                    value={fieldData?.value}
                    classes={{
                        popupIndicator: classes.autoCompletePopupIndicator,
                    }}
                    popupIcon={<KeyboardArrowDown />}
                />
            </FormField>
        )
    }
    if (parent === 'Address' && metadataPath === 'GoogleAutocomplete') {
        return ( 
        
        <div>
            
            {accountCountries && (
                
            <SearchLocationInput
            formDataNew={ formDataNew }
            setFormDataNew={ setFormDataNew }
            accountCountries={ accountCountries }
            token = {token}
            label = {label}
            showLabel = {showLabel}
            />)} 
            
        </div>)

    }
    return (
        <FormField label={showLabel ? label : ''} required={isMandatoryField}>
            <SignupTextField
                id={fieldKey}
                disabled={disabled}
                value={fieldData?.value}
                error={!isNilOrEmpty(fieldData?.error)}
                helperText={fieldData?.error}
                onChange={handleOnInputChange}
            />
        </FormField>
    )
}

export default CustomField
