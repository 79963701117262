import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
    Backdrop as MaterialUIBackdrop,
    CircularProgress,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { TranslationService } from '../../../services'
import theme from '../../../theme/salesPortal/theme'
import { usePromiseTracker } from "react-promise-tracker";


const useStyles = makeStyles((theme) => ({
    fullscreenBackdrop: {
        zIndex: theme.zIndex.tooltip + 1,
        backgroundColor: 'rgba(255,255,255,1)',
    },
    nonFullScreenBackdrop: {
        backgroundColor: 'rgba(255,255,255,.75)',
        zIndex: theme.zIndex.tooltip - 1,
    },
}))

const Loader = (props) => {
    const classes = useStyles()
    const { promiseInProgress } = usePromiseTracker();
    const { t } = TranslationService.useTranslation()
    const {
        showText,
        showProgress,
        text,
        useFullScreen,
        ...materialUIProps
    } = props

    const [ open, setOpen ] = useState( false )

    useEffect(() => {
        const force = props.forceOpen ? true : false
        setOpen(force)
    }, [props.forceOpen])

    useEffect(()=>{
        const force = props.forceOpen ? true : false
        setOpen( promiseInProgress || force)
    }, [ promiseInProgress])

    const renderProgress = () => {

            return <CircularProgress color="primary"></CircularProgress>

    }

    const renderText = () => {
        let displayText = t('general.loading')

        if (text) displayText = text
        if (showText)
            return (
                <Typography style={{ marginLeft: theme.spacing(2) }}>
                    {displayText}
                </Typography>
            )
        else return null
    }

    const getClassName = () => {
        if (props.useFullScreen) return classes.fullscreenBackdrop
        return classes.nonFullScreenBackdrop
    }

    return (
        <MaterialUIBackdrop className={getClassName()} open={open} style={props.style} >
            {renderProgress()}
            {renderText()}
            {props.children}
        </MaterialUIBackdrop>
    )
}

Loader.defaultProps = {
    showText: true,
    showProgress: true,
    text: '',
    open: true,
    useFullScreen: true,

}

Loader.propTypes = {
    showText: PropTypes.bool,
    showProgress: PropTypes.bool,
    text: PropTypes.string,
    open: PropTypes.bool,
    useFullScreen: PropTypes.bool,

}

export default Loader
