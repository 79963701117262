export const SET_KMAT_PRODUCT = 'SET_KMAT_PRODUCT';
export const SET_VARIANT_PRODUCTS = 'SET_VARIANT_PRODUCTS';
export const SET_SELECTED_VARIANT_PRODUCTS = 'SET_SELECTED_VARIANT_PRODUCTS';
export const ADD_SELECTED_VARIANT_PRODUCT = 'ADD_SELECTED_VARIANT_PRODUCT';
export const REMOVE_SELECTED_VARIANT_PRODUCT = 'REMOVE_SELECTED_VARIANT_PRODUCT';
export const RESET_VARIANT_DATA = 'RESET_VARIANT_DATA';
export const SET_VARIANT_SEARCH = 'SET_VARIANT_SEARCH';
export const ADD_VARIANT_FILTER = 'ADD_VARIANT_FILTER';
export const SET_FILTER_COUNT = 'SET_FILTER_COUNT';
export const REMOVE_VARIANT_FILTER = 'REMOVE_VARIANT_FILTER';
export const RESET_VARIANT_SEARCH_FILTERS = 'RESET_VARIANT_SEARCH_FILTERS';
export const SET_PREVIOUSLY_ORDERED_PRODUCTS_URIS = 'SET_PREVIOUSLY_ORDERED_PRODUCTS_URIS';
export const SET_ALL_VARIANT_ATTRIBUTES = 'SET_ALL_VARIANT_ATTRIBUTES';
export const SET_VARIANT_COMPARISON_DATA = 'SET_VARIANT_COMPARISON_DATA';