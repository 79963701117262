import {
    INITIAL_SEARCH_HISTORY,
    ADD_SEARCH_HISTORY,
    REMOVE_SEARCH_HISTORY,
    REMOVE_ALL_SEARCH_HISTORY,
    UPDATE_PREVIOUS_RAW_QUERY_VALUE,
    ADD_SEARCH_SETTING_FILTER,
    REMOVE_SEARCH_SETTING_FILTER,
    REMOVE_ALL_SEARCH_SETTING_FILTER,
    UPDATE_SEARCH_SETTING_FILTER_OPTION,
    ADD_SEARCH_SETTING_SORTER,
    UPDATE_SEARCH_SETTING_SORTER_OPTION,
    REMOVE_SEARCH_SETTING_SORTER,
    REMOVE_ALL_SEARCH_SETTING_SORTER,
    UPDATE_SEARCH_SETTING_MODE,
} from './search-actions'
import { SEARCH_TYPE_OPTION } from '../../helpers/search-helper'
const initialState = {
    GENERIC_SEARCH_HISTORY: {
        result: [],
    },
    GENERIC: {
        lastRawQuery: '',
        exitFromSettingMode: null,
        filter: [], //selected
        sorter: [], //selected
    },
    EQUIPMENT: {
        lastRawQuery: '',
        exitFromSettingMode: null,
        filter: [], //selected
        sorter: [], //selected
    },
    CONSUMABLES: {
        lastRawQuery: '',
        query: '',
        exitFromSettingMode: null,
        filter: [], //selected
        sorter: [], //selected
    },
    SPARE_PARTS: {
        lastRawQuery: '',
        query: '',
        exitFromSettingMode: null,
        filter: [], //selected
        sorter: [], //selected
    },
    PRODUCTS: {
        lastRawQuery: '',
        query: '',
        exitFromSettingMode: null,
        filter: [], //selected
        sorter: [], //selected
    },
    ALL: {
        lastRawQuery: '',
        query: '',
        exitFromSettingMode: null,
        filter: [], //selected
        sorter: [], //selected
    },
}

export const searchReducers = (state = initialState, action) => {
    var SEARCH_TYPE = ''
    if (action.payload && action.payload.searchType)
        SEARCH_TYPE = SEARCH_TYPE_OPTION[action.payload.searchType]

    switch (action.type) {
        case INITIAL_SEARCH_HISTORY:
            return {
                ...state,
                GENERIC_SEARCH_HISTORY: {
                    ...state.GENERIC_SEARCH_HISTORY,
                    result: action.payload.allHistory,
                },
            }
        case ADD_SEARCH_HISTORY:
            const history = state.GENERIC_SEARCH_HISTORY.result
            const uniqueHistory = []
            const newQyery = action.payload.history

            for (var i = 0; i < history.length; i++) {
                if (history[i].query !== newQyery.query) {
                    uniqueHistory.push(history[i])
                }
            }

            state.GENERIC_SEARCH_HISTORY.result = uniqueHistory

            return {
                ...state,
                GENERIC_SEARCH_HISTORY: {
                    ...state.GENERIC_SEARCH_HISTORY,
                    result: [
                        action.payload.history,
                        ...state.GENERIC_SEARCH_HISTORY.result,
                    ],
                },
            }
        case REMOVE_SEARCH_HISTORY:
            let historyId = action.payload.id
            return {
                ...state,
                GENERIC_SEARCH_HISTORY: {
                    ...state.GENERIC_SEARCH_HISTORY,
                    result: state.GENERIC_SEARCH_HISTORY.result.filter(
                        (history) => history.id !== historyId
                    ),
                },
            }
        case REMOVE_ALL_SEARCH_HISTORY:
            return {
                ...state,
                GENERIC_SEARCH_HISTORY: {
                    ...state.GENERIC_SEARCH_HISTORY,
                    result: [],
                },
            }
        case UPDATE_PREVIOUS_RAW_QUERY_VALUE:
            return {
                ...state,
                [SEARCH_TYPE]: {
                    ...state[SEARCH_TYPE],
                    lastRawQuery: action.payload.rawQuery,
                },
            }
        case ADD_SEARCH_SETTING_FILTER:
            return {
                ...state,
                [SEARCH_TYPE]: {
                    ...state[SEARCH_TYPE],
                    filter: [
                        ...state[SEARCH_TYPE].filter,
                        action.payload.filter,
                    ],
                },
            }
        case UPDATE_SEARCH_SETTING_MODE:
            return {
                ...state,
                [SEARCH_TYPE]: {
                    ...state[SEARCH_TYPE],
                    exitFromSettingMode: action.payload.exitFromSettingMode,
                },
            }
        case UPDATE_SEARCH_SETTING_FILTER_OPTION:
            var filterId = action.payload.filter.id
            var option = action.payload.filter.option

            //check if filter object exist, if not add it.
            var exist = false
            for (let i = 0; i < state[SEARCH_TYPE].filter.length; i++) {
                if (state[SEARCH_TYPE].filter[i].id === filterId) {
                    exist = true
                    break
                }
            }

            if (exist) {
                let exitFromSettingMode = null
                let finalUpdatedFilter = []
                let updatedFirstPastFilter = state[SEARCH_TYPE].filter.map(
                    (filterItem) => {
                        if (filterItem.id === filterId) {
                            return {
                                ...filterItem,
                                option: option,
                            }
                        } else {
                            return filterItem
                        }
                    }
                )

                //we remove the whole filter group item if no option is selected
                if (!option.length) {
                    finalUpdatedFilter = updatedFirstPastFilter.filter(
                        (filterItem) => {
                            if (filterItem.id !== filterId) return filterItem
                        }
                    )

                    if (!finalUpdatedFilter.length) {
                        exitFromSettingMode = true
                    }
                } else {
                    finalUpdatedFilter = updatedFirstPastFilter
                }

                return {
                    ...state,
                    [SEARCH_TYPE]: {
                        ...state[SEARCH_TYPE],
                        exitFromSettingMode: exitFromSettingMode,
                        filter: finalUpdatedFilter,
                    },
                }
            } else {
                //add
                return {
                    ...state,
                    [SEARCH_TYPE]: {
                        ...state[SEARCH_TYPE],
                        filter: [
                            ...state[SEARCH_TYPE].filter,
                            {
                                ...action.payload.filter,
                                // id: filterId,
                                option: option,
                            },
                        ],
                    },
                }
            }
        case REMOVE_SEARCH_SETTING_FILTER:
            filterId = action.payload.id
            return {
                ...state,
                [SEARCH_TYPE]: {
                    ...state[SEARCH_TYPE],
                    filter: state[SEARCH_TYPE].filter(
                        (filter) => filter.id !== filterId
                    ),
                },
            }
        case REMOVE_ALL_SEARCH_SETTING_FILTER:
            return {
                ...state,
                [SEARCH_TYPE]: {
                    ...state[SEARCH_TYPE],
                    filter: [],
                },
            }

        case ADD_SEARCH_SETTING_SORTER:
            return {
                ...state,
                [SEARCH_TYPE]: {
                    ...state[SEARCH_TYPE],
                    sorter: [
                        ...state[SEARCH_TYPE].sorter,
                        action.payload.sorter,
                    ],
                },
            }
        case REMOVE_SEARCH_SETTING_SORTER:
            var sorterId = action.payload.id
            return {
                ...state,
                [SEARCH_TYPE]: {
                    ...state[SEARCH_TYPE],
                    sorter: state[SEARCH_TYPE].sorter(
                        (sorter) => sorter.id !== sorterId
                    ),
                },
            }
        case REMOVE_ALL_SEARCH_SETTING_SORTER:
            return {
                ...state,
                [SEARCH_TYPE]: {
                    ...state[SEARCH_TYPE],
                    sorter: [],
                },
            }
        case UPDATE_SEARCH_SETTING_SORTER_OPTION:
            var sorterId = action.payload.sorter.id
            var sorterItem = action.payload.sorter
            var option = action.payload.sorter.option

            //check if sorter object exist, if not add it.
            var exist = false
            for (let i = 0; i < state[SEARCH_TYPE].sorter.length; i++) {
                if (state[SEARCH_TYPE].sorter[i].id === sorterId) {
                    exist = true
                    break
                }
            }

            if (exist) {
                return {
                    ...state,
                    [SEARCH_TYPE]: {
                        ...state[SEARCH_TYPE],
                        sorter: state[SEARCH_TYPE].sorter.map((sorterItem) => {
                            if (sorterItem.id === sorterId) {
                                return {
                                    ...sorterItem,
                                    option: option,
                                }
                            } else {
                                return sorterItem
                            }
                        }),
                    },
                }
            } else {
                //add
                return {
                    ...state,
                    [SEARCH_TYPE]: {
                        ...state[SEARCH_TYPE],
                        sorter: [
                            ...state[SEARCH_TYPE].sorter,
                            {
                                ...sorterItem,
                                option: option,
                            },
                        ],
                    },
                }
            }
        default:
            return state
    }
}
