import { mergePricingData } from '../../helpers'
import {
    INITIAL_FAVOURITE_PRODUCT,
    ADD_FAVOURITE_PRODUCT,
    REMOVE_FAVOURITE_PRODUCT,
    SET_PRODUCT_PRICING,
    RESET_PRODUCT_PRICING
} from './product-actions'

const initialState = {
    favourite: [],
    productPrices: []
}

export const productReducers = (state = initialState, action) => {
    switch (action.type) {
        case INITIAL_FAVOURITE_PRODUCT:
            return {
                ...state,
                favourite: action.payload.products,
            }
        case ADD_FAVOURITE_PRODUCT:
            let [existingFavouriteProduct] = state.favourite.filter((o) => {
                if (o.id === action.payload.product.id) {
                    return o
                }
            })

            if (existingFavouriteProduct) return state

            return {
                ...state,
                favourite: [...state.favourite, action.payload.product],
            }
        case REMOVE_FAVOURITE_PRODUCT:
            let productId = action.payload.id

            return {
                ...state,
                favourite: state.favourite.filter(
                    (product) => product.id !== productId
                ),
            }
        case SET_PRODUCT_PRICING:
            let updatedProductPrices = [];
            if (state.productPrices?.length && action?.payload?.length) {
                for (const it of action.payload) {
                    const exist = state.productPrices.find(p => {
                        if(p?.productId) {
                            return p.uri === it.linksProduct
                        } else {
                            return p.linksProduct === it.uri
                        }
                    });
                    if (!exist) updatedProductPrices.push(it)
                    else {
                        // update pricing/product details
                        const index = state.productPrices.findIndex(p => {
                            if(p?.productId) {
                                return p.uri === it.linksProduct
                            } else {
                                return p.linksProduct === it.uri
                            }
                        });
                        let updatedProduct
                        if(it?.productId) {
                            updatedProduct = mergePricingData(it, exist)
                        } else {
                            updatedProduct = mergePricingData(exist, it)
                        }
                        state.productPrices.splice(index, 1)
                        updatedProductPrices.push(updatedProduct)
                    }
                }
                updatedProductPrices = [...state.productPrices, ...updatedProductPrices];
            } else updatedProductPrices = action.payload;
            return {...state, productPrices: updatedProductPrices};
        case RESET_PRODUCT_PRICING:
            return {...state, productPrices: []};
        default:
            return state
    }
}
