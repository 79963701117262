import { api, base } from './ajax'
import {
    getAttributeFragment,
    getAttributeTemplate,
    getObjectKey,
    attributeArrayToObject,
    isNilOrEmpty,
    isNil,
} from '../helpers'
import { createBDATemplate, createBRATemplate } from '../helpers/admin-helper'
import { AdminService } from '../services/admin-service'
import { ProductService } from './product-service'
import { MasterDataService } from './masterdata-service'
import { DestinationService } from './destination-service'

export const CustomisationService = {
    PORTAL_ENTITY_TYPE_TEXT:
        'http://www.inmindcloud.dev.com/application/schema.owl#EnumPortalEntityTypeText',
    PORTAL_ENTITY_TYPE_BUTTON:
        'http://www.inmindcloud.dev.com/application/schema.owl#EnumPortalEntityTypeButton',
    PortalSettings_METADATA: {},
    PortalSocialMediaLink_METADATA: {},
    PortalAdditionalLink_METADATA: {},
    PortalNavigationPage_METADATA: {},
    PortalScreenConfiguration_METADATA: {},
    PortalLandingPageBanner_METADATA: {},
    PortalMediaFile_METADATA: {},
    PortalEntity_METADATA: {},
    PortalTheme_METADATA: {},
    PortalProductFilter_METADATA: {},
    PortalNavigationSubEntity_METADATA: {},
    PortalNavigationCustomSetting_METADATA: {},
    ProductCategory_METADATA: {},
    PortalNavigationPage_RouteMapping: {
        SparePartsPage: '/spare-parts',
        ConsumerablesPage: '/consumables',
        EquipmentPage: '/equipment',
        ProductsPage: '/products',
        AllPage: '/all',
        InstalledBasePage: '/install-base',
    },
    commerceSettings: {},

    getCustomRouteMapping(path) {
        return '/custom/' + path
    },

    METADATA: [],
    SCREEN_CONFIGURATION: {},
    CommerceInquiryEnabled: null,
    CommerceOrderEnabled: null,
    DestinationConfig: null,
    CommerceEmployeeUser: false,

    async getMetadata(type) {
        return api.get('/metadatas/' + type)
    },

    async getMetaDataByType(businessType) {
        let cached = this.METADATA.find((a) => a.businessType === businessType)

        if (!isNilOrEmpty(cached)) {
            return cached.metadata
        }

        let metadata = await AdminService.getMetadata(businessType)

        if (!isNilOrEmpty(metadata)) {
            let found = this.METADATA.find(
                (a) => a.businessType === businessType
            )

            if (isNilOrEmpty(found))
                this.METADATA.push({
                    businessType: businessType,
                    metadata: metadata.data,
                })

            return metadata.data
        }
    },

    getScreenConfiguration() {
        return this.SCREEN_CONFIGURATION
    },

    async getInitialSettingData() {
        // this.ProductCategory_METADATA = await AdminService.getMetadata(
        //     'ProductCategory'
        // )

        return new Promise((resolve, reject) => {
            api.post('/settings/search', {
                data: {
                    type: 'Search',
                    include: [
                        'includesPortalSocialMediaLink',
                        'includesPortalNavigationPage',
                        'includesPortalNavigationPage.includesPortalNavigationSubEntity',
                        'includesPortalNavigationPage.includesPortalNavigationCustomSetting',
                        'includesPortalNavigationPage.includesPortalNavigationSubEntity.usesCategory',
                        'includesPortalMediaFile',
                        'includesPortalAdditionalLink',
                        'includesPortalTheme',
                        'includesPortalTheme.hasPortalFontFamily',
                        'includesPortalLandingPageBanner',
                        'includesPortalLandingPageBanner.includesPortalEntity',
                        'includesPortalProductFilter',
                        'includesPortalScreenConfiguration',
                        'includesPortalScreenConfiguration.includesPortalScreenConfigurationSection',
                        'includesPortalScreenConfiguration.includesPortalScreenConfigurationSection.includesPortalScreenConfigurationField',
                        'includesPortalScreenConfiguration.includesPortalScreenConfigurationSection.includesPortalScreenConfigurationField.includesConfigurationFieldAdditionalAttributes',
                        'includesPortalScreenConfiguration.includesPortalScreenConfigurationSection.includesPortalScreenConfigurationField.includesConfigurationFieldAdditionalAttributesDropDownList',
                        'includesPortalScreenConfiguration.includesPortalScreenConfigurationSection.includesPortalScreenConfigurationSubSection',
                        'includesPortalTheme.includesPortalCustomIcon',
                        'includesPortalTheme.includesPortalCustomDefaultImage'
                    ],
                },
            })
                .then((response) => {
                    if (!isNilOrEmpty(response.data.entry)) {
                        // pre process and normalize PortalEntity for PortalLandingPageBanner

                        for (let key in response.data.entry[0]) {
                            if (key === 'includesPortalLandingPageBanner') {
                                //loop through PortalLandingPageBanner instances
                                response.data.entry[0][
                                    key
                                ] = loopPortalLandingPageBannerInstances(
                                    response.data.entry[0][key]
                                )
                            }
                        }

                        function loopPortalLandingPageBannerInstances(instances) {
                            return instances.map((o) => {
                                o.includesPortalEntity.sort((current, next) => {
                                    if (
                                        current &&
                                        current.portalEntityType ===
                                        'http://www.inmindcloud.dev.com/application/schema.owl#EnumPortalEntityTypeText'
                                    )
                                        return -1
                                    else return 1
                                })

                                return o
                            })
                        }

                        // pre process and normalize PortalEntity for PortalLandingPageBanner END

                        this.commerceSettings = response.data.entry[0]
                        this.SCREEN_CONFIGURATION =
                            this.commerceSettings.includesPortalScreenConfiguration
                    }
                    resolve(this.commerceSettings)
                })
                .catch((error, a) => {
                    if (error.response) {
                        switch (error.response.status) {
                            case 401:
                                let splitCurrentLocation = window.location.href.split(
                                    '#'
                                )
                                let baseUrl = splitCurrentLocation[0]
                                // if (baseUrl[baseUrl.length - 1] !== '/')
                                //     baseUrl += '/'
                                window.location.href = baseUrl + '#/login'
                                break
                        }
                    }

                    reject(error)
                })
        })
    },
    extractRequiredData(inData, metadata) {
        //we extract what we need for setting only.

        let out = {}

        let portalSettingsData = inData

        var metadataMap = {}
        let metadataAttribute = metadata.attributes.map((dto) => {
            let attributeId = dto.attribute.id
            let attributeFragment = getAttributeFragment(attributeId)
            metadataMap[attributeFragment] = dto
        })

        let label = portalSettingsData.label || ''
        let comment = portalSettingsData.comment || ''

        for (let key in portalSettingsData) {
            let value = portalSettingsData[key]

            if (metadataMap[key]) {
                if (metadataMap[key].type === 'data') {
                    out[key] = createBDATemplate({
                        attributeFragment: key,
                        valuetype: metadataMap[key].valuetype,
                        value: value,
                        type: 'data',
                    })
                } else if (metadataMap[key].type === 'relation') {
                    out[key] = createBRATemplate({
                        attributeFragment: key,
                        value: value,
                        type: 'relation',
                    })
                    let bdaValue = out[key].value.map((o) => {
                        if (
                            CustomisationService[o.type + '_METADATA'] &&
                            CustomisationService[o.type + '_METADATA'].data
                        ) {
                            let typeMeta =
                                CustomisationService[o.type + '_METADATA'].data
                            return CustomisationService.extractRequiredData(
                                o,
                                typeMeta
                            )
                        } else {
                            console.warn(`metadata not defined for - ${o.type}`)
                            return o
                        }
                    })
                    out[key].value = bdaValue
                }
            }
        }

        out = {
            ...out,
            ...{
                id: portalSettingsData.id,
                uri: portalSettingsData.uri,
                type: portalSettingsData.type,
                label: label,
                comment: comment,
            },
        }

        return out
    },
    async getAllLanguages() {
        return AdminService.getAllLanguages()
    },
    async getAllProductCategories(runInBackground = false) {
        return ProductService.getAllProductCategoriesTree(runInBackground)
    },
    getPublicCustomisationData() {
        return base.get('/api/settings/public')
    },
    getSSOConfig() {
        return api.get('/getSSOConfig');
    },
    async setEmployeeUser(value) {
        this.CommerceEmployeeUser = value
    },

    isEmployeeUser() {
        return this.CommerceEmployeeUser
    },

    async setCommerceInquirySetting(value) {
        if (isNilOrEmpty(value)) {
            this.CommerceInquiryEnabled = false
        } else {
            this.CommerceInquiryEnabled = value
        }
    },

    getCommerceInquirySetting() {
        return this.CommerceInquiryEnabled
    },

    async setCommerceOrderSetting(value) {
        if (isNilOrEmpty(value)) {
            this.CommerceOrderEnabled = false
        } else {
            this.CommerceOrderEnabled = value
        }
    },

    getCommerceOrderSetting() {
        return this.CommerceOrderEnabled
    },

    getCommerceSettings() {
        return this.commerceSettings
    },

    async setDestinationConfig() {
        return new Promise((resolve, reject) => {
            DestinationService.getAllDestinationConfig()
                .then((response) => {
                    const result = response.data
                    this.DestinationConfig = result.entry
                    resolve(result)
                })
                .catch((error) => {
                    console.log(error)
                    this.DestinationConfig = []
                    resolve([])
                })
        })
    },

    async getDestinationConfig() {
        if (isNil(this.DestinationConfig)) {
            await this.setDestinationConfig()
        }

        return this.DestinationConfig
    },

    async setDestinationConfigForThreeKit() {
        return new Promise((resolve, reject) => {
            DestinationService.getAllDestinationConfigForThreeKit()
                .then((response) => {
                    const result = response.data
                    this.DestinationConfig = result.entry
                    resolve(result)
                })
                .catch((error) => {
                    console.log(error)
                    this.DestinationConfig = []
                    resolve([])
                })
        })
    },

    async getDestinationConfigForThreeKit() {
        if (isNil(this.DestinationConfig)) {
            await this.setDestinationConfigForThreeKit()
        }

        return this.DestinationConfig
    },
}
