import React, { Suspense, lazy, useEffect, useState} from 'react'

import './app.scss'

import { HashRouter as Router, Switch, Route } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import theme from '../../theme/salesPortal/theme'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { AuthenticationService, TranslationService } from '../../services'
import Login from '../login'
import SignupWrapper from '../sign-up';
import LoginSSO from '../login-sso';
import ScrollToTop from '../../components/scroll-to-top'
import { CookiesProvider } from 'react-cookie'

const AdministrationHome = lazy(() => {
    return import('../administration-home')
})

const AppContent = lazy(() => {
    return import('../app-content')
})

const App = () => {
    const [translationLoaded, setTranslationLoaded] = useState(false);

    useEffect(() => {
        TranslationService.init(() => {
            setTranslationLoaded(true);
        });
    }, []);

    if (!translationLoaded) return null;

    return (
        <CookiesProvider>
            <Router>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <div className="App">
                        <ScrollToTop>
                            <Switch>
                                <Route path="/login">
                                    <Login></Login>
                                </Route>
                                <Route path='/login-sso'>
                                    <LoginSSO />
                                </Route>
                                <Route path = '/selfRegistration'>
                                    <SignupWrapper />
                                </Route>
                                <Route path="/administration">
                                    <Suspense fallback={null}>
                                        <AdministrationHome></AdministrationHome>
                                    </Suspense>
                                </Route>

                                <Router>
                                    <Suspense fallback={null}>
                                        <AppContent></AppContent>
                                    </Suspense>
                                </Router>
                            </Switch>
                        </ScrollToTop>
                    </div>
                </ThemeProvider>
            </Router>
        </CookiesProvider>
    )
}

export default withTranslation()(App)
