import { api, base } from './ajax'
import { AuthenticationService } from './authentication-service'
import { AdminService } from './admin-service'
import {
    getAttributeFragment,
    getAttributeTemplate,
    getObjectKey,
    getObjectValue,
    attributeArrayToObject,
    isNilOrEmpty,
    uriFragment,
    parseDto,
} from '../helpers'
import { useSelector } from 'react-redux'
import { CustomisationService } from './customisation-service'
import { setCommerceInquiryEnabled } from '../store/customisation/customisation-actions'
import { SEND_ORDER_FOR_REVIEW_PERMISSION, SUBMIT_INQUIRY_PERMISSION } from '../const/const'

export const UserService = {
    userPreferredLanguage : '',

    forgotPassword({ userName = '' }) {
        return base.get('/webapp/unsecure/requestUserPassword', {
            params: {
                userID: userName,
                applicationContext: 'SalesPortal',
            },
        })
    },
    Contact_METADATA: {},
    User_METADATA: {},
    async getMetadata(type) {
        return api.get('/metadatas/' + type)
    },

    checkKey() {
        var key = window.location.href.split('login?key=')[1]

        return base.get(
            '/webapp/unsecure/requestUserPassword/checkkey?recordKey=' + key
        )
    },
    changePassword(password) {
        var key = window.location.href.split('login?key=')[1]

        return base.get(
            '/webapp/unsecure/requestUserPassword/changePassword?key=' +
            key +
            '&pw=' +
            password,
            {
                params: {
                    applicationContext: 'SalesPortal',
                },
            }
        )
    },

    createPortalEndUser(personId) {
        return api.post('/users', {
            params: {
                personId: personId,
            },
            data: {},
        })
    },

    resetPassword(currentPassword, newPassword, userBO) {
        return api.post('/user/resetUserPassword', {
            data: {
                type: {
                    id:
                        'http://www.inmindcloud.com/application/schema.owl#UserPassword',
                },
                instance: {
                    id: '',
                },
                attributes: [
                    {
                        attribute: {
                            id:
                                'http://www.inmindcloud.com/application/schema.owl#objectName',
                        },
                        type: 'data',
                        value: [btoa(newPassword)],
                    },
                    {
                        attribute: {
                            id:
                                'http://www.inmindcloud.com/application/schema.owl#userPasswordIncludedBy',
                        },
                        type: 'relation',
                        value: [userBO],
                    },
                ],
            },
            params: {
                oldPassword: btoa(currentPassword),
            },
        })
    },

    async getOwnPermission(userData) {
        await api.get('/user/ownPermissions').then((response) => {
            userData['permissions'] = {
                value: response.data,
            }
        })
    },

    async updateUserLastLogin() {
        await api.patch('/user/updateUserLastLogin', {}, { trackThisPromise: false })
    },

    getAccountPartnerFunctions(accountBO) {
        // const accountBO = getObjectValue(userData, 'contactContainedBy')
        return api
            .post('/partnerrolerecord/all', {
                params: {
                    instanceid: accountBO,
                },
            })
            .then((response) => {
                const records = response.data
                let shipTo = []
                let billTo = []

                if (!isNilOrEmpty(records)) {
                    records.map((record) => {
                        const parsedRecord = attributeArrayToObject(
                            getObjectKey(record, 'attributes')
                        )
                        const hasPartnerFunction = getObjectValue(
                            parsedRecord,
                            'hasPartnerFunction'
                        )
                        const hasPartnerFunctionKey = uriFragment(
                            hasPartnerFunction
                        )

                        const valueDto = getObjectKey(
                            parsedRecord,
                            'linksAddress/valueDto'
                        )

                        if (!isNilOrEmpty(valueDto)) {
                            const address = getObjectKey(
                                parsedRecord,
                                'linksAddress/valueDto/0'
                            )

                            if (hasPartnerFunctionKey === 'BILL_TO_PARTY') {
                                billTo.push(address)
                            } else if (
                                hasPartnerFunctionKey === 'SHIP_TO_PARTY'
                            ) {
                                shipTo.push(address)
                            }
                        }
                    })
                }

                return {
                    billTo: billTo,
                    shipTo: shipTo,
                }
                // userData['billTo'] = billTo
                // userData['shipTo'] = shipTo
            })
            .catch(() => { })
    },
    uploadProfileImage(payload) {
        let file = payload.file
        let primaryBo = payload.primaryBo
        let instanceBo = payload.instanceBo

        let data = new FormData()
        data.append('file', file)

        return api.post('/content/uploadPersonImage', {
            data: data,
            params: {
                primaryBo: primaryBo,
                instanceBo: instanceBo,
            },
            headers: { 'content-type': 'multipart/form-data' },
        })
    },
    removeProfileImage(payload) {
        let primaryBo = payload.primaryBo
        let instanceBo = payload.instanceBo

        return api.post('/content/removePersonImage', {
            data: {},
            params: {
                primaryBo: primaryBo,
                instanceBo: instanceBo,
            },
        })
    },
    updateMyUserData({ userId = '', payload = {} }) {
        let myUserId = userId
        let path = '/user/' + myUserId
        return api.patch(path, {
            data: payload,
        })
    },
    getMyUser(userBo) {
        let userId = btoa(userBo)
        let path = '/user/search'
        return api.post(path, {
            data: {
                filter: 'id eq ' + userId,
                include: ['containsPerson', 'includesAddress'],
            },
        })
    },

    getUserContextForIFrame(userBo, include = []) {
        let userId = btoa(userBo)
        let path = '/user/search'
        return api.post(path, {
            data: {
                filter: 'id eq ' + userId,
                include: include,
            },
        })
    },

    getUserPreferredLanguage() {
        return this.userPreferredLanguage
    },

    setUserPreferredLanguage(language) {
        this.userPreferredLanguage = language
    },

    //cannot use the above function because on login, unable to access any userid
    getMyUserDetail(callBackUserBO) {
        return new Promise((resolve, reject) => {
            api.post('/init')
                .then(async (response) => {
                    let userData = UserService.extractRequiredUserData(
                        response.data
                    )

                    var userId = getObjectKey(userData, 'userId/value')
                    var userEmail = getObjectKey(userData, 'personEmail/value')

                    window.u_wm_object = {
                        userID : userId,
                        userEmailAddress : userEmail
                    };

                    this.setUserPreferredLanguage(userData.userLanguage)

                    await this.getOwnPermission(userData)
                    await this.getMySalesInfo(userData, true)

                    if (callBackUserBO)
                        callBackUserBO(userData.userBO.value)

                    resolve(userData)
                })
                .catch((error, a) => {
                    if (error.response) {
                        switch (error.response.status) {
                            case 401:
                                let splitCurrentLocation = window.location.href.split(
                                    '#'
                                )
                                let baseUrl = splitCurrentLocation[0]

                                // if (baseUrl[baseUrl.length - 1] !== '/')
                                //     baseUrl += '/'

                                // if (baseUrl.endsWith('.html/')) {
                                //     baseUrl = baseUrl.slice(0, -1)
                                // }
                                window.location.href = baseUrl + '#/login'
                                break
                        }
                    }

                    reject(error)
                })
        })
    },

    async getMySalesInfo(userData, requireCurrency) {
        const contactBO = getObjectValue(userData, 'instanceId')
        const accountBO = getObjectValue(userData, 'contactContainedBy')

        return new Promise((resolve, reject) => {
            api.get('/contact/salesInfos', {
                params: {
                    contactBO: contactBO,
                },
            })
                .then((res) => {
                    let salesInfos = res.data

                    if (!isNilOrEmpty(salesInfos)) {
                        let salesOrg = ''
                        let distributionChannel = ''
                        let divison = ''
                        let currency = ''
                        let currencyData = {}

                        salesInfos = parseDto(salesInfos)

                        for (let i = 0; i < salesInfos.length; i++) {
                            const salesInfo = salesInfos[i]
                            const contactSalesInfoDefault = getObjectKey(
                                salesInfo,
                                'contactSalesInfoDefault/value/0'
                            )

                            if (
                                !isNilOrEmpty(contactSalesInfoDefault) &&
                                contactSalesInfoDefault
                            ) {
                                salesOrg = getObjectKey(
                                    salesInfo,
                                    'linksSalesInfo/valueDto/0/hasSalesOrg/value'
                                )
                                distributionChannel = getObjectKey(
                                    salesInfo,
                                    'linksSalesInfo/valueDto/0/hasDistributionChannel/value'
                                )
                                divison = getObjectKey(
                                    salesInfo,
                                    'linksSalesInfo/valueDto/0/hasDivision/value'
                                )
                                currency = getObjectKey(
                                    salesInfo,
                                    'linksSalesInfo/valueDto/0/hasCurrency/value'
                                )
                                currencyData = getObjectKey(
                                    salesInfo,
                                    'linksSalesInfo/valueDto/0/hasCurrency/valueDto/0'
                                )
                                break
                            }
                        }

                        // if currency is not available
                        // get salesdata for simulation quote creation
                        if(!requireCurrency) {
                            resolve({
                                salesOrg: salesOrg,
                                distributionChannel: distributionChannel,
                                division: divison,
                            })
                        }

                        if (
                            !isNilOrEmpty(salesOrg) &&
                            !isNilOrEmpty(distributionChannel) &&
                            !isNilOrEmpty(divison) &&
                            !isNilOrEmpty(currency)
                        ) {
                            let data = {
                                priceRequestParam: {
                                    hasAccount: accountBO,
                                    hasSalesOrg: salesOrg,
                                    hasDistributionChannel: distributionChannel,
                                    hasDivision: divison,
                                    hasCurrency: currency,
                                },
                                currencyData: currencyData,
                            }
                            userData['salesInfo'] = data
                            resolve(data)
                        } else resolve(null)
                    } else {
                        //TODO TEST
                        //let data = {
                        //    priceRequestParam : {
                        //        hasAccount : accountBO,
                        //        hasSalesOrg : 'test',
                        //        hasDistributionChannel : 'test',
                        //        hasDivision : 'test',
                        //        hasCurrency : 'test',
                        //    },
                        //    currencyData : { objectERPId : { value : 'SGD'}}
                        //}
                        //userData[ 'salesInfo' ] = data

                        resolve(null)
                    }
                })
                .catch((errorResponse) => {
                    reject(errorResponse)
                })
        })
    },
    extractRequiredUserData(inData) {
        //we extract what we need for portal only.
        let out = {}
        let userId = { value: inData.userid }
        let username = { value: inData.username }
        let userLanguage = { value: inData.userLanguage }
        let userPasswordStatus = { value: inData.userPasswordStatus }
        let userDetails = inData.userDetails
        let userBO = { value: userDetails.instance.id }
        let systemCurrency = {
            value: getObjectKey(inData, 'systemCurrency/id'),
        }
        let sscEnabled = {
            value: getObjectKey(inData, 'ssc_enabled'),
        }

        let filteredAttributes = userDetails.attributes.filter((dto) => {
            let attributeId = dto.attribute.id
            let attributeFragment = getAttributeFragment(attributeId)

            switch (attributeFragment) {
                case 'containsPerson':
                    return dto
                case 'objectId':
                    out['objectId'] = {
                        value: getObjectKey(dto, 'value/0') || '',
                    }
                case 'hasRole':
                        out['hasRole'] = {
                            value: getObjectKey(dto, 'value/0') || '',
                        }
                case 'hasDeniedPermission':
                    out['hasDeniedPermission'] = {
                        value: getObjectKey(dto, 'value') || [],
                    }
            }

            return false
        })

        filteredAttributes.map((attributeDto) => {
            switch (getAttributeFragment(attributeDto.attribute.id)) {
                case 'containsPerson':
                    let containsPersonAttributes = attributeArrayToObject(
                        getObjectKey(attributeDto, 'valueDto/0/attributes')
                    )

                    out['instanceId'] = {
                        value: getObjectKey(
                            attributeDto,
                            'valueDto/0/instance/id'
                        ),
                    }

                    out['personFirstName'] = getObjectKey(
                        containsPersonAttributes,
                        'personFirstName'
                    )
                    out['personLastName'] = getObjectKey(
                        containsPersonAttributes,
                        'personLastName'
                    )

                    out['personEmail'] = getObjectKey(
                        containsPersonAttributes,
                        'personEmail'
                    )
                    out['personPicture'] = getObjectKey(
                        containsPersonAttributes,
                        'personPicture'
                    )
                    out['personMobile'] = getObjectKey(
                        containsPersonAttributes,
                        'personMobile'
                    )
                    out['contactContainedBy'] = {
                        value: getObjectValue(
                            containsPersonAttributes,
                            'contactContainedBy'
                        ),
                    }
            }
        })

        let isProspectAccount = inData.isProspectAccount

        out = {
            ...out,
            userId: userId,
            username: username,
            userLanguage: userLanguage,
            userPasswordStatus: userPasswordStatus,
            userBO,
            systemCurrency,
            sscEnabled,
            isProspectAccount: isProspectAccount
        }

        return out
    },

    extractRequiredData(inData, metadata) {
        //we extract what we need.

        return AdminService.extractRequiredData(inData, metadata)
    },
    getTranslationJSON(language) {
        return base.get(`api/settings/public/userdata/locales/${language}`);
    },
    getUserAccount(payload) {
        return api.post('accounts/search', { data: payload });
    }
}
