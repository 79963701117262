import { api } from './ajax'
import { isNil, isNilOrEmpty } from '../helpers'
import { AuthenticationService } from './authentication-service'
import { TextareaAutosize } from '@material-ui/core'
import { STATUS_ORDER_COMPLETED } from '../const/const'

export const MasterDataService = {
    orderStatusCompleted: {},
    orderStatus: [],
    paymentMode: [],

    getAppData(type) {
        return api.post(
            '/masterdata/objectsLight',
            {
                data: [
                    `http://www.inmindcloud.com/application/schema.owl#${type}`,
                ],
            },
            {
                headers: {
                    Authorization: AuthenticationService.getLocalStorageOAuthTokenValue(
                        'oauthToken'
                    ),
                },
                trackThisPromise: true,
            }
        )
    },

    getSettingValue(type) {
        return api.get(
            '/app/getSettingValue',
            {
                params: {
                    settingBO: `http://www.inmindcloud.com/application/schema.owl#${type}`,
                },
            },
            {
                headers: {
                    Authorization: AuthenticationService.getLocalStorageOAuthTokenValue(
                        'oauthToken'
                    ),
                    'Content-Type': 'application/json',
                },
            }
        )
    },

    getAllProductMaterialGroup(runInBackground) {
        return api.post('/masterdatas/ProductMaterialGroup/search', {
            data: {
                type: 'Search',
                limit: 99999,
                offset: 0,
            },
        },
            {
                headers: {
                    Authorization: AuthenticationService.getLocalStorageOAuthTokenValue(
                        'oauthToken'
                    ),
                    'Content-Type': 'application/json',
                }, trackThisPromise: !runInBackground
            })
    },

    async getOrderStatusCompleted() {
        if (!isNilOrEmpty(this.orderStatusCompleted)) {
            return this.orderStatusCompleted
        }

        const statusData = await api.post('/masterdatas/OrderStatus/search', {
            data: {
                type: 'Search',
                filter: 'uri eq http://www.inmindcloud.com/application/schema.owl#ORDER_COMPLETED'
            },
        },
            {
                headers: {
                    Authorization: AuthenticationService.getLocalStorageOAuthTokenValue(
                        'oauthToken'
                    ),
                    'Content-Type': 'application/json',
                },
            })

        if (statusData.data && statusData.data.entry) {
            let [found] = statusData.data.entry.filter(s => s.uri === STATUS_ORDER_COMPLETED)

            if (found) {
                this.setOrderStatusCompleted(found)
            }
        }
        return this.orderStatusCompleted
    },

    getMetaSearch(metaData, payload) {

        return api.post('/masterdatas/' + metaData + '/search', {
            data: payload
        },
            {
                headers: {
                    Authorization: AuthenticationService.getLocalStorageOAuthTokenValue(
                        'oauthToken'
                    ),
                    'Content-Type': 'application/json',
                },
            })

    },

    setOrderStatusCompleted(status) {
        this.orderStatusCompleted = status
    },

    async getOrderStatus() {
        if (!isNilOrEmpty(this.orderStatus)) {
            return this.orderStatus
        }

        const statusData = await api.post('/masterdatas/OrderStatus/search', {
            data: {
                type: 'Search'
            },
        },
            {
                headers: {
                    Authorization: AuthenticationService.getLocalStorageOAuthTokenValue(
                        'oauthToken'
                    ),
                    'Content-Type': 'application/json',
                },
            })

        if (statusData.data && statusData.data.entry) {
            this.setOrderStatus(statusData.data.entry)
        }
        return this.orderStatus
    },

    setOrderStatus(entry) {
        this.orderStatus = entry
    },

    async getPaymentMode() {
        if (!isNilOrEmpty(this.paymentMode)) {
            return this.paymentMode
        }

        const paymentModeData = await api.post('/masterdatas/OrderPaymentMode/search', {
            data: {
                type: 'Search'
            },
        },
            {
                headers: {
                    Authorization: AuthenticationService.getLocalStorageOAuthTokenValue(
                        'oauthToken'
                    ),
                    'Content-Type': 'application/json',
                },
            })

        if (paymentModeData.data && paymentModeData.data.entry) {
            this.setPaymentMode(paymentModeData.data.entry)
        }
        return this.paymentMode
    },

    setPaymentMode(entry) {
        this.paymentMode = entry
    },

    getSalesAreaData(data) {
        return api.post(
            '/masterdata/objectsLight',
            {
                data
            },
            {
                headers: {
                    Authorization: AuthenticationService.getLocalStorageOAuthTokenValue(
                        'oauthToken'
                    ),
                },
                trackThisPromise: true,
            }
        )
    },

    getCountryState(countryURI) {

        var data = ({
            "Request_BusinessType": "http://www.inmindcloud.com/application/schema.owl#CountryRegion",
            "Filter": [
                {
                    "BusinessType": "http://www.inmindcloud.com/application/schema.owl#Country",
                    "Predicate": "",
                    "Value": [countryURI]
                }
            ]
        })

        return api.post(
            '/masterdata/businessType/businessObject/filter',
            {
                data
            },
            {
                headers: {
                    Authorization: AuthenticationService.getLocalStorageOAuthTokenValue(
                        'oauthToken'
                    ),
                },
                trackThisPromise: true,
            }
        )
    }
}
