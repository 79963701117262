import { useEffect } from 'react'
import ajax, { api } from './ajax'
import { useSelector, useDispatch } from 'react-redux'
import querystring from 'querystring'
import {
    logOutUserSession,
    logInUserSession,
} from '../store/user/user-action-types'
const USER_NAME_SESSION_ATTRIBUTE_NAME = 'authenticatedUser'
const TOKEN_SESSION_ATTRIBUTE_NAME = 'oauthToken'

export const AuthenticationService = {
    authenticate({ username = '', password = '' }) {
        return api.post(
            '/oauth/token',
            {
                data: querystring.stringify({
                    grant_type: 'password',
                    username: username,
                    password: password,
                    client_id: process.env.REACT_APP_CLIENT_ID,
                }),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            },
            { loader: true }
        )
    },

    getOAuthToken(responseData = {}) {
        if (!responseData.access_token) return ''
        return responseData.access_token
    },
    setOAuthBearerString(responseData = {}) {
        let localStorageString = ''
        let oAuthToken = this.getOAuthToken(responseData)
        if (oAuthToken) localStorageString = 'Bearer ' + oAuthToken

        localStorage.setItem(TOKEN_SESSION_ATTRIBUTE_NAME, localStorageString)
    },
    updateSession(responseData) {
        this.setOAuthBearerString(responseData)
    },
    getLocalStorageOAuthTokenValue() {
        return localStorage.getItem(TOKEN_SESSION_ATTRIBUTE_NAME)
    },
    registerSuccessfulLogin(username = '', responseData = {}) {
        localStorage.setItem(USER_NAME_SESSION_ATTRIBUTE_NAME, username)
        this.setOAuthBearerString(responseData)
    },

    isUserLoggedIn() {
        let user = localStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME)
        if (!user) {
            return false
        } else {
            return true
        }
    },

    getLoggedInUserName() {
        let user = localStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME)
        if (!user) return ''
        return user
    },
    setLoggedInUserName(userName) {
        localStorage.setItem(USER_NAME_SESSION_ATTRIBUTE_NAME, userName);
    },
    logout() {
        return api.post('/oauth/logout')
    },

    logoutSystemUser(token) {
        return api.post(
            '/oauth/logout',
            { headers: { authorization: `Bearer ${token}` } },
            { trackThisPromise: false }
        )
    },

    clearUserInfo() {
        localStorage.removeItem(TOKEN_SESSION_ATTRIBUTE_NAME)
        localStorage.removeItem(USER_NAME_SESSION_ATTRIBUTE_NAME)
    },
    /**
     * Use this custom hook after successful authentication.
     * This will retrieve the oAuthToken and userName value from the localStorage and set in redux.
     */
    useLogin() {
        const dispatch = useDispatch()
        let oAuthTokenValue = AuthenticationService.getLocalStorageOAuthTokenValue()
        let userName = AuthenticationService.getLoggedInUserName()

        useEffect(() => {
            dispatch(
                logInUserSession({
                    oAuthToken: oAuthTokenValue,
                    userName: userName,
                    initialDispatchCalled: true,
                })
            )
        }, [])
    },
    /**
     * Use this custom hook after successful logout().
     * This will set the oAuthToken and userName value in redux to ''.
     */
    useLogout() {
        const dispatch = useDispatch()
        dispatch(logOutUserSession, {
            toLogOut: true,
        })
    },
    /**
     * Use this custom hook in entry view component that requires logged in user.
     * if redux toLogOut = true, push to login view.
     * @param routerHistory
     * @param path
     */
    useAuthenticatorTrack(callback) {
        const userSessionData = useSelector((state) => {
            return state.userReducers.session
        })

        useEffect(() => {
            if (userSessionData.initialDispatchCalled && !userSessionData.isLogoutClicked) {
                if (!userSessionData.oAuthToken || userSessionData.toLogOut) {
                    callback(false)
                } else callback(true)
            }
        }, [
            userSessionData.initialDispatchCalled,
            userSessionData.oAuthToken,
            userSessionData.toLogOut,
            userSessionData.isLogoutClicked
        ])
    },
}
