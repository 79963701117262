import {
    formatDate,
    getObjectBoolean,
    getObjectKey,
    isNilOrEmpty,
} from './helpers'
import {
    getAttributeFragment,
    getAttributeById,
    getValueType,
} from './model-helper'
import { get } from 'store'
import { AdminService, CustomisationService } from '../services'
import { getSortedArray, isNil } from '.'

const SCREEN_COFIGURATION_SECTION_BRA =
    'includesPortalScreenConfigurationSection'
const SCREEN_COFIGURATION_FIELD_BRA = 'includesPortalScreenConfigurationField'

export const PRODUCT_DETAIL_SCREEN_CONFIG =
    'PortalScreenConfigurationProductDetail'
export const PRODUCT_CARD_TILE_SCREEN_CONFIG =
    'PortalScreenConfigurationProductCardTile'
export const PRODUCT_CARD_LIST_SCREEN_CONFIG =
    'PortalScreenConfigurationProductCardList'
export const SHOPPING_CART_SCREEN_CONFIG =
    'PortalScreenConfigurationShoppingCart'
export const SHOPPING_CART_INQUIRY_SCREEN_CONFIG =
    'PortalScreenConfigurationShoppingCartInquiry'
export const ORDER_HISTORY_OVERVIEW =
    'PortalScreenConfigurationOrderHistoryOverview'
export const ORDER_HISTORY_DETAIL =
    'PortalScreenConfigurationOrderHistoryDetail'
export const ORDER_CHECKOUT = 'PortalScreenConfigurationCheckoutOrder'
export const ORDER_REVIEW = 'PortalScreenConfigurationReviewOrder'
export const ORDER_AMENDMENT = 'PortalScreenConfigurationOrderAmendment'
export const ORDER_REORDER = 'PortalScreenConfigurationReorder'
export const ORDER_REORDER_REVIEW = 'PortalScreenConfigurationReorderReview'
export const INQUIRY_HISTORY_OVERVIEW =
    'PortalScreenConfigurationInquiryHistory'
export const INQUIRY_HISTORY_DETAIL = 'PortalScreenConfigurationInquiryDetail'

export const SECTION_ORDER_HISTORY_DETAIL_ITEM_HEADER = 'LineItemHeader'
export const SECTION_ORDER_HISTORY_DETAIL_ITEM_SUB_HEADER = 'LineItemSubHeader'
export const SECTION_ORDER_HISTORY_DETAIL_ITEM_ATTRIBUTES = 'LineItemAttributes'

export const EQUIPMENT_CARD_TILE_SCREEN_CONFIG =
    'PortalScreenConfigurationEquipmentCardTile'
export const EQUIPMENT_CARD_LIST_SCREEN_CONFIG =
    'PortalScreenConfigurationEquipmentCardList'

export const SELF_REGISTRATION_SCREEN_CONFIG =
    'PortalScreenConfigurationSelfRegistration'

export const ADVANCE_SPARE_PART_FINDER =
    'PortalScreenConfigurationSparePartFinder'

const getSections = (config) => {
    if (isNilOrEmpty(config)) {
        return []
    }
    const sections = getObjectKey(config, SCREEN_COFIGURATION_SECTION_BRA)
    if (!isNilOrEmpty(sections)) {
        return sections
    }
}

const getSectionsForScreens = (screens) => {
    const screenConfigCustomizationData = CustomisationService.getScreenConfiguration()

    let sections = []
    if (!isNilOrEmpty(screenConfigCustomizationData)) {
        screens.forEach((screen) => {
            const configs = screenConfigCustomizationData
            const [found] = configs.filter((c) => c.objectId === screen)
            if (!isNilOrEmpty(found)) {
                let sec = found.includesPortalScreenConfigurationSection
                sections = sections.concat(sec)
            }
        })
    }
    return sections
}

const getSectionsByScreenAndIds = (screen, sectionIds) => {
    const screenConfigCustomizationData = CustomisationService.getScreenConfiguration()

    let sections = []
    if (!isNilOrEmpty(screenConfigCustomizationData)) {
        const configs = screenConfigCustomizationData
        const [found] = configs.filter((c) => c.objectId === screen)
        if (!isNilOrEmpty(found)) {
            let sec = found.includesPortalScreenConfigurationSection
            sec.forEach((s) => {
                sectionIds.forEach((id) => {
                    if (s.objectId === id) {
                        sections.push(s)
                    }
                })
            })
        }
    }
    return sections
}

const getFieldKey = (sectionName, fieldPath) => {
    return sectionName + '-' + fieldPath
}

const getSection = (sections, objectId) => {
    let section = {}
    if (isNilOrEmpty(sections) || !Array.isArray(sections)) {
        return section
    }

    sections.map((sec) => {
        if (sec.objectId === objectId) {
            section = sec
        }
    })
    return section
}

const getFields = (section) => {
    let fields = []
    if (isNilOrEmpty(section)) {
        return fields
    }

    fields = getObjectKey(section, SCREEN_COFIGURATION_FIELD_BRA)
    if (!isNilOrEmpty(fields)) {
        return fields
    }
    return fields
}

const getValue = (sourceObject, path) => {
    let pathSplit = path.split('.')
    const resource = sourceObject.resource
    const include = sourceObject.include

    if (typeof path !== 'string') return undefined

    if (pathSplit.length == 1) {
        return getObjectKey(resource, path)
    }

    let currentObject
    const splitRel = pathSplit[0].split(':')
    const sourceRelation = splitRel[0]
    let arrayObject = getObjectKey(resource, sourceRelation)

    if (!isNilOrEmpty(arrayObject) && arrayObject.length > 1) {
        for (let arrObjectIndex = 0; arrObjectIndex < arrayObject.length; arrObjectIndex++) {

            let individualObject = arrayObject[arrObjectIndex]

            let retrieveColumnValue = getObjectKey(individualObject, pathSplit[1])

            let isObjectType = getObjectKey(individualObject, 'uri')

            if (isNilOrEmpty(isObjectType)) {
                let retrieveObjectFromInclude = include.filter((inc) => {
                    if (inc.uri === individualObject) return inc
                })
                if (!isNilOrEmpty(retrieveObjectFromInclude)) {
                    retrieveColumnValue = getObjectKey(retrieveObjectFromInclude[0], pathSplit[1])
                }
            }

            if (!isNilOrEmpty(currentObject))
                currentObject = currentObject + ',' + retrieveColumnValue
            else
                currentObject = retrieveColumnValue

        }
    }
    else {
        for (let index = 0; index < pathSplit.length; index++) {
            const aPath = pathSplit[index]
            const aPathSplit = aPath.split(':')
            const relation = aPathSplit[0]
            const bt = aPathSplit.length > 1 ? aPathSplit[1] : ''
            if (index == 0) {
                currentObject = getObjectKey(resource, relation + '/0')
                if (typeof currentObject === 'string') {
                    const found = include.filter((inc) => {
                        if (!isNilOrEmpty(inc) && inc.uri === currentObject) return true
                    })
                    if (!isNilOrEmpty(found)) {
                        currentObject = found[0]
                    }
                } else {
                    const uri = getObjectKey(currentObject, 'uri')
                    const found = include.filter((inc) => {
                        if (inc.uri === uri) return true
                    })
                    if (!isNilOrEmpty(found)) {
                        currentObject = found[0]
                    }
                }
            } else {
                if (index === pathSplit.length - 1) {
                    currentObject = getObjectKey(currentObject, relation)
                } else {
                    currentObject = getObjectKey(currentObject, relation + '/0')
                    if (typeof currentObject === 'string') {
                        const found = include.filter((inc) => {
                            if (inc.uri === currentObject) return true
                        })
                        if (!isNilOrEmpty(found)) {
                            currentObject = found[0]
                        }
                    }
                }
            }
        }
    }

    return currentObject
}

const getAttributeValueType = async (field, parentBusinessType) => {
    const fullPath = getObjectKey(field, 'metadataPath')
    let pathSplit = fullPath.split('.')
    let businessType = ''
    let metadataPath = ''

    if (pathSplit.length == 1) {
        businessType = parentBusinessType
        metadataPath = fullPath
    } else {
        metadataPath = pathSplit[pathSplit.length - 1]
        const aPath = pathSplit[pathSplit.length - 2]
        const aPathSplit = aPath.split(':')
        const relation = aPathSplit[0]
        businessType = aPathSplit.length > 1 ? aPathSplit[1] : ''
    }

    const meta = await CustomisationService.getMetaDataByType(businessType)
    let valueType = getValueType(meta, metadataPath)
    if (isNilOrEmpty(valueType)) {
        valueType = 'string'
    }
    return valueType
}

const getParsedField = async (field, sourceObject, parentBusinessType) => {
    let out = null

    const metadataPath = getObjectKey(field, 'metadataPath')
    const sourceObjectPath = metadataPath.includes(":") ? metadataPath.split(':')[0] : getObjectKey(field, 'metadataPath')
    const fullPath = getObjectKey(field, 'metadataPath')
    const label = getObjectKey(field, 'label')
    const showLabel = getObjectBoolean(field, 'showLabel')
    const isCustomField = getObjectBoolean(field, 'isCustomField')
    const isEditableField = getObjectBoolean(field, 'isEditableField')
    const isMandatoryField = getObjectBoolean(field, 'isMandatoryField')
    const objectId = getObjectKey(field, 'objectId')
    const objectName = getObjectKey(field, 'objectName')
    const hideField = getObjectBoolean(field, 'hideField')
    const doServerSideValidate = getObjectBoolean(field, 'doServerSideValidate')
    const isMultiValued = getObjectKey(field, 'isMultiValued')
    const isValueHelpPicker = !isNilOrEmpty(getObjectKey(field, 'isValueHelpPicker')) ? getObjectKey(field, 'isValueHelpPicker') : false
    const includesConfigurationFieldAdditionalAttributes = getObjectKey(field, 'includesConfigurationFieldAdditionalAttributes')
    const includesConfigurationFieldAdditionalAttributesDropDownList = getObjectKey(field, 'includesConfigurationFieldAdditionalAttributesDropDownList')


    let valueType = null
    let value = null
    let rawValue = null

    if (!isCustomField) {
        valueType = getObjectKey(field, 'valuetype')
        rawValue = getValue(sourceObject, sourceObjectPath)
        value = rawValue

        valueType = !isNilOrEmpty(value) && Array.isArray(value) ? 'array' : valueType
        switch (valueType) {
            case 'string':
                out = value
                break
            case 'numeric':
                out = value
                break
            case 'date':
                out = formatDate(value)
                break
            case 'boolean':
                out = getObjectBoolean(value)
                break
            case 'array':
                let arrInclude = getObjectKey(sourceObject, 'include')
                let subObjectFound = []

                if (!isNilOrEmpty(value)) {
                    for (let i = 0; i < arrInclude.length; i++) {
                        let includeUri = getObjectKey(arrInclude[i], 'uri')
                        for (let l = 0; l < value.length; l++) {
                            if (value[l] === includeUri) {
                                subObjectFound.push(arrInclude[i])
                            }
                        }
                    }

                    if (!isNilOrEmpty(subObjectFound)) {
                        out = subObjectFound
                    }
                    else {
                        out = value
                    }
                }
                else {
                    out = value
                }

                break
            default:
                out = value
                break
        }
    }

    return {
        type: valueType,
        value: out,
        rawValue: rawValue,
        label: label,
        showLabel: showLabel,
        isCustomField: isCustomField,
        isEditableField: isEditableField,
        isMandatoryField: isMandatoryField,
        doServerSideValidate: doServerSideValidate,
        objectName: objectName,
        objectId: objectId,
        hideField: hideField,
        metadataPath: fullPath,
        isMultiValued: isMultiValued,
        isValueHelpPicker: isValueHelpPicker,
        includesConfigurationFieldAdditionalAttributes: includesConfigurationFieldAdditionalAttributes,
        includesConfigurationFieldAdditionalAttributesDropDownList: includesConfigurationFieldAdditionalAttributesDropDownList,
        isBRA: !isNilOrEmpty(fullPath) && fullPath.includes(":") ? true : false,
        valueType: getObjectKey(field, 'valuetype')
    }
}

const getParsedFieldWithOutValue = async (field, parentBusinessType) => {
    const id = getObjectKey(field, 'id')
    const fullPath = getObjectKey(field, 'metadataPath')
    const label = getObjectKey(field, 'label')
    const showLabel = getObjectBoolean(field, 'showLabel')
    const isCustomField = getObjectBoolean(field, 'isCustomField')
    const isEditableField = getObjectBoolean(field, 'isEditableField')
    const isMandatoryField = getObjectBoolean(field, 'isMandatoryField')
    const objectId = getObjectKey(field, 'objectId')
    const objectName = getObjectKey(field, 'objectName')
    const hideField = getObjectBoolean(field, 'hideField')

    let valueType = null

    if (!isCustomField) {
        valueType = getObjectKey(field, 'valuetype')
    }

    return {
        id: id,
        type: valueType,
        label: label,
        showLabel: showLabel,
        isCustomField: isCustomField,
        isEditableField: isEditableField,
        isMandatoryField: isMandatoryField,
        objectName: objectName,
        objectId: objectId,
        hideField: hideField,
        metadataPath: fullPath,
    }
}

const getFormattedValue = (sourceObject, metadataPath, valueType) => {
    let out = null
    let value = null

    value = getValue(sourceObject, metadataPath)

    switch (valueType) {
        case 'string':
            out = value
            break
        case 'numeric':
            out = value
            break
        case 'date':
            out = formatDate(value)
            break
        case 'dateTime':
            out = formatDate(value)
            break
        case 'boolean':
            out = getObjectBoolean(value)
            break
        default:
            out = value
            break
    }

    return out
}

const getSortedConfigs = (configs = []) => {
    let sorted = configs.sort((current, next) => {
        if (current.sequenceNumber.value > next.sequenceNumber.value) return 1
        else if (current.sequenceNumber.value < next.sequenceNumber.value)
            return -1
        return 0
    })

    return sorted
}

const getAllIncludeParamForScreen = (config) => {
    const sections = getSections(config)
    return getAllIncludeParamForSections(sections)
}

const getAllIncludeParamForSections = (sections) => {
    let fields = []
    sections.forEach((section) => {
        fields = fields.concat(getFields(section))
    })
    const filterdField = fields.filter((f) => !getObjectBoolean(f, 'hideField'))
    const metaPaths = filterdField.map((o) => o.metadataPath)
    const relationsWithFields = metaPaths.filter((o) => o.indexOf('.') > 0)
    const relations = relationsWithFields
        .map((o) => {
            const lastIndex = o.lastIndexOf('.')
            const rel = o.substring(0, lastIndex)
            return rel
        })
        .sort()

    let out = relations
    relations.forEach((rel) => {
        let relation = ''
        let split = rel.split('.')
        let first = true
        split.forEach((r) => {
            relation = first ? r : relation + '.' + r
            first = false
            const found = out.find((e) => e === relation)
            if (isNilOrEmpty(found)) {
                out.push(relation)
            }
        })
    })

    return [...new Set(out)]
}

const getAllIncludeParamForSectionsReorder = (sections) => {
    let fields = []
    sections.forEach((section) => {
        fields = fields.concat(getFields(section))
    })
    const filterdField = fields.filter((f) => !getObjectBoolean(f, 'hideField'))
    const metaPaths = filterdField.map((o) => o.metadataPath)
    const relationsWithFields = metaPaths.filter((o) => o.indexOf(':') > 0)
    const relations = relationsWithFields
        .map((o) => {
            if (o.includes('.')) {
                const lastIndex = o.lastIndexOf('.')
                const rel = o.substring(0, lastIndex)
                return rel
            }
            else
                return o
        })
        .sort()

    let out = relations
    relations.forEach((rel) => {
        let relation = ''
        let split = rel.split('.')
        let first = true
        split.forEach((r) => {
            relation = first ? r : relation + '.' + r
            first = false
            const found = out.find((e) => e === relation)
            if (isNilOrEmpty(found)) {
                out.push(relation)
            }
        })
    })

    return [...new Set(out)]
}

const getAllIncludeParamForSectionsAmendmentOrder = (sections) => {
    let fields = []
    sections.forEach((section) => {
        fields = fields.concat(getFields(section))
    })
    const filterdField = fields.filter((f) => !getObjectBoolean(f, 'hideField'))
    const metaPaths = filterdField.map((o) => o.metadataPath)
    const relationsWithFields = metaPaths.filter((o) => o.indexOf(':') > 0)
    const relations = relationsWithFields
        .map((o) => {
            if (o.includes('.')) {
                const lastIndex = o.lastIndexOf('.')
                const rel = o.substring(0, lastIndex)
                return rel
            }
            else
                return o
        })
        .sort()

    let out = relations


    return [...new Set(out)]
}

const getAllIncludeElementsForSections = (sections, rel) => {
    let outElements = {}
    let outIncludeElements = {}
    let out = {}

    let fields = []
    sections.forEach((section) => {
        fields = fields.concat(getFields(section))
    })
    const filterdField = fields.filter((f) => !getObjectBoolean(f, 'hideField'))
    const metaPaths = filterdField.map((o) => o.metadataPath)
    const relationsWithFields = metaPaths.filter((o) => o.indexOf('.') > 0)

    // bda and bra for elements
    let directFields = []
    metaPaths.forEach((o) => {
        if (isNilOrEmpty(rel)) {
            if (typeof o === 'string' && o.indexOf('.') === -1) {
                directFields.push(o)
            }
        } else {
            if (typeof o === 'string') {
                const splits = o.split(rel)
                if (splits.length > 1) {
                    const tempField = splits[1]
                    if (
                        typeof tempField === 'string' &&
                        tempField.indexOf('.') === -1
                    ) {
                        directFields.push(tempField)
                    }
                }
            }
        }
    })
    outElements.bda = [...new Set(directFields)]
    let bra = []
    if (isNilOrEmpty(rel)) {
        bra = relationsWithFields.map((o) => {
            const relation = o.split('.')[0]
            const bra = relation.split(':')[0]
            return bra
        })
    } else {
        relationsWithFields.forEach((o) => {
            const relSplit = o.split('linksOrderItemProduct:Product.')
            if (relSplit.length > 1) {
                const relString = relSplit[1]
                const relation = relString.split('.')
                if (relation.length > 1) {
                    const relationParam = relation[0]
                    const relationParamSplit = relationParam.split(':')
                    if (relationParamSplit.length > 1) {
                        bra.push(relationParamSplit[0])
                    }
                }
            }
        })
    }

    outElements.bra = [...new Set(bra)]

    let includeElementsMap = new Map()

    const sorted = relationsWithFields.sort((current, next) => {
        var A = current.split('.')
        var B = next.split('.')
        if (A.length < B.length) {
            return -1
        }
        if (A.length > B.length) {
            return 1
        }
        return 0
    })

    let loop = true
    let index = 0
    while (loop) {
        index = index + 1
        let filtered = sorted.filter(
            (relation) => relation.split('.').length === index + 1
        )
        if (filtered.length > 0) {
            for (let i = 0; i < filtered.length; i++) {
                const o = filtered[i]
                const lastIndex = o.lastIndexOf('.')
                const key = o.substring(0, lastIndex)
                const bda = o.substring(lastIndex + 1, o.length)

                prepareIncludeElement(includeElementsMap, key, bda)
            }
        } else {
            loop = false
        }
    }

    if (isNilOrEmpty(rel)) {
        for (let [key, value] of includeElementsMap) {
            outIncludeElements[key] = value
        }
    } else {
        for (let [key, value] of includeElementsMap) {
            if (key !== rel) {
                if (key.startsWith(rel)) {
                    const relationParam = key.split(rel)
                    if (relationParam.length > 1) {
                        const newKey = relationParam[1]
                        outIncludeElements[newKey] = value
                    }
                }
            }
        }
    }

    out.elements = outElements
    out.includeElements = outIncludeElements

    return out
}

const prepareIncludeElement = (includeElementsMap, key, bdaToAdd) => {
    let includeElement = {
        bda: [],
        bra: [],
    }
    if (includeElementsMap.get(key)) {
        includeElement = includeElementsMap.get(key)
    }

    if (bdaToAdd) {
        includeElement.bda.push(bdaToAdd)
    }
    includeElementsMap.set(key, includeElement)

    //check relation to be added to parent
    if (key.split('.').length > 1) {
        const lastIndex = key.lastIndexOf('.')
        const parentRel = key.substring(0, lastIndex)
        const dependentRelWithModifier = key.substring(
            lastIndex + 1,
            key.length
        )
        const dependentRelIndex = dependentRelWithModifier.lastIndexOf(':')
        const dependentRel = dependentRelWithModifier.substring(
            0,
            dependentRelIndex
        )

        if (includeElementsMap.get(parentRel)) {
            let parentIncludeElement = includeElementsMap.get(parentRel)
            let found = parentIncludeElement.bra.find((e) => e === dependentRel)
            if (isNilOrEmpty(found)) {
                parentIncludeElement.bra.push(dependentRel)
            }
            includeElementsMap.set(parentRel, parentIncludeElement)
        } else {
            let parentElement = {
                bda: [],
                bra: [],
            }
            parentElement.bra.push(dependentRel)
            includeElementsMap.set(parentRel, parentElement)
        }
    }
}

const mergeObject = (to, from) => {
    for (const n in from) {
        if (typeof to[n] != 'object') {
            to[n] = from[n]
        } else if (typeof from[n] == 'object') {
            if (Array.isArray(from[n]) && Array.isArray(to[n])) {
                const concat = from[n].concat(to[n])
                to[n] = [...new Set(concat)]
            } else {
                to[n] = mergeObject(to[n], from[n])
            }
        }
    }
    return to
}

const getAllIncludeParamForFields = (metaPaths) => {
    const relationsWithFields = metaPaths.filter((o) => o.indexOf('.') > 0)
    const relations = relationsWithFields
        .map((o) => {
            const lastIndex = o.lastIndexOf('.')
            const rel = o.substring(0, lastIndex)
            return rel
        })
        .sort()

    let out = relations
    relations.forEach((rel) => {
        let relation = ''
        let split = rel.split('.')
        let first = true
        split.forEach((r) => {
            relation = first ? r : relation + '.' + r
            first = false
            const found = out.find((e) => e === relation)
            if (isNilOrEmpty(found)) {
                out.push(relation)
            }
        })
    })

    return [...new Set(out)]
}

const getValue2 = (sourceObject, metadata, path) => {
    let pathSplit = path.split('.')
    const resource = sourceObject.resource
    const include = sourceObject.include
    const attributes = metadata.attributes

    if (typeof path !== 'string') return undefined

    if (pathSplit.length == 1) {
        return getObjectKey(resource, path)
    }

    let currentObject
    let targetObject

    if (!currentObject) targetObject = resource

    for (let index = 0; index < pathSplit.length; index++) {
        const aPath = pathSplit[index]
        const attribute = getAttributeById(attributes, aPath)
        if (attribute.type === 'relation') {
            currentObject = getObjectKey(targetObject, aPath + '/0')
        } else if (attribute.type === 'data') {
            if (typeof currentObject !== 'string') {
                return getObjectKey(currentObject, aPath)
            } else {
            }
        }
    }

    return ''
}

const getValue3 = (sourceObject, metadata, path) => {
    let pathSplit = path.split('.')
    const resource = sourceObject.resource
    const include = sourceObject.include

    if (typeof path !== 'string') return undefined

    if (pathSplit.length == 1) {
        return getObjectKey(resource, path)
    }

    return ''
}

const getDefaultBdaBraForProductCard = () => {
    let out = {}

    let aDefaultIncludes = [
        'hasUnitofMeasure:UnitofMeasurement',
        'definesAnnotation',
    ]

    let aElements = {
        bda: ['objectName', 'label', 'objectERPId', 'productClassified'],
        bra: [
            'hasUnitofMeasure',
            'definesAnnotation',
            'containsKnowledgeBase',
            'hasProductClassification',
            'includesKMAT'
        ],
    }

    let aIncludeElements = {
        'hasUnitofMeasure:UnitofMeasurement': {
            bda: ['label', 'objectName', 'objectId', 'objectERPId'],
            bra: [''],
        },
    }

    out.include = aDefaultIncludes
    out.elements = aElements
    out.includeElements = aIncludeElements

    return out
}

const getDefaultBdaBraForItem = () => {
    let out = {}

    let aDefaultIncludes = [
        'hasUnitofMeasure:UnitofMeasurement',
        'definesAnnotation',
    ]

    let aElements = {
        bda: ['objectName', 'label', 'objectERPId'],
        bra: [
            'hasUnitofMeasure',
            'definesAnnotation',
            'containsKnowledgeBase',
            'hasProductClassification',
        ],
    }

    let aIncludeElements = {
        'hasUnitofMeasure:UnitofMeasurement': {
            bda: ['label', 'objectName', 'objectId', 'objectERPId'],
            bra: [''],
        },
    }

    out.include = aDefaultIncludes
    out.elements = aElements
    out.includeElements = aIncludeElements

    return out
}

export {
    getSections,
    getSection,
    getFields,
    getValue,
    getSortedConfigs,
    getAllIncludeParamForScreen,
    getAllIncludeParamForSectionsReorder,
    getAllIncludeParamForSections,
    getSectionsForScreens,
    getSectionsByScreenAndIds,
    getAttributeValueType,
    getParsedField,
    getParsedFieldWithOutValue,
    getFormattedValue,
    getAllIncludeParamForFields,
    getAllIncludeElementsForSections,
    mergeObject,
    getDefaultBdaBraForProductCard,
    getDefaultBdaBraForItem,
    getFieldKey,
}
