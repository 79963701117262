import {
    UPDATE_LOGO_SRC,
    UPDATE_NAVIGATION_ITEM,
    UPDATE_LOGIN_VIEW_BANNER_SRC,
    UPDATE_BRAND_NAME_VALUE,
    SET_INITIAL_DATA,
    UPDATE_BDA,
    SET_BRA_VALUE,
    SET_BRAND_LANGUAGE,
    SET_TOP_LEVEL_PROPERTY,
    SET_COMMERCE_SETTINGS,
    UPDATE_FAVICON_SRC,
    SET_COMMERCE_ORDER_AND_INQUIRY_SETTINGS
} from './customisation-action-types'

export function setTopLevelProperty( payload) {
    return {type : SET_TOP_LEVEL_PROPERTY, payload}
}

export function updateLogoSrc(payload) {
    return { type: UPDATE_LOGO_SRC, payload }
}
export function updateLoginViewBannerSrc(payload) {
    return { type: UPDATE_LOGIN_VIEW_BANNER_SRC, payload }
}
export function updateNavigationItem(payload) {
    return { type: UPDATE_NAVIGATION_ITEM, payload }
}

export function updateBrandNameValue(payload) {
    return { type: UPDATE_BRAND_NAME_VALUE, payload }
}

export function setInitialCustomisationData(payload) {
    return { type: SET_INITIAL_DATA, payload }
}

export function updateBDA(payload) {
    return { type: UPDATE_BDA, payload }
}
export function setBRAValue(payload) {
    return { type: SET_BRA_VALUE, payload }
}

export function setBrandLanguage(payload) {
    return { type: SET_BRAND_LANGUAGE, payload }
}

export function setCommerceSettingsData(payload) {
    return { type: SET_COMMERCE_SETTINGS, payload }
}

export function updateFavIconSrc(payload) {
    return { type: UPDATE_FAVICON_SRC, payload }
}

export function setCommerceOrderAndInquirySettings(payload) {
    return { type: SET_COMMERCE_ORDER_AND_INQUIRY_SETTINGS, payload }
}
