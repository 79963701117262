import {SET_ROOT_NODE, ADD_NODE_CHILDREN, SELECT_NODE, SET_CHILDREN_LOADING, TOGGLE_NODE, SET_NODE_IMAGES, SET_CURRENT_ROOT_ID, 
    SET_SELECTED_IMAGE, SET_NODE_BOM, SET_NODE_DOCUMENTS, SET_NODE_SELECTED_BOM, SET_NODE_SELECTED_BOM_DETAIL,
    RESET_ALL_DATA} 
from './spare-part-finder-actions';

const defaultState = {
    currentRootNodeId: null,
    rootNodeData: [],
    selectedNode: [],
    selectedNodeImages: [],
    selectedImageId: [],
    selectedNodeBOM: [],
    selectedNodeDocuments: [],
    nodeSelectedBOM: [],
    nodeSelectedBOMDetail: []
};

const getTreeNode = (nodeTree, nodeId) => {
    let nodeFound = false;
    if (nodeTree.id === nodeId) {
        nodeFound = true;
    } else if (nodeTree.children) {
        for (let i = 0; i < nodeTree.children.length; i++) {
            const result = getTreeNode(nodeTree.children[i], nodeId);
            if (result.nodeFound) return result;
        }
    }
    return {nodeFound, nodeTree};
};

const updateTree = (nodeTree, nodeId, obj, updateChildren) => {
    let nodeFound = false;
    if (nodeTree.id === nodeId) {
        nodeFound = true;
        if (updateChildren) {
            if (nodeTree.children) nodeTree.children = {...nodeTree.children, ...obj};
            else nodeTree.children = obj;
        } else nodeTree = {...nodeTree, ...obj};
    } else if (nodeTree.children) {
        for (let i = 0; i < nodeTree.children.length; i++) {
            const result = updateTree(nodeTree.children[i], nodeId, obj);
            if (result.nodeFound) nodeTree.children[i] = result.nodeTree;
        }
    }
    return {nodeFound, nodeTree};

};

const closeTreeChildren = (nodeTree, actionState) => {
    nodeTree.isOpen = actionState;
    if (!actionState && nodeTree?.children) {
        for (let i = 0; i< nodeTree.children.length; i++) {
            nodeTree.children[i] = closeTreeChildren(nodeTree.children[i], false);
        }
    }
    return nodeTree;
};

export default (state = defaultState, action = {}) => {
    switch(action.type) {
        case SET_CURRENT_ROOT_ID:
            return {...state, currentRootNodeId: action.rootNodeId};
        case SET_ROOT_NODE:
        {
            const {rootNodeId, data} = action;
            const [selectedRootNode] = state.rootNodeData.filter(n => n.id === rootNodeId);
            if (selectedRootNode) {
                const updatedRootNodeData = state.rootNodeData.map(nd => {
                    if (nd.id === rootNodeId) nd = {id: rootNodeId, ...data, isOpen: false};
                    return nd;
                });
                return {...state, rootNodeData: updatedRootNodeData};
            } else {
                return {...state, rootNodeData: [...state.rootNodeData, {id: rootNodeId, ...data, isOpen: false}]};
            }
        }
        case ADD_NODE_CHILDREN:
        {
            const {rootNodeId, nodeId, data} = action;
            const updatedTree = state.rootNodeData.map(nd => {
                if (nd.id === rootNodeId) nd = updateTree(nd, nodeId, {...data}).nodeTree;
                return nd;
            });
            return {...state, rootNodeData: updatedTree};
        }
        case SELECT_NODE:
        {
            const {rootNodeId, nodeId, nodeType, childrenLoaded} = action;
            const [selectedRootNode] = state.selectedNode.filter(n => n.rootNodeId === rootNodeId);
            const updatedRootNodeData = state.rootNodeData.map(nd => {
                if (nd.id === rootNodeId) nd = updateTree(nd, nodeId, {isOpen: true}).nodeTree;
                return nd;
            });
            if (selectedRootNode) {
                const updatedTree = state.selectedNode.map(nd => {
                    if (nd.rootNodeId === rootNodeId) nd = {rootNodeId, nodeId, nodeType, childrenLoaded};
                    return nd;
                });
                return {...state, selectedNode: updatedTree, rootNodeData: updatedRootNodeData};
            } else {
                return {...state, selectedNode: [...state.selectedNode, {rootNodeId, nodeId, nodeType, childrenLoaded}], rootNodeData: updatedRootNodeData};
            }
        }
        case TOGGLE_NODE:
        {
            const {rootNodeId, nodeId, state: actionState} = action;
            const updatedRootNodeData = state.rootNodeData.map(nd => {
                if (nd.id === rootNodeId) {
                    let selectedNodeTree = getTreeNode(nd, nodeId).nodeTree;
                    if (selectedNodeTree) {
                        selectedNodeTree = closeTreeChildren(selectedNodeTree, actionState);
                    }
                }
                return nd;
            });
            return {...state, rootNodeData: updatedRootNodeData};
        }
        case SET_CHILDREN_LOADING:
        {
            const {rootNodeId, nodeId} = action;
            const updatedTree = state.rootNodeData.map(nd => {
                if (nd.id === rootNodeId) nd = updateTree(nd, nodeId, {childrenLoaded: false}).nodeTree;
                return nd;
            });
            return {...state, rootNodeData: updatedTree};
        }
        case SET_NODE_IMAGES:
        {
            const {rootNodeId, images} = action;
            const [selectedRootNode] = state.selectedNodeImages.filter(n => n.rootNodeId === rootNodeId);
            if (selectedRootNode) {
                const updatedSelectedNodeImages = state.selectedNodeImages.map(nd => {
                    if (nd.rootNodeId === rootNodeId) nd = {...nd, images};
                    return nd;
                });
                return {...state, selectedNodeImages: updatedSelectedNodeImages};
            } else {
                return {...state, selectedNodeImages: [...state.selectedNodeImages, {rootNodeId, images}]};
            }
        }
        case SET_SELECTED_IMAGE:
        {
            const {rootNodeId, imageId} = action;
            const [selectedRootNode] = state.selectedImageId.filter(n => n.rootNodeId === rootNodeId);
            if (selectedRootNode) {
                const updatedSelectedImageIds = state.selectedImageId.map(nd => {
                    if (nd.rootNodeId === rootNodeId) nd = {...nd, imageId};
                    return nd;
                });
                return {...state, selectedImageId: updatedSelectedImageIds};
            } else {
                return {...state, selectedImageId: [...state.selectedImageId, {rootNodeId, imageId}]};
            }
        }
        case SET_NODE_BOM:
        {
            const {rootNodeId, bom} = action;
            const [selectedRootNode] = state.selectedNodeBOM.filter(n => n.rootNodeId === rootNodeId);
            if (selectedRootNode) {
                const updatedNodeBOM = state.selectedNodeBOM.map(nd => {
                    if (nd.rootNodeId === rootNodeId) nd = {...nd, bom};
                    return nd;
                });
                return {...state, selectedNodeBOM: updatedNodeBOM};
            } else {
                return {...state, selectedNodeBOM: [...state.selectedNodeBOM, {rootNodeId, bom}]};
            }
        }
        case SET_NODE_DOCUMENTS:
        {
            const {rootNodeId, equipmentDocuments, productDocuments} = action;
            const [selectedRootNode] = state.selectedNodeDocuments.filter(n => n.rootNodeId === rootNodeId);
            if (selectedRootNode) {
                const updatedNodeDocuments = state.selectedNodeDocuments.map(nd => {
                    if (nd.rootNodeId === rootNodeId) nd = {...nd, ...{equipmentDocuments, productDocuments}};
                    return nd;
                });
                return {...state, selectedNodeDocuments: updatedNodeDocuments};
            } else {
                return {...state, selectedNodeDocuments: [...state.selectedNodeDocuments, {rootNodeId, equipmentDocuments, productDocuments}]};
            }
        }
        case SET_NODE_SELECTED_BOM:
        {
            const {rootNodeId, data} = action;
            const [selectedRootNode] = state.nodeSelectedBOM.filter(n => n.rootNodeId === rootNodeId);
            if (selectedRootNode) {
                const updatedNodeSelectedBOM = state.nodeSelectedBOM.map(nd => {
                    if (nd.rootNodeId === rootNodeId) nd = {...nd, ...data};
                    return nd;
                });
                return {...state, nodeSelectedBOM: updatedNodeSelectedBOM};
            } else {
                return {...state, nodeSelectedBOM: [...state.nodeSelectedBOM, {rootNodeId, ...data}]};
            }
        }
        case SET_NODE_SELECTED_BOM_DETAIL:
        {
            const {rootNodeId, data, prices} = action;
            const [selectedRootNode] = state.nodeSelectedBOMDetail.filter(n => n.rootNodeId === rootNodeId);
            if (selectedRootNode) {
                const updatedNodeSelectedBOM = state.nodeSelectedBOMDetail.map(nd => {
                    if (nd.rootNodeId === rootNodeId) nd = {...nd, data, prices};
                    return nd;
                });
                return {...state, nodeSelectedBOMDetail: updatedNodeSelectedBOM};
            } else {
                return {...state, nodeSelectedBOMDetail: [...state.nodeSelectedBOMDetail, {rootNodeId, data, prices}]};
            }
        }
        case RESET_ALL_DATA:
        {
            const {rootNodeId} = action;
            const updatedRootNodeData = state.rootNodeData.filter(n => n.id !== rootNodeId);
            const updatedSelectedNode = state.selectedNode.filter(n => n.rootNodeId !== rootNodeId);
            const updatedNodeImages = state.selectedNodeImages.filter(n => n.rootNodeId !== rootNodeId);
            const updatedSelectedImageId = state.selectedImageId.filter(n => n.rootNodeId !== rootNodeId);
            const updatedSelectedNodeBOM = state.selectedNodeBOM.filter(n => n.rootNodeId !== rootNodeId);
            const updatedNodeDocuments = state.selectedNodeDocuments.filter(n => n.rootNodeId !== rootNodeId);
            const updatedNodeSelectedBOM = state.nodeSelectedBOM.filter(n => n.rootNodeId !== rootNodeId);
            const updatedNodeSelectedBOMDocs = state.nodeSelectedBOMDetail.filter(n => n.rootNodeId !== rootNodeId);
            return {...state, currentRootNodeId: null, rootNodeData: updatedRootNodeData, selectedNode: updatedSelectedNode,
                selectedNodeImages: updatedNodeImages, selectedImageId: updatedSelectedImageId, selectedNodeBOM: updatedSelectedNodeBOM,
                selectedNodeDocuments: updatedNodeDocuments, nodeSelectedBOM: updatedNodeSelectedBOM, nodeSelectedBOMDetail: updatedNodeSelectedBOMDocs
            };
        }
        default:
            return state;
    }
};