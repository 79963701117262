import {
    UPDATE_USER_PROFILE,
    INITIAL_USER_PROFILE,
    UPDATE_USER_SESSION,
    LOGOUT_USER_SESSION,
    LOGIN_USER_SESSION,
    UPDATE_USER_ADDRESS,
    SET_APP_INIT_DATA_LOADED,
    SET_USER_BO,
    SET_USER_ACCOUNT_PAYMENT_MODE,
    SET_IS_SSO_ENABLED,
    SET_SSO_CONFIG
} from './user-actions'

export function setInitialUserProfile(payload) {
    return { type: INITIAL_USER_PROFILE, payload }
}
export function updateUserProfile(payload) {
    return { type: UPDATE_USER_PROFILE, payload }
}

export function updateUserSession(payload) {
    return { type: UPDATE_USER_SESSION, payload }
}

export function updateUserAddress(payload) {
    return { type: UPDATE_USER_ADDRESS, payload }
}

export function logInUserSession(payload) {
    return { type: LOGIN_USER_SESSION, payload }
}

export function logOutUserSession(payload) {
    return { type: LOGOUT_USER_SESSION, payload }
}

export function setAppInitialDataIsLoaded( payload) {
    return { type : SET_APP_INIT_DATA_LOADED, payload}
}

export function setUserBO( payload) {
    return { type : SET_USER_BO, payload}
}

export function setUserAccountPaymentMode(payload) {
    return { type: SET_USER_ACCOUNT_PAYMENT_MODE, payload};
}

export function setIsSSOEnabled(enabled) {
    return {type: SET_IS_SSO_ENABLED, enabled};
}

export function setSSOConfig(config) {
    return {type: SET_SSO_CONFIG, config};
};