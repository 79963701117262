import { SEND_ORDER_FOR_REVIEW_PERMISSION, SUBMIT_INQUIRY_PERMISSION } from '../../const/const'
import {
    UPDATE_LOGO_SRC,
    UPDATE_LOGIN_VIEW_BANNER_SRC,
    UPDATE_NAVIGATION_ITEM,
    UPDATE_BRAND_NAME_VALUE,
    SET_INITIAL_DATA,
    UPDATE_BDA,
    SET_BRA_VALUE,
    SET_BRAND_LANGUAGE,
    SET_TOP_LEVEL_PROPERTY,
    SET_COMMERCE_SETTINGS,
    UPDATE_FAVICON_SRC,
    SET_COMMERCE_ORDER_AND_INQUIRY_SETTINGS
} from './customisation-action-types'

const initialState = {
    brandLanguages: [],
    documentTitleBrand: 'AfterSales Portal',
    brandName: {
        defaultValue: 'AfterSales Portal',
    },
    favicon: {
        defaultSrc: '/favicon.png',
    },
    logo: {
        src: '',
        defaultSrc: '/logo.png',
    },
    whiteLogo: {
        defaultSrc: '/white-logo.png'
    },
    defaultAvatar: {
        src: '',
    },

    loginViewBanner: {
        src: '',
        defaultSrc: '/login-banner.jpg',
    },
    navigationItems: {
        items: {
            equipments: {
                id: 'equipments',
                i18nValue: 'menu.equipments',
                sequence: 0,
                hidden: false,
                to: '/equipment',
            },
            consumables: {
                id: 'consumables',
                i18nValue: 'menu.consumables',
                sequence: 1,
                hidden: false,
                to: '/consumables',
            },
            spareParts: {
                id: 'spareParts',
                i18nValue: 'menu.spareParts',
                sequence: 2,
                hidden: false,
                to: '/spare-parts',
            },
            installBase: {
                id: 'installBase',
                i18nValue: 'menu.installBase',
                sequence: 3,
                hidden: false,
                to: '/install-base',
            },
        },
        order: ['equipment', 'consumables', 'spareParts', 'installBase'],
    },
    customLinkItems: {
        items: {
            aboutUs: {
                id: 'aboutUs',
                i18nValue: 'menu.aboutUs',
                sequence: 0,
                hidden: false,
                to: 'http://example.com',
            },
            contactUs: {
                id: 'contactUs',
                i18nValue: 'menu.contactUs',
                sequence: 1,
                hidden: false,
                to: 'http://example.com',
            },
            faq: {
                id: 'faq',
                i18nValue: 'menu.faq',
                sequence: 2,
                hidden: false,
                to: 'http://example.com',
            },
        },
        order: ['aboutUs', 'contactUs', 'faq'],
    },
    productCategory: { initialLoaded: null, items: [] },
    productMaterialGroup: { initialLoaded: null, items: [] },
    // socialMediaLinkItems: {
    //     items: {
    //         fb: {
    //             id: 'fb',
    //             sequence: 0,
    //             hidden: false,
    //             to: 'http://example.com',
    //             icon: 'facebook',
    //         },
    //         ig: {
    //             id: 'ig',
    //             sequence: 0,
    //             hidden: false,
    //             to: 'http://example.com',
    //             icon: 'instagram',
    //         },
    //         linkedIn: {
    //             id: 'linkedIn',
    //             sequence: 0,
    //             hidden: false,
    //             to: 'http://example.com',
    //             icon: 'linkedin',
    //         },
    //         pinterest: {
    //             id: 'pinterest',
    //             sequence: 0,
    //             hidden: false,
    //             to: 'http://example.com',
    //             icon: 'pinterest',
    //         },
    //         twitter: {
    //             id: 'twitter',
    //             sequence: 0,
    //             hidden: false,
    //             to: 'http://example.com',
    //             icon: 'twitter',
    //         },
    //     },
    //     order: ['fb', 'ig', 'linkedIn', 'pinterest', 'twitter'],
    // },
    // address: {
    //     full: `MBS Building, 6001 Beach Rd,
    //     #15-10, GOLDEN MILE TOWER
    //      Singapore 199589`,
    // },
    // contactEmail: {
    //     value: 'support@inmindcloud.com',
    //     displayValue: 'support@inmindcloud.com',
    // },
    // contactPhone: {
    //     value: '+6562276985',
    //     displayValue: '+65 6227 6985',
    // },
    // footerAdditionalLinks: {
    //     items: {
    //         aboutUs: {
    //             id: 'aboutUs',
    //             i18nValue: 'footer.additionalLinks.aboutUs',
    //             sequence: 0,
    //             hidden: false,
    //             to: '/aboutus',
    //         },
    //         contactUs: {
    //             id: 'contactUs',
    //             i18nValue: 'footer.additionalLinks.contactUs',
    //             sequence: 0,
    //             hidden: false,
    //             to: '/contactus',
    //         },
    //         faqs: {
    //             id: 'faqs',
    //             i18nValue: 'footer.additionalLinks.faqs',
    //             sequence: 0,
    //             hidden: false,
    //             to: '/faq',
    //         },
    //     },
    //     order: ['aboutUs', 'contactUs', 'faqs'],
    // },
    commerceAddress1: '',
    commerceAddress2: '',
    commerceAddress3: '',
    commerceAddress4: '',
    commercePhoneNumber: '',
    commerceEmailAddress: '',
    commerceCompanyName: '',
    commerceLogoutURL: '',
    commercebannerDisplayDuration: null,
    commerceLandingPageBanner: {
        items: [],
    },
    commerceMediaFile: { items: [] },
    commerceAdditionalLink: { items: [] },
    commerceSocialMediaLink: { items: [] },
    commerceNavigationPage: { items: [] },
    commerceScreenConfiguration: { items: [] },
    commerceTheme: { items: [] },
    commerceProductFilter: { items: [] },
    commerceNavigationPage: { items: [] },
    commerceSettings: {},
    commerceInquiryEnabled: null,
    commerceOrderEnabled: null,
    commerceActiveTheme: null
}

export const customisationReducers = (state = initialState, action) => {
    switch (action.type) {
        case SET_BRAND_LANGUAGE:
            return {
                ...state,
                brandLanguages: action.payload,
            }
        case SET_TOP_LEVEL_PROPERTY:
            return {
                ...state,
                [action.payload.key]: action.payload.value

            }
        case UPDATE_BDA:
            var newUpdatedPayload = {
                ...state,
            }

            for (let bdaKey in action.payload) {
                newUpdatedPayload[bdaKey] = {
                    ...state[bdaKey],
                    value: action.payload[bdaKey].value,
                }
            }

            return newUpdatedPayload
        case SET_BRA_VALUE:
            var parentType = action.payload.parentType
            var value = action.payload.value

            var newUpdatedPayload = {
                ...state,
                portalSettingData: {
                    ...state,
                    [parentType]: {
                        ...state[parentType],
                        value: value,
                    },
                },
            }
            return newUpdatedPayload

        case UPDATE_LOGO_SRC:
            return {
                ...state,
                logo: {
                    ...state.logo,
                    src: action.payload,
                },
            }
        case UPDATE_BRAND_NAME_VALUE:
            return {
                ...state,
                brandName: {
                    ...state.brandName,
                    value: action.payload,
                },
            }

        case UPDATE_LOGIN_VIEW_BANNER_SRC:
            return {
                ...state,
                loginViewBanner: {
                    ...state.loginViewBanner,
                    src: action.payload,
                },
            }
        case UPDATE_NAVIGATION_ITEM:
            //TODO
            return {
                ...state,
                navigationItems: {
                    ...state.navigationItems,
                    items: {
                        ...state.navigationItems.items,
                        ['equipments']: {
                            ...state.navigationItems.items['equipments'],
                            hidden: action.payload,
                        },
                    },
                },
            }
        case SET_COMMERCE_SETTINGS:
            return {
                ...state,
                commerceSettings: action.payload,
                commerceTheme: {
                    ...state.commerceTheme,
                    items: action.payload.includesPortalTheme,
                },
                commerceMediaFile: {
                    ...state.commerceMediaFile,
                    items: action.payload.includesPortalMediaFile,
                },
                commercebannerDisplayDuration: action.payload.portalbannerDisplayDuration,
                commerceLandingPageBanner: {
                    ...state.commerceLandingPageBanner,
                    items: action.payload.includesPortalLandingPageBanner,
                },
                commerceAdditionalLink: {
                    ...state.commerceAdditionalLink,
                    items: action.payload.includesPortalAdditionalLink,
                },
                commerceSocialMediaLink: {
                    ...state.commerceSocialMediaLink,
                    items: action.payload.includesPortalSocialMediaLink,
                },
                commerceNavigationPage: {
                    ...state.commerceNavigationPage,
                    items: action.payload.includesPortalNavigationPage,
                },
                commerceScreenConfiguration: {
                    ...state.commerceNavigationPage,
                    items: action.payload.includesPortalScreenConfiguration,
                },
                commerceProductFilter: {
                    ...state.commerceProductFilter,
                    items: action.payload.includesPortalProductFilter
                },
                commerceCompanyName: action.payload.portalCompanyName,
                commerceLogoutURL: action.payload.portalLogoutURL,
                commerceEmailAddress: action.payload.portalEmailAddress,
                commercePhoneNumber: action.payload.portalPhoneNumber,
                commerceAddress1: action.payload.portalAddress1,
                commerceAddress2: action.payload.portalAddress2,
                commerceAddress3: action.payload.portalAddress3,
                commerceAddress4: action.payload.portalAddress4,
                commerceActiveTheme: action.payload.includesPortalTheme ? action.payload.includesPortalTheme.find(th => th.isDefaultPortalTheme === true) : null
            }
        case UPDATE_FAVICON_SRC:
            return {
                ...state,
                favicon: {
                    defaultSrc: action.payload,
                },
            }
        case SET_COMMERCE_ORDER_AND_INQUIRY_SETTINGS:
            const orderInquirySettings = action.payload
            return {
                ...state,
                commerceInquiryEnabled: orderInquirySettings.settingCommerceInquiryEnabled,
                commerceOrderEnabled: orderInquirySettings.settingCommerceOrderEnabled
            }
        default:
            return state
    }
}
