import { api } from './ajax'
import { isNilOrEmpty } from '../helpers'
import { getAllIncludeElementsForSections, getAllIncludeParamForSections, getSectionsForScreens, INQUIRY_HISTORY_OVERVIEW, mergeObject, PRODUCT_CARD_TILE_SCREEN_CONFIG } from '../helpers/screen-configuration-helper'
import { AuthenticationService } from './authentication-service'

export const QuoteService = {
    getCount(filter) {
        return api.get(`/quotes/count`, {
            params: {
                filter: filter,
            },
        }, { trackThisPromise: false })
    },

    createQuote(productBO, currencyBO, accountBO, simulationMode) {
        return api.post('/quotes', {
            params: {
                simulationMode,
            },
            data: {
                type: 'Quote',
                hasCurrency: currencyBO,
                quoteContainedBy: accountBO,
                includesSalesItem: productBO,
            },
        })
    },

    deleteQuote(quoteBO) {
        return api.delete(`/quotes/${btoa(quoteBO)}`)
    },

    getQuote(quoteBO, include) {
        let data = {
            filter: `uri eq ${quoteBO}`,
        }

        if (!isNilOrEmpty(include)) data['include'] = include

        return api.post('quotes/search', {
            data,
        })
    },

    saveQuote(quoteId) {
        return api.put(`quotes/${quoteId}/save`, {
            data: {},
        })
    },

    triggerGroovyScriptOnConfigurationExitsquoteBO(sQuoteBO, sSalesItemBO) {
        return api.post(
            `/quotes/${btoa(sQuoteBO)}/triggerGroovyScriptOnConfigurationExits`,
            {
                params: {
                    salesItemId: btoa(sSalesItemBO),
                },
            }
        )
    },

    updateSalesItemDescription(quoteBO, salesItemBO, description) {
        return api.post('/salesItem/update', {
            data: {
                instance: {
                    id: salesItemBO,
                },
                type: {
                    id:
                        'http://www.inmindcloud.com/application/schema.owl#SalesItem',
                },
                attributes: [
                    {
                        attribute: {
                            id:
                                'http://www.inmindcloud.com/application/schema.owl#salesItemDescription',
                        },
                        type: 'data',
                        valuetype: 'string',
                        value: [description],
                    },
                ],
            },
            params: {
                quoteId: quoteBO,
            },
        })
    },

    updateSalesBOM(quoteBO) {
        return api.post('/salesItem/updateSalesBOM', {
            params: {
                quoteId: quoteBO,
            },
        })
    },

    getQuoteInquiryHistory(aIncludes, filters, search) {
        const sections = getSectionsForScreens([INQUIRY_HISTORY_OVERVIEW])
        let dynamicIncludeParams = getAllIncludeParamForSections(sections)

        if (!isNilOrEmpty(aIncludes))
            dynamicIncludeParams = dynamicIncludeParams.concat(aIncludes)

        let uniqueIncludeParams = [...new Set(dynamicIncludeParams)]

        let data = {
            type: 'search',
            include: uniqueIncludeParams,
            sort: ['objectDateOfCreation DESC'],
        }

        if (!isNilOrEmpty(filters)) data['filter'] = filters

        if (!isNilOrEmpty(search)) data['search'] = search

        return api.post('/quotes/search', {
            data,
        })
    },

    getInquiriesHistory(aIncludes, filters, search, aElements, aIncludeElements, aSections, aLimit, aOffset) {

        let finalIncludesParams = []
        let finalIncludElementsParam = {}
        let finalElementsParam = {}

        let dynamicIncludeParams = getAllIncludeParamForSections([aSections])
        let dynamicIncludeElements = getAllIncludeElementsForSections([aSections])

        if (!isNilOrEmpty(aIncludes)) {
            dynamicIncludeParams = dynamicIncludeParams.concat(aIncludes)
        }
        finalIncludesParams = [...new Set(dynamicIncludeParams)]

        if (!isNilOrEmpty(aIncludeElements)) {
            finalIncludElementsParam = mergeObject(dynamicIncludeElements.includeElements, aIncludeElements)
        }

        if (!isNilOrEmpty(aElements)) {
            finalElementsParam = mergeObject(dynamicIncludeElements.elements, aElements)
        }

        let data = {
            type: 'search',
            include: finalIncludesParams,
            sort: ['objectDateOfCreation DESC'],
            elements: finalElementsParam,
            includeElements: aIncludeElements,
            limit: aLimit,
            offset: aOffset
        }

        if (!isNilOrEmpty(filters)) data['filter'] = filters

        if (!isNilOrEmpty(search)) data['search'] = search

        return api.post('/quotes/search', {
            data,
        })
    },

    getInquiryCreators(filter) {
        let data = {
            type: 'search',
            filter: filter,
            include: ['containsCreator'],
            sort: ['objectDateOfCreation DESC'],
            elements: {
                bda: [''],
                bra: ['containsCreator']
            }
            // ,includeElements: {
            //     containsCreator: {
            //         bda: ['personFirstName', 'personLastName', 'objectName'],
            //         bra: ['']
            //     }
            // }
        }

        return api.post(
            '/quotes/search',
            {
                data,
            },
            {
                headers: {
                    Authorization: AuthenticationService.getLocalStorageOAuthTokenValue(
                        'oauthToken'
                    ),
                },
                trackThisPromise: true,
            }
        )
    },

    getInquiryById(id, forScreens) {
        const sections = getSectionsForScreens(forScreens)
        let dynamicIncludeParams = getAllIncludeParamForSections(sections)
        dynamicIncludeParams = dynamicIncludeParams.map(
            (p) => 'includesSalesItem.' + p
        )

        let aDefaultIncludes = [
            'containsCreator',
            'includesSalesItem',
            'includesSalesItem.definesAnnotation',
            'includesSalesItem.includesItemHeaderPriceItem',
            'includesSalesItem.isProduct:Product',
            'includesSalesItem.isProduct:Product.definesAnnotation',
            'includesSalesItem.includesSalesItem',
            'includesSalesItem.includesSalesItem.includesItemHeaderPriceItem',
            'includesSalesItem.includesSalesItem.isProduct:Product',
            'includesSalesItem.includesSalesItem.isProduct:Product.definesAnnotation',
            'includesSalesItem.includesSalesItem.isProduct:Product.hasUnitofMeasure:UnitofMeasurement'
        ]

        dynamicIncludeParams = dynamicIncludeParams.concat(aDefaultIncludes)
        let uniqueIncludeParams = [...new Set(dynamicIncludeParams)]

        let data = {
            type: 'search',
            filter: 'id eq ' + id + ' AND isCommerceQuote eq true',
            include: uniqueIncludeParams,
            sort: ['objectDateOfCreation DESC'],
        }

        return api.post(
            '/quotes/search',
            {
                data,
            }
        )
    },

    getInquiredProducts(runInBackground) {
        let trackThisPromise = isNilOrEmpty(runInBackground) ? true : !runInBackground
        const forScreens = [PRODUCT_CARD_TILE_SCREEN_CONFIG]
        const sections = getSectionsForScreens(forScreens)
        let dynamicIncludeParams = getAllIncludeParamForSections(sections)
        dynamicIncludeParams = dynamicIncludeParams.map(p => 'includesSalesItem.isProduct:Product.' + p)

        let aDefaultIncludes = [
            'includesSalesItem',
            'includesSalesItem.isProduct:Product',
            'includesSalesItem.isProduct:Product.hasUnitofMeasure:UnitofMeasurement',
            'includesSalesItem.isProduct:Product.definesAnnotation',
        ]

        dynamicIncludeParams = dynamicIncludeParams.concat(aDefaultIncludes)
        let uniqueIncludeParams = [...new Set(dynamicIncludeParams)];

        let data = {
            type: 'search',
            filter: 'isCommerceQuote eq true',
            include: uniqueIncludeParams,
            sort: ['objectDateOfCreation DESC'],
        }

        return api.post(
            '/quotes/search',
            {
                data,
            },
            {
                headers: {
                    Authorization: AuthenticationService.getLocalStorageOAuthTokenValue(
                        'oauthToken'
                    ),
                },
                trackThisPromise: trackThisPromise,
            }
        )
    },

    resetAttributes(quoteId, configItemId) {
        if (!isNilOrEmpty(configItemId)) configItemId = btoa(configItemId)

        return api.post(`quotes/${quoteId}/configItem/${configItemId}/reset`, {
            data: {},
            params: {
                simulationMode: false,
            },
        })
    },

    downloadInquiry(quoteBO, isCommerce) {
        return api.post('/quotes/quote/defaultReport', {
            responseType: "blob" ,
            headers: {
                'Content-Type': 'application/pdf',
              },
            params: {
                quoteId: quoteBO.uri,
                isCommerce: isCommerce,
            },
        })
    },
}
