import { isNilOrEmpty } from '.'

export const createUserData = (formData, accountExist) => {
    const registerObj = { type: 'Bundle', total: 1, entry: [] }

    const contactObj = {
        type: 'Contact',
    }

    const addressObj = {
        type: 'Address',
        addressShipTo: true,
        addressBillTo: true,
    }

    const accountObj = {
        type: 'Account',
        accountProspect: true,
    }

    for (const [key, value] of Object.entries(formData)) {
        const formKeys = key.split('-')
        if (
            !isNilOrEmpty(formKeys) &&
            Array.isArray(formKeys) &&
            formKeys.length > 1
        ) {
            const dataType = formKeys[0]
            const path = formKeys[1]
            let val = null
            if (
                dataType === 'Address' &&
                (path === 'hasCountry' || path === 'hasCountryRegion')
            ) {
                val = value?.value?.uri
            } else {
                val = value?.value
            }

            switch (dataType) {
                case 'Contact':
                    contactObj[path] = val
                    break
                case 'Address':
                    addressObj[path] = val
                    break
                case 'Account':
                    accountObj[path] = val
                    break
                default:
            }
        }
    }
    contactObj['includesAddress'] = [addressObj]
    registerObj.entry.push(contactObj)

    if (!accountExist) {
        accountObj['includesAddress'] = [addressObj]
        registerObj.total = 2
        registerObj.entry.push(accountObj)
    }
    return registerObj
}
