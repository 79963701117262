import {
    imageSrc,
    getObjectKey,
    getObjectBoolean,
    isNilOrEmpty,
} from './helpers'
import { CustomisationService, ProductService } from '../services'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import reduxStore from '../store'
import { SUB_ENTITY_TYPE_PRODUCT_CATEGORY, SUB_ENTITY_TYPE_PRODUCT_MATERIAL_GROUP } from '../views/administration/util'
import { PRODUCT_CLASSIFICATION_PRODUCT } from '../views/administration/util'
import { formatNumber } from './number-helper'

const PRODUCT_CLASSIFICATION_TO_ROUTE_PARAM_MAPPING = {
    'http://www.inmindcloud.com/application/schema.owl#CONSUMABLE': {
        uri: 'http://www.inmindcloud.com/application/schema.owl#CONSUMABLE',
        routeParam: 'consumables',
    },
    'http://www.inmindcloud.com/application/schema.owl#SPARE_PART': {
        uri: 'http://www.inmindcloud.com/application/schema.owl#SPARE_PART',
        routeParam: 'spare-parts',
    },
    'http://www.inmindcloud.com/application/schema.owl#PRODUCT': {
        uri: 'http://www.inmindcloud.com/application/schema.owl#PRODUCT',
        routeParam: 'products',
    },
}

const useGetSalesInfoCurrencyData = () => {
    const salesInfoCurrencyData = useSelector((state) => {
        return state.userReducers.user.salesInfo.currencyData
    })

    return salesInfoCurrencyData
}

const getTrimmed = (img) => {
    if (img.startsWith('Product/')) return img.replace('Product/', '')
    else if (img.startsWith('/images/Product/')) return img.replace('/images/Product/', '')
    else return img
}

const getImageURL = (img) => {
    let imgName = ''
    if (img) {
        if (Array.isArray(img)) {
            if (img.length > 0) imgName = img[0]
        } else {
            imgName = img
        }
    }
    return imageSrc(getTrimmed(imgName))
}

const getMainProductImage = (product) => {
    let productImage = ''
    const annotations = getObjectKey(product, 'definesAnnotation')

    if (!isNilOrEmpty(annotations)) {

        annotations.sort((current, next) => {
            if (current.productImagePosition > next.productImagePosition)
                return 1
            else if (current.productImagePosition < next.productImagePosition)
                return -1

            return 0
        })

        let firstProductImage = ''
        annotations.forEach((attach) => {
            const attachmentMain = isNilOrEmpty(attach.attachmentMain) ? false : attach.attachmentMain
            const attachmentInvisibleToPortal =
                isNilOrEmpty(attach.attachmentInvisibleToPortal) ? false : attach.attachmentInvisibleToPortal
            const hasAttachmentUsage = getObjectKey(
                attach,
                'hasAttachmentUsage'
            )
            if (
                isNilOrEmpty(firstProductImage) &&
                !attachmentInvisibleToPortal &&
                !isNilOrEmpty(hasAttachmentUsage) &&
                hasAttachmentUsage.indexOf(
                    'http://www.inmindcloud.com/application/schema.owl#PRODUCT_IMAGE'
                ) > -1
            ) {
                firstProductImage = attach.attachmentInternalPath
            }
            if (attachmentMain && !attachmentInvisibleToPortal) {
                productImage = attach.attachmentInternalPath
                return
            }
        })
        if (isNilOrEmpty(productImage)) productImage = firstProductImage
    }
    return productImage
}

const productCardDetailFromUserFavourite = (entries, include) => {
    const products = []

    entries.map((p) => {
        if (!Array.isArray(p.objectName)) {
            const classificationType = getObjectKey(p, 'hasProductClassification/0') || ''
            const isConfigurable = isConfigurableProduct(p) //getObjectBoolean(p, 'productConfigurable'),
            const interactiveImage = hasInteractiveImage(p, classificationType)
            const canInquire = canAddToInquiry(isConfigurable)
            const canConfigureVariant = canConfigureVariantProduct(p)
            const includesKMAT = getObjectKey(p, 'includesKMAT/0') || ''
            const complementary = getObjectKey(p, 'complementary') || [];

            const product = {
                name: p.objectName,
                label: p.label,
                erpId: p.objectERPId,
                id: p.id,
                uri: p.uri,
                price1: '0',
                price2: '0',
                discount: '0',
                currency: '',
                uom: '',
                imageSrc: getImageURL(getMainProductImage(p)),
                productConfigurable: isConfigurable,
                hasInteractiveImage: interactiveImage,
                canAddToInquiry: canInquire,
                canAddToShoppingCart: !isConfigurable,
                canConfigureVariant: canConfigureVariant,
                includesKMAT: includesKMAT,
                type: 'consumables',
                favourite: false,
                productId: p.id,
                productUri: p.uri,
                productClassificationUri: classificationType,
                hasUnitofMeasure: getUoM(include, p) || '',
                _uiNoPricingInformation: false,
                sourceObject: {
                    resource: p,
                    include: include,
                },
                complementary
            }
            products.push(ProductService.createProductModelTemplate(product))
        }
    })

    return products
}

const productCardDetailFromProduct = (include) => {
    const products = []

    include.map((p) => {
        if (!isNilOrEmpty(p.type) && p.type === 'Product') {
            products.push(getProductModelTemplate(p, include))
        }
    })

    return products
}

const productCardDetailBundle = (bundle) => {
    const products = []
    const entry = bundle.entry
    const include = bundle.include

    entry.map((p) => {
        if (!isNilOrEmpty(p.type) && p.type === 'Product') {
            products.push(getProductModelTemplate(p, include))
        }
    })

    return products
}

const getProductModelTemplate = (productResource, include = []) => {
    const classificationType = getObjectKey(productResource, 'hasProductClassification/0') || ''
    const isConfigurable = isConfigurableProduct(productResource) //getObjectBoolean(p, 'productConfigurable'),
    const interactiveImage = hasInteractiveImage(productResource, classificationType)
    const canInquire = canAddToInquiry(isConfigurable)
    const canConfigureVariant = canConfigureVariantProduct(productResource)
    const includesKMAT = getObjectKey(productResource, 'includesKMAT/0') || ''

    const product = {
        name: productResource.objectName,
        label: productResource.label,
        erpId: productResource.objectERPId,
        id: productResource.id,
        uri: productResource.uri,
        price1: '0',
        price2: '0',
        discount: '0',
        currency: '',
        uom: '',
        imageSrc: getImageURL(getMainProductImage(productResource)),
        type: 'consumables',
        favourite: false,
        productId: productResource.id,
        productUri: productResource.uri,
        productClassificationUri: classificationType,
        productConfigurable: isConfigurable,
        hasInteractiveImage: interactiveImage,
        canAddToInquiry: canInquire,
        canAddToShoppingCart: !isConfigurable,
        canConfigureVariant: canConfigureVariant,
        includesKMAT: includesKMAT,
        hasUnitofMeasure: getUoM(include, productResource) || '',
        sourceObject: {
            resource: productResource,
            include: include,
        },
    }
    return ProductService.createProductModelTemplate(product)
}

const getUoM = (include, product) => {
    let [hasUnitofMeasure] = include.filter((o) => {
        if (
            o.type === 'UnitofMeasurement' &&
            o.uri === getObjectKey(product, 'hasUnitofMeasure/0')
        ) {
            return o
        }
    })

    if (hasUnitofMeasure) {
        return hasUnitofMeasure
    } else {
        return undefined
    }
}

const getProductDetailPath = ({
    productClassificationUri = '',
    productId = '',
}) => {
    let classification =
        PRODUCT_CLASSIFICATION_TO_ROUTE_PARAM_MAPPING[productClassificationUri]

    if (classification) {
        let classificationParam = classification.routeParam
        return `/${classificationParam}/${productId}`
    }

    return undefined
}

//find the category chain between root and current inverse hasParentCategory. productCategory data is customisationReducers.productCategory
const constructGroupChain = (guidedGroups, currentId, guidedGroupType) => {
    if (currentId === 'ROOT_ENTRY_CATEGORY_ID') {
        let topLevel = []
        if (guidedGroupType === SUB_ENTITY_TYPE_PRODUCT_CATEGORY) {
            topLevel = guidedGroups.items.filter((o) => {
                if (!getObjectKey(o, 'hasParentProductCategory/0')) {
                    return o
                }
            })
        } else if (guidedGroupType === SUB_ENTITY_TYPE_PRODUCT_MATERIAL_GROUP) {
            topLevel = guidedGroups.items.filter((o) => {
                if (!getObjectKey(o, 'hasParentProductMaterialGroup/0')) {
                    return o
                }
            })
        }

        return { accumulator: topLevel, childGroups: [] }
    }

    var accumulator = []
    lookup(currentId, accumulator)

    //last is current. find all direct child category/material group under it
    let [currentSelected] = guidedGroups.items.filter((o) => {
        if (o.id === accumulator[accumulator.length - 1].id) return o
    })

    let allChildURI = []

    if (guidedGroupType === SUB_ENTITY_TYPE_PRODUCT_CATEGORY) {
        allChildURI =
            getObjectKey(currentSelected, 'hasChildProductCategory') || []
    } else if (guidedGroupType === SUB_ENTITY_TYPE_PRODUCT_MATERIAL_GROUP) {
        allChildURI =
            getObjectKey(currentSelected, 'hasParentProductMaterialGroup') || []
    }

    let allChildObject = guidedGroups.items.filter((o) => {
        if (allChildURI.indexOf(o.uri) > -1) {
            return o
        }
    })

    return { accumulator: accumulator, childGroups: allChildObject }

    function lookup(currentId, accu) {
        let [foundCurrent] = guidedGroups.items.filter((o) => {
            if (o.id === currentId) return o
        })
        if (foundCurrent) {
            accu.unshift(foundCurrent)
            let parentId = ''

            if (guidedGroupType === SUB_ENTITY_TYPE_PRODUCT_CATEGORY) {
                parentId = getObjectKey(
                    foundCurrent,
                    'hasParentProductCategory/0'
                )
            } else if (
                guidedGroupType === SUB_ENTITY_TYPE_PRODUCT_MATERIAL_GROUP
            ) {
                parentId = getObjectKey(
                    foundCurrent,
                    'hasParentProductMaterialGroup/0'
                )
            }

            lookup(btoa(parentId), accu)
        }
    }
}

const getPricingUOM = uom => uom ? (uom.label || uom.objectERPID || uom.objectName || '') : '';

const findAndMergePricingData = (productData = [], priceDtoData = []) => {
    let state = reduxStore.getState()
    let salesInfoCurrencyData = state.userReducers.user.salesInfo.currencyData
    let currencyDisplay = getObjectKey(
        salesInfoCurrencyData,
        'objectERPId/value'
    )
    const currencyBO = getObjectKey(
        salesInfoCurrencyData,
        'parentInformation/id'
    )

    productData.map((product) => {
        let [found] = priceDtoData.filter((priceDto) => {
            if (priceDto.linksProduct === product.uri) return product
        })

        if (found) {
            product.price1 = formatNumber(found.listPrice.toString())
            product.price2 = formatNumber(found.netPrice.toString())
            product.discount = formatNumber(found.discount.toString())
            product.currency = currencyDisplay
            product.currencyBO = currencyBO
            product.pricingDone = true
            product.priceUOM = getPricingUOM(found.unitOfMeasurement)
        } else {
            product._uiNoPricingInformation = true
        }
    })
    return productData;
}

const mergePricingData = (productData, priceData) => {
    productData.price1 = formatNumber(priceData.listPrice.toString())
    productData.price2 = formatNumber(priceData.netPrice.toString())
    productData.discount = formatNumber(priceData.discount.toString())
    productData.currency = priceData.currencyDisplay
    productData.currencyBO = priceData.currencyBO
    productData.pricingDone = true
    productData.priceUOM = getPricingUOM(priceData.unitOfMeasurement);
    return productData
}

const displayERPId = (rawERPId = '') => {
    if (rawERPId && typeof rawERPId === 'string') {
        let formatted = ''
        let split = rawERPId.split('')
        let endOfLeadingZero = false
        let leadingZeroRemoved = split.filter((char) => {
            if (endOfLeadingZero) return char

            if (char !== '0') {
                endOfLeadingZero = true
                return char
            }
        })
        if (leadingZeroRemoved) {
            formatted = leadingZeroRemoved.join('')
        }

        return formatted
    }

    return ''
}

const isConfigurableProduct = (product) => {
    let isConfigurable = false

    if (
        !isNilOrEmpty(product) &&
        !isNilOrEmpty(product.containsKnowledgeBase)
    ) {
        isConfigurable = true
    }

    return isConfigurable
}

const canConfigureVariantProduct = (product) => {
    let isVariant = false

    const commerceInquiryEnabled = CustomisationService.getCommerceInquirySetting()

    if (
        !isNilOrEmpty(product) && product.productClassified &&
        !isNilOrEmpty(product.includesKMAT)
    ) {
        isVariant = true
    }

    return isVariant && commerceInquiryEnabled
}

const hasInteractiveImage = (product, classificationType) => {
    let out = false

    if (classificationType === PRODUCT_CLASSIFICATION_PRODUCT) {
        if (!isNilOrEmpty(product.definesAnnotation)) {
            product.definesAnnotation.map((d) => {
                let bHasAttachmentUsageIsArray = Array.isArray(getObjectKey(d, 'hasAttachmentUsage'))
                let sHasAttachmentUsage = (bHasAttachmentUsageIsArray) ? getObjectKey(d, 'hasAttachmentUsage/0') : getObjectKey(d, 'hasAttachmentUsage')
                if (
                    !isNilOrEmpty(getObjectKey(d, 'interactiveImageData')) ||
                    (!isNilOrEmpty(sHasAttachmentUsage) && sHasAttachmentUsage === 'http://www.inmindcloud.com/application/schema.owl#THREEKIT_PRODUCT_FINDER')
                ) {
                    out = true
                }
            })
        }
    }

    return out
}

const canAddToInquiry = (isConfigurableProduct) => {
    let out = false

    const commerceInquiryEnabled = CustomisationService.getCommerceInquirySetting()

    if (isConfigurableProduct && commerceInquiryEnabled) {
        out = true
    }

    return out
}

const sortByExt = (files) => {
    let arr = []
    let sortedFiles = files.sort((current, next) => {
        const curr = getObjectKey(current, 'objectName').split(/[\.]/g)
        const currExt = curr[curr.length - 1]
        const currName = curr[0]

        const nextArr = getObjectKey(next, 'objectName').split(/[\.]/g)
        const nextExt = nextArr[nextArr.length - 1]
        const nextName = nextArr[0]

        if (currExt > nextExt) return 1
        else if (currExt < nextExt) return -1
        else {
            if (currName > nextName) return 1
            else if (currName < nextName) return -1
            else return 0
        }
    })

    return sortedFiles
}

export {
    useGetSalesInfoCurrencyData,
    productCardDetailFromUserFavourite,
    productCardDetailFromProduct,
    getProductModelTemplate,
    getImageURL,
    getMainProductImage,
    PRODUCT_CLASSIFICATION_TO_ROUTE_PARAM_MAPPING,
    getProductDetailPath,
    constructGroupChain,
    findAndMergePricingData,
    mergePricingData,
    displayERPId,
    isConfigurableProduct,
    hasInteractiveImage,
    canAddToInquiry,
    getTrimmed,
    productCardDetailBundle,
    canConfigureVariantProduct,
    sortByExt,
    getPricingUOM
}
