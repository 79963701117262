import { isNilOrEmpty } from '../helpers'
import { getValue } from '../helpers/screen-configuration-helper'
import { api } from './ajax'
import { UserService } from './user-service'

export const DestinationService = {
    getAllDestinationConfig(appOption = { trackThisPromise: true }) {
        return api.get(`/destinationservice/destination`, {}, appOption)
    },

    getAllDestinationConfigForThreeKit(appOption = { trackThisPromise: true }) {
        return api.get(
            `/destinationservice/destination/threekit`,
            {},
            appOption
        )
    },

    isAvailable() {
        return api.get(`/destinationservice/destinationservice/isAvailable`)
    },

    getAllDestinationConfigForPaymentGateway(
        appOption = { trackThisPromise: false }
    ) {
        return api.get(`/destinationservice/destination/paymentGateway`, {})
    },

    getUiURL(payload = {}, appOption = { trackThisPromise: true }) {
        return api.post(
            `/destinationservice/execute`,
            { data: payload },
            appOption
        )
    },

    async execute(
        destinationConfigName,
        input = {},
        appOption = { trackThisPromise: true }
    ) {
        return new Promise((resolve, reject) => {
            const payload = {}
            payload.type = 'iFrame'
            payload.destinationName = destinationConfigName
            payload.payload = input
            this.getUiURL(payload, appOption)
                .then((response) => {
                    const result = response.data
                    resolve(result)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    },

    async getPayloadMap(userBO, include = [], paramsMap, userLanguage) {
        return new Promise((resolve, reject) => {
            UserService.getUserContextForIFrame(userBO, include)
                .then((respose) => {
                    if (!isNilOrEmpty(respose.data)) {
                        const bundle = respose.data
                        const entry = bundle.entry.length ? bundle.entry[0] : {}
                        const sourceObject = {
                            resource: entry,
                            include: bundle.include,
                        }

                        let payloadMap = new Map()
                        for (let paramPair of paramsMap) {
                            var [key, param] = paramPair
                            let value = ''
                            if (param === 'logonLanguage') {
                                value = userLanguage.value
                                    ? userLanguage.value
                                    : userLanguage
                            } else {
                                value = getValue(sourceObject, param)
                            }
                            payloadMap.set(key, value)
                        }

                        let payload = {}
                        for (let keyValue of payloadMap) {
                            var [key, value] = keyValue
                            payload = {
                                ...payload,
                                [key]: value,
                            }
                        }
                        resolve(payload)
                    } else {
                        resolve({})
                    }
                })
                .catch((err) => {
                    console.log(err)
                    reject(err)
                })
        })
    },
}
