export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE'
export const INITIAL_USER_PROFILE = 'INITIAL_USER_PROFILE'
export const UPDATE_USER_SESSION = 'UPDATE_USER_SESSION'
export const LOGOUT_USER_SESSION = 'LOGOUT_USER_SESSION'
export const LOGIN_USER_SESSION = 'LOGIN_USER_SESSION'
export const UPDATE_USER_ADDRESS = 'UPDATE_USER_ADDRESS'
export const SET_APP_INIT_DATA_LOADED = 'SET_APP_INIT_DATA_LOADED'
export const SET_USER_BO = 'SET_USER_BO'
export const SET_USER_ACCOUNT_PAYMENT_MODE = 'SET_USER_ACCOUNT_PAYMENT_MODE';
export const SET_IS_SSO_ENABLED = 'SET_IS_SSO_ENABLED';
export const SET_SSO_CONFIG = 'SET_SSO_CONFIG';