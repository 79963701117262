import { api } from './ajax'
import { isNil, isNilOrEmpty } from '../helpers'
import { AuthenticationService } from './authentication-service'
import {
    getAllIncludeParamForSections,
    getAllIncludeParamForSectionsReorder,
    getAllIncludeElementsForSections,
    getSectionsForScreens,
    ORDER_AMENDMENT,
    ORDER_CHECKOUT,
    ORDER_HISTORY_OVERVIEW,
    ORDER_REVIEW,
    ORDER_REORDER,
    PRODUCT_CARD_TILE_SCREEN_CONFIG,
    mergeObject,
    getDefaultBdaBraForItem,
} from '../helpers/screen-configuration-helper'

export const OrderService = {
    getCount(filter) {
        return api.get(`/orders/count`, {
            params: {
                filter: filter,
            },
        }, { trackThisPromise: false })
    },

    getOrder(orderId, aIncludes) {
        const sections = getSectionsForScreens([ORDER_AMENDMENT])
        let dynamicIncludeParams = getAllIncludeParamForSections(sections)
        dynamicIncludeParams = dynamicIncludeParams.map(
            (p) => 'includesOrderItem.' + p
        )

        let aDefaultIncludes = [
            'includesOrderItem',
            'includesOrderItem.linksOrderItemProduct:Product',
            'includesOrderItem.linksOrderItemProduct:Product.hasUnitofMeasure:UnitofMeasurement',
            'includesOrderItem.linksOrderItemProduct:Product.definesAnnotation',
            'orderBillTo:Address',
            'orderBillTo.hasCountry',
            'orderBillTo.hasCountryRegion',
            'orderShipTo:Address',
            'orderShipTo.hasCountry',
            'orderShipTo.hasCountryRegion',
        ]

        if (!isNilOrEmpty(aIncludes))
            aDefaultIncludes = aDefaultIncludes.concat(aIncludes)

        dynamicIncludeParams = dynamicIncludeParams.concat(aDefaultIncludes)
        let uniqueIncludeParams = [...new Set(dynamicIncludeParams)]

        return api.post(
            '/orders/search',
            {
                data: {
                    filter: 'uri eq ' + atob(orderId),
                    type: 'search',
                    include: uniqueIncludeParams,
                },
            },
            {
                headers: {
                    Authorization: AuthenticationService.getLocalStorageOAuthTokenValue(
                        'oauthToken'
                    ),
                },
            }
        )
    },

    getOrders() {
        return api
            .get('/orders', {
                headers: {
                    Authorization: AuthenticationService.getLocalStorageOAuthTokenValue(
                        'oauthToken'
                    ),
                },
            })
            .then((response) => {
                // If request is good...
                console.log(response.data)
            })
            .catch((error) => {
                console.log('error 3 ' + error)
            })
    },

    getSimulationReviewOrder(orderId, paramBDA, paramInclude, portalScreenConfigurationType) {

        const sections = getSectionsForScreens([portalScreenConfigurationType])
        let dynamicIncludeParams = null

        if (portalScreenConfigurationType === ORDER_REVIEW)
            dynamicIncludeParams = getAllIncludeParamForSections(sections)
        else if (portalScreenConfigurationType === ORDER_REORDER)
            dynamicIncludeParams = getAllIncludeParamForSectionsReorder(sections)
        else if (portalScreenConfigurationType === ORDER_AMENDMENT)
            dynamicIncludeParams = getAllIncludeParamForSectionsReorder(sections)

        let parsedDynamicIncludeParams = []

        for (let i = 0; i < dynamicIncludeParams.length; i++) {
            let parsedParam = dynamicIncludeParams[i].split(':')[0]

            if (!parsedParam.includes('linksOrderItemProduct'))
                parsedDynamicIncludeParams.push(parsedParam)
        }

        parsedDynamicIncludeParams = parsedDynamicIncludeParams.concat(paramInclude)

        let uniqueIncludeParams = [...new Set(parsedDynamicIncludeParams)]


        let data = {
            type: 'search',
            // filter: filter,
            include: uniqueIncludeParams,
            elements: {
                bda: paramBDA,
                bra: uniqueIncludeParams
            },

        }

        return api
            .post(`/orders/${orderId}/simulationOrder`,
                {
                    data,
                },
                {
                    headers: {
                        Authorization: AuthenticationService.getLocalStorageOAuthTokenValue(
                            'oauthToken'
                        ),
                    },
                    trackThisPromise: true,
                })
    },

    getPastOrdersAndProducts(aIncludes, filters, search) {
        let aDefaultIncludes = [
            'includesOrderItem',
            'includesOrderItem.linksOrderItemProduct',
            'includesOrderItem.linksOrderItemProduct.hasUnitofMeasure',
            'includesOrderItem.linksOrderItemProduct.definesAnnotation',
        ]

        if (!isNilOrEmpty(aIncludes))
            aDefaultIncludes = aDefaultIncludes.concat(aIncludes)

        let data = {
            type: 'search',
            include: aDefaultIncludes,
            sort: ['objectDateOfCreation DESC'],
        }

        if (!isNilOrEmpty(filters)) data['filter'] = filters

        if (!isNilOrEmpty(search)) data['search'] = search

        return api.post(
            '/orders/search',
            {
                data,
            },
            {
                headers: {
                    Authorization: AuthenticationService.getLocalStorageOAuthTokenValue(
                        'oauthToken'
                    ),
                },
                trackThisPromise: true,
            }
        )
    },

    getOrderHistory(aIncludes, filters, search) {
        const sections = getSectionsForScreens([ORDER_HISTORY_OVERVIEW])
        let dynamicIncludeParams = getAllIncludeParamForSections(sections)

        if (!isNilOrEmpty(aIncludes))
            dynamicIncludeParams = dynamicIncludeParams.concat(aIncludes)

        let uniqueIncludeParams = [...new Set(dynamicIncludeParams)]

        let data = {
            type: 'search',
            include: uniqueIncludeParams,
            sort: ['objectDateOfCreation DESC'],
        }

        if (!isNilOrEmpty(filters)) data['filter'] = filters

        if (!isNilOrEmpty(search)) data['search'] = search

        return api.post(
            '/orders/search',
            {
                data,
            },
            {
                headers: {
                    Authorization: AuthenticationService.getLocalStorageOAuthTokenValue(
                        'oauthToken'
                    ),
                },
                trackThisPromise: true,
            }
        )
    },

    getOrdersHistory(aIncludes, filters, search, aElements, aIncludeElements, aSections, aLimit, aOffset) {
        let finalIncludesParams = []
        let finalIncludElementsParam = {}
        let finalElementsParam = {}

        let dynamicIncludeParams = getAllIncludeParamForSections([aSections])
        let dynamicIncludeElements = getAllIncludeElementsForSections([aSections])

        if (!isNilOrEmpty(aIncludes)) {
            dynamicIncludeParams = dynamicIncludeParams.concat(aIncludes)
        }
        finalIncludesParams = [...new Set(dynamicIncludeParams)]

        if (!isNilOrEmpty(aIncludeElements)) {
            finalIncludElementsParam = mergeObject(dynamicIncludeElements.includeElements, aIncludeElements)
        }

        if (!isNilOrEmpty(aElements)) {
            finalElementsParam = mergeObject(dynamicIncludeElements.elements, aElements)
        }

        let data = {
            type: 'search',
            include: finalIncludesParams,
            sort: ['objectDateOfCreation DESC'],
            elements: finalElementsParam,
            includeElements: aIncludeElements,
            limit: aLimit,
            offset: aOffset
        }

        if (!isNilOrEmpty(filters)) data['filter'] = filters

        if (!isNilOrEmpty(search)) data['search'] = search

        return api.post(
            '/orders/search',
            {
                data,
            },
            {
                headers: {
                    Authorization: AuthenticationService.getLocalStorageOAuthTokenValue(
                        'oauthToken'
                    ),
                },
                trackThisPromise: true,
            }
        )
    },

    getOrderCreators(filter) {
        let data = {
            type: 'search',
            filter: filter,
            include: ['containsCreator'],
            sort: ['objectDateOfCreation DESC'],
            elements: {
                bda: [''],
                bra: ['containsCreator']
            }
            // ,includeElements: {
            //     containsCreator: {
            //         bda: ['personFirstName', 'personLastName', 'objectName'],
            //         bra: ['']
            //     }
            // }
        }

        return api.post(
            '/orders/search',
            {
                data,
            },
            {
                headers: {
                    Authorization: AuthenticationService.getLocalStorageOAuthTokenValue(
                        'oauthToken'
                    ),
                },
                trackThisPromise: true,
            }
        )
    },

    getOrderById(id, forScreens) {
        const sections = getSectionsForScreens(forScreens)
        let dynamicIncludeParams = getAllIncludeParamForSections(sections)
        dynamicIncludeParams = dynamicIncludeParams.map(

            (p) => {
                if (p != "hasOrderPaymentMode:OrderPaymentMode")
                    if (p.includes('linksOrderItemProduct'))
                        return 'includesOrderItem.' + p
                    else
                        return p
                else
                    return p
            }
        )

        let aDefaultIncludes = [
            'hasOrderStatus',
            'includesOrderItem',
            'includesOrderItem.linksOrderItemProduct:Product',
            'includesOrderItem.linksOrderItemProduct:Product.hasUnitofMeasure:UnitofMeasurement',
            'includesOrderItem.hasUnitofMeasure:UnitofMeasurement',
            'includesOrderItem.linksOrderItemProduct:Product.definesAnnotation',
            'orderBillTo:Address',
            'orderBillTo.hasCountry',
            'orderBillTo.hasCountryRegion',
            'orderShipTo:Address',
            'orderShipTo.hasCountry',
            'orderShipTo.hasCountryRegion',
        ]

        dynamicIncludeParams = dynamicIncludeParams.concat(aDefaultIncludes)
        let uniqueIncludeParams = [...new Set(dynamicIncludeParams)]

        let data = {
            type: 'search',
            filter: 'id eq ' + id,
            include: uniqueIncludeParams,
            sort: ['objectDateOfCreation DESC'],
        }

        return api.post(
            '/orders/search',
            {
                data,
            },
            {
                headers: {
                    Authorization: AuthenticationService.getLocalStorageOAuthTokenValue(
                        'oauthToken'
                    ),
                },
                trackThisPromise: true,
            }
        )
    },

    getPreviouslyOrdered(runInBackground) {
        let trackThisPromise = isNilOrEmpty(runInBackground) ? true : !runInBackground
        const forScreens = [PRODUCT_CARD_TILE_SCREEN_CONFIG]
        const sections = getSectionsForScreens(forScreens)
        let dynamicIncludeParams = getAllIncludeParamForSections(sections)
        dynamicIncludeParams = dynamicIncludeParams.map(
            (p) => 'includesOrderItem.linksOrderItemProduct:Product.' + p
        )

        let aDefaultIncludes = [
            'includesOrderItem',
            'includesOrderItem.linksOrderItemProduct:Product',
            'includesOrderItem.linksOrderItemProduct:Product.hasUnitofMeasure:UnitofMeasurement',
            'includesOrderItem.linksOrderItemProduct:Product.definesAnnotation',
        ]

        dynamicIncludeParams = dynamicIncludeParams.concat(aDefaultIncludes)
        let uniqueIncludeParams = [...new Set(dynamicIncludeParams)]

        let data = {
            type: 'search',
            include: uniqueIncludeParams,
            sort: ['objectDateOfCreation DESC'],
            elements: {
                bda: [
                    ''
                ],
                bra: [
                    'includesOrderItem'
                ]
            },
        }

        return api.post(
            '/orders/search',
            {
                data,
            },
            {
                headers: {
                    Authorization: AuthenticationService.getLocalStorageOAuthTokenValue(
                        'oauthToken'
                    ),
                },
                trackThisPromise: trackThisPromise,
            }
        )
    },

    getPreviouslyOrderedItemsByFilter({ filter = '' }) {
        return api.post(
            '/orders/filter',
            {
                data: {
                    type: 'Search',
                    filter: filter,
                    elements: {
                        bda: ['', ''],
                        bra: ['linksOrderItemProduct'],
                    },
                    sort: ['objectDateOfCreation DESC'],
                },
            },
            { trackThisPromise: false }
        )
    },

    getPreviouslyOrderedItems(payload = {}) {
        return api.post(
            '/products/search',
            {
                data: payload
            },
            { trackThisPromise: false }
        )
    },

    createOrder(payload, isSimulation) {
        let simulationMode = false

        if (!isNil(isSimulation)) simulationMode = isSimulation

        return api.post('/orders', {
            data: payload,
            params: {
                simulationMode,
            },
        })
    },

    createOrderFromCart() {
        return api.post('/orders/createFromCart', {
            params: {
                simulationMode: true,
            },
        })
    },

    createSelectedOrderFromCart(aCartItemIds, cartId, runInBackground) {
        return api.post(`/orders/${cartId}/createFromSelectedCartItems`, {
            data: aCartItemIds,
            params: {
                simulationMode: true,
            },
        }, { trackThisPromise: !runInBackground })
    },

    createOrderFromOrder(orderId, newOrder, runInBackground) {
        return api.post(`/orders/${orderId}/createFromOrder`, {
            data: newOrder,
            params: {
                simulationMode: true,
            },
        }, { trackThisPromise: !runInBackground })
    },

    getOrderInsimulation(orderId, runInBackground) {
        return api.get(`/orders/${orderId}`, {
            params: {
                simulationMode: true,
                resolveRelations: 'includesOrderItem,orderBillTo,orderBillTo.hasCountry,orderBillTo.hasCountryRegion,orderShipTo,orderShipTo.hasCountry,orderShipTo.hasCountryRegion',
            },
        }, { trackThisPromise: !runInBackground })
    },

    clearOrderInsimulation(orderId) {
        return api.delete(`/orders/${orderId}`, {
            params: {
                simulationMode: true,
            },
        })
    },

    placeOrder(orderId) {
        return api.patch(`/orders/${orderId}`, {
            data: {
                type: 'Order',
                hasOrderStatus: [
                    'http://www.inmindcloud.com/application/schema.owl#ORDER_COMPLETED',
                ],
            },
        })
    },

    rejectOrder(orderId) {
        return api.patch(`/orders/${orderId}`, {
            data: {
                type: 'Order',
                hasOrderStatus: [
                    'http://www.inmindcloud.com/application/schema.owl#ORDER_REJECTED',
                ],
            },
        })
    },

    updateOrder(orderId, payload, isSimulation, runInBackground, isReview) {
        let simulationMode = false

        if (!isNil(isSimulation)) simulationMode = isSimulation

        if (payload?.orderShipTo && payload.orderShipTo[0] == "Other")
            payload.orderShipTo = []

        if (payload?.orderBillTo && payload.orderBillTo[0] == "Other")
            payload.orderBillTo = []

        return api.patch(`/orders/${orderId}${isReview ? '/review' : ''}`, {
            data: payload,
            params: {
                simulationMode,
            },
        }, { trackThisPromise: !runInBackground })
    },

    deleteOrderItems(orderId, itemIDs, isSimulation) {
        let simulationMode = false

        if (!isNil(isSimulation)) simulationMode = isSimulation

        if (!Array.isArray(itemIDs)) itemIDs = [itemIDs]

        return api.delete(`/orders/${orderId}/items/${itemIDs}`, {
            params: {
                simulationMode,
            },
        })
    },

    updateOrderItem(orderId, itemId, payload, isSimulation) {
        let simulationMode = false

        if (!isNil(isSimulation)) simulationMode = isSimulation

        return api.patch(`/orders/${orderId}/item/${itemId}`, {
            data: payload,
            params: {
                simulationMode,
            },
        })
    },

    getCheckoutItems(productURIs, runInBackground) {
        const forScreens = [ORDER_CHECKOUT]
        const sections = getSectionsForScreens(forScreens)
        let dynamicIncludeParams = getAllIncludeParamForSections(sections)
        dynamicIncludeParams = dynamicIncludeParams.map((p) =>
            p.replace('linksOrderItemProduct:Product.', '')
        )
        dynamicIncludeParams = dynamicIncludeParams.map((p) =>
            p.replace('linksOrderItemProduct:Product', '')
        )
        dynamicIncludeParams = dynamicIncludeParams.filter(
            (p) => !isNilOrEmpty(p)
        )

        let aDefaultIncludes = [
            'hasUnitofMeasure:UnitofMeasurement',
            'definesAnnotation',
        ]

        dynamicIncludeParams = dynamicIncludeParams.concat(aDefaultIncludes)
        let uniqueIncludeParams = [...new Set(dynamicIncludeParams)]

        const filter = productURIs.map((uri) => `uri eq ${uri}`).join(' AND ')

        let defaultBdaBra = getDefaultBdaBraForItem()
        const includeElementsForSections = getAllIncludeElementsForSections(sections, 'linksOrderItemProduct:Product.')

        let data = {
            type: 'Search',
            filter: filter,
            include: uniqueIncludeParams,
        }

        let finalElementsParam = defaultBdaBra.elements
        if (!isNilOrEmpty(includeElementsForSections.elements)) {
            finalElementsParam = mergeObject(includeElementsForSections.elements, defaultBdaBra.elements)
            data = {
                ...data,
                elements: finalElementsParam
            }
        }

        let finalIncludElementsParam = defaultBdaBra.includeElements
        if (!isNilOrEmpty(includeElementsForSections.includeElements)) {
            finalIncludElementsParam = mergeObject(includeElementsForSections.includeElements, defaultBdaBra.includeElements)

            data = {
                ...data,
                includeElements: finalIncludElementsParam
            }
        }

        return api.post('/products/search', {
            data: data,
        }, { trackThisPromise: !runInBackground })
    },

    getReviewItems(productURIs, runInBackground) {
        const forScreens = [ORDER_REVIEW]
        const sections = getSectionsForScreens(forScreens)
        let dynamicIncludeParams = getAllIncludeParamForSections(sections)
        dynamicIncludeParams = dynamicIncludeParams.map((p) =>
            p.replace('linksOrderItemProduct:Product.', '')
        )
        dynamicIncludeParams = dynamicIncludeParams.map((p) =>
            p.replace('linksOrderItemProduct:Product', '')
        )
        dynamicIncludeParams = dynamicIncludeParams.filter(
            (p) => !isNilOrEmpty(p)
        )

        let aDefaultIncludes = [
            'hasUnitofMeasure:UnitofMeasurement',
            'definesAnnotation',
        ]

        dynamicIncludeParams = dynamicIncludeParams.concat(aDefaultIncludes)
        let uniqueIncludeParams = [...new Set(dynamicIncludeParams)]

        const filter = productURIs.map((uri) => `uri eq ${uri}`).join(' AND ')

        let defaultBdaBra = getDefaultBdaBraForItem()
        const includeElementsForSections = getAllIncludeElementsForSections(sections, 'linksOrderItemProduct:Product.')

        let data = {
            type: 'Search',
            filter: filter,
            include: uniqueIncludeParams,
        }

        let finalElementsParam = defaultBdaBra.elements
        if (!isNilOrEmpty(includeElementsForSections.elements)) {
            finalElementsParam = mergeObject(includeElementsForSections.elements, defaultBdaBra.elements)
            data = {
                ...data,
                elements: finalElementsParam
            }
        }

        let finalIncludElementsParam = defaultBdaBra.includeElements
        if (!isNilOrEmpty(includeElementsForSections.includeElements)) {
            finalIncludElementsParam = mergeObject(includeElementsForSections.includeElements, defaultBdaBra.includeElements)

            data = {
                ...data,
                includeElements: finalIncludElementsParam
            }
        }

        return api.post('/products/search', {
            data: data,
        }, { trackThisPromise: !runInBackground })
    },

    getProductPricing(orderId, runInBackground, refreshedPrice) {
        let trackThisPromise = isNilOrEmpty(runInBackground) ? true : !runInBackground
        let endPoint = refreshedPrice ? `/orders/${orderId}/price?refresh=true` : `/orders/${orderId}/price`
        return api.get(endPoint, {}, { trackThisPromise: trackThisPromise })
    },

    getAddresses(runInBackground) {
        let trackThisPromise = isNilOrEmpty(runInBackground) ? true : !runInBackground
        return api.get('/orders/partnerAddresses', {}, { trackThisPromise: trackThisPromise })
    },

    getEstimatedDeliveryDate(aCartItems, orderRequestedDate, runInBackground = false) {
        aCartItems["orderRequestedDate"] = orderRequestedDate

        return api.post(`/products/materialAvailability`, {
            data: aCartItems,
        }, { trackThisPromise: !runInBackground })
    },

    downloadOrder(orderBO, isCommerce) {
        return api.get('/orders/order/downloadOrderPdf', {
            responseType: "blob",
            headers: {
                'Content-Type': 'application/pdf',
            },
            params: {
                orderId: orderBO.uri,
                isCommerce: isCommerce,
            },
        })
    },

    updatePaymentStatus(orderId) {
        return api.post('/paymentGateway/updatePaymentStatus', {
            params: {
                orderId: orderId
            },
        })
    },
}
