import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useCookies } from 'react-cookie'
import { AuthenticationService } from '../../services'
import ForgotPasswordForm from './components/forgot-password-form'
import ChangePasswordForm from './components/change-password-form'
import './login-form.scss'
import store from 'store'
import {
    Grid,
    Box,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Button,
    Typography,
    Link,
    Checkbox,
    FormControlLabel,
    CircularProgress,
    InputAdornment,
    IconButton,
    OutlinedInput,
    Slide,
    FormHelperText,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'

import { makeStyles } from '@material-ui/core/styles'
import { TranslationService } from '../../services'
import { RememberMe as RememberMeHelper } from '../../helpers/remember-me-helper'
import { isNilOrEmpty, getObjectKey } from '../../helpers'
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },

    input: {
        marginBottom: '5px',
        fontSize: '0.7777777777777778rem',
        minHeight: '68px',
        '&:last-child': {
            marginBottom: 0
        }
    },
    forgotPasswordLink: {
        textAlign: 'right',
    },
    formTitle: {
        paddingBottom: '3px',
        marginBottom: theme.spacing(2),
    },
    loginHelperTextContainer: {
        minHeight: '17px'
    },
    loginHelperText: {
        fontSize: '12px',
        marginLeft: '14px'
    },
    loginButtonWrapper: {
        paddingTop: theme.spacing(1),
    },
    loginButton: {
        fontSize: '1.125rem',
        textTransform: 'none',
    },
    signupText: {
        margin: '10px auto 0 auto'
    }
}))

const LoginForm = (props) => {
    const classes = useStyles()
    const { t } = TranslationService.useTranslation()

    const [formData, setFormData] = useState({
        userName: { value: '', required: true, error: false, helperText: '' },
        password: { value: '', required: true, error: false, helperText: '' },
    })

    const [cookies, setCookie, removeCookie] = useCookies(['portalRememberMe'])
    const [forgotPasswordFormView, setForgotPasswordFormView] = useState(null)
    const [changePasswordFormView, setChangePasswordFormView] = useState(null)
    const [rememberMeCheckedValue, setRememberMeCheckedValue] = useState(false)
    const [authenticationSuccess, setAuthenticationSuccess] = useState(false)
    const [readOnly, setReadOnly] = useState(true)
    const isSelfRegistrationEnabled = useSelector(state => state.signUpReducer.isSelfRegistrationEnabled);

    //on load, we retrieve local rememberme value. then populate checkbox and form fields accordingly.
    useEffect(() => {
        if (window.location.href.indexOf('login?key=') != -1) {
            //direct to chg pw
            setChangePasswordFormView(true)
        }

        // let rememberMeObject = RememberMeHelper.getRememberMeLocalStorageValue()
        const rememberMeUser = getObjectKey(cookies, 'portalRememberMe')
        if (!isNilOrEmpty(rememberMeUser)) {
            {
                setReadOnlyFalse()
                setRememberMeCheckedValue(true)
                setFormData({
                    ...formData,
                    userName: {
                        ...formData.userName,
                        value: rememberMeUser,
                    },
                    password: {
                        ...formData.password,
                        value: '',
                    },
                })
            }
        } else setRememberMeCheckedValue(false)
    }, [])

    //we only set remember me when authentication is successful
    useEffect(() => {
        if (authenticationSuccess) {
            if (rememberMeCheckedValue) {
                setCookie('portalRememberMe', formData.userName.value, {
                    path: '/',
                    maxAge: 1440, // 4 hours
                })
                // RememberMeHelper.setLocalStorageValue({
                //     userName: formData.userName.value,
                //     password: formData.password.value,
                // })
            }
        }
    }, [authenticationSuccess])

    //we remove local when user uncheck it.
    useEffect(() => {
        if (!rememberMeCheckedValue) {
            RememberMeHelper.clearLocalStorageValue()
        }
    }, [rememberMeCheckedValue])

    const [loginButtonData, setLoginButtonData] = useState({
        text: t('general.login'),
        loading: false,
        disabled: false,
        error: false,
    })

    const [showPassword, setShowPassword] = useState(false)

    const loginUserNameInput = useRef()
    const passwordInputRef = useRef()
    useEffect(() => {
        if (
            !forgotPasswordFormView &&
            typeof forgotPasswordFormView === 'boolean'
        )
            loginUserNameInput.current.focus()
    }, [forgotPasswordFormView])

    /**
     *
     * @param {*} type
     * @param {*} formDataToUse
     */
    const validateAttribute = (type = '', formDataToUse) => {
        let copyFormData = {}
        let errorArray = []
        let dataToProcess = formDataToUse

        if (formDataToUse) dataToProcess = formDataToUse

        for (let typeKey in dataToProcess) {
            let error = false
            let helperText = ''

            if (type && type !== typeKey) continue

            if (dataToProcess[typeKey].required) {
                if (!dataToProcess[typeKey].value) error = true

                if (error) {
                    helperText = t(`validation.input.` + typeKey + `.invalid`)
                }
            }

            if (type) copyFormData = { ...dataToProcess }
            else copyFormData = { ...copyFormData }

            copyFormData = {
                ...copyFormData,
                [typeKey]: {
                    ...dataToProcess[typeKey],
                    error: error,
                    helperText: helperText,
                },
            }

            if (error) {
                errorArray.push({
                    type: typeKey,
                })
            }
        }

        return {
            data: copyFormData,
            error: errorArray,
        }
    }

    const loginButtonRef = useRef()
    function checkParentContainsId(dom, id, level) {
        let capLevel = 4

        if (!dom) return undefined

        if (!level) level = 1
        else level++

        if (level >= capLevel) {
            return undefined
        }
        if (dom.id === id) {
            return dom
        } else {
            dom = checkParentContainsId(dom.parentElement, id, level)
            if (dom) return dom
        }

        return undefined
    }
    useEffect(() => {
        //loginUserNameInput.current.focus()

        document.onmouseup = function (event) {
            setTimeout(() => {
                let foundDOM = checkParentContainsId(
                    event.target,
                    'loginButton'
                )
                if (foundDOM) {
                    if (typeof foundDOM.click === 'function') foundDOM.click()
                }
            }, 0)
        }

        return () => {
            document.onmouseup = null
        }
    }, [])

    const handleUsernameInputChange = (event) => {
        setReadOnlyFalse()
        let value = event.target.value
        let copyFormData

        copyFormData = {
            ...formData,
            userName: { ...formData.userName, value: value },
        }

        let copyData = validateAttribute('userName', copyFormData).data
        setFormData(copyData)
    }

    const handlePasswordInputChange = (event) => {
        let value = event.target.value
        let copyFormData

        copyFormData = {
            ...formData,
            password: { ...formData.password, value: value },
        }

        let copyData = validateAttribute('password', copyFormData).data
        setFormData(copyData)
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }
    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    const PasswordVisibilityIcon = (props) => {
        if (showPassword)
            return <Visibility color={props.color} fontSize="small" />

        return <VisibilityOff color={props.color} fontSize="small" />
    }

    const handleLoginClick = (event) => {
        let helperSetLoginButtonData = (
            loading = false,
            disabled = false,
            helperText = ''
        ) => {
            setLoginButtonData({
                ...loginButtonData,
                loading: loading,
                disabled: disabled,
                helperText: helperText,
            })
        }

        helperSetLoginButtonData(true, true)

        let response = validateAttribute(null, formData)
        let errorArray = response.error

        setFormData(response.data)

        if (errorArray.length) {
            //show error
            helperSetLoginButtonData(false, false)
        } else {
            //proceed to authenticate

            AuthenticationService.authenticate({
                username: formData.userName.value,
                password: formData.password.value,
            }).then(
                (response) => {
                    helperSetLoginButtonData(false, true)
                    AuthenticationService.registerSuccessfulLogin(
                        formData.userName.value,
                        response.data
                    )

                    setAuthenticationSuccess(true)
                    props.authenticateSuccess()
                },
                (res) => {
                    let errorMsg = t('general.invalidCredentials')
                    const errorDescription = getObjectKey(
                        res,
                        'response/data/error_description'
                    )

                    if (!isNilOrEmpty(errorDescription))
                        errorMsg = errorDescription

                    setAuthenticationSuccess(false)
                    helperSetLoginButtonData(false, false, errorMsg)
                }
            )
        }
    }

    const loginWithEnter = (e) => {
        if (e.keyCode === 13) handleLoginClick()
    }

    const LoginHelperText = () => {
        return (
            <Typography
                align="right"
                variant="body2"
                noWrap={true}
                color="error"
                className={classes.loginHelperText}
                title={loginButtonData.helperText}
            >
                {loginButtonData.helperText}
            </Typography>
        )
    }

    const LoginButton = () => {
        const ButtonContent = () => {
            if (loginButtonData.loading) {
                return <CircularProgress size={20} />
            } else {
                return (
                    <Typography variant="body1">
                        {loginButtonData.text}
                    </Typography>
                )
            }
        }
        return (
            <>
                <Button
                    tabIndex={2}
                    ref={loginButtonRef}
                    id="loginButton"
                    type="submit"
                    color="primary"
                    variant="contained"
                    disableElevation
                    fullWidth
                    onClick={handleLoginClick}
                    disabled={loginButtonData.disabled}
                    className={classes.loginButton}
                >
                    <ButtonContent />
                </Button>
            </>
        )
    }

    const setReadOnlyFalse = () => {
        if (readOnly) {
            setReadOnly(false)
        }
    }

    const rememberMeCheckboxChange = (event) => {
        let checkedValue = event.currentTarget.checked

        if (!checkedValue) removeCookie('portalRememberMe')

        setRememberMeCheckedValue(checkedValue)
    }
    const RememberMe = () => {
        if (props.showRememberMe)
            return (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={rememberMeCheckedValue}
                            onChange={rememberMeCheckboxChange}
                            disableRipple
                            color="primary"
                            name={t('general.rememberMe')}
                        />
                    }
                    label={
                        <Typography variant="caption">
                            {t('general.rememberMe')}
                        </Typography>
                    }
                />
            )
        else return null
    }

    const ForgotPasswordLink = (props) => {
        const handleForgotPasswordClick = (event) => {
            setForgotPasswordFormView(true)
        }

        if (props.showForgotPassword)
            return (
                <Link
                    className={classes.forgotPasswordLink}
                    onClick={handleForgotPasswordClick}
                    style={{ cursor: 'pointer' }}
                    noWrap={true}
                >
                    <Typography variant="caption" noWrap={true}>
                        {t('general.forgotYourPassword')}
                    </Typography>
                </Link>
            )
        else return null
    }

    ForgotPasswordLink.defaultProps = {
        showForgotPassword: true,
    }

    ForgotPasswordLink.propTypes = {
        showForgotPassword: PropTypes.bool,
    }

    return (
        <div style={{ position: 'relative' }}>
            <Slide
                direction="right"
                in={!forgotPasswordFormView && !changePasswordFormView}
            >
                <Box style={{ position: 'relative', margin: 20 }}>
                    <Typography
                        variant="h3"
                        className={classes.formTitle}
                        noWrap={true}
                        title={t('general.login')}
                    >
                        {t('general.login')}
                    </Typography>

                    <form noValidate>
                        <TextField
                            id="userName"
                            inputRef={loginUserNameInput}
                            placeholder={t('general.username')}
                            label=""
                            autoFocus
                            variant="outlined"
                            size="small"
                            color="primary"
                            fullWidth
                            className={classes.input}
                            value={formData.userName.value}
                            onInput={handleUsernameInputChange}
                            error={formData.userName.error}
                            helperText={formData.userName.helperText}
                            onKeyDown={loginWithEnter}
                            onClick={setReadOnlyFalse}
                        />
                        <FormControl
                            variant="outlined"
                            fullWidth
                            size="small"
                            color="primary"
                            className={classes.input}
                        >
                            <OutlinedInput
                                id="password"
                                inputRef={passwordInputRef}
                                placeholder={t('general.password')}
                                type={showPassword ? 'text' : 'password'}
                                value={formData.password.value}
                                onKeyDown={loginWithEnter}
                                onInput={handlePasswordInputChange}
                                error={formData.password.error}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClickShowPassword}
                                            onMouseDown={
                                                handleMouseDownPassword
                                            }
                                            edge="end"
                                        >
                                            <PasswordVisibilityIcon color="primary" />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                inputProps={{
                                    readOnly: readOnly,
                                }}
                                onFocus={setReadOnlyFalse}
                            />
                            <FormHelperText error>
                                {formData.password.helperText}
                            </FormHelperText>
                        </FormControl>
                    </form>

                    <Grid container>
                        <Grid
                            container
                            item
                        >
                            <Grid
                                container
                                item
                                xs={12}
                                className={classes.loginHelperTextContainer}
                                alignContent="center"
                            >
                                <LoginHelperText />
                            </Grid>
                            <Grid container item xs={6} justify="flex-start">
                                <RememberMe />
                            </Grid>
                            <Grid
                                container
                                item
                                xs={6}
                                justify="flex-end"
                                alignContent="center"
                            >
                                <ForgotPasswordLink
                                    showForgotPassword={
                                        props.showForgotPassword
                                    }
                                />
                            </Grid>
                        </Grid>

                        <Grid container className={classes.loginButtonWrapper}>
                            <Grid item xs={12}>
                                <LoginButton />
                            </Grid>
                            {isSelfRegistrationEnabled && <Typography color = 'textPrimary' className = {classes.signupText}>{t('general.newUser')}<Link color = 'primary' href = '#/selfRegistration'>{t('general.signup')}</Link></Typography>}
                        </Grid>
                    </Grid>
                </Box>
            </Slide>
            <Slide direction="left" in={forgotPasswordFormView}>
                <Box style={{ position: 'absolute', width: '100%', top: 0 }}>
                    <ForgotPasswordForm
                        onBackButtonClick={() => {
                            setForgotPasswordFormView(false)
                        }}
                        show={forgotPasswordFormView}
                        validateAttribute={validateAttribute}
                    />
                </Box>
            </Slide>
            <Slide in={changePasswordFormView}>
                <Box style={{ position: 'absolute', width: '100%', top: 0 }}>
                    <ChangePasswordForm
                        onBackButtonClick={() => {
                            setForgotPasswordFormView(false)
                            setChangePasswordFormView(false)
                        }}
                        show={changePasswordFormView}
                        validateAttribute={validateAttribute}
                    />
                </Box>
            </Slide>
        </div>
    )
}

LoginForm.defaultProps = {
    authenticateSuccess: () => {},
    authenticateError: () => {},

    showRememberMe: true,
    showForgotPassword: true,
}
LoginForm.propTypes = {
    authenticateSuccess: PropTypes.func,
    authenticateError: PropTypes.func,

    showRememberMe: PropTypes.bool,
    showForgotPassword: PropTypes.bool,
}
export default LoginForm
