import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { UserService, TranslationService } from '../../../../services'
import {
    Grid,
    IconButton,
    Typography,
    TextField,
    CircularProgress,
    Button,
    Box,
    FormControl,
    OutlinedInput,
    InputAdornment,
} from '@material-ui/core'

import { ChevronLeft, Visibility, VisibilityOff } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { useSnackbar } from '../../../../helpers'

const useStyles = makeStyles((theme) => ({
    input: {
        marginBottom: theme.spacing(2),
        fontSize: '1.125rem',
    },

    forgotPasswordMessage: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        maxHeight: '6rem',
        overflowY: 'auto',
    },
    forgotPasswordButton: {
        fontSize: '1.125rem',
        textTransform: 'none',
    },
    forgotPasswordSubmitEmailHelperText: {
        marginTop: theme.spacing(2),
    },
}))

const ChangePasswordForm = (props) => {
    const { t } = TranslationService.useTranslation()
    const classes = useStyles()
    const passwordInputRef = useRef()
    const [showNewPassword, setShowNewPassword] = useState(false)
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false)

    const validateAttribute = props.validateAttribute

    const [changePasswordData, setChangePasswordData] = useState({
        text: t('general.send'),
        loading: false,
        disabled: false,
    })
    const [changePasswordFormData, setChangePasswordFormData] = useState({
        newPassword: {
            value: '',
            required: true,
            error: false,
            helperText: '',
        },
        confirmNewPassword: {
            value: '',
            required: true,
            error: false,
            helperText: '',
        },
    })

    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        UserService.checkKey().then((response) => {
            if (response.data.success);
            else {
                setChangePasswordData({
                    ...changePasswordData,
                    disabled: true,
                    helperText:
                        'Reset password link not valid anymore. Please request a new one.',
                })

                // let messageType = 'error'
                // let message = 'invalid url'
                // enqueueSnackbar(
                //     message,
                //     {
                //         variant: messageType,
                //     }
                // )
            }
        })
    }, [])

    const handleForgotPasswordNewPasswordInputChange = (event) => {
        let value = event.target.value
        let copyRequestPasswordFormData

        copyRequestPasswordFormData = {
            ...changePasswordFormData,
            newPassword: {
                ...changePasswordFormData.newPassword,
                value: value,
            },
        }

        let data = validateAttribute('newPassword', copyRequestPasswordFormData)
            .data

        setChangePasswordFormData(data)
    }

    const handleForgotPasswordConfirmNewPasswordInputChange = (event) => {
        let value = event.target.value
        let copyRequestPasswordFormData

        copyRequestPasswordFormData = {
            ...changePasswordFormData,
            confirmNewPassword: {
                ...changePasswordFormData.confirmNewPassword,
                value: value,
            },
        }

        let data = validateAttribute(
            'confirmNewPassword',
            copyRequestPasswordFormData
        ).data

        setChangePasswordFormData(data)
    }

    const handleClickShowNewPassword = () => {
        setShowNewPassword(!showNewPassword)
    }

    const handleClickShowConfirmNewPassword = () => {
        setShowConfirmNewPassword(!showConfirmNewPassword)
    }

    const PasswordVisibilityIcon = (props) => {
        if (showNewPassword)
            return <Visibility color={props.color} fontSize="small" />

        return <VisibilityOff color={props.color} fontSize="small" />
    }

    const handleForgotPasswordRequestClick = (event) => {
        event.preventDefault()
        let helperSetRequestPasswordData = (
            loading = false,
            disabled = false,
            helperText = ''
        ) => {
            setChangePasswordData({
                ...changePasswordData,
                loading: loading,
                disabled: disabled,
                helperText: helperText,
            })
        }
        //let response = validateAttribute(null, changePasswordFormData)
        //let errorArray = response.error

        //setChangePasswordFormData(response.data)

        if (
            changePasswordFormData.newPassword.value ==
            changePasswordFormData.confirmNewPassword.value
        ) {
            // run change pw
            UserService.changePassword(
                changePasswordFormData.newPassword.value
            ).then((response) => {
                if (response.data.success == 'yes') {
                    props.onBackButtonClick()
                    let messageType = 'success'
                    let message = 'password changed'
                    enqueueSnackbar(message, {
                        variant: messageType,
                    })
                } else {
                    let error = response.data.error.replace('\n', '')
                    setChangePasswordData({
                        ...changePasswordData,
                        helperText: error,
                    })
                }
            })
        } else {
            setChangePasswordData({
                ...changePasswordData,
                helperText: 'Passwords do not match',
            })
        }

        // if (errorArray.length) {
        //     //show error
        //     helperSetRequestPasswordData(false, false)
        // } else {
        //     helperSetRequestPasswordData(true, true)

        //     UserService.forgotPassword({
        //         newPassword: changePasswordFormData.newPassword.value,
        //     })
        //         .then((response) => {
        //             let messageType = 'warning'
        //             let message = ''
        //             let success = false
        //             if (response.data === 'Wrong credentials') {
        //                 messageType = 'error'
        //                 message = t('general.forgotYourPasswordRetrieve.invalidUserName')
        //             }
        //             else if (response.data === 'Email sent') {
        //                 success = true
        //                 messageType = 'success'
        //                 message = t('general.forgotYourPasswordRetrieve.emailSuccessSent')
        //             }

        //             enqueueSnackbar(
        //                 message,
        //                 {
        //                     variant: messageType,
        //                 }
        //             )

        //             helperSetRequestPasswordData(false, false, message)
        //             if(success)
        //                 ;// success
        //         })
        //         .catch(() => {
        //             helperSetRequestPasswordData(
        //                 false,
        //                 false,
        //                 t('general.forgotYourPasswordRetrieve.errorMessage')
        //             )
        //         })
        // }
    }

    const ForgotPasswordSubmitEmailButton = () => {
        const ButtonContent = () => {
            if (changePasswordData.loading) {
                return <CircularProgress color="secondary" size={20} />
            } else {
                return (
                    <Typography variant="body1">
                        {changePasswordData.text}
                    </Typography>
                )
            }
        }
        return (
            <>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disableElevation
                    fullWidth
                    onClick={handleForgotPasswordRequestClick}
                    disabled={changePasswordData.disabled}
                    className={classes.forgotPasswordButton}
                >
                    <ButtonContent />
                </Button>
            </>
        )
    }

    const handleBackButtonClick = () => {
        props.onBackButtonClick()
    }

    const loginWithEnter = (e) => {
        if (e.keyCode === 13) handleForgotPasswordRequestClick()
    }

    const ChangePasswordSubmitEmailHelperText = () => {
        return (
            <Typography
                align="left"
                variant="body2"
                color="error"
                className={classes.changePasswordSubmitEmailHelperText}
                title={changePasswordData.helperText}
            >
                {changePasswordData.helperText}
            </Typography>
        )
    }

    return (
        <Box style={{ position: 'relative' }}>
            <Grid container alignItems="center" justify="flex-start">
                <Grid container item xs={12} alignItems="center">
                    <IconButton onClick={handleBackButtonClick}>
                        <ChevronLeft />
                    </IconButton>
                    <Typography
                        variant="h2"
                        noWrap={true}
                        style={{ maxWidth: '84%' }}
                        title={t('general.forgotYourPasswordRetrieve.title')}
                    >
                        {t('general.forgotYourPasswordRetrieve.title')}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Typography
                        variant="body1"
                        className={classes.forgotPasswordMessage}
                        title={t('general.forgotYourPasswordRetrieve.message')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <form noValidate autoComplete="off">
                        <FormControl
                            variant="outlined"
                            fullWidth
                            size="small"
                            color="primary"
                            className={classes.input}
                        >
                            <OutlinedInput
                                id="newPassword"
                                inputRef={passwordInputRef}
                                placeholder={'New Password'}
                                type={showNewPassword ? 'text' : 'password'}
                                value={changePasswordFormData.newPassword.value}
                                onInput={
                                    handleForgotPasswordNewPasswordInputChange
                                }
                                disabled={changePasswordData.disabled}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClickShowNewPassword}
                                            edge="end"
                                        >
                                            <PasswordVisibilityIcon color="primary" />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>

                        <FormControl
                            variant="outlined"
                            fullWidth
                            size="small"
                            color="primary"
                            className={classes.input}
                        >
                            <OutlinedInput
                                id="confirmNewPassword"
                                inputRef={passwordInputRef}
                                placeholder={'Confirm New Password'}
                                type={
                                    showConfirmNewPassword ? 'text' : 'password'
                                }
                                value={
                                    changePasswordFormData.confirmNewPassword
                                        .value
                                }
                                onInput={
                                    handleForgotPasswordConfirmNewPasswordInputChange
                                }
                                onKeyDown={loginWithEnter}
                                disabled={changePasswordData.disabled}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={
                                                handleClickShowConfirmNewPassword
                                            }
                                            edge="end"
                                        >
                                            <PasswordVisibilityIcon color="primary" />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>

                        <ForgotPasswordSubmitEmailButton></ForgotPasswordSubmitEmailButton>
                        <ChangePasswordSubmitEmailHelperText />
                    </form>
                </Grid>
            </Grid>
        </Box>
    )
}

ChangePasswordForm.propTypes = {
    onBackButtonClick: PropTypes.func,
    validateAttribute: PropTypes.func,
}

export default ChangePasswordForm
